import VueApexCharts from "vue3-apexcharts"

export default {
  name: 'dashboard-mutirao',
  components: {
    'apexchart': VueApexCharts
  },
  data: () => ({
    mutiraoName: "",
    examined: undefined,
    patientTotal: undefined,
    lastUpdate: undefined,
    lastUpdateMutirao: undefined,
    referredCanvas: undefined,
    unreferredCanvas: undefined,
    examinedCountText: undefined,
    totalPatientText: null,

    totalExamCountText: null,
    dateHour: undefined,
    toggleTheme: false,
    isConnected: false,

    donutSeries: [0, 0],
    chartDonutOptions: {
      chart: {
        width: 380,
        type: 'donut',
      },
      dataLabels: {
        enabled: false,
        name: {
          show: false
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 280
          },
          legend: {
            show: false
          }
        }
      }],
      legend: {
        show: false,
      },
      fill: {
        colors: ['#337ab7', '#2abb98']
      }
    },

    gaugeSeries: [0], // examinedCount / examTotal
    chartGaugeOptions: {
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#dddddd",
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          },
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 260
          },
          legend: {
            show: false
          }
        }
      }],
      legend: {
        show: false,
      },
      grid: {
        padding: {
          top: -10
        },
      },
      fill: {
        colors: "#337ab7",
      },
    }

  }),
  mounted() {
    this.$nextTick(() => {
      window.dispatchEvent(new Event('resize')); // quickfix -> remove duplicated donut
    })

    this.referredCanvas = 0.1
    this.unreferredCanvas = 0.1 
    this.gaugeValue = 120

    this.donutSeries = [this.referredCanvas, this.unreferredCanvas]

    this.startDateAndTime()
    this.keepAlive();
    this.initializeWebsocket();
  },
  methods: {
    startDateAndTime() {
      const zeroFill = n => {
        return ('0' + n).slice(-2);
      }
  
      setInterval(() => {
        const now = new Date();
        // TODO use moment.format instead (format from locale)
        const dataHora =
        zeroFill(now.getUTCDate()) + '/' + zeroFill((now.getMonth() + 1)) + '/' + now.getFullYear() + ' ' +
        zeroFill(now.getHours()) + ':' + zeroFill(now.getMinutes()) + ':' + zeroFill(now.getSeconds());
  
        this.dateHour = dataHora;
      }, 1000);
    },
    keepAlive: async function() {
      setTimeout(async () => {
        try {
          await this.$mutiraoService.keepAlive();
          this.isConnected = true
        } catch (err) {
          this.isConnected = false
        }
        this.keepAlive()
      }, 1000 * 30)
    },
    initializeWebsocket() {
      let io = this.$io;

      for (let e of ['mutirao_listening', 'mutirao_updated']) {
        console.log('[mutirao] unlisten to ws event:', e)
        io.socket.off(e);
      }

      if (io.socket.isConnected()) {
        console.log("io socket connection active, listening now!")
        this.listenEvents(io)
        return
      }

      io.socket.on('connect', () => {
        this.isConnected = true
        this.listenEvents(io);
      });

      io.socket.on('disconnect', () => {
        this.isConnected = false
      });

    },

    listenEvents(io) {
      io.socket.get('/api/v2/eyercloud/clinic/mutirao/listen', function gotResponse(body, response) {
        console.log('* [mutirao] listen response:', body);
      });

      io.socket.on('mutirao_listening', data => {
        console.log("Mutirao Registration: " + JSON.stringify(data));
        this.isConnected = true
      });

      io.socket.on('mutirao_updated', data => {
        console.log("mutirao_updated:" + JSON.stringify(data));
        if (!data.result) {
          console.log("result is missing, skipping...")
          return
        }
        
        let mutirao = data.result
        this.mutiraoName = mutirao.name

        this.referredCanvas = mutirao.referredExamCount
        this.unreferredCanvas = mutirao.unreferredExamCount
        this.donutSeries[0] = this.referredCanvas
        this.donutSeries[1] = this.unreferredCanvas

        this.examined = mutirao.examinedCount
        this.patientTotal = mutirao.patientTotal

        this.lastUpdate = data.date ? data.date : this.dateHour
        this.lastUpdateMutirao = moment(data.date).format('DD/MM/YYYY HH:mm:ss')

        let examCompleted = (mutirao.examinedCount / mutirao.examTotal) * 100
        this.gaugeSeries[0] = Number(examCompleted).toFixed(0)

        this.totalPatientText = mutirao.patientTotal
        this.examinedCountText = mutirao.examinedCount
        this.totalExamCountText = mutirao.examTotal
      })
    },
  }
}
