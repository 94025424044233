<template>
  <BaseInfiniteTable class="no-border exam-table" v-if="table_exam" :tabledata="table_exam"
    @item-opened="$emit('item-opened')" @loadmore="$emit('loadmore')" v-bind="$attrs">
    <template v-slot:column_name="{ item }">
      <div class="patient-box list" :class="{ 'anonymous': isJokerPatient(item) }">
        <a href="#" @click.prevent.stop="isJokerPatient(item) ? $emit('do-assign', item) : toPatientDetails(item)">
          <span>{{ isJokerPatient(item) ? $t('noPatientSelected') : item.patient.fullName }}</span>
        </a>
      </div>
    </template>

    <template v-slot:column_captures="{ item }">
      <div class="preview-image-box" v-if="item.examImages.length" :data-title="$t('exam.iaText')">
        <ul class="preview-image-list">
          <template v-for="(image, index) in item.examImages">
            <li v-if="allowedImages(image.type) && index < 3">
              <img :src="thumb_path + '/' + image.name" alt="">
            </li>
          </template>
          <li class="counter" v-if="item.examImages.length > 2">+{{ item.examImages.length - 3 }}</li>
        </ul>
      </div>
      <p class="no-captures" v-else>{{ $t('exam.noCaptures') }}</p>
    </template>

    <template v-slot:column_content="{ item }">
      <div class="d-inline-block">
        <span v-show="item.examType.key == 'patient_progression'" class="label label-blue-phelcom"
          :data-title="$t(`exam.examTypeName_${item.examType.key}`)">{{ $t(`exam.examType_${item.examType.key}`)
          }}</span>

        <button class="no-btn" type="button" name="button" data-title="Download"
          :data-test="`btnExamDownload_${item.uuid}`" @click.stop.prevent="doDownload(item)">
          <IconDownload />
        </button>
        <button class="no-btn" :disabled="!item.hasImage" type="button" name="button" :data-title="$t('tooltips.image')"
          :data-test="`btnExamHasImage_${item.uuid}`">
          <IconImage />
        </button>
        <button class="no-btn" :disabled="!item.hasReport" type="button" name="button"
          :data-title="$t('tooltips.report')" :data-test="`btnExamHasReport_${item.uuid}`" @click.stop.prevent="doDownloadPDF(item)">
          <IconPdf />
        </button>
        <i v-if="showMutirao" v-show="item.referred > 0" class="fa"
          :class="item.referred == 1 ? 'fa-tint' : 'fa-check-circle-o'" aria-hidden="true"></i>
      </div>
    </template>

    <template v-slot:column_ai="{ item }">
      <div style="display: flex; gap: 10px; align-items: center;">

        <div class="ai-box" v-if="item.scoreResult" :data-title="$t('exam.iaText')">
          <p>AI</p>
          <span :class="item.scoreResult.odStatus ?? ''"></span>
          <span :class="item.scoreResult.osStatus ?? ''"></span>
        </div>
        <div class="alteration-list" v-if="showMutirao && item?.scoreResult?.alterationText">
          <template v-for="text of item.scoreResult.alterationText">
            <b v-if="text === 'clinicConfig.mcRAS_c1'">
              {{ $t(text) }}
            </b>
            <span v-else>
              {{ $t(text) }}
            </span>
          </template>
        </div>
      </div>
    </template>

  </BaseInfiniteTable>
</template>

<style lang="scss" scoped>
.fa {
  font-size: 22px !important;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-inline-block {
  display: flex !important;
  flex-direction: row;
  // gap: 2px;
  align-items: center;
}

.alteration-list {
  display: flex;
  flex-direction: column;
  color: #101010;
  font-size: 0.9em;
  align-self: center;
  gap: 5px;

  b {
    order: 0;

    &:last-child {
      order: 1;
    }
  }

  span {
    order: 2;
  }
}

.preview-image-box {
  ul {
    display: flex;
    align-items: center;
    flex-direction: row;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      width: 31px;
      height: 26px;

      img {
        @include border_radius(99px);
        width: 100%;
        height: 100%;
        border: 2px solid var(--Shades-W, #FFF);
      }

      &:not(:first-child) {
        margin-left: -5px;
      }

      &.counter {
        @include border_radius(99px);
        color: var(--Shades-60, #727272);
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        line-height: 2;
        letter-spacing: 0.6px;
        border: 1px solid var(--Shades-40, #B7B7B7);
        background: var(--Shades-W, #FFF);
      }
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'ExamTableList',
  props: ['table_exam', 'thumb_path'],
  data: () => ({
    session: {
      collaborator: {},
      currentClinic: {},
      showMutirao: false,
    },
  }),
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.showMutirao = this.session.features.includes('mutirao')
    console.log('table_exam', this.table_exam);
  },
  methods: {
    doDownload(exam) {
      try {
        NProgress.start()
        this.$examService.download(exam.id)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
    },
    toPatientDetails(item) {
      this.$router.push({ name: 'patient-show', params: { patientID: item.patient.id }, query: { tab: 'data' } })
    },
    isJokerPatient(item) {
      return item.patient.uuid == this.session.currentClinic.jokerPatientUuid
    },
    allowedImages(image) {
      // return image.endsWith('COLOR') ||
      //   image.endsWith('REDFREE') ||
      //   image.endsWith('redfree') ||
      //   image.endsWith('TEXTURE') ||
      //   image.endsWith('HEATMAP') ||
      //   image.endsWith('heatmap') ||
      //   image.endsWith('image') &&
      return !image.endsWith('VIDEO')
    },
    async doDownloadPDF(item){
      console.log('item: ', item)
      let params = {
        id: item.id,
      }
      try {
        let response = await this.$reportService.getList(params);

        if (response.result.length > 0) {
          let theReport = response.result[response.result.length - 1]
          let params = {
            id: theReport.id
          }
          this.$reportService.generateReport(params);
        }

      } catch(err) {
         this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }

    }
  }
}
</script>
