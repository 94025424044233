<template>
  <div class="" v-loader.opacity.tab="loading">
    <div class="row justify-content-md-start">
      <div class="col-md-4">
        <ul class="anchor-menu">
          <li :class="{'active': activeAnchor === 'general'}">
            <a  @click="setActiveAnchor('general')">{{ $t('patient.patientInfo') }}</a>
          </li>
          <!-- <li :class="{'active': activeAnchor === 'patientInfo'}">
            <a href="#patientInfo" @click="setActiveAnchor('patientInfo')">{{ $t('patient.patientInfo') }}</a>
          </li> -->
          <li :class="{'active': activeAnchor === 'underlying'}">
            <a  @click="setActiveAnchor('underlying')">{{ $t('patient.underlyingDisease') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'ophthalmic'}">
            <a  @click="setActiveAnchor('ophthalmic')">{{ $t('patient.ophthalmicDiseases') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'other'}">
            <a  @click="setActiveAnchor('other')">{{ $t('patient.other') }}</a>
          </li>
        </ul>
      </div>

      <div class="col-md-8 offset-md-4 scrollbar" id="sectionsBox" ref="scrollContainer" v-if="patient">
        <div class="alert" :class="alert.class" role="alert" v-if="alert.message || alert_msg.message">
          <span v-html="alert.message"></span>
          <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
        </div>

        <section id="general">
          <div class="form-check">
            <BaseSquareCheckBox id="mydriasis" :item="'mydriasis'" @checked="doCheck"/>
            <label class="form-check-label" for="mydriasis">
              {{ $t('exam.mydriasis') }}
            </label>
            <!-- <label for="mydriasis" class="">{{ $t('exam.mydriasis') }}</label>
            <input type="checkbox" id="mydriasis" v-model="exam.mydriasis" :placeholder="$t('exam.mydriasis')"> -->
          </div>
          <div class="form-floating">
            <textarea name="name" class="form-control" ref="observation" id="observation" v-model="exam.observation" autofocus :placeholder="$t('exam.observation')" data-test="textareaExamObservation"></textarea>
            <label for="observation">{{ $t('exam.observation') }}</label>
          </div>
          <div class="form-floating v-select-floating">
            <v-select
              id="status"
              class="v-select form"
              :placeholder="$t('exam.status')"
              v-model="statusSelected"
              data-test="vSelectExamStatus"
              :reduce="x => x.value"
              :options="statusOptions">
            </v-select>
            <label for="status">{{ $t('exam.status') }}</label>
          </div>
          <div class="form-floating disabled">
            <input type="text" class="form-control" id="dateTime" :value="$filters.dateTime(exam.createdAt)" :placeholder="$t('exam.dateAndTime')" data-test="inputExamDateTime" readonly>
            <label for="dateTime">{{ $t('exam.dateAndTime') }}</label>
          </div>
          <div class="row" v-if="debug">
            <div class="form-floating">
              <textarea name="name" class="form-control" ref="observation" id="metadata" v-model="exam.metadata" placeholder="METADATA" data-test="textareaExamMetadata"></textarea>
              <label for="metadata">=== METADATA ===</label>
            </div>
          </div>
        </section>

        <section v-if="!this.isAnonymousPatient" id="patientInfo">
          <h3 class="sub-title">{{ $t('patient.patientInfo') }}</h3>
          <div class="form-floating disabled">
            <input type="text" class="form-control" id="firstname" v-model="patient.firstName" data-test="inputPatientFirstName" :placeholder="$t('patient.firstName')" readonly>
            <label for="firstname">{{ $t('patient.firstName') }}</label>
          </div>
          <div class="form-floating disabled">
            <input type="text" class="form-control" id="surname" v-model="patient.lastName" data-test="inputPatientLastName" :placeholder="$t('patient.lastName')" readonly>
            <label for="surname">{{ $t('patient.lastName') }}</label>
          </div>
          <div class="form-floating disabled">
            <input type="text" class="form-control" id="medicalReconrdNumber" v-model="patient.mrn" data-test="inputPatientMrn" :placeholder="$t('mrn')" readonly>
            <label for="medicalReconrdNumber">{{ $t('mrn') }}</label>
          </div>
          <div class="form-floating disabled">
            <input type="text" class="form-control" id="birthday" v-model="birthday" v-maska
                   :data-maska="birthday ? $t('inputMask.date') : ''" :placeholder="$t('patient.birthday')" data-test="inputPatientBirthday" readonly>
            <label for="birthday">{{ $t('patient.birthday') }}</label>
          </div>
        </section>

        <section id="underlying" v-if="patient.anamnesis">
          <ExamInfoUnderlyingDiseases :underlying="underlying"/>
        </section>

        <section id="ophthalmic" v-if="patient.anamnesis">
          <ExamInfoOphthalmicDiseases :ophthalmic="ophthalmic"/>
        </section>

        <section id="other">
          <h3 class="sub-title">{{ $t('patient.other') }}</h3>
          <div class="form-floating mb-3 disabled">
            <textarea name="name" class="form-control" id="otherObservation" v-model="patient.observation" :placeholder="$t('patient.observation')" data-test="textareaPatientObservation" readonly></textarea>
            <label for="otherObservation">{{ $t('patient.observation') }}</label>
          </div>
        </section>
      </div>
    </div>

    <div class="action-bar">
      <button class="btn btn-success" v-show="hasFormChanged" type="button" name="button" data-test="btnUpdateExam" @click="doUpdate">
        <span>{{ $t("patient.updateAction") }}</span>
      </button>

      <button class="btn btn-secondary" type="button" name="button" data-test="btnDownloadExam" @click.prevent="doDownload">
        <span>{{ $t("download") }}</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.action-bar {
  display:                         flex;
  justify-content:                 flex-start;
  align-items:                     center;
  gap:                             10px;
  height:                          64px;
  padding:                         10px 20px;
  border-top:                      1px solid var(--Shades-30);
}

.btn {
  @include                         border_radius(99px);
  font-size:                       var(--font-size-12);
  font-weight:                     400;
  letter-spacing:                  3.64px;
  text-transform:                  uppercase;
}

.btn-outline-danger {
  @include                         border_radius(99px);
  color:                           var(--Complementary-Orange);
  font-weight:                     700;
  padding:                         15px 20px;
  height:                          44px;
  margin:                          0 !important;
  border:                          1px solid var(--Complementary-Orange);
  background:                      #FFEBE8;
}

.btn-success {
  color:                           var(--Shades-W);
  font-weight:                     700;
  height:                          44px;
  padding:                         15px 20px;
  background:                      var(--Brand-Secondary);
  border:                          1px solid var(--Brand-Secondary);
}

.btn-secondary {
  color:                           var(--Brand-Primary);
  font-weight:                     700;
  height:                          44px;
  padding:                         15px 20px;
  background:                      var(--Shades-10);
  border:                          1px solid var(--Shades-10);
}

.sub-title {
  color:                           var(--Shades-60, #727272);
  font-size:                       16px;
  font-weight:                     500;
  line-height:                     normal;
  letter-spacing:                  3.2px;
  text-transform:                  uppercase;
  text-overflow:                   ellipsis;
  white-space:                     nowrap;
  overflow:                        hidden;
  border:                          0;
  margin:                          0;
}

.anchor-menu {
  display:                         flex;
  flex-direction:                  column;
  align-items:                     flex-start;
  gap:                             20px;
  padding:                         0;
  li {
    display:                       flex;
    width:                         auto;
    a {
      @include                     border_radius(99px);
      color:                       var(--Shades-60, #727272);
      font-size:                   var(--font-size-14);
      font-weight:                 500;
      line-height:                 normal;
      letter-spacing:              2.8px;
      text-decoration:             none;
      text-transform:              uppercase;
      text-overflow:               ellipsis;
      overflow:                    hidden;
      padding:                     10px 20px;
      background:                  var(--Shades-10, #FAFAFA);
      border:                      1px solid var(--Shades-30, #D8D8D8);
    }

    &:target,
    &:hover,
    &:focus,
    &:active,
    &.active {
      a {
        color:                     var(--Support-Success, #81BAA7);
        border:                    1px solid var(--Support-Success, #81BAA7);
      }
    }
  }
}

.col-md-4 {
  position:                        fixed;
  display:                         flex;
  justify-content:                 center;
}

.retract {
  .col-md-4 {
    justify-content:               flex-end;
  }
}

.col-lg-6 {
  padding-bottom:                  33px;
}

.col-md-8 section,
.col-lg-6 {
  display:                         flex;
  gap:                             19px;
  flex-direction:                  column;
}

.col-md-8 {
  overflow-y:                      auto;
  height:                          100%;
  max-height:                      calc(100vh - 324px);
  // max-height:                    calc(100vh - 217px);

  section {
    max-width:                     550px;
    width:                         100%;
    scroll-margin-top: 62px;

    &:first-child {
      padding-bottom:              33px;
    }

    &:last-child {
      padding-bottom:              4rem;
    }
  }
}

@media (max-width: 991px) {
  .col-md-4 {
    position:                      relative;
    justify-content:               flex-start;
  }

  .anchor-menu {
    flex-direction:                  row;
    align-items:                     flex-start;
    gap:                             20px;
    overflow-x:                      scroll;
    padding:                         0;
    margin-top:                      -10px;
    background:                      var(--Shades-10, #FAFAFA);
    border-bottom:                   1px solid var(--Shades-30, #D8D8D8);

    &::-webkit-scrollbar {
      display:                       none;
    }

    li {
      a {
        @include                     border_radius(0);
        font-size:                   12px;
        white-space:                 nowrap;
        padding:                     15px 20px;
        background:                  transparent;
        border:                      0;
      }

      &:target,
      &:hover,
      &:focus,
      &:active,
      &.active {
        a {
          border:                    0;
          border-bottom:             2px solid var(--Support-Success, #81BAA7);
        }
      }
    }
  }

  .sub-title {
    font-size:                       14px;
  }

  .col-md-8 {
    max-height:                       calc(100dvh - 295px) !important;
    padding:                          0 15px 0 24px;
  }
}

@media (max-width: 1500px) {
  .col-md-8 {
    max-height:                       calc(100vh - 318px);
  }
}

@media screen and (min-width: 992px) and (max-width: 1365px) {
  .col-md-8 {
    max-height:                       calc(100vh - 303px);
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import ExamInfoOphthalmicDiseases from './ExamInfoOphthalmicDiseases.vue'
import ExamInfoUnderlyingDiseases from './ExamInfoUnderlyingDiseases.vue'

// TODO EC-337 ENH deal programatically with modal control (open, close, cancel and the action)

export default {
  name: 'ExamInfoTab',
  props: ['exam', 'patient', 'alert_msg'],
  components: {
    ExamInfoOphthalmicDiseases,
    ExamInfoUnderlyingDiseases,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: null,
    disabled: true,
    statusOptions: [],
    statusSelected: 'open',
    score: null,
    isSpecialist: null,
    debug: false,
    initialForm: {},
    hasFormChanged: false,
    isExamMounted: false,
    birthday: '',
    activeAnchor: 'general',
    underlying: {
      diabetes: false,
      hypertension: false,
      cholesterol: false,
      smoker: false,
      nwn_underlying: false,
    },
    ophthalmic: {
      diabeticRetinopathy: false,
      dmri: false,
      glaucoma: false,
      cataract: false,
      pterygium: false,
      lowVisualAcuity: false,
      nwn_ophthalmic: false,
    },
    isAnonymousPatient: false,
  }),
  watch: {
    alert_msg: function(newVal) {
      if (newVal) {
        this.alert.message = newVal.message
        this.alert.class = newVal.class
      } else {
        this.alert.message = null
      }
    },
    exam: {
      immediate: true,
      handler: function(newValue) {
        this.checkFormChange(newValue, 'exam')
    }, deep: true},
    statusSelected: {
      immediate: true,
      handler: function(newValue) {
        this.checkFormChange(newValue, 'statusSelected')
    }, deep: true},
  },
  mounted: function() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.isSpecialist = this.session.isSpecialist
    this.statusOptions = [
      {value: 'open', label: this.$i18n.t('exam.open')},
      {value: 'closed', label: this.$i18n.t('exam.closed')}
    ];

    this.isAnonymousPatient = this.patient.uuid == this.session.currentClinic.jokerPatientUuid

    this.score = this.$aiService.alterationScore(this.exam.metadata)
    this.statusSelected = this.exam.status

    const DATE_FORMAT  = this.$i18n.t('dateFormat')
    this.birthday = this.patient.birthday ? moment.utc(this.patient.birthday).format(DATE_FORMAT) : ""

    this.anamnesisFormat()

    let formWrap = {
      exam: this.exam,
      statusSelected: this.statusSelected
    }

    this.initialForm = _.cloneDeep(formWrap)

    this.$nextTick(() => {
      this.hasFormChanged = false
    })

    this.$nextTick(() => {
      const divElement = document.getElementById('sectionsBox')
      divElement.addEventListener('scroll', this.updateActiveAnchor);
      this.updateActiveAnchor(); // Initialize the active section
    })

    this.isExamMounted = true
  },
  beforeUnmount() {
    const divElement = document.getElementById('sectionsBox')
    divElement.removeEventListener('scroll', this.updateActiveAnchor);
  },
  methods: {
    anamnesisFormat() {
      let anamnesis = this.patient.anamnesis ? this.patient.anamnesis : {}

      for (let key of Object.keys(this.ophthalmic)) {
        if (key in anamnesis)
          this.ophthalmic[key] = anamnesis[key]
      }

      for (let key of Object.keys(this.underlying)) {
        if (key in anamnesis)
          this.underlying[key] = anamnesis[key]
      }
    },
    dateFormat(val) {
      console.log(val);
      console.log(moment(val).format('L'));
      return moment(val).format('L')
    },
    setActiveAnchor(anchor) {
      this.activeAnchor = anchor;
      this.$nextTick(() => {
        const headerHeight = 62;
        const element = document.getElementById(anchor);
        const container = this.$refs.scrollContainer;

        if (element && container) {
          const elementPosition = element.offsetTop;
          const offsetPosition = elementPosition - headerHeight;

          container.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      });
    },
    updateActiveAnchor() {
      const sections = ['general', 'underlying', 'ophthalmic'];
      const divElement = document.getElementById('sectionsBox')
      const divHeight = divElement.offsetHeight
      let currentActive;

      for (const section of sections) {
        // console.log('section', section);
        const element = document.getElementById(section);
        if (!element) continue;

        const rect = element.getBoundingClientRect();
        // console.log('rect.top <= divHeight / 2 && rect.bottom >= divHeight / 2', rect.top <= divHeight / 2 && rect.bottom >= divHeight / 2);
        if (rect.top <= divHeight / 2 && rect.bottom >= divHeight / 2) {
          currentActive = section;
          break;
        }
      }

      if (currentActive && currentActive !== this.activeAnchor) {
        this.activeAnchor = currentActive;
      }
    },
    doCheck() {
      this.exam.mydriasis = !this.exam.mydriasis
    },
    checkFormChange(value, obj) {
      if (JSON.stringify(value) === JSON.stringify(this.initialForm[obj])) {
        this.hasFormChanged = false
      } else {
        this.hasFormChanged = true
      }
    },
    editFields() {
      this.disabled = !this.disabled;
      this.$nextTick(() => {
        this.$refs.observation.focus()
      })
    },
    doUpdate: async function() {
      this.alert.message = null;

      this.loading = true
      NProgress.start()
      try {
        let params = {
          exam: this.exam
        }

        params.exam.status = this.statusSelected
        delete params.exam.examType
        await this.$examService.update(params)
        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
      this.disabled = !this.disabled;
    },
    doCancel() {
      if (!this.disabled)
        this.disabled = !this.disabled;
      else {
        this.$router.push({name: 'exam-list'})
      }
    },
    doDownload() {
      this.alert.message = null;

      this.loading = true
      NProgress.start()
      try {
        this.$examService.download(this.exam.id)
      } catch(err) {
        // TODO log to bugsnag
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    activeAiClasses(exam) {
      return this.$aiService.mcRASClasses(exam)
    },
    hasMCRAS(exam) {
      return this.$aiService.hasMCRAS(exam)
    }
  }
}
</script>
