<template>
  <div class="">
    <ul class="wizard-level">
      <li :class="{active: step === 0, past: step > 0}">
        <small>Precisa melhorar muito</small>
      </li>
      <li :class="{active: step === 1, past: step > 1}">
        <small>Vai chegar lá!</small>
      </li>
      <li :class="{active: step === 2, past: step > 2}">
        <small>Ta até que bão</small>
      </li>
      <li :class="{active: step === 3, past: step > 3}">
        <small>Profissa das fotos</small>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.wizard-level {
  width:                                200px;
  margin:                               0 auto 20px;
  display:                              flex;
  justify-content:                      space-between;
  padding:                              0;
  margin-top:                           20px;
  li {
    @include                            border_radius(50%);
    @include                            transition(all .1s ease);
    position:                           relative;
    margin-bottom:                      2px;
    width:                              25px;
    height:                             25px;
    background:                         white;
    display:                            inline-block;
    padding:                            5px;
    border:                             2px solid white;
    z-index:                            1;
    &:before {
      content:                          '';
      position:                         absolute;
      top:                              7px;
      left:                             22px;
      width:                            36px;
      height:                           8px;
      background:                       white;
      border:                           0;
      z-index:                          -1;
    }
    small {
      position:                         absolute;
      font-size:                        10px;
      color:                            white;
      bottom:                           -20px;
      display:                          none;
    }
    &:before,
    small {
      @include                          transition(all .8s ease);
    }
    &:last-child:before {
      display:                          none;
    }
    &.active,
    &.past {
      &:first-child {
        background:                     rgb(255,0,0);
        border:                         2px solid rgb(255,0,0);
      }
      &:nth-child(2) {
        background:                     rgb(214,86,0);
        border:                         2px solid rgb(214,86,0);
      }
      &:nth-child(3) {
        background:                     rgb(58,214,0);
        border:                         2px solid rgb(58,214,0);
      }
      &:last-child {
        background:                     rgba(16,113,0,1);
        border:                         2px solid rgba(16,113,0,1);
      }
    }
    &.past {
      &:first-child {
        background:                     rgb(255,0,0);
        &:before {
          background:                   linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(214,86,0,1) 100%);
        }
      }
      &:nth-child(2) {
        background:                     rgb(214,86,0);
        &:before {
          background:                   linear-gradient(90deg, rgba(214,86,0,1) 0%, rgba(58,214,0,1) 100%);
        }
      }
      &:nth-child(3) {
        background:                     rgb(58,214,0);
        &:before {
          background:                   linear-gradient(90deg, rgba(58,214,0,1) 0%, rgba(16,113,0,1) 100%);
        }
      }
      &:last-child {
        background:                     rgba(16,113,0,1);
      }
    }
    &.active {
      &:first-child {
        &:before {
          background:                   linear-gradient(90deg, rgba(255,0,0,1) 0%, white 100%);
        }
        small {left: -3px; width: 110px; display: block;}
      }
      &:nth-child(2) {
        &:before {
          background:                   linear-gradient(90deg, rgba(214,86,0,1) 0%, white 100%);
        }
        small {left: -20px; width: 80px; display: block;}
      }
      &:nth-child(3) {
        &:before {
          background:                   linear-gradient(90deg, rgba(58,214,0,1) 0%, white 100%);
        }
        small {left: -24px; width: 80px; display: block;}
      }
      &:last-child {
        small {left: -58px; width: 85px; display: block;}
      }
    }
  }
}

@media (max-width: 767px) {
  .wizard-level {
    width:                              auto;
    margin-bottom:                      10px;
    li {
      &.active {
        display:                        block;
        margin:                         0 auto;
      }
      &,
      &.past,
      &:before,
      small {
        display:                        none;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1500px) {
  .wizard-level {
    margin:                             15px auto;
    li {
      width:                            20px;
      height:                           20px;
      &:before {
        top:                            5px;
        left:                           16px;
        width:                          43px;
        height:                         6px;
      }
    }
  }
  .level-box {
    > p {
      font-size:                        1em;
      margin:                           10px 0px 0px;
    }
    div > p {
      margin:                           27px auto 0px;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 991px) {
  @media screen and (min-height: 600px) and (max-height: 768px) {
    .wizard-level {
      margin:                           10px auto 13px;
    }
    .level-box {
      > p,
      div > p {
        font-size:                      0.8em;
      }
      div > p {
        width:                          60%;
        margin:                         22px auto 0px;
      }
    }
  }
}

@media screen and (min-width: 1280px) and (max-width: 1365px) {
  @media screen and (min-height: 600px) and (max-height: 720px) {
    .wizard-level {
      margin:                           10px auto 13px;
    }
    .level-box {
      > p,
      div > p {
        font-size:                      0.8em;
      }
      div > p {
        width:                          60%;
        margin:                         22px auto 0px;
      }
    }
  }
}
</style>

<script>
module.exports = {
  name: 'WizardLevel',
  data: () => ({
    step: 0,
  }),
  created: function() {
  },
}
</script>
