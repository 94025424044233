<template>
  <div class="external-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-7" :style="backgroundStyle">
          <!-- <div class="">
            <h6>{{ $t('createAccount.welcome') }}</h6>
            <img src="@/assets/images/phelcom/eyercloud_logo_white.svg" alt="Eyercloud logo">
          </div -->>
        </div>
        <div class="col-lg-5 flex-between">
          <div class=""></div>

          <div class="external-content">
            <h3 class="mb-3">{{ $t('createAccount.clinicRegistration') }}</h3>

            <h3 class="mb-3">
              <span>{{ firstName }}</span>,
              {{ $t('createAccount.congratulation') }}
            </h3>

            <p class="mb-3">{{ $t('createAccount.clickBelow') }}</p>

            <a href="/login" class="btn btn-success" data-test="anchorLogin">{{ $t('login.login') }}</a>
          </div>

          <div class="powered-box">
            <p class="d-inline-block">POWERED BY</p>
            <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
              <img src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
            </a>
            <p class="d-inline-block">v {{ appVersion }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.col-lg-5 {
  h3 {
    span {
      font-weight:              700;
    }
  }
}
.col-lg-7 {
    @include                               border_radius(10px);
    display:                               flex;
    flex-direction:                        column;
    justify-content:                       space-between;
    background:                            linear-gradient(252deg, #000 0%, #0D4636 100%);
    background:                            url('@/assets/images/phelcom/register_banner_en.png') no-repeat center bottom 100%, linear-gradient(252deg, #000 0%, #0D4636 100%);
    background-size:                       cover;
    // background:                            url('@/assets/images/phelcom/welcome_banner_en-US.png') no-repeat right center, linear-gradient(252deg, #000 0%, #0D4636 100%);
    padding:                               5rem 3rem 3rem;
  }

@media (max-width: 991px) {
  .external-page {
    width:                      100%;
    overflow-y:                 hidden;

    .col-lg-7 {
      order:                     2;
    }

    .col-lg-5 {
      .external-content {
        width:                   100%;
      }
    }
  }
}
</style>

<script>
  import utilService from '../../../services/util-service.js';
  export default {
    name: 'registration-clinic-created',
    data: () => ({
      firstName: "",
      countryCode: 'en',
      backgroundStyle: null,
    }),
    mounted() {
      this.countryCode = utilService.getCurrentLanguage()  
      const imagePath = require(`@/assets/images/phelcom/register_banner_${this.countryCode}.png`);
      this.backgroundStyle = `background: url(${imagePath}) no-repeat center bottom 100%, linear-gradient(252deg, #000 0%, #0D4636 100%); background-size: cover;`
      
      let data = this.$utils.parseDataQuery(this.$route.query.data)
      this.firstName = data.firstName
    },
  }
</script>
