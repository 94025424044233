<template>
  <div class="filter-box">
    <h6>{{ $t('exam.examDate') }}</h6>
    <BaseDateFilterNew class="d-inline-block" @change-date="setDates"></BaseDateFilterNew>

    <!-- <v-select
      class="v-select form filter"
      v-model="filterSelected"
      data-test="vSelectFilter"
      :reduce="x => x.value"
      :options="filterOptions">
    </v-select> -->

    <!-- <div class="">
      <h6>Tipos de exames</h6>

      <div class="row">
        <div class="col-6">
          <div class="form-check">
            <BaseSquareCheckBox id="normal" :item="'normal'" @checked="doCheck"/>
            <label class="form-check-label" for="normal">
              Normal
            </label>
          </div>
          <div class="form-check">
            <BaseSquareCheckBox id="eyermaps" :item="'eyermaps'" @checked="doCheck"/>
            <label class="form-check-label" for="eyermaps">
              Eyermaps
            </label>
          </div>
          <div class="form-check">
            <BaseSquareCheckBox id="oe" :item="'oe'" @checked="doCheck"/>
            <label class="form-check-label" for="oe">
              {{ $t('oe') }}
            </label>
          </div>
        </div>

        <div class="col-6">
          <div class="form-check">
            <BaseSquareCheckBox id="redfree" :item="'redfree'" @checked="doCheck"/>
            <label class="form-check-label" for="redfree">
              Redfree
            </label>
          </div>
          <div class="form-check">
            <BaseSquareCheckBox id="pano" :item="'pano'" @checked="doCheck"/>
            <label class="form-check-label" for="pano">
              {{ $t('imageType.panoramic') }}
            </label>
          </div>
          <div class="form-check">
            <BaseSquareCheckBox id="od" :item="'od'" @checked="doCheck"/>
            <label class="form-check-label" for="od">
              {{ $t('od') }}
            </label>
          </div>
        </div>
      </div>
    </div> -->

    <button class="btn btn-success" type="button" name="button" data-test="btnFilter" @click="doFilter(true)">{{ $t('applyFilter') }}</button>
    <!-- <button class="btn btn-primary" type="button" name="button" @click="openModalDownload">{{ $t('download') }}</button> -->

    <!-- <teleport to="body">
      <ExamDownloadModal v-if="showModalDownload" @close="showModalDownload = false"></ExamDownloadModal>
    </teleport> -->
  </div>
</template>

<style lang="scss" scoped>
  .filter-box {
    width:                         100%;
    padding:                       40px 20px 20px 20px;
    // background: white;

    h6 {
      color:                       var(--Shades-70);
      font-size:                   var(--font-size-12);
      font-weight:                 400;
      line-height:                 normal;
      letter-spacing:              1.8px;
      text-transform:              uppercase;
      text-overflow:               ellipsis;
      overflow:                    hidden;
    }

    .form-check {
      margin-bottom:               10px;
    }

    .btn-success {
      color:                       var(--Shades-W);
      font-size:                   var(--font-size-12);
      font-weight:                 700;
      line-height:                 normal;
      letter-spacing:              3.6px;
      text-transform:              uppercase;
      width:                       100%;
      padding:                     15px;
    }
  }
</style>

<script>
import { useExamFilterStore } from '@/store/examFilter';

export default {
  name: 'ExamFilter',
  data: () => ({
    filterOptions: {
      normal: false,
      eyermaps: false,
      redfree: false,
      pano: false,
      oe: false,
      od: false,
    },
    filterSelected: 'all',
    filterDates: {
      startDate: null,
      endDate: null
    },
  }),
  mounted() {
    // this.filterOptions = [
    //   {value: 'all', label: this.$i18n.t('exam.all')},
    //   {value: 'open', label: this.$i18n.t('exam.open')},
    //   {value: 'closed', label: this.$i18n.t('exam.closed')}
    // ];
    if (this.$route.query.data) {
      let data = this.$utils.parseDataQuery(this.$route.query.data)
      if (data.startDate) {
        this.filterDates.startDate = data.startDate
      }

      if (data.endDate) {
        this.filterDates.endDate = data.endDate
      }
      console.log('tem route query');
      this.debounce()
    } else {
      this.debounce()
    }

  },
  methods: {
    setDates(val) {
      this.filterDates = val;

      if (this.$route.query.data) {
        let data = this.$utils.parseDataQuery(this.$route.query.data)
        if (data.filterSelected) {
          this.filterOptions = data.filterSelected
        }
        console.log('tem route query');
        this.debounce()
      }
    },
    debounce: _.debounce(function() {
      this.doFilter()
    }, 250, { 'maxWait': 100 }),
    doFilter(newRequest) {
      let filter = {
        startDate: this.filterDates.startDate,
        endDate: this.filterDates.endDate,
        filterSelected: 'all',
        newRequest: newRequest ? newRequest : false
      }

      const examFilterStore = useExamFilterStore()
      examFilterStore.refreshFilter(filter)

      if (newRequest)
        this.$emit('close');
      // this.$emit('do-filter', filter);
    },
    doCheck(item) {
      for (const [key, value] of Object.entries(this.filterOptions)) {
        if (key == item.value) {
          this.filterOptions[key] = item.checked
        }
      }

      console.log('filter options', this.filterOptions);
    }
  },
  // unmounted() {
  //   this.filterSelected = 'all'
  //   this.filterDates = undefined
  // },
}
</script>
