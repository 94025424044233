<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="passwordOffcanvas" data-bs-backdrop="static" aria-labelledby="passwordOffcanvasLabel">
    <div class="offcanvas-header">
      <button class="btn-back" id="closeBtn" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close" @click="close()">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" id="passwordOffcanvasLabel">
        {{ $t('user.changePassword') }}
      </h5>
    </div>
    <vee-form v-slot="{ handleSubmit }" as="div">
      <form @submit.prevent.stop="handleSubmit(changePassword)">
        <div class="offcanvas-body">
          <div class="form-floating">
            <vee-field name="currentPassword" v-model="currentPassword" rules="required" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="password" class="form-control" ref="currentPassword" id="currentPassword" :placeholder="$t('user.currentPassword')" :disabled="disabled" data-test="inputCurrentPassword">
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="currentPassword">{{ $t('user.currentPassword') }}</label>
          </div>
          <div class="form-floating">
            <vee-field name="newPassword" v-model="newPassword" rules="required|min:8" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="password" class="form-control" id="newPassword" ref="newPassword" :placeholder="$t('user.newPassword')" :disabled="disabled" data-test="inputNewPassword">
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="newPassword">{{ $t('user.newPassword') }}</label>
          </div>
          <div class="form-floating">
            <vee-field name="confirmPassword" v-model="passwordConfirmation" rules="required|confirmed:newPassword" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="password" class="form-control" id="passwordConfirmation" :placeholder="$t('user.passwordConfirmation')" :disabled="disabled" data-test="inputConfirmPassword">
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="passwordConfirmation">{{ $t('user.passwordConfirmation') }}</label>
          </div>

          <div class="offcanvas-footer">
            <button type="submit" class="btn btn-success" data-test="btnModifyConfirm">{{ $t('user.modify') }}</button>
            <!-- <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="offcanvas" data-test="btnCancel" @click="doCancel">{{ $t('user.cancel') }}</button> -->
          </div>
        </div>
      </form>
    </vee-form>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    p,
    a {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin:                           0;
    }
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn {
      font-size:                        var(--font-size-14);
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   4.2px;
      text-align:                       center;
      text-transform:                   uppercase;
      // gap:                              10px;
      // flex:                             1 0 0;
      width:                            100%;
      padding:                          20px;
    }

    .btn-success {
      color:                            var(--Shades-W, #FFF);
      // margin-top:                       26px;
    }
  }

  .vee-error {
    width:                              100% !important;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'ExternalPatientChangePasswordOffcanvas',
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    currentPassword: "",
    newPassword: "",
    passwordConfirmation: "",
    disabled: false,
    offcanvas_password: null,
  }),
  mounted() {
  },
  methods: {
    show(options = {}) {
      this.offcanvas_password = new bootstrap.Offcanvas(document.getElementById('passwordOffcanvas')).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.offcanvas_password = null;
      this.resolvePromise(true)
    },

    doCancel() {
      this.offcanvas_password = null;
      this.resolvePromise(false)
    },

    async changePassword() {
      NProgress.start()
      try {
        const params = {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword
        }
        let result = await this.$externalService.changePassword(params)

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')

        this.doConfirm()

        this.$nextTick(() => {
          this.close()
        })
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },

    async checkEmail() {
      try {
        let result = await this.$externalService.checkEmail()

        if (!result.isEmailValid) {
          this.disabled = true;
          this.alert = this.$message.alert(this, 'email.registerEmail', 'warning')
          this.$message.popup(this, 'email.registerEmail', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
    },

    close() {
      this.currentPassword = ''
      this.newPassword = ''
      this.passwordConfirmation = ''
      this.offcanvas_password = null
      document.getElementById('btnClose').click()
    }
  }
}
</script>
