<template>
  <div class="patients-main">
    <div class="sub-header-box">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
        <IconArrowBack/>
        <span>{{ $t('goBack') }}</span>
      </button>

      <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/exam/" data-test="btnRouter">
              {{ $t('home') }}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ patientName }}</li>
        </ol>
      </nav>
    </div>

    <div class="title-box">
      <div class="flex-start">
        <div class="">
          <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
            <IconArrowBack/>
          </button>

          <h2 class="title">{{ patientName }}</h2>
        </div>

        <div class="">
          <div id="btnSharePatientShowSlot"></div>

          <div class="flex-end" id="btnDeletePatientShowSlot"></div>
        </div>
      </div>

    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-data>
        {{ $t('patient.data') }}
      </template>
      <template v-slot:data>
        <PatientInfoTab v-if="tabActive === 'data' && isDataReady" :patient_id="patientId"></PatientInfoTab>
      </template>

      <template v-slot:tab-head-exams>
        {{ $t('patient.exams') }}
      </template>
      <template v-slot:exams>
        <PatientExamTab v-if="tabActive === 'exams'" :patient_id="patientId" :patient_name="patientName"></PatientExamTab>
      </template>

      <template v-slot:tab-head-records>
        {{ $t('patient.medicalRecord') }}
      </template>
      <template v-slot:records>
        <PatientMedicalRecordListTab v-if="tabActive === 'records'" :patient_id="patientId" :patient_name="patientName"></PatientMedicalRecordListTab>
      </template>
    </BaseTab>

    <!-- <div class="action-bar">
      <div id="patientActionBar"></div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.breadcrumb-item {
  text-transform:                  capitalize;
}

.patients-main {
  .sub-header-box {
    display:                       flex;
    flex-direction:                row;
    justify-content:               flex-start;
    align-items:                   center;
    gap:                           20px;
    width:                         100%;
    padding:                       15px 20px;

    ol {
      margin:                      0;
    }
  }

  .title-box {
    display:                       flex;
    flex-direction:                row;
    align-items:                   center;
    justify-content:               space-between;
    height:                        55px;
    padding:                       10px 25px;
    // margin:                          2.3rem 0 1.7rem;

    > div {
      width:                       auto !important;
    }

    .flex-end,
    .btn-back {
      display:                     none;
    }

    .flex-start {
      gap:                         20px;

      > div {
        display:                   flex;
        flex-direction:            row;
        justify-content:           flex-start;
        align-items:               center;
        gap:                       20px;
      }
    }
  }
}

.btn-outline-secondary {
  @include                         border_radius(99px);
  color:                           var(--Brand-Primary, #1B294A);
  font-size:                       12px;
  font-weight:                     400;
  line-height:                     normal;
  letter-spacing:                  3.12px;
  text-transform:                  uppercase;
  display:                         flex;
  align-items:                     center;
  gap:                             10px;
  width:                           auto;
  // margin:                          0 0 0 20px;
  margin-left:                     20px;
  margin-bottom:                   0 !important;
  padding:                         10px 15px 10px 10px;
  border:                          1px solid var(--Shades-30, #D8D8D8);
  background:                      var(--Shades-10, #FAFAFA);
}

.inside-box {
  &::-webkit-scrollbar {
    display:                       none;
  }
  // overflow-y: hidden !important;
}

@media (max-width: 991px) {
  .patients-main {
    .sub-header-box,
    .title-box .btn-outline-secondary span {
      display:                     none !important;
    }

    .title-box {
      padding:                     10px;

      .title {
        font-size:                 18px !important;
      }

      .flex-start {
        > div {
          gap:                     15px;
        }
      }

      .btn-back,
      .flex-start > div:last-child .btn-outline-secondary:last-child,
      .flex-end {
        display:                   flex;
      }

      .btn-outline-secondary {
        padding:                   11.5px 15px;
        svg {
          margin:                  0;
        }
      }

      .btn-back,
      .btn-outline-secondary {
        height:                    35px;
        margin:                    0 !important;
      }
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import PatientMedicalRecordListTab from './PatientMedicalRecordListTab.vue'
import PatientInfoTab from './PatientInfoTab.vue'
import PatientExamTab from './PatientExamTab.vue'
import PatientExamNewOffcanvas from './PatientExamNewOffcanvas.vue'

export default {
  name: 'patient-show',
  components: {
    PatientMedicalRecordListTab,
    PatientInfoTab,
    PatientExamTab,
    PatientExamNewOffcanvas
  },
  data: () => ({
    loading: null,
    alert: {
      message: null,
      class: null
    },
    isSpecialist: null,
    tabActive: 'data',
    tabs: ['data'],
    patientName: null,
    isDataReady: false,
    showOffcanvasNewExam: false,
  }),
  watch: {
    patientId: {
      immediate: true,
      handler: function (patient_id) {
        if (patient_id){
          this.addTabs()
        }
    }, deep: true }
  },
  computed: {
    patientId() {
      return this.$route.params.patientID;
    },
    tab() {
      return this.$route.query.tab;
    },
  },
  mounted() {
    this.$activeLink.value = 'patient'

    if (this.tab) {
      this.tabActive = this.tab;
    }

    this.fetchData()

    this.isDataReady = true
  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
    goBack() {
      this.$router.push({name: 'patient-list'})
      // this.$router.go(-1)
    },
    addTabs() {
      const userStore = useUserStore()
      this.session = userStore.sessionInfo
      this.isSpecialist = this.session.isSpecialist

      if (this.tabs.includes('exams') && this.tabs.includes('records'))
        return

      if (!this.tabs.includes('exams'))
        this.tabs.push('exams')

      const showMedicalRecord = this.$utils.isFeatureEnabled('medicalRecord')
      if (!this.isSpecialist && showMedicalRecord) {
        this.tabs.push('records')
      }
    },
    async fetchData() {
      this.alert.message = null
      this.initialForm = {}

      let params = {
        id: this.patientId
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.getPatient(params)
        this.patient = response.patient

        let isJokerPatient = this.patient.uuid == this.session.currentClinic.jokerPatientUuid
        this.patientName = isJokerPatient ? this.$i18n.t('noPatientSelected') : this.patient.fullName
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    openOffCanvasNewExam() {
      this.showOffcanvasNewExam = true

      setTimeout(function() {
        new bootstrap.Offcanvas(offCanvasNewExam).show();
      }, 100);
    },
  }
}
</script>
