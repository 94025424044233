import eyerCloud from '@/config/eyercloud'

class DeviceService {

  async getList() {
    let response = await eyerCloud.post('/api/v2/eyercloud/device/list')
    return response.data
  }

  async getDevice(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/device/get`, params)
    return response.data
  }

  async registerDevice(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/device/register`)
    return response.data
  }

  async getLogList(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/device/log/list`, params)
    return response.data
  }

  async downloadLog(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/device/log/download`, params)
    return response.data
  }

}
const deviceService = new DeviceService();
export default deviceService;
