<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="emailOffcanvas" data-bs-backdrop="static" aria-labelledby="emailOffcanvasLabel">
    <div class="offcanvas-header">
      <button class="btn-back" id="btnClose" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close" @click="close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" id="emailOffcanvasLabel">
        {{ $t('email.changeEmail') }}
      </h5>
    </div>
    <vee-form v-slot="{ handleSubmit }" as="div">
      <form @submit.prevent.stop="handleSubmit(changeEmail)">
        <div class="offcanvas-body">
          <div v-if="!confirmEmail">
            <div class="form-floating">
              <vee-field name="newEmail" v-model="newEmail" rules="required|email" v-slot="{ field, errorMessage }">
                <input v-bind="field" type="email" class="form-control" id="newEmail" :placeholder="$t('email.newEmail')" data-test="inputPatientNewEmail">
                <span class="vee-error">{{ errorMessage }}</span>
              </vee-field>
              <label for="newEmail">{{ $t('email.newEmail') }}</label>
            </div>
            <div class="form-floating">
              <vee-field name="currentPasswordEmail" v-model="currentPassword" rules="required" v-slot="{ field, errorMessage }">
                <input v-bind="field" type="password" class="form-control" id="currentPasswordEmail" :placeholder="$t('user.currentPassword')" data-test="inputPatientCurrentPassword">
                <span class="vee-error">{{ errorMessage }}</span>
              </vee-field>
              <label for="currentPasswordEmail">{{ $t('user.currentPassword') }}</label>
            </div>
          </div>

          <div v-else>
            <p>{{ $t('email.linkSent') }} <strong>{{ newEmail }}</strong>.</p>
            <p>{{ $t('email.insertCode') }}</p>
            <div class="form-floating">
              <vee-field name="verificationCode" v-model="verificationCode" rules="required" v-slot="{ field, errorMessage }">
                <input v-bind="field" type="password" class="form-control" id="verificationCode" :placeholder="$t('email.verificationCode')" data-test="inputPatientVerificationCode">
                <span class="vee-error">{{ errorMessage }}</span>
              </vee-field>
              <label for="verificationCode">{{ $t('email.verificationCode') }}</label>
            </div>
          </div>

          <div class="offcanvas-footer">
            <button type="button" class="btn btn-success" data-test="btnModifyRegister" @click="registerNewEmail" v-if="!confirmEmail">{{ $t('user.modify') }}</button>
            <button type="button" class="btn btn-success" data-test="btnModifyConfirm" @click="confirmNewEmail" v-else>{{ $t('user.modify') }}</button>
            <!-- <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="offcanvas" data-test="btnCancel" @click="doCancel">{{ $t('user.cancel') }}</button> -->
          </div>
        </div>
      </form>
    </vee-form>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    &,
    > div {
      display:                          flex;
      flex-direction:                   column;
      gap:                              26px;
    }

    p,
    a {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin:                           0;
    }
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn {
      font-size:                        var(--font-size-14);
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   4.2px;
      text-align:                       center;
      text-transform:                   uppercase;
      // gap:                              10px;
      // flex:                             1 0 0;
      width:                            100%;
      padding:                          20px;
    }

    .btn-success {
      color:                            var(--Shades-W, #FFF);
      // margin-top:                       26px;
    }
  }

  .vee-error {
    width:                              100% !important;
  }
}
</style>

<script>
export default {
  name: 'ExternalPatientChangeEmailOffcanvas',
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    currentPassword: "",
    newEmail: "",
    confirmEmail: false,
    activationCode: null,
    verificationCode: null,
    offcanvas_email: null,
  }),
  mounted() {
  },
  methods: {
    show(options = {}) {
      this.offcanvas_email = new bootstrap.Offcanvas(document.getElementById('emailOffcanvas')).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.offcanvas_email = null;
      this.resolvePromise(true)
    },

    doCancel() {
      this.offcanvas_email = null;
      this.resolvePromise(false)
    },

    async registerNewEmail() {
      let params = {
        email: this.newEmail,
        password: this.currentPassword
      }

      NProgress.start()
      try {
        let result = await this.$externalService.registerNewEmail(params)
        console.log('result', result);
        this.confirmEmail = true
        this.activationCode = result.activationCode;
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },
    async confirmNewEmail() {
      let params = {
        activationCode: this.activationCode,
        verificationCode: this.verificationCode
      }

      NProgress.start()
      try {
        let result = await this.$externalService.confirmNewEmail(params)

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')

        this.doConfirm()

        this.$nextTick(() => {
          this.close()
        })
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },
    close() {
      this.currentPassword = ''
      this.newEmail = ''
      this.confirmEmail = false
      this.activationCode = null
      this.verificationCode = null
      this.offcanvas_email = null
      document.getElementById('btnClose').click()
    }
  }
}
</script>
