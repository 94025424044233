<template>
  <div>

    <h2 class="sub-title">{{ $t(`medicalRecord.${section}`)}}</h2>

    <template v-for="r of rows" :key="r">
      <h3 v-if="r.title">{{$t(`medicalRecord.${section}_${r.title}`)}}</h3>
      <p v-if="r.label">
        <strong>{{ $t(`medicalRecord.${section}_${r.label}`) }}: </strong><div v-html="r.value"></div>
      </p>

      <p v-if="r.isMultiple">
        <template v-for="e of r.values" :key="e">
          <span style="margin-right: 10px"><strong>{{ $t(`medicalRecord.${section}_${e.label}`) }}: </strong>{{e.value}}</span>
        </template>
      </p>
    </template>

  </div>
</template>

<script>
const DEBUG = false

export default {
  name: 'PatientMedicalRecordTextSection',
  props: ['section', 'content'],
  data: () => ({
    titleWasShow: [],
    rows: [],
  }),
  mounted() {
    this.debug(">>>> THE CONTENT >>>", this.$utils.raw(this.content))

    /*
      e1 muitas queixas
      e2 pouca historia
      e3 vai falar ou não?
      e4_s1 true  <------------ multiple entries, print title using entry key
      e4_s2 true
      e4_s3 true
      e4_s4 outros o q?
      e4_s5 muitas alergias....

      e5n1 foo      <----- must print titles: e5, e5n1
      e5n1_s1 bar   <-----must print titles: e5, e5n1
    */

    // row -> title
    //        label: value
    //        label: boolean, label: boolean, ...
    let row = []
    const keys = Object.keys(this.content)
    const entryCount = keys.length

    for (let i=0; i < entryCount; i++) {
      let key = keys[i]
      let value = this.content[key]
      let isBoolean = this.isBoolean(value)
      this.debug("current entry", `${key}, ${value}`)

      // dont add checkboxes marked as false (they could be removed from the MODEL instead)
      if (isBoolean && value == false) {
        if (row.length > 0) {
          this.rows.push({isMultiple: true, values: row})
          row = []
        }
        continue
      }

      let showSecondaryTitle = this.printSecondaryKeyAsTitle(key)
      if (showSecondaryTitle) {
        // if (row.length > 0) {
        //   this.rows.push({isMultiple: true, values: row})
        //   row = []
        // }

        let specialKey = this.specialKey(key)
        this.debug("showSecondaryTitle", `key:${key}, specialKey:${specialKey}`)
        this.rows.push({title: specialKey})
      }

      // this.debug(k, this.$utils.raw(value))
      let showTitle = this.printMainEntryKeyAsTitle(key)
      if (showTitle) {
        if (row.length > 0) {
          this.rows.push({isMultiple: true, values: row})
          row = []
        }
        const mainKey = this.mainKey(key)
        this.debug("showTitle", `key:${key}, mainKey:${mainKey}`)
        this.rows.push({title: mainKey})
      }

      if (isBoolean) {
        let translatedValue = this.$i18n.t(`medicalRecord.${value}`)
        row.push({label: key, value: translatedValue})
        if (i == entryCount - 1) {
          this.rows.push({isMultiple: true, values: row})
          row = []
        }

        continue
      } else {
        if (row.length > 0) {
          this.rows.push({isMultiple: true, values: row})
          row = []
        }
      }

      this.rows.push({label: key, value: value})
    }

    this.debug("THE ROWS >>>", this.$utils.raw(this.rows))
  },
  methods: {
    mainKey(value) {
      const parts = value.split('_')
      return parts.length > 0 ? parts[0] : parts
    },
    isBoolean(value) {
      return typeof value === "boolean"
    },
    printMainEntryKeyAsTitle(key) {
      const parts = key.split('_')
      if (parts.length == 1)
        return false

      let mainKey = parts[0]
      if (this.titleWasShow.includes(mainKey))
        return false

      this.titleWasShow.push(mainKey)

      return true
    },

    specialKey(key) {
      return key.substring(0, key.indexOf('n'))
    },

    printSecondaryKeyAsTitle(key) {
      if (!key.includes('n'))
        return false

      const specialKey = this.specialKey(key)
      this.debug("SPECIAL KEY >>>>>", specialKey)

      if (this.titleWasShow.includes(specialKey))
        return false

      this.titleWasShow.push(specialKey)
      return true
    },

    debug(tag, message) {
      if (!DEBUG)
        return

      console.log(`[DEBUG] [${tag}]`, message)
    }
  }
}
</script>
