<template>
  <div class="settings-main">
    <div class="sub-header-box">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>

      <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/exam/" data-test="btnRouter">
              {{ $t('home') }}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ $t('createClinic.configuration') }}</li>
        </ol>
      </nav>
    </div>

    <div class="title-box">
      <div class="flex-start">
        <div class="">
          <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
            <IconArrowBack/>
          </button>

          <h2 class="title">{{ $t('createClinic.configuration') }}</h2>
        </div>
      </div>

      <div class="flex-end" id="btnDeletePatientShowSlot"></div>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-users>
        {{ $t('menu.users') }}
      </template>
      <template v-slot:users>
        <UserList v-if="tabActive === 'users'" />
      </template>

      <template v-slot:tab-head-clinics>
        {{ $t('menu.clinics') }}
      </template>
      <template v-slot:clinics>
        <ClinicList v-if="tabActive === 'clinics' && isDataReady"/>
      </template>

      <template v-slot:tab-head-devices>
        {{ $t('menu.devices') }}
      </template>
      <template v-slot:devices>
        <DeviceList v-if="tabActive === 'devices'"/>
      </template>

      <template v-slot:tab-head-audit>
        {{ $t('menu.audit') }}
      </template>
      <template v-slot:audit>
        <AuditList v-if="tabActive === 'audit'"/>
      </template>
    </BaseTab>

    <div class="action-bar">
      <div id="settingsActionBar"></div>
    </div>

    <!-- <div class="settings-content">
      <router-view></router-view>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.settings-main {
  .sub-header-box {
    display:                         flex;
    flex-direction:                  row;
    justify-content:                 flex-start;
    align-items:                     center;
    gap:                             20px;
    width:                           100%;
    padding:                         15px 20px;

    ol {
      margin:                        0;
    }
  }

  .title-box {
    display:                         flex;
    flex-direction:                  row;
    align-items:                     center;
    justify-content:                 space-between;
    height:                          55px;
    margin:                          0;
    padding:                         10px 25px;
    // margin:                          2.3rem 0 1.7rem;

    > div {
      width:                         auto !important;
    }

    .btn-back {
      display:                       none;
    }
  }
}

.action-bar {
  height:                            64px;
  padding:                           10px 20px;
  border-top:                        1px solid var(--Shades-30);

  > div {
    display:                         flex;
    justify-content:                 flex-start;
    align-items:                     center;
    gap:                             10px;
    height:                          100%;
  }
}

@media (max-width: 991px) {
  .settings-main {
    .sub-header-box,
    .title-box .btn-outline-secondary span {
      display:                       none;
    }

    .title-box {
      padding:                       10px;

      .title {
        font-size:                   18px !important;
      }

      .flex-start {
        > div {
          display:                   flex;
          align-items:               center;
          gap:                       15px;
        }
      }

      .btn-back {
        display:                     flex;
      }

      .btn-outline-secondary {
        padding:                     11.5px 15px;
        svg {
          margin:                    0;
        }
      }

      .btn-back,
      .btn-outline-secondary {
        height:                      35px;
        margin:                      0 !important;
      }
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import ClinicList from '../clinic/clinic-list.vue'
import UserList from '../user/user-list.vue'
import DeviceList from '../device/device-list.vue'
import AuditList from '../audit/audit-list.vue'

export default {
  name: 'settings',
  components: {
    ClinicList,
    UserList,
    DeviceList,
    AuditList,
  },
  data: () => ({
    active: null,
    allowSynchronization: true,
    isSpecialist: null,
    isPowerfull: null,
    session: null,
    isDataReady: false,
    tabActive: null,
    tabs: [],
    // 'users', 'devices', 'audit'
  }),
  mounted: function() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.isSpecialist = this.session.isSpecialist
    this.isPowerfull = this.session.isPowerfull

    if (this.isPowerfull) {
      this.tabs.push('users')
      this.tabs.push('clinics')
      this.tabs.push('devices')
      this.tabs.push('audit')

      this.switchTab('users')
    } else {
      this.tabs.push('clinics')

      this.switchTab('clinics')
    }

    if (this.$route.query.tab) {
      this.switchTab(this.$route.query.tab)
    }

    this.allowSynchronization = this.session.currentClinic.synchronizePatientData
    if (this.allowSynchronization == undefined)
      this.allowSynchronization = true

    this.isDataReady = true
  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
    goBack() {
      // this.$router.push({name: 'patient-list'})
      this.$router.go(-1)
    },
  }
}
</script>
