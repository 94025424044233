<template>
  <div style="padding-left: 2em;">
    <h4 class="sub-title">{{ $t('varFields.pre') }}</h4>
    <div class="action-info-box">
      <div class="rd-box" style="flex-direction: column;">
        <div class="flex-acenter mb-2">
          <div class="lat-box">
            <span class="marked"></span>
            <span></span>
          </div>
          <h4>{{ $t('varFields.rightEye') }}</h4>
          <div class="btn-group" role="group">
            <input class="btn-check" type="radio" name="rightEye" id="rightEye1" autocomplete="off"
              v-model="options.rightEye" data-test="radioRightEye1" value="2" @change="doSave">
            <label @click="unmarkPrechecked('od')" class="btn btn-outline-secondary" :class="preCheckClasses.od[2]"
              for="rightEye1">{{
                $t('varFields.yes') }}</label>
            <input class="btn-check" type="radio" name="rightEye" id="rightEye2" autocomplete="off"
              v-model="options.rightEye" data-test="radioRightEye2" value="1" @change="doSave">
            <label @click="unmarkPrechecked('od')" class="btn btn-outline-secondary" :class="preCheckClasses.od[1]"
              for="rightEye2">{{
                $t('varFields.no') }}</label>
            <input class="btn-check" type="radio" name="rightEye" id="rightEye3" autocomplete="off"
              v-model="options.rightEye" data-test="radioRightEye3" value="3" @change="doSave">
            <label @click="unmarkPrechecked('od')" class="btn btn-outline-secondary" :class="preCheckClasses.od[3]"
              for="rightEye3">{{
                $t('varFields.impossibleToSee') }}</label>
          </div>
        </div>
        <div class="flex-acenter mb-2">
          <div class="lat-box">
            <span></span>
            <span class="marked"></span>
          </div>
          <h4>{{ $t('varFields.leftEye') }}</h4>
          <div class="btn-group" role="group">
            <input class="btn-check" type="radio" name="leftEye" id="leftEye1" autocomplete="off"
              v-model="options.leftEye" data-test="radioLeftEye1" value="2" @change="doSave">
            <label @click="unmarkPrechecked('os')" class="btn btn-outline-secondary" :class="preCheckClasses.os[2]"
              for="leftEye1">{{
                $t('varFields.yes') }}</label>
            <input class="btn-check" type="radio" name="leftEye" id="leftEye2" autocomplete="off"
              v-model="options.leftEye" data-test="radioLeftEye2" value="1" @change="doSave">
            <label @click="unmarkPrechecked('os')" class="btn btn-outline-secondary" :class="preCheckClasses.os[1]"
              for="leftEye2">{{
                $t('varFields.no') }}</label>
            <input class="btn-check" type="radio" name="leftEye" id="leftEye3" autocomplete="off"
              v-model="options.leftEye" data-test="radioLeftEye3" value="3" @change="doSave">
            <label @click="unmarkPrechecked('os')" class="btn btn-outline-secondary" :class="preCheckClasses.os[3]"
              for="leftEye3">{{
                $t('varFields.impossibleToSee') }}</label>
          </div>
        </div>
        <div class="flex-acenter mb-2">
          <div class="lat-box" style="visibility: hidden;">
            <span></span>
            <span class="marked"></span>
          </div>
          <h4>{{ $t('varFields.referred') }}</h4>
          <div class="btn-group" role="group">
            <input class="btn-check" type="radio" name="referred" id="referred1" autocomplete="off"
              v-model="options.referred" data-test="radioReferred" value="1" @change="doSave">
            <label @click="unmarkPrechecked('referred')" class="btn btn-outline-secondary"
              :class="preCheckClasses.referred[1]" for="referred1">{{
                $t('varFields.yes') }}</label>
            <input class="btn-check" type="radio" name="referred" id="referred2" autocomplete="off"
              v-model="options.referred" data-test="radioReferred" value="2" @change="doSave">
            <label @click="unmarkPrechecked('referred')" class="btn btn-outline-secondary"
              :class="preCheckClasses.referred[2]" for="referred2">{{
                $t('varFields.no') }}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="text-end">
      <button class="btn btn-success" type="button" name="button" @click="doSave">{{ $t('save') }}</button>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.lat-box {
  display: flex;
  align-items: center;
  margin-right: 5px;
  background: var(--Shades-W, #FFF);

  span {
    @include border_radius(50%);
    position: relative;
    width: 12px;
    height: 12px;
    background-color: white;
    border: 1px solid #818181;
    z-index: 2;
  }

  span.marked {
    background-color: #919191;
  }

  :nth-child(2) {
    position: relative;
    left: -2px;
    z-index: 1;
  }
}

.sub-title {
  color: var(--Shades-60, #727272);
  font-size: var(--font-size-14);
  font-weight: 500;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  // margin-top: 2.5rem;
  border: unset;
}

.action-info-box {
  display: flex;
  align-items: center;

  >div:first-child h4 {
    font-weight: normal;
    margin: 0 20px 0 0;
    vertical-align: middle;
  }

  .rd-box {
    width: auto;

    h4 {
      color: var(--Shades-80, #232323);
      font-size: var(--font-size-14);
      text-align: right;
      margin-right: 5px;
      min-width: 100px;
    }
  }

  .btn-group,
  >div:first-child h4,
  .rd-box h4,
  .rd-box {
    display: flex;

  }

  .btn-group {
    .btn-outline-secondary {
      @include border_radius(0);
      color: var(--Shades-50, #8F8F8F);
      font-size: var(--font-size-14); // need create 12
      letter-spacing: 2.275px;
      text-transform: uppercase;
      width: auto;
      padding: 10px 16.25px;
      margin: 0;
      // background-color:             var(--Shades-20, #F0F0F0);
      border: 1px solid var(--Shades-30, #D8D8D8);

      &:nth-child(2) {
        @include border_radius(10px 0 0 10px);
      }

      &:last-child {
        @include border_radius(0 10px 10px 0);
      }

      &.pre-check {
        background-color: rgb(191, 217, 255) !important;
        color: black !important;
      }
    }
  }

  .btn-group .btn-outline-secondary.pre-check:hover,
  .btn-group .btn-outline-secondary:hover,
  .btn-group .btn-outline-secondary.pre-check:focus,
  .btn-group .btn-outline-secondary.pre-check:active,
  .btn-check:checked+.btn-outline-secondarypre-check {
    // background-color:               white;
    background-color: hsl(172, 60%, 72%) !important;
    color: black !important;
  }

  .btn-check:checked+.btn.btn-outline-secondary {
    background-color: #00b99f;
    color: white;
  }
}

@media (max-width: 1049px) {
  .action-info-box {
    text-align: center;

    &,
    .btn-success,
    .rd-box,
    .rd-box h4 {
      display: block;
    }

    .rd-box {
      width: 100%;

      h4 {
        font-size: 14px;
        text-align: center;
        margin-right: 0;
        min-width: unset;
      }

      >div {
        display: inline-block;
        margin: 0;
        width: 33%;
        vertical-align: top;
      }

      .btn {
        font-size: 12px;
        margin: 0 auto 5px;
        float: unset;
      }
    }

    .btn-success {
      margin: 0 auto;
    }
  }
}

@media (max-width: 500px) {
  .action-info-box .rd-box>div {
    display: inline-block;
    margin: 0;
    width: 49%;
  }
}

@media (min-width: 991px) {
  .icon-ai {
    font-size: 2em;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'ExamImagePreAnalysis',
  props: ['exam', 'patient'],
  data: () => ({
    options: {
      rightEye: null,
      leftEye: null,
      referred: null,
    },
    alterationScore: {},
    fullScore: {
      od: {},
      os: {}
    },
    threshold: 0.2, // DEFAUlt
    systemConfiguration: {
      preAnalysis: {
        autoReferrerMode: null, // "auto"
      }
    },
    preCheckClasses: {
      od: {
        1: '',
        2: '',
        3: ''
      },
      os: {
        1: '',
        2: '',
        3: ''
      },
      referred: {
        1: '',
        2: ''
      }
    }
  }),
  computed: {
  },
  mounted() {
    const userStore = useUserStore()
    const session = userStore.sessionInfo

    const clinic = session.currentClinic
    this.systemConfiguration = this.$clinicService.getSystemConfiguration(clinic)

    this.threshold = this.systemConfiguration.preAnalysis.threshold

    this.options.referred = this.exam.referred ? this.exam.referred : null
    this.options.rightEye = this.exam.rightEyeHealth ? this.exam.rightEyeHealth : null
    this.options.leftEye = this.exam.leftEyeHealth ? this.exam.leftEyeHealth : null

    // this.alterationScore =  this.$aiService.alterationScore(this.exam.metadata)
    this.keepRetroCompatibility()
    if (!this.options.leftEye && !this.options.rightEye && !this.options.leftEye)
      this.computePrechecked()
  },
  methods: {
    unmarkPrechecked(clicked = undefined) {
      if (this.options.leftEye && (clicked === 'os' || clicked === 'referred')) {
        this.preCheckClasses.os[1] = ''
        this.preCheckClasses.os[2] = ''
        this.preCheckClasses.os[3] = ''
      }
      if (this.options.rightEye && (clicked === 'od' || clicked === 'referred')) {
        this.preCheckClasses.od[1] = ''
        this.preCheckClasses.od[2] = ''
        this.preCheckClasses.od[3] = ''
      }

      if (this.options.referred && (clicked === 'referred')) {
        this.preCheckClasses.referred[1] = ''
        this.preCheckClasses.referred[2] = ''
        this.doSave()
      }
    },
    doSave: async function () {

      if (!this.options.leftEye || !this.options.rightEye || !this.options.referred) {
        console.log('Not all fields are valid')
        return
      }

      console.log('Sending data')
      let params = {
        exam: this.exam.id,
        options: this.options
      }

      NProgress.start()
      try {
        await this.$mutiraoService.savePreAnalysis(params)
        this.emitAlert(this.$i18n.t('varFields.saveSuccess'), 'alert-success')
        this.exam.rightEyeHealth = this.options.rightEye
        this.exam.leftEyeHealth = this.options.leftEye

        // const targetView = this.systemConfiguration.preAnalysis.nextScreen
        // switch(targetView) {
        //   case 'quickReport':
        //     let data = this.$utils.dataQuery({
        //       patient: JSON.stringify(this.patient),
        //       isQuickReport: true
        //     })
        //
        //     this.$router.push({name: 'report', params: {examID: this.exam.id}, query: {data: data}})
        //     break
        //   case 'examList':
        //     this.$router.push({name: 'exam-list'})
        //     break
        // }
      } catch (err) {
        this.emitAlert(`${this.$i18n.t('varFields.saveError')} <strong>${err}</strong>`, 'alert-danger')
      }
      NProgress.done(true)
    },
    emitAlert(alertMsg, alertClass) {
      let alert = {
        message: alertMsg,
        class: alertClass
      }
      this.$emit('alertmsg', alert)
    },
    healthyEyeClass(laterality) {
      const eyeScore = this.fullScore[laterality]
      if (eyeScore && eyeScore.value !== null && eyeScore.value <= this.threshold) {
        if (laterality === 'od') {
          this.options.rightEye = 1
        }
        else if (laterality === 'os') {
          this.options.leftEye = 1
        }
        return 'pre-check'
      }
    },
    unhealthyEyeClass(laterality) {
      const eyeScore = this.fullScore[laterality]
      if (eyeScore && eyeScore.value !== null && eyeScore.value > this.threshold) {
        if (laterality === 'od') {
          this.options.rightEye = 2
        }
        else if (laterality === 'os') {
          this.options.leftEye = 2
        }
        return 'pre-check'
      }
    },
    lowQualityClass(laterality) {
      const eyeScore = this.fullScore[laterality]
      if (eyeScore && (eyeScore.value === 'LQ' || eyeScore.value === null)) {
        if (laterality === 'od') {
          this.options.rightEye = 3
        }
        else if (laterality === 'os') {
          this.options.leftEye = 3
        }
        return 'pre-check'
      }
    },
    referrerClass() {
      const referrerMode = this.systemConfiguration.preAnalysis.autoReferrerMode
      if (referrerMode != 'auto')
        return ""

      const status = this.alterationScore?.status
      if (status && status !== 'green') {
        this.options.referred = 1
        return 'pre-check'
      }


      const odStatus = this.alterationScore?.odStatus
      const osStatus = this.alterationScore?.osStatus

      if ((odStatus && odStatus !== 'green') || (osStatus && osStatus !== 'green')) {
        this.options.referred = 1
        return 'pre-check'
      }
      return ''

      // if (this.alterationScore && (this.alterationScore.od > this.threshold || this.alterationScore.os > this.threshold))
      //  return 'pre-check'
    },
    unreferrerClass() {
      const referrerMode = this.systemConfiguration.preAnalysis.autoReferrerMode
      if (referrerMode != 'auto')
        return ""

      // if (this.alterationScore && (this.alterationScore.od <= this.threshold && this.alterationScore.os <= this.threshold))
      //   return 'pre-check'

      const status = this.alterationScore?.status
      if (status === 'green') {
        this.options.referred = 2
        return 'pre-check'
      }

      if (this.alterationScore?.odStatus === 'green' && this.alterationScore?.osStatus === 'green') {
        this.options.referred = 2
        return 'pre-check'
      }
      return ''
    },
    /**
     * keep retro compatibility (old mutirao)
     */
    keepRetroCompatibility() {
      if (this.patient.referred > 0) {
        this.options.referred = this.patient.referred
      }

      if (this.exam.alteredRetina > 0) {
        let eyeHealth = (this.exam.alteredRetina == 1) ? 2 : 1 // healthy x unhealthy
        this.options.rightEye = eyeHealth
        this.options.leftEye = eyeHealth
      }

      if (this.exam.satisfactoryQuality == 2) {
        this.options.rightEye = 3 // impossible to observe
        this.options.leftEye = 3 // impossible to observe
      }
    },
    computePrechecked() {
      this.alterationScore = this.$aiService.alterationScore(this.exam.metadata)
      this.fullScore['od'] = { value: this.alterationScore.od }
      this.fullScore['os'] = { value: this.alterationScore.os }

      this.preCheckClasses.od[1] = this.healthyEyeClass('od')
      this.preCheckClasses.od[2] = this.unhealthyEyeClass('od')
      this.preCheckClasses.od[3] = this.lowQualityClass('od')

      this.preCheckClasses.os[1] = this.healthyEyeClass('os')
      this.preCheckClasses.os[2] = this.unhealthyEyeClass('os')
      this.preCheckClasses.os[3] = this.lowQualityClass('os')

      this.preCheckClasses.referred[1] = this.referrerClass()
      this.preCheckClasses.referred[2] = this.unreferrerClass()
    }
  }
}
</script>
