import eyercloud from '@/config/eyercloud'
import { useUserStore } from '@/store/user'
import { setLocale } from '@vee-validate/i18n';
import {useRouter} from 'vue-router'

class AuthService {

    goToFirstPage() {
      const router = useRouter()
      const userStore = useUserStore()
      const sessionInfo = userStore.sessionInfo
      console.log("goToFirstPage()", sessionInfo)

      // router guard???
      if (!userStore.isAuthenticated) {
        router.push("/login");
        return
      }

      if (sessionInfo.currentClinic) {
        console.log('SESSION INFO', JSON.parse(JSON.stringify(sessionInfo)))
        // const allowSynchronization = sessionInfo.currentClinic.synchronizePatientData
        // if (!allowSynchronization) {
        //   router.push("/clinic");
        // } else {
          router.push("/exam");
        // }
        return
      }

      router.push("/external-home");
    }

    async doLogin(params) {
      let response = await eyercloud.post(`/api/v2/eyercloud/auth/login`, params)
      let sessionInfo = response.data
      this.sessionChanged(sessionInfo)
      // TODO EC-406 set cookie
      // key = `${user.email}_${browser}_${os}`
      // SSID_${sha1(key)}

      // browser info >> https://www.npmjs.com/package/detect-browser

      return sessionInfo
    }

    /**
     * Use session information for wololo an account.
     */
    async doWololo(params) {
      let response = await eyercloud.post(`/api/v2/eyercloud/auth/wololo`, params)
      let sessionInfo = response.data
      this.sessionChanged(sessionInfo)
    }

    sessionChanged(sessionInfo) {
      console.log('sessionInfo: ', sessionInfo);
      
      //if (sessionInfo.currentClinic.percentageValue)
      sessionInfo.currentClinic.percentageValue = null
      sessionInfo.currentClinic.showAlert = null
      sessionInfo.currentClinic.showCloseStatisticsButton = null
    
      const userStore = useUserStore()
      userStore.loggedIn(sessionInfo) // TODO refactor, it may not be a login
      setLocale(sessionInfo.currentClinic.locale);
    }

    async logout(appContext) {
      await eyercloud.post(`/api/v2/eyercloud/auth/logout`, {})

      const userStore = useUserStore()
      userStore.logout()

      const io = appContext.$io
      console.log("removing all WS listeners...")
      io.socket.removeAllListeners();
    }

    async refreshSession(appContext) {
      let response = await eyercloud.get('/api/v2/eyercloud/user/session')
      appContext.$authService.sessionChanged(response.data)
    }

    async resetPassword(params) {
      let response = await eyercloud.post(`/api/v2/eyercloud/auth/password-recovery`, params)
      return response
    }

    async changePassword(params) {
      let response = await eyercloud.post(`/api/v2/eyercloud/auth/change-password`, params)
      return response
    }

  }

const authService = new AuthService();
export default authService;
