import eyerCloud from '@/config/eyercloud'

class RegistrationService {

    /**
     * Starts the user registration process after receiving an email with a link.
     *
     * @param {*} params
     * @returns
     */
    async invitation(params) {
      let response = await eyerCloud.post(`/api/v2/eyercloud/registration/invitation`, params)
      return response.data
    }

    async createCollaborator(params) {
      let response = await eyerCloud.post(`/api/v2/eyercloud/registration/create-collaborator`, params)
      return response.data
    }

    /**
     *
     * @param {*} params
     * @returns
     */
    async registerExistingUser(params) {
      let response = await eyerCloud.post(`/api/v2/eyercloud/registration/register-existing-user`, params)
      return response.data
    }

    async createAccount(params) {
      let response = await eyerCloud.post(`/api/v2/eyercloud/registration/first-access/create-account`, params)
      return response.data
    }

    async checkTicket(params) {
      let response = await eyerCloud.post(`/api/v2/eyercloud/registration/first-access/check-ticket`, params)
      return response.data
    }

    async createClinic(params) {
      let response = await eyerCloud.post(`/api/v2/eyercloud/registration/first-access/create-clinic`, params)
      return response.data
    }
  }

  const registrationService = new RegistrationService();
  export default registrationService;
