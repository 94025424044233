<template>
  <div class="" v-loader.opacity="loading">
    <div class="sub-header-box">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>

      <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/exam/" data-test="btnRouter">
              {{ $t('home') }}
            </router-link>
          </li>
          <li class="breadcrumb-item" aria-current="page">
            <router-link to="/settings/" data-test="btnRouter">
              {{ $t('createClinic.configuration') }}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page" v-if="device">EYER {{ device.serialNumber }}</li>
        </ol>
      </nav>
    </div>

    <div class="title-box">
      <div class="flex-start">
        <div class="">
          <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
            <IconArrowBack/>
          </button>

          <h2 class="title">{{ $t('device.devices') }}</h2>
        </div>
      </div>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-data>
        {{ $t('device.data') }}
      </template>
      <template v-slot:data>
        <DeviceDataTab v-if="tabActive === 'data' && device" :serial="device.serialNumber"></DeviceDataTab>
      </template>

      <template v-slot:tab-head-logs>
        Logs
      </template>
      <template v-slot:logs>
        <DeviceLogsTab v-if="tabActive === 'logs'" :device="device"></DeviceLogsTab>
      </template>
    </BaseTab>
  </div>
</template>

<style lang="scss" scoped>
.sub-header-box {
  display:                         flex;
  flex-direction:                  row;
  justify-content:                 flex-start;
  align-items:                     center;
  gap:                             20px;
  width:                           100%;
  padding:                         15px 20px;

  ol {
    margin:                        0;
  }
}

.title-box {
  display:                         flex;
  flex-direction:                  row;
  align-items:                     center;
  justify-content:                 space-between;
  height:                          55px;
  padding:                         10px 25px;
  // margin:                          2.3rem 0 1.7rem;

  > div {
    width:                         auto !important;
  }

  .flex-start {
    gap:                           20px;

    > div {
      display:                     flex;
      flex-direction:              row;
      justify-content:             flex-start;
      align-items:                 center;
      gap:                         20px;
    }
  }

  .btn-back,
  .btn {
    margin:                        0;
  }

  .title,
  .date {
    font-weight:                   400;
    line-height:                   normal;
    text-overflow:                 ellipsis;
    overflow:                      hidden;
    margin:                        0;
  }

  .btn-back {
    display:                       none;
  }
}

@media (max-width: 991px) {
  .sub-header-box,
  .title-box .btn-outline-secondary span {
    display:                       none;
  }

  .title-box {
    padding:                       10px;

    .date {
      font-size:                   18px;
    }

    .flex-start {
      > div {
        gap:                       15px;
      }
    }

    // .flex-start > div:last-child .btn-outline-secondary:last-child {
    .btn-back {
      display:                     flex;
    }

    .btn-outline-secondary {
      padding:                     11.5px 15px;
      svg {
        margin:                    0;
      }
    }

    .btn-back,
    .btn-outline-secondary {
      height:                      35px;
      margin:                      0 !important;
    }
  }
}
</style>

<script>
import DeviceDataTab from './DeviceDataTab.vue'
import DeviceLogsTab from './DeviceLogsTab.vue'

export default {
  name: 'device-show',
  components: {
    DeviceDataTab,
    DeviceLogsTab,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    msgError: null,
    tabActive: 'data',
    tabs: ['data'],
    device: null,
    deviceId: null,
  }),
  computed: {
    tab() {
      return this.$route.query.tab;
    },
  },
  created() {
    // watch param clinicID coming from notifications to force reload data
    this.$watch( // TODO REFACTOR KISS
      () => this.$route.params.deviceID,
      (toParams, previousParams) => {
        if (toParams != previousParams && this.$route.name == 'device-show')
          this.fetchData()
      }
    )
  },
  mounted() {
    this.deviceId = this.$route.params.deviceID;
    this.$activeLink.value = 'device'
    this.fetchData()

    if (this.tab) {
      this.switchTab(this.tab)
    }
  },
  methods: {
    goBack() {
      this.$router.push({name: 'settings', query: {tab: 'devices'}})
    },
    switchTab(tab) {
      this.tabActive = tab;
    },
    async fetchData() {
      let params = {
        id: this.deviceId
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$deviceService.getDevice(params)
        this.device = response.result
        console.log(response);
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    }
  }
}
</script>
