import eyercloud from '@/config/eyercloud'

class ExamDataService {

  async get(examDataID) {
    let response = await eyercloud.get(`/api/v2/eyercloud/examData/get`, { 
      params: {
        id: examDataID
      }
    })
    return response.data
  }

  async list(params) {
    let response = await eyercloud.get(`/api/v2/eyercloud/examData/list`, {params: params})
    return response.data
  }

  async remove(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/examData/remove`, params)
    return response.data
  }

  async download(params) {
    
    let response = await eyercloud.post(`/api/v2/eyercloud/examData/download-link`, params)
    return response.data
  }

  async save(examData, blob) {
    let formData = new FormData()
    formData.append('examData', JSON.stringify(examData))
    formData.append('data', blob) // binary data ALWAYS at the end

    let response = await eyercloud.post(`/api/v2/eyercloud/examData/save`, formData)
    return response.data
  }

  async createPanoramic(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/examData/create-panoramic`, params)
    //let response = {data: '807313f6-f8db-4586-90e8-9705d15ed167'}
    return response.data
  }

  async createPatientProgression(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/examData/create-patient-progression`, params)
    //let response = {data: '807313f6-f8db-4586-90e8-9705d15ed167'}
    return response.data
  }

  async createStereo(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/examData/create-stereo`, params)
    return response.data
  }

  async createPreview(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/examData/create-preview`, params)
    return response.data
  }

  async saveCroppedImage(params) {
    let formData = new FormData()
    formData.append('id', params.id)
    formData.append('data',  params.image) // binary data ALWAYS at the end

    let response = await eyercloud.post(`/api/v2/eyercloud/examData/save-cropped-image`, formData)
    return response.data
  }

  async createHeatmap(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/examData/create-ras`, params)
    return response.data
  }

}
const examDataService = new ExamDataService();
export default examDataService;
