<template>
  <div class="logs-box" v-loader.opacity.tab="loading">
    <!-- <div class="title-box">
      <h2 class="title">{{ $t('deviceLog.list') }}</h2>
    </div> -->

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseTable class="no-border" v-if="tableLogs" :tabledata="tableLogs" @item-opened="toDetails">
    </BaseTable>

  </div>
</template>

<style lang="scss" scoped>
.logs-box {
  padding:                     10px;
}

.inside-box {
  border:                      unset;
}

@media (max-width: 600px) {
  .logs-box .title-box .title {
    width:                     100%;
    text-align:                center;
    max-width:                 unset;
  }
}
</style>

<script>
export default {
  name: 'DeviceLogsTab',
  props: ['device'],
  data: () => ({
    loading: true,
    alert: {
      message: null,
      class: null
    },
    tableLogs: null,
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
  }),
  mounted() {
    this.fetchData()
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
      this.fetchData();
      this.amarracao()
    },
    fetchData: async function() {
      this.alert = {}

      let params = {
        id: this.device.id
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$deviceService.getLogList(params)

        for (let d of response.result) {
          d.actions = `
            <span>
              <a id="log_${d.uuid}" class="logFile" style="text-decoration: none;">
                <i class="fa fa-file-pdf-o" style="font-size: 18px; vertical-align: bottom;"></i>
                Download
              </a>
            </span>
          `
        }

        this.tableLogs = {
          'items': response.result,
          'total': response.totalCount,
          'columns': [
            {name: this.$i18n.t('deviceLog.name'), type: 'string', value: 'name', width: '60%'},
            {name: this.$i18n.t('deviceLog.date'), type: 'date', value: 'date', width: '20%'},
            {name: this.$i18n.t('deviceLog.action'), type: 'string', value: 'actions', width: '20%'}
          ]
        };
        this.totalPages = Math.ceil(response.totalCount / this.perPage);

        if (response.result.length === 0) {
          this.tableLogs = undefined;
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async toDetails(item) {
      let params = {
        uuid: item.uuid
      }
      let response = await this.$deviceService.downloadLog(params)
      const downloadURL = response.downloadURL
      const newWindow = window.open("","_blank");
      newWindow.location.href = downloadURL
    }
  }
}
</script>
