<template>
  <div class="row">
    <div class="col flex-between">
      <div class="">
        <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
          <IconArrowBack/>
          {{ $t('goBack') }}
        </button>

        <h1>{{ $t('externalPatient.myInformationTitle') }}</h1>

        <!-- <div class="action-buttons rounded text-end">
          <button class="btn btn-outline-secondary" type="button" name="button" data-test="btnOpenModalChangePassword" @click="openChangePassword()">
            <span>{{ $t('user.changePassword') }}</span>
          </button>
          <button class="btn btn-outline-secondary" type="button" name="button" data-test="btnOpenModalChangeEmail" @click="openChangeEmail()">
            <span>{{ $t('email.changeEmail') }}</span>
          </button>
        </div> -->

        <!-- <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
          <span v-html="alert.message"></span>
          <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
        </div> -->

        <div class="scrollbar">
          <div class="form-floating">
            <input type="text" class="form-control" name="patient[firstName]" ref="firstName" id="firstName" :placeholder="$t('user.firstName')" v-model="patientData.firstName" data-test="inputPatientFirstName" disabled>
            <label for="firstName">{{ $t('user.firstName') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="lastName" name="patient[lastName]" :placeholder="$t('user.lastName')" v-model="patientData.lastName" data-test="inputPatientLastName" disabled>
            <label for="lastName">{{ $t('user.lastName') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="telephone1" name="patient[telephone1]" :placeholder="$t('patient.telephone1')" v-model="patientData.telephone2" data-test="inputPatientTelephone" disabled>
            <label for="document">{{ $t('patient.telephone1') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="email" name="user[email]" :placeholder="$t('user.email')" v-model="user.email" data-test="inputPatientEmail" disabled>
            <label for="email">{{ $t('user.email') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="document" name="patient[document]" :placeholder="$t('user.document')" v-model="patientData.document" data-test="inputPatientDocument" disabled>
            <label for="document">{{ $t('user.document') }}</label>
          </div>

          <button class="btn btn-success" type="button" name="button" @click="openChangeEmail">{{ $t('email.changeEmail') }}</button>
          <button class="btn btn-success" type="button" name="button" @click="openChangePassword">{{ $t('user.changePassword') }}</button>
        </div>
      </div>
    </div>

    <teleport to="body">
      <ExternalPatientChangeEmailOffcanvas ref="externalPatientChangeEmailOffcanvas"/>

      <ExternalPatientChangePasswordOffcanvas ref="externalPatientChangePasswordOffcanvas"/>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.row {
  height:                         100%;
}

.col {
  flex-direction:                 column;
  > div:not(.flex-center) {
    padding-bottom:               2rem;
    padding-top:                  1rem;

    &,
    .scrollbar {
      display:                      flex;
      flex-direction:               column;
      align-items:                  flex-start;
      gap:                          26px;
      max-width:                    440px;
    }

    .scrollbar {
      width:                        100%;
      max-height:                   calc(100vh - 273px);
      padding-right:                10px;
    }
  }
  h1 {
    color:                        var(--Brand-Primary);
    font-size:                    38px;
    font-weight:                  300;
    line-height:                  normal;
    margin:                       0;
  }
  p {
    color:                        var(--Brand-Primary);
    font-size:                    var(--font-size-14);
    font-weight:                  300;
    line-height:                  140%; /* 19.6px */
  }
  .form-floating {
    width:                        100%;
  }
}

@media (max-width: 991px) {
  .col {
    h1 {
      font-size:                  32px;
    }
  }

  .scrollbar {
    overflow-y:                   scroll;
  }
}
</style>

<script>
import { useExternalStore } from '@/store/external'

import ExternalPatientChangeEmailOffcanvas from './ExternalPatientChangeEmailOffcanvas.vue'
import ExternalPatientChangePasswordOffcanvas from './ExternalPatientChangePasswordOffcanvas.vue'

export default {
  name: 'external-patient-show',
  components: {
    ExternalPatientChangeEmailOffcanvas,
    ExternalPatientChangePasswordOffcanvas,
  },
  data: () => ({
    alert: {},
    showChangeEmail: false,
    showChangePassword: false,
    patientData: {},
    user: {},
    showEmail: false
  }),
  mounted() {
    const externalStore = useExternalStore()
    this.patientData = externalStore.sessionInfo.patient
    this.user = externalStore.sessionInfo.user

    console.log(this.patientData);

    if (this.user.email.endsWith("@null.com")){
        this.user.email = ""        
    }
    
  },
  methods: {
    goBack() {
      this.$router.push({name: 'external-exam-list'})
    },
    async openChangePassword() {
      const ok = await this.$refs.externalPatientChangePasswordOffcanvas.show()
      if (ok) {
        console.log('ok externalPatientChangePasswordOffcanvas', ok);
      }
    },
    async openChangeEmail() {
      const ok = await this.$refs.externalPatientChangeEmailOffcanvas.show()
      if (ok) {
        console.log('ok externalPatientChangeEmailOffcanvas', ok);
      }
    },
  }
}
</script>
