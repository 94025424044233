export class Subject {

    callbacks = []

    subscribe(callback) {
        this.callbacks.push(callback)
    }

    next(data) {
        if (!this.callbacks) return

        for(let callback of this.callbacks){
            callback(data)
        }
    }

    unsubscribe(callback = undefined) {
        if(callback){
            const index = this.callbacks.indexOf(callback)
            if(index !== -1)
                this.callbacks.splice(index, 1)
            return
        }
        this.callback = []
    }
}