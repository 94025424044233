<template>
  <div class="external-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-7" :style="backgroundStyle">
          <!-- <div class="">
            <h6>{{ $t('createAccount.welcome') }}</h6>
            <img src="@/assets/images/phelcom/eyercloud_logo_white.svg" alt="Eyercloud logo">
          </div> -->
        </div>
        <div class="col-lg-5 flex-between">
          <div class=""></div>

          <div class="external-content">
            <h3 class="registration-title" v-if="step != 2">
              {{ $t('createAccount.clinicRegistration') }}
            </h3>

            <p class="mb-3" v-if="step != 2">
              <span>{{ firstName }}</span>, {{ $t('createAccount.completeTheSequence') }}
            </p>

            <ul class="wizard-step">
              <li :class="{active: step === 0, past: step > 0, full: step > 1}">
                {{ $t('createAccount.identification') }}
                <IconArrowRight/>
              </li>
              <li :class="{active: step === 1, past: step > 1}">
                {{ $t('createAccount.location') }}
                <IconArrowRight/>
              </li>
              <li :class="{active: step === 2, past: step > 2}">
                {{ $t('createAccount.finalize') }}
              </li>
            </ul>

            <vee-form v-slot="{ handleSubmit }" as="div">
              <form @submit.prevent.stop="handleSubmit(doSave)">
                <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
                  <span v-html="alert.message"></span>
                  <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
                </div>

                <div :key="0" v-if="step === 0">
                  <RegistrationClinicStepOne :clinic="clinic"/>
                </div>

                <div :key="1" v-if="step === 1">
                  <RegistrationClinicStepTwo :clinic="clinic"/>
                </div>

                <div :key="2" v-if="step === 2">
                  <RegistrationClinicStepThree :clinic="clinic"/>
                </div>

                <hr>

                <div class="action-buttons">
                  <button class="btn btn-default" type="button" name="button" v-if="step === 2" @click="step = 0" data-test="btnChangeData">{{ $t('createAccount.changeData') }}</button>
                  <button class="btn btn-success" type="submit" name="button" v-if="step < 2" data-test="btnNext">{{ $t('pagination.next') }}</button>
                  <button class="btn btn-success" type="submit" name="button" v-if="step === 2" :disabled="loading" data-test="btnFinish">{{ $t('pagination.finish') }}</button>
                </div>
              </form>
            </vee-form>
          </div>

          <div class="powered-box">
            <p class="d-inline-block">POWERED BY</p>
            <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
              <img src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
            </a>
            <p class="d-inline-block">v {{ appVersion }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.external-page .col-lg-5 .external-content {
  form {
    max-height: 60vh;
  }
}

.wizard-step {
  margin-bottom:                0;
}

.col-lg-5 {
  h3 {
    span {
      font-weight:              700;
    }
  }
  .flex-between {
    gap:                        20px;
    margin:                     45px 0;
  }
  .form-control {
    @include                    border_radius(5px);
    height:                     67px;
    border:                     1px solid #DCDCDC;
    background:                 #FFF;
  }
}
.col-lg-7 {
    @include                               border_radius(10px);
    display:                               flex;
    flex-direction:                        column;
    justify-content:                       space-between;
    background:                            linear-gradient(252deg, #000 0%, #0D4636 100%);
    background:                            url('@/assets/images/phelcom/register_banner_en.png') no-repeat center bottom 100%, linear-gradient(252deg, #000 0%, #0D4636 100%);
    background-size:                       cover;
    // background:                            url('@/assets/images/phelcom/welcome_banner_en-US.png') no-repeat right center, linear-gradient(252deg, #000 0%, #0D4636 100%);
    padding:                               5rem 3rem 3rem;
  }

@media (max-width: 991px) {
  .external-page {
    width:                      100%;
    overflow-y:                 hidden;

    .col-lg-7 {
      display:                  none;
    }

    .col-lg-5 {
      .external-content {
        width:                  100%;

        form {
          max-height:           calc(100dvh - 170px);
        }
      }

      .powered-box {
        justify-content:        center;
      }
    }
  }
}
</style>

<script>
import RegistrationClinicStepOne from './RegistrationClinicStepOne.vue'
import RegistrationClinicStepTwo from './RegistrationClinicStepTwo.vue'
import RegistrationClinicStepThree from './RegistrationClinicStepThree.vue'
import utilService from '../../../services/util-service.js';

export default {
  name: 'registration-clinic',
  components: {
    RegistrationClinicStepOne,
    RegistrationClinicStepTwo,
    RegistrationClinicStepThree,
  },
  data: () => ({
    alert: {
      message: null,
      class: null,
    },
    loading: false,
    step: 0,
    firstName: "",
    ticket: {},
    clinic: {
      clinicName: "",
      document: "",
      mandatoryDocument: 'none',
      locale: "pt-br",
      street: "",
      city: "",
      state: "",
      country: "",
      telephone: "",
      email: "",
      mobile: "",
      telephone3: ""
    },
    appVersion: process.env.VUE_APP_VERSION,
    countryCode: 'en',
    backgroundStyle: null,
  }),
  mounted() {
    this.countryCode = utilService.getCurrentLanguage()  
    const imagePath = require(`@/assets/images/phelcom/register_banner_${this.countryCode}.png`);
    this.backgroundStyle = `background: url(${imagePath}) no-repeat center bottom 100%, linear-gradient(252deg, #000 0%, #0D4636 100%); background-size: cover;`
    
    try {
      let data = this.$utils.parseDataQuery(this.$route.query.data)
      this.firstName = data.firstName
      this.ticket = data.ticket
    } catch(err) {
      // TODO show error asking for "checkURL and try again..."
    }
  },
  methods: {
    async doSave() {
      let params = {
        clinic: this.clinic,
        ticket: this.ticket
      }

      try {
        if (this.step != 2) {
          this.step += 1;
          return
        }

        this.loading = true
        NProgress.start()
        await this.$registrationService.createClinic(params)
        let data = this.$utils.dataQuery({
          firstName: this.firstName
        })
        this.$router.push({name: 'registration-clinic-created', query: { data: data} })
      } catch (err) {
        console.log(err) // TODO ENH EC-291 (for production is a must)
        let errorMessage = err.response.data.errorMessage == 'consumedTicket' ?
          err.response.data.errorMessage : 'general.createdError'

        this.$message.popup(this, errorMessage, 'operationFail', 'error')
        // this.alert = this.$message.alert(this, errorMessage, 'danger')
      }
      NProgress.done(true)
      this.loading = false
    }
  }
}
</script>
