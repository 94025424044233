import axios from 'axios'
import { useUserStore } from '@/store/user'

const BASE_URL = process.env.VUE_APP_API_URL

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 16000,
  withCredentials: true
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  if (error.response.status === 402) { // "payment is needed"
    const errorData = error.response.data ? error.response.data : {}
    const checkForMFA = errorData.error = 'checkForMFA'
    if (checkForMFA) {
      // TODO is this needed (reset user store)??
      // const userStore = useUserStore()
      // userStore.reset() // avoid reusing other clinic info
      window.location = `/auth/mfa?data=${errorData.data}`
      return
    }
  }

  if (error.response.status === 403) {
    const userStore = useUserStore()
    userStore.reset() // avoid reusing other clinic info
    // EC-517 avoid login loop
    // console.log("????????????? EXPIROUUU", "isAuthenticated? " + userStore.isAuthenticated)

    window.location = "/auth/expired"
  }

  if (error.response.status === 503) {
    window.location = "/maintenance"
  }
  console.log('status', error.response.status);
  console.log(error, ' error ');
  return Promise.reject(error);
});

export default {
  ...instance
}
