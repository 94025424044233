<template>
  <div class="row scrollbar">
    <div class="col-lg-7 flex-center">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>

      <h1 v-if="exam">{{ $t('user.exam') }} {{ formatDate(exam.date) }}</h1>

      <div class="scrollbar" v-if="examImages.length">
        <lightgallery-vue
          class="image-info-box grid-wrap-between ease-all" ref="lGallery" id="lightgallery"
          :settings="lightGallerySettings" :onAfterOpen="extraCustomization">
          <template v-for="(item, index) in examImages" :key="index">

            <a v-if="item.type.endsWith('VIDEO')" onclick="return false" class="thumbnail-box loading-eye" :class="item.type" :key="`video_${index}`" data-lg-size="1280-720"
              :data-test="`anchorVideo_${index}`" :data-video="getVideoSource(item)" data-download-url="" data-sub-html="<h4></h4><p></p>">
              <img class="lg-selector ease-all" src="@/assets/images/default-video2.png" alt="" @load="removeLoading($event)"/>
              <div class="thumb-actions">
                <div class="btn-group btn-group-sm" role="group" aria-label="Thumb actions">
                  <button class="btn btn-outline-secondary group" type="button" name="button" :data-test="`btnDownloadImage_${index}`" @click.stop.prevent="doDownload(item)">
                    <i class="fa fa-download"></i>
                  </button>
                </div>
              </div>
            </a>

            <a v-else class="thumbnail-box loading-eye" onclick="return false" :class="item.type" :href="dataPath +'/'+ item.name" :data-test="`anchorImage_${index}`">
              <img class="lg-selector ease-all" :src="thumbDataPath +'/'+ item.name" alt="" @load="removeLoading($event)"/>
              <div class="thumb-actions">
                <div class="btn-group btn-group-sm" role="group" aria-label="Thumb actions">
                  <button class="btn btn-outline-secondary group" type="button" name="button" :data-test="`btnDownloadImage_${index}`" @click.stop.prevent="doDownload(item)">
                    <i class="fa fa-download"></i>
                  </button>
                </div>
              </div>
            </a>
          </template>
        </lightgallery-vue>
      </div>
      <!-- YAMA temporary removal -->
      <button class="btn btn-success" type="button" name="button" @click.stop.prevent="doDownloadExam()"> {{ $t('externalPatient.downloadImages') }}</button>
    </div>
    <div class="col-lg-5 flex-center">
      <BaseInfiniteTable class="no-border" v-if="tableReport" :tabledata="tableReport">
        <template v-slot:column_content="{item}">
          <button class="btn btn-outline-secondary" type="button" name="button" :data-title="$t('tooltips.report')" :data-test="`btnExamDownload_${item.uuid}`"  @click.stop.prevent="downloadPDF(item)">
            <IconDownloadPatient/>
            <span class="ms-2"> {{ $t('tooltips.report') }}</span>
          </button>
        </template>
      </BaseInfiniteTable>
      <!--
      <button class="btn-icon btn-top" type="button" name="button" v-if="currentPage > 1 && tableReport.items.length > 20" @click="goTop">
        <IconArrowUp/>
      </button>
      -->
      <!-- <ExternalExamReportPreview v-if="examID" :exam_id="examID"/> -->

      <!-- TODO YAMANAKA: endpoint para baixar todos os laudos de uma vez, é possivel? ou removo esse trem? hahahaha -->
      <!-- YAMA temporary removal -->
      <button class="btn btn-success" type="button" name="button" @click.stop.prevent="downloadLatestPDF()">{{ $t('externalPatient.downloadLatestReports') }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.row {
  // height:                       calc(100% - 70px);
  align-items:                    center; // flex-end
}

.col-lg-7,
.col-lg-5 {
  flex-direction:                 column;
  // width:                        70%;
  // max-width:                    340px;
  // padding-bottom:               2rem;
  // padding-top:                  1rem;
  h1 {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    38px;
    font-weight:                  300;
    line-height:                  normal;
    margin:                       15px 0 40px;
  }
  > div {
    display:                      flex;
    flex-direction:               column;
    align-items:                  flex-start;
    gap:                          26px;
    max-height:                   440px;
    height:                       100%;
    overflow-y:                   auto;
    padding-right:                10px;
    margin-bottom:                26px;
  }

  .no-border {
    width:                        100%;
  }
}

.col-lg-7 {
  align-items:                    flex-start;
}


.btn {
  &.btn-success {
    // color:                        var(--Brand-Primary, #1B294A);
    font-size:                    var(--font-size-14);
    font-weight:                  700;
    line-height:                  normal;
    letter-spacing:               4.2px;
    text-transform:               uppercase;
    text-align:                   center;
    max-width:                    340px;
    width:                        100%;
    margin:                       0 auto;
    border:                       1px solid var(--Shades-30, #D8D8D8);
    background:                   var(--Brand-Secondary, #3CB391);
  }
}

.scrollbar,
.image-info-box {
  width:                          100%;
}

.image-info-box {
  &.grid-wrap-between {
    grid-template-columns:        repeat(auto-fill, 32%) !important;
  }
  .thumbnail-box {
    // margin:                       0;
    img {
      aspect-ratio:               87 / 74;
    }
  }
  .thumb-actions {
    .btn-group .btn:last-child {
      @include                    border_radius(99px);
      padding:                    6.244px 12.489px;
    }
  }
}

@media (max-width: 991px) {
  .row {
    max-height:                   calc(100dvh - 135px);
    padding-top:                  40px;
    overflow-y:                   scroll;
  }

  .col-lg-7 {
    h1 {
      font-size:                  32px;
      margin-bottom:              26px;
    }

    .scrollbar {
      overflow:                   hidden;
    }
  }

  .col-lg-7,
  .col-lg-5 {
    max-height:                   unset;
    padding-right:                0;
  }

  .image-info-box.grid-wrap-between {
    grid-template-columns:        repeat(auto-fill, 48%);
  }

  .btn.btn-success {
    width:                        calc(100% - 10px);
  }
}
</style>

<script>
import ExternalExamReportPreview from './ExternalExamReportPreview.vue'

import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';
import lgZoom from '@/assets/dependencies/lightGallery/plugins/zoom';
// import lgZoom from 'lightgallery/plugins/zoom';

const IS_PRODUCTION = process.env.NODE_ENV == 'production'

export default {
  name: 'external-exam-show',
  components: {
    ExternalExamReportPreview,
  },
  data: () => ({
    loading: null,
    alert: {
      message: null,
      class: null
    },
    urlPdf: {
      url: null,
      id: null,
    },
    examID: null,
    exam: null,
    examImages: [],
    tableReport: {
      items: [],
      total: 0,
      columns: []
    },
    thumbDataPath: null,
    dataPath: null,
    lightGallerySettings: {
      showZoomInOutIcons: true,
      actualSize: false,
      autoplayVideoOnSlide: true,
      speed: 300,
      controls: true,
      licenseKey: 'CE440F6C-34CE4C2A-86065648-48895C83',
      plugins: [],
    },
    patientId: null,
    patientName: null,
  }),
  created() {
    this.lightGallerySettings.plugins = [lgAutoplay, lgFullscreen, lgThumbnail, lgVideo, lgZoom]
  },
  mounted() {
    const data = this.$utils.parseDataQuery(this.$route.query.data)
    this.examID = this.$route.params.examID
    this.patientId = data.patientId
    this.patientName = data.patient_name
    //console.log('data : ', data )
    //console.log('this.$route.params : ', this.$route.params )

    this.fetchData()
    this.fetchReportData()

    // TODO EC-360
    // retrieve exam details using this endpoint -> /api/v2/eyercloud/external/exam/get [param -> id: exam.id]
    // this endpoint will return the examDataList too
  },
  provide() {
    return {
      scrollToTop: (callback) => {
        this.scrollTopCallback = callback;
      }
    };
  },
  methods: {
    goTop() {
      if (this.scrollTopCallback) {
        this.scrollTopCallback();
      }

      this.$nextTick(() => {
        this.currentPage = 1

        this.fetchData()
      })
    },
    goBack() {
      this.$router.push({name: 'external-exam-list'})
    },
    fetchData: async function () {
      this.examImages = []
      this.loading = true
      NProgress.start()
      try {
        let response = await this.$externalService.getExam({id: this.examID});
        this.exam = response.exam
        this.examImages = response.examDataList

        this.thumbDataPath = `${response.thumbDataPath}`
        this.dataPath = `${response.dataPath}`

        // TODO move to service
        if (!IS_PRODUCTION) {
          this.thumbDataPath = `http://localhost:1337${response.thumbDataPath}`
          this.dataPath = `http://localhost:1337${response.dataPath}`
        }

        // this.extraCustomization()
      } catch (err) {
        console.log("OPSSS...", err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async fetchReportData() {
      this.alert.message = null;

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$externalService.getReportList({id: this.examID});
        console.log('response', response);

        this.tableReport = {
          'items': response.result,
          'total': response.totalCount,
          'columns': [
            {name: this.$i18n.t('exam.date'), type: 'date', value: 'date', width: '40%'},
            // {key: 'name', name: this.$i18n.t('exam.diagnosticHypothesis'), type: 'string', value: this.$i18n.t('exam.diagnosticHypothesis'), width: '40%'},
            {name: this.$i18n.t('physician'), type: 'string', value: 'responsible.rawName', width: '50%'},
            {key: 'content', name: '', type: 'custom', value: 'actions', width: '10%'}
          ]
        };

        if (response.result.length === 0) {
          this.tableReport = {}
          // this.$message.popup(this, 'request_empty', 'operationWarning', 'warn')
          this.alert = this.$message.alert(this, 'request_empty', 'warning')
        }
        // this.generateUrlPDF(response.result[0].id)
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    extraCustomization() {
      let self = this

      $(".lg-download").on("click", function (event) {
        event.preventDefault();
        let examDataUUID = this.href.split('/').pop()
        let item = { uuid: examDataUUID }
        self.doDownload(item, event)
      })
    },
    async doDownload(item) {
      this.loading = true
      NProgress.start()
      try {
        let params = {}
        if (item.id)
          params['id'] = item.id

        if (item.uuid)
          params['uuid'] = item.uuid

        let response = await this.$externalService.downloadExamData(params)

        fetch(response.downloadURL)
          .then(res => res.blob())
          .then(blob => {
            let objectURL = URL.createObjectURL(blob);
            let el = document.createElement('a');
            el.href = objectURL;
            el.download = `${item.uuid}.jpeg`;
            el.click();
            URL.revokeObjectURL(objectURL);
        });
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async doDownloadExam(){
        console.log('this.examID: ',  this.examID)
        let params = {
            id: this.examID,
            patientId: this.patientId,
            patientName: this.patientName,
        }
        try{
            await this.$externalService.downloadExam(params)
        }catch (error){
            console.log('Error:', error)
        }

    },
    removeLoading(event) {
      let parentNode = event.target.parentElement.classList;
      let currentlyNode = event.target.classList;

      this.$nextTick(() => {
        currentlyNode.add('loaded')
        parentNode.remove('loading-eye')
      });
    },
    getVideoSource(item) {
      let result = {
        "source": [
          {
            "src": `${this.dataPath}/${item.name}?autoplay=1`,
            "type": "video/mp4"
          }
        ],
        "attributes": {
          "preload": false,
          "playsinline": false,
          "controls": true,
          "loop": true
        }
      }

      return JSON.stringify(result)
    },
    downloadPDF(item) {
      try{
        this.$externalService.generateReport(item.id);
      }catch (error) {
        console.log('Error:', error)
      }
    },
    downloadLatestPDF(){
      let latest = this.tableReport.items[0].id
      console.log("latest: ", latest);
      try{
        this.$externalService.generateReport(latest);
      }catch (error) {
        console.log('Error:', error)
      }
    },
    formatDate(date) {
      return moment(date).format(this.$i18n.t('dateTimeFormat'))
    },
  },
  // unmounted() {
  //   this.tabActive = 'info'
  // }
}
</script>
