// reference https://stephanlangeveld.medium.com/simple-local-storage-implementation-using-vue-3-vueuse-and-pinia-with-zero-extra-lines-of-code-cb9ed2cce42a
import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useUserStore = defineStore('user', {
    state: () => ({
        isAuthenticated: useStorage('isAuthenticated', false),
        sessionInfo: useStorage('sessionInfo', {}),
        tipsCount: useStorage('tipsCount', 0),
        // TODO expiration: new Date(NEWDATE.getTime() + (30 * 60 * 1000))
    }),

    getters: {
        /*
            isAuthenticated() {
                return this.isAuthenticated
            }
        */
    },

    actions: {
        reset() {
            this.isAuthenticated = false
            this.sessionInfo = {}
        },
        loggedIn(_sessionInfo) {
            this.$patch({
                isAuthenticated: true,
                sessionInfo: _sessionInfo
            })

            // we could do other stuff like redirecting the user
        },
        logout() {
            this.$patch({
                isAuthenticated: false,
                sessionInfo: {}
            })

            // we could do other stuff like redirecting the user
        },

        termAccepted(_sessionInfo) {
            this.$patch({
                sessionInfo: _sessionInfo
            })
        },
        
        welcomeAccepted(_sessionInfo){
            this.$patch({
                sessionInfo: _sessionInfo
            })
        },

        incrementTipsCount(tipsCount) {
          if (tipsCount != 0)
            this.tipsCount += tipsCount
          else
            this.tipsCount = tipsCount
          // console.log('this.tipsCount', this.tipsCount);
        }
    },
})
