<template>
  <div class="main-box" v-loader.opacity="loading">
    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseInfiniteTable class="no-border" v-if="tableUsers.items.length" :tabledata="tableUsers" @item-opened="toDetails">
      <template  v-slot:column_security="{item}">
        <span class="label" :class="mfaStatus(item)">
          MFA
        </span>
      </template>

      <template v-slot:column_permissions="{item}">
        <span class="label" :class="getPermissionClass(item)">
          {{ $t(item.permissions) }}
        </span>
      </template>
      <!-- <template v-slot:column_actions="{item}" v-if="session.isPowerfull">
        <button class="btn btn-danger btn-sm float-end" type="button" name="button" data-title="Delete"
                v-if="!item.permissions.includes('owner')" @click.stop="openModalDelete(item.id)">
          <i class="fa fa-trash-o"></i>
        </button>
      </template> -->
    </BaseInfiniteTable>

    <button class="btn-icon btn-top" type="button" name="button" v-if="currentPage > 1 && tableUsers.items.length > 20" @click="goTop">
      <IconArrowUp/>
    </button>

    <UserInvitationOffcanvas :offcanvas_id="'userInvitationOffcanvas'" ref="userInvitationOffcanvas"/>

    <!-- <teleport to="body"> -->
      <!-- <UserInvitationModal v-if="showModalInvitation" @alertmsg="setAlert" @close="showModalInvitation = false"></UserInvitationModal> -->

      <!-- <BaseGenericModal :modal_id="'modalUserGeneric'" ref="modalGeneric"></BaseGenericModal> -->
    <!-- </teleport> -->

    <teleport to="#settingsActionBar" v-if="isSettingsMenuMounted">
      <button class="btn btn-success" type="button" name="button" data-test="btnOpenModalInvitation" @click="openModalInvitation">
        {{ $t('user.invite') }}
      </button>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.main-box {
  padding:                                 10px;
}

.btn {
  @include                                 border_radius(99px);
  font-size:                               var(--font-size-12);
  font-weight:                             400;
  letter-spacing:                          3.64px;
  text-transform:                          uppercase;
}

.btn-success {
  color:                                   var(--Shades-W);
  font-weight:                             700;
  height:                                  44px;
  padding:                                 15px 20px;
  background:                              var(--Brand-Secondary);
  border:                                  1px solid var(--Brand-Secondary);
}

  .title-box,
  .title-box .btn,
  .vtable > tbody > tr > td .btn.btn-danger.btn-sm {
    margin-bottom:                         0;
  }

  .vtable {
    > tbody > tr > td {
      span.label-warning {
        text-transform:                    uppercase;
        vertical-align:                    middle;
      }
      .btn.btn-danger.btn-sm {
        line-height:                       1;
      }
      &:first-child {
        span {
          text-transform:                  capitalize;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .main-box .alert {
      margin:                              0 5px;
    }
  }

  @media (min-width: 768px) {
    .main-box .alert {
      margin:                              0 15px;
    }
  }
</style>

<script>
import { useUserStore } from '@/store/user'
import UserInvitationOffcanvas from './UserInvitationOffcanvas.vue'

export default {
  name: 'users-list',
  components: {
    UserInvitationOffcanvas,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    tableUsers: {
      items: [],
      total: 0,
      columns: []
    },
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
    showModalInvitation: false,
    isSettingsMenuMounted: false,
  }),
  mounted: async function() {
    this.$activeLink.value = 'users'
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.fetchData();

    this.isSettingsMenuMounted = true
  },
  provide() {
    return {
      scrollToTop: (callback) => {
        this.scrollTopCallback = callback;
      }
    };
  },
  methods: {
    goTop() {
      if (this.scrollTopCallback) {
        this.scrollTopCallback();
      }

      this.$nextTick(() => {
        this.currentPage = 1

        this.fetchData()
      })
    },
    mfaStatus(user) {
      return this.$userService.mfaStatus(user)
    },
    onPageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    fetchData: async function() {
      this.alert = {}
      let params = {
        page: this.currentPage,
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$userService.list(params);
        console.log("THE USERS >>>", response.result)

        let hideMFA = !this.session.isPowerfull // TODO retrieve from clinic.security.mfaMode [optional or mandatory]

        this.tableUsers = {
          'items': response.result,
          // 'total': response.totalCount,
          'total': response.result.length,
          'columns': [
            {name: this.$i18n.t('user.name'), type: 'string', value: 'collaborator.rawName', width: '20%'},
            {name: this.$i18n.t('user.email'), type: 'string', value: 'email', width: '20%'},
            {name: this.$i18n.t('user.document'), type: 'string', value: 'collaborator.document', width: '20%', align: 'text-center'},
            {key: 'permissions', name: this.$i18n.t('user.permissions'), type: 'custom', value: 'permissions', width: '20%', align: 'text-center'},
            {key: 'security', hide: hideMFA, name: this.$i18n.t('user.security'), type: 'custom', value: 'security', width: '20%', align: 'text-center'},
            // {key: 'actions', name: this.$i18n.t('user.administration'), type: 'custom', value: 'actions', width: '10%', align: 'text-center'},
          ]
        };
        this.totalPages = Math.ceil(response.result.length / this.perPage);
        // this.totalPages = Math.ceil(response.totalCount / this.perPage);

        if (response.result.length === 0) {
          this.tableUsers = {};
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    toDetails(item) {
      this.$router.push({name: 'user-show', params: {userID: item.id}, query: {tab: 'data'}})
    },
    async openModalInvitation() {
      const ok = await this.$refs.userInvitationOffcanvas.show({
        title: this.$i18n.t('user.inviteCollaborator'),
        // text: text,
        okButton: this.$i18n.t('user.send'),
        okButtonClass: 'btn-success',
        // cancelButton: this.$i18n.t('termsPatient.doNotAccept'),
        // cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        console.log('the invitation has been sent');
        // this.$router.push({name: 'patient-create', params: {patientID: ''}})
      }
    },
    setAlert(val) {
      this.alert = {
        message: val.message,
        class: val.class
      };
    },
    getPermissionClass(item) {
      if (item.permissions == 'admin') {
        return 'label-blue'
      } else if (item.permissions == 'physician') {
        return 'label-green'
      } else if (item.permissions == 'technician') {
        return 'label-purple'
      } else if (item.permissions == 'specialist') {
        return 'label-warning'
      } else {
        return 'label-pink'
      }
    }
  }
}
</script>
