<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" :id="offcanvas_id" data-bs-backdrop="static" :aria-labelledby="`${offcanvas_id}_label`">
    <div class="offcanvas-header">
      <button class="btn-back" id="btnBack" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" :id="`${offcanvas_id}_label`">{{ $t('clinic.selectClinic') }}</h5>
    </div>
    <div class="offcanvas-body">
      <p>{{ $t('clinic.selectClinicChoose') }}</p>

      <ul class="scrollbar">
        <li v-for="(item, index) in clinicList" :key="index">
          <button class="btn btn-outline-secondary" :class="{'active': session.currentClinic && session.currentClinic.id == item.id}" type="button" name="button" @click="doChange(item)">
            {{ item.name }}
            <span v-if="session.currentClinic && session.currentClinic.id == item.id"> ({{ $t('createClinic.currentClinic') }})</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  // width:                                510px !important;
  // padding:                              40px 20px 40px 40px;

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    p {
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin-bottom:                    26px;
    }

    ul {
      list-style-type:                  none;
      display:                          flex;
      flex-direction:                   column;
      gap:                              10px;
      padding:                          0 20px 0 0;
      max-height:                       calc(100% - 65px);
      overflow-y:                       auto;

      li {
        width:                          100%;

        .btn-outline-secondary {
          @include                      border_radius(99px);
          font-size:                    11px;
          font-weight:                  700;
          line-height:                  normal;
          letter-spacing:               3.3px;
          text-transform:               uppercase;
          width:                        100%;
          padding:                      15px 20px;

          &:focus,
          &:hover,
          &.active {
            background:                 #E3FDF4;
            border:                     1px solid var(--Brand-Secondary, #3CB391);
          }
        }
      }
    }

    p,
    ul li .btn-outline-secondary {
      color:                            var(--Brand-Primary);
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'ClinicChangeOffcanvas',
  props: ['offcanvas_id'],
  data: () => ({
    loading: false,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    title: null,
    text: null,
    offcanvas_clinic: null,
    clinicList: {},
    currentPage: 1,
  }),
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.fetchData()
  },
  methods: {
    show(options = {}) {
      this.offcanvas_clinic = new bootstrap.Offcanvas(document.getElementById(this.offcanvas_id)).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      let _self = this
      setTimeout(async _ => {
        await this.$router.push({name: 'app'})
      }, 300)

      document.getElementById('btnBack').click()

      this.offcanvas_clinic = null;
      this.$message.popup(this, 'operationSuccess', 'operationSuccess', 'success')
      //this.resolvePromise(true)
    },

    doCancel() {
      this.offcanvas_clinic = null;
      this.resolvePromise(false)
    },

    async fetchData() {
      this.clinicList = {}
      let params = {
        page: this.currentPage,
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.getList(params);
        this.clinicList = response.result

      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true);
      this.loading = false
    },

    async doChange(item) {
      let params = {
        id: item.id,
      }

      this.loading = true
      NProgress.start()
      try {

        const sessionInfo = await this.$clinicService.changeClinic(params);
        this.session = sessionInfo;
        this.session.examCurrentPage = 1
        this.$authService.sessionChanged(sessionInfo)

        this.doConfirm()

      } catch (err) {
        this.$message.popup(this, 'clinic.errorChangeClinic', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'clinic.errorChangeClinic', 'danger')
      }
      this.loading = false
      NProgress.done(true);
    },
  }
}
</script>
