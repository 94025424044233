<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" :id="offcanvas_id" data-bs-backdrop="static" :aria-labelledby="`${offcanvas_id}_label`">
    <div class="offcanvas-header">
      <button class="btn-back" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" :id="`${offcanvas_id}_label`">{{ $t('dashboard.notification') }}</h5>
    </div>
    <div class="offcanvas-body scrollbar">
      <ul v-if="notification">
        <li :id="`event_${item.uuid}`" v-for="(item, index) in notification" :key="index" :data-test="`btnNotificationClicked_${index}`">
          <h5>
            <span></span>
            {{ $filters.dateTime(item.date) }}
          </h5>

          <h3>{{ item.csvUpload ? $t('clinicConfig.uploadCsvCompleted') : $t('clinicConfig.newExam') }}</h3>

          <p>
            {{ item.csvUpload ? $t('clinicConfig.importCountMessage', {insertedCount: item.insertedCount}) : $t('clinicConfig.newExamMessage') }}
          </p>

          <button class="btn btn-outline-secondary" type="button" name="button" v-if="!item.csvUpload" @click="notificationClicked(item.exam.id)">{{ $t('clinicConfig.accessNow' )}}</button>
        </li>
      </ul>

      <div class="no-msg" v-else>
        <h3>{{ $t('dashboard.noNotification') }}</h3>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  width:                                510px !important;
  padding:                              40px 20px 40px 40px;

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    overflow-y:                         auto;
    padding-right:                      20px;

    h5 {
      font-size:                        var(--font-size-12);
      font-weight:                      400;
      display:                          flex;
      align-items:                      center;
      gap:                              5px;

      span {
        @include                        border_radius(50%);
        display:                        inline-block;
        width:                          11px;
        height:                         11px;
        background:                     var(--Shades-30);
      }
    }

    h3 {
      font-size:                        18px;
      font-weight:                      500;
    }

    h5,
    h3,
    p,
    .btn-outline-secondary {
      color:                            var(--Brand-Primary);
      line-height:                      140%; /* 19.6px */
    }

    p {
      font-size:                        var(--font-size-14);
      font-weight:                      300;
    }

    ul {
      list-style-type:                  none;
      display:                          flex;
      flex-direction:                   column;
      align-items:                      flex-start;
      gap:                              19px;
      padding:                          0;

      li {
        width:                          100%;
        padding-top:                    10px;
        border-top:                     1px solid var(--Shades-20, #F0F0F0);



        .btn-outline-secondary {
          font-size:                    11px;
          font-weight:                  700;
          line-height:                  normal;
          letter-spacing:               3.3px;
          text-transform:               uppercase;
          width:                        100%;
        }
      }
    }

    .no-msg {
      height:                           100%;
      display:                          flex;
      align-items:                      center;
      justify-content:                  center;
      overflow:                         hidden;
    }
  }
}
</style>

<script>
export default {
  name: 'NotificationsOffcanvas',
  props: ['offcanvas_id'],
  data: () => ({
    offcanvas_notification: null,
    notification: null,
  }),
  mounted() {
    console.log('this.notification', this.notification);
  },
  methods: {
    show(options = {}) {
      console.log('clicou show no trem dentro do offcanvas');
      if (options.notification)
        this.notification = options.notification

      this.offcanvas_notification = new bootstrap.Offcanvas(document.getElementById(this.offcanvas_id)).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.offcanvas_notification = null;
      this.resolvePromise(true)
    },

    doCancel() {
      this.offcanvas_notification = null;
      this.resolvePromise(false)
    },

    async addFake() {
      try {
        let response = await this.$notificationService.addFakeNotifications();
      } catch (e) {
        console.log('erro', e);
      }
    },
    notificationClicked(examid) {
      examid ? this.$router.push({name: 'exam-show', params: {examID: examid}, query: {tab: 'images'}})
               : this.$router.push({name: 'patient-list'})
    },
  }
}
</script>
