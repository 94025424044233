<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" :id="offcanvas_id" data-bs-backdrop="static" :aria-labelledby="`${offcanvas_id}_label`">
    <div class="offcanvas-header">
      <button class="btn-back" type="button" name="button" data-test="btnCloseOffcanvas" @click="isNewPatient = false" aria-label="Close" v-if="isNewPatient">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <button class="btn-back" id="btnCloseAddPatient" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" @click="closeAddPatient" aria-label="Close" v-else>
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" :id="`${offcanvas_id}_label`">{{ $t('assignPatient.assignPatient') }}</h5>
    </div>

    <ExamPatientAddFormData v-if="isNewPatient" @patient-created="closePatient"/>

    <div class="offcanvas-body" v-else>
      <p>{{ $t('assignPatient.searchPatient') }}</p>

      <div class="form-floating">
        <input class="form-control" type="text" id="searchPatient" placeholder="Digite o nome..." v-model="search" @keypress.enter="doSearch">
        <label for="searchPatient">{{ $t('patientSearch.patientSearchPlaceholder') }}</label>
        <button class="btn-icon" type="button" name="button" data-test="btnSearchPatient" @click="fetchData">
          <IconSearch/>
        </button>
      </div>

      <ul class="scrollbar">
        <!-- precisa de um novo endpoint trazendo todos os pacientes sem paginar -->
        <li v-for="(item, index) in patientList" :class="{'active' : item.uuid == selectedPatient.uuid}" @click="selectedPatient = item">
          {{ item.fullName }}
        </li>
      </ul>

      <p>{{ $t('assignPatient.addPatientInfo') }}</p>

      <!-- footer inside body to avoid empty space between body and footer -->
      <div class="offcanvas-footer">
        <button class="btn btn-outline-secondary" type="button" data-test="btnAdd" @click="isNewPatient = true">{{ $t('assignPatient.addPatient') }}</button>
        <button class="btn btn-success" type="button" name="button" @click="doAssign">{{ $t('assignPatient.assign') }}</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  // width:                                510px !important;
  // padding:                              40px;

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    p:not(.patient-selected) {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin-bottom:                    0;
    }

    .patient-selected {
      @include                          border_radius(99px);
      color:                            var(--Brand-Primary, #1B294A);
      text-align:                       center;
      font-size:                        18px;
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   0.9px;
      display:                          flex;
      justify-content:                  center;
      align-items:                      center;
      height:                           55px;
      margin-bottom:                    0;
      padding:                          20px;
      border:                           2px solid var(--Brand-Primary, #1B294A);
      background:                       var(--Shades-10, #FAFAFA);
    }

    .btn-icon {
      padding:                          0;
      border:                           0;
    }

    ul {
      list-style-type:                  none;
      height:                           100%;
      max-height:                       calc(100vh - 500px);
      // max-height:                       calc(100vh - 470px);
      margin-bottom:                    0;
      padding:                          0 10px 0 0;
      overflow-y:                       auto;

      li {
        @include                        border_radius(10px);
        color:                          var(--Brand-Primary, #1B294A);
        font-size:                      var(--font-size-14);
        font-weight:                    400;
        line-height:                    normal;
        letter-spacing:                 0.7px;
        text-overflow:                  ellipsis;
        overflow:                       hidden;
        margin-bottom:                  10px;
        padding:                        10px;
        background:                     var(--Shades-10, #FAFAFA);
        border:                         1px solid var(--Shades-20, #F0F0F0);
        cursor:                         pointer;

        &.active {
          color:                        #28989F;
          background:                   #E0F0EF;
          border-color:                 #28989F;
        }

        &:last-child {
          margin:                       0;
        }
      }
    }
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn {
      font-size:                        var(--font-size-14);
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   4.2px;
      text-align:                       center;
      text-transform:                   uppercase;
      width:                            100%;
      margin:                           0;
      padding:                          20px;
    }
  }
}
</style>

<script>
import ExamPatientAddFormData from './ExamPatientAddFormData.vue'

export default {
  name: 'ExamPatientAssignOffcanvas',
  components: {
    ExamPatientAddFormData
  },
  props: ['offcanvas_id'],
  data: () => ({
    isNewPatient: false,
    exam: {},
    patientList: {},
    search: null,
    selectedPatient: {},
    offcanvas_assign: null,
    resolvePromise: null,
    rejectPromise: null
  }),
  mounted() {
    // this.fetchData()

    document.getElementById('searchPatient').focus();
  },
  methods: {
    show(options = {}) {
      this.selectedPatient = {}
      this.search = null

      this.exam = options.exam

      console.log('exaaaaaaaaaaaaaaaaaaam', this.exam);

      this.fetchData()

      this.offcanvas_assign = new bootstrap.Offcanvas(document.getElementById(this.offcanvas_id)).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.offcanvas_assign = null;
      this.resolvePromise(true)
    },

    doCancel() {
      this.offcanvas_assign = null;
      this.resolvePromise(false)
    },

    doClose() {
      // HERE IT SHOULD CLOSE!!
    },

    closePatient(patient) {
      this.isNewPatient = false,
      this.search = patient.fullName
      this.fetchData()
      // this.$emit('offcanvas-patient', patient)
    },

    async fetchData(pageValue) {
      this.alert = {}

      // TODO yamanaka: endpoint trazendo uma lista completa dos pacientes ? ou usar scroll infinito ?
      let params = {
        page: 1
      }

      if (this.search)
        params.q = this.search

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.getList(params)
        this.patientList = response.result

        if (response.result.length === 0) {
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async doSearch() {
      this.alert = {}

      let params = {
        // page: this.currentPage
        q: this.search
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.getList(params)
        this.patientList = response.result

        if (response.result.length === 0) {
          // this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },
    async doAssign() {
      let params = {
        exam: this.exam,
        patientUuid: this.selectedPatient.uuid
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$examService.updateAnonymous(params)

        if (!response.exam) {
          // this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
        // BRUNO: Need to add a success message to user and close canvas.
        this.$message.popup(this, 'general.createdSuccess', 'operationSuccess', 'success')

        document.getElementById('btnCloseAddPatient').click()
        this.$emit('patient-assigned')
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },
    closeAddPatient(){
       this.isNewPatient = false
    }
  }
}
</script>
