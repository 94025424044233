const AI_CLASS_THRESHOLD = 0.2

const MULTIPLE_HEADER_CLASSES = ['c3', 'c4', 'c5']

class AIService {

  mcRASLabels = {
    0: 'clinicConfig.mcRAS_noAlt',
    1: 'clinicConfig.mcRAS_c1',
    2: 'clinicConfig.mcRAS_c2',
    3: 'clinicConfig.mcRAS_c3',
    4: 'clinicConfig.mcRAS_c5',
    5: 'clinicConfig.mcRAS_c6'
  }

  alterationScore(metadata) {
    if (!metadata)
      return null

    let data = JSON.parse(metadata)
    const modelVersion = this.getModelVersion({ metadata })
    if (modelVersion && modelVersion.includes('mcRAS')) {
      return this.processMCRASFromExam({ metadata })
    }
    // TODO yamanaka ainda vamos utilizar o mcras???
    // if (data.mcRAS)
    //   return this.mcrasScore(data)

    return this.rasScore(data)
  }

  rasScore(metadata) {
    let scoreResult = {
      os: null,
      od: null,
      osText: null,
      odText: null,
      osStatus: null,
      odStatus: null
    }

    scoreResult.od = metadata.maxAlterationScoreOD
    scoreResult.os = metadata.maxAlterationScoreOS

    scoreResult.odText = scoreResult.od ? this.formatAlterationScore(scoreResult.od) : '-'
    scoreResult.osText = scoreResult.os ? this.formatAlterationScore(scoreResult.os) : '-'

    if (scoreResult.os >= 0.7) {
      scoreResult.osStatus = "red" // danger
    } else if (scoreResult.os < 0.7 && scoreResult.os > 0.3) {
      scoreResult.osStatus = "yellow" // warning
    } else if (scoreResult.os <= 0.3) {
      scoreResult.osStatus = "green" // success
    }

    if (scoreResult.od >= 0.7) {
      scoreResult.odStatus = "red" // danger
    } else if (scoreResult.od < 0.7 && scoreResult.od > 0.3) {
      scoreResult.odStatus = "yellow" // warning
    } else if (scoreResult.od <= 0.3) {
      scoreResult.odStatus = "green" // success
    }

    // console.log('score result ai service', scoreResult);

    return scoreResult
  }

  mcrasScore(metadata) {
    const mcRAS = metadata.mcRAS

    let scoreResult = {
      os: null,
      od: null,
      osText: null,
      odText: null,
      status: null
    }

    let maxScore = 0
    for (let i in mcRAS) {
      if (i == 0) // ignoring first class (normal)
        continue

      let value = mcRAS[i]
      if (value > maxScore)
        maxScore = value
    }

    if (maxScore < 0.2) {
      scoreResult.status = "green"
    } else if (maxScore >= 0.2 && maxScore < 0.6) {
      scoreResult.status = "yellow"
    } else {
      scoreResult.status = "red"
    }

    return scoreResult
  }


  mcRASHeader(exam) {
    const aiClasses = this.mcRASClasses(exam)

    if (aiClasses.length == 0)
      return ""

    if (aiClasses.length > 1)
      return "multiple"

    const singleClass = aiClasses[0]
    if (MULTIPLE_HEADER_CLASSES.includes(singleClass))
      return "multiple" // "Suspeita de uma das seguintes condições:"

    return "single"
  }

  mcRASClasses(exam) {
    let result = []
    try {
      if (!exam.metadata)
        return []

      let data = JSON.parse(exam.metadata)

      for (let i in data.mcRAS) {
        if (i == 0) // ignoring first class (normal)
          continue

        let value = data.mcRAS[i]
        if (i == 1) {
          if (value >= 0.004) {
            result.push(`c${i}`)
          }
          continue
        }

        if (value >= AI_CLASS_THRESHOLD) {
          result.push(`c${i}`)
        }
      }
    } catch (err) {
      console.error("Error parsing metadata.", err)
    }

    return result
  }

  processMCRASScore(score, klass, quality = undefined) {
    if (score === 'LQ' || score === undefined || typeof score !== 'number') {
      return 'no-info'
    }
    if (klass === 0 && score > 0.2 && (quality === 2 || !quality)) {
      return 'green'
    }
    if (klass === 0 && score > 0.2 && quality <= 1) {
      // TODO evaluate usage of blue and its design
      // return 'blue'
      return 'green'
    }

    if (klass === 1) {
      if (score < 0.004) {
        return 'green'
      } else if (score < 0.2) {
        return 'yellow'
      }
      return 'red'
    }

    if (score >= 0.2 && score < 0.7) {
      return 'yellow'
    }
    if (score >= 0.7)
      return 'red'
    return 'no-info'
  }

  getModelVersion(exam) {
    if (!exam.metadata)
      return false
    const metadata = JSON.parse(exam.metadata)

    return metadata.modelVersion
  }

  getMCRAS(exam, laterality = undefined) {
    let key = 'mcRASE2'
    if (laterality)
      key += laterality
    if (!exam.metadata)
      return undefined
    const metadata = JSON.parse(exam.metadata)
    return metadata[key]
  }

  getMaxAlterationQualityClassMCRAS(exam, laterality = undefined) {
    let key = 'maxAlterationQualityMCRAS'
    if (laterality)
      key += laterality

    const metadata = JSON.parse(exam.metadata)
    return metadata[key]
  }

  hasMCRAS(exam) {
    if (!exam.metadata)
      return false
    const metadata = JSON.parse(exam.metadata)

    return metadata.mcRASE2 || metadata.mcRASE2OD || metadata.mcRASE2OS
  }

  processMCRASFromExam(exam) {
    const scoreResult = {
      os: null,
      od: null,
      osText: null,
      odText: null,
      osStatus: null,
      odStatus: null,
      alterationText: []
    }
    const mcRASOD = this.getMCRAS(exam, 'OD')
    const mcRASOS = this.getMCRAS(exam, 'OS')

    if (mcRASOD) {
      let altScoreOD = Math.max(...mcRASOD.slice(1))
      if (altScoreOD < 0.2)
        altScoreOD = 1 - mcRASOD[0]
      scoreResult.od = altScoreOD
    }

    if (mcRASOS) {
      let altScoreOS = Math.max(...mcRASOS.slice(1))
      if (altScoreOS < 0.2)
        altScoreOS = 1 - mcRASOS[0]
      scoreResult.os = altScoreOS
    }


    const qualityOD = this.getMaxAlterationQualityClassMCRAS(exam, 'OD')
    const qualityOS = this.getMaxAlterationQualityClassMCRAS(exam, 'OS')

    scoreResult.odStatus = this.processMCRASArray(mcRASOD, qualityOD)
    scoreResult.osStatus = this.processMCRASArray(mcRASOS, qualityOS)

    if ((scoreResult.odStatus === 'green' && scoreResult.osStatus === 'green') ||
      (scoreResult.odStatus === 'green' && scoreResult.osStatus === 'no-info') ||
      (scoreResult.odStatus === 'no-info' && scoreResult.osStatus === 'green')) {
      scoreResult.alterationText = [this.mcRASLabels[0]]
    } else {
      const wordSet = new Set([...this.addAlterationText(mcRASOD), ...this.addAlterationText(mcRASOS)])
      scoreResult.alterationText = Array.from(wordSet)
    }

    return scoreResult

  }


  addAlterationText(scores) {
    if (!scores)
      return []
    let alterationText = scores.map((score, index) => index > 0 && score > 0.2 ? this.mcRASLabels[index] : '')
      .filter(text => text !== '')
    return alterationText
  }

  processMCRASArray(classes, quality) {
    if (!classes || classes.length === 0)
      return 'no-info'
    const alterationClasses = classes.slice(1)
    const maxAlteration = Math.max(...alterationClasses)
    const hasAlteration = alterationClasses.some(score => score >= 0.2)

    if (!hasAlteration) {
      if (quality && (quality[0] === -1 || quality[0] === 0))
        return 'no-info'
      return 'green'
      // TODO evaluate usage of ai-blue and its design
      // return qualities[0] === 2 ? 'ai-green' : 'ai-blue'
    }

    return maxAlteration > 0.7 ? 'red' : 'yellow'
  }

  formatAlterationScore(score) {
    if (score === "LQ")
      return score

    let num = (score * 100).toString()
    let idx = num.indexOf('.')
    if (idx == -1) {
      idx = num.length
    }
    return `${num.substring(0, idx)}%`
  }


  dragasClasses(exam) {
    let result = {
      os: [],
      od: []
    }
    let eyes = {
      os: null,
      od: null
    }

    try {
      if (!exam.metadata)
        return []

      let data = JSON.parse(exam.metadata)

      if (!data.dragas)
        return []

      let isDrHigh
      for (const obj in data.dragas) {
        if (obj) {
          for (const [key, value] of Object.entries(data.dragas[obj])) {
            let dragas = {
              text: null
            }
            if (key == 'noAlteration') // ignoring noAlteration class (normal)
              continue

            if (key == 'dr' && value >= AI_CLASS_THRESHOLD)
              isDrHigh = true

            if (value >= AI_CLASS_THRESHOLD) {
              if ((key == 'hasEdema' && value && !isDrHigh) || (key == 'hasEdema' && !value))
                continue

              dragas.text = key

              if (value >= AI_CLASS_THRESHOLD && value < 0.7) {
                eyes[obj] = eyes[obj] != 'red' ? 'yellow' : eyes[obj]
              } else {
                eyes[obj] = eyes[obj] == 'yellow' || eyes[obj] == 'green' || eyes[obj] == null ? 'red' : eyes[obj]
              }
            } else {
              eyes[obj] = eyes[obj] != 'yellow' && eyes[obj] != 'red' ? 'green' : eyes[obj]
            }

            if (dragas.text && result[obj] && !result[obj].includes(dragas.text))
              result[obj].push(dragas)
          }

          if (result[obj] && eyes)
            result[obj].push(eyes)
        }
      }
      return result
    } catch (err) {
      console.error("Error parsing dargas metadata.", err)
    }
  }
}

const aiService = new AIService()
export default aiService
