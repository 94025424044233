<template>
  <div class="registration-page">
    <div class="container">
      <img src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo">

      <div class="row">
        <div class="col-lg-6 flex-center">
          <div class="">
            <h1>
              {{ $t('createAccount.collaboratorRegister') }}
            </h1>

            <p>
              {{ $t('createAccount.collaboratorRegisterInfo') }}
            </p>
            <!-- <p>
              Abaixo será a clínica que ele será registrado. Caso necessite trocar, é só clicar no botão ao lado.
            </p> -->

            <p class="clinic-info" v-if="clinic">
              <span>{{ $t('clinic.clinic') }}</span>
              {{ clinic.name }}
            </p>
          </div>
        </div>
        <div class="col-lg-6 flex-center">
          <div class="scrollbar">
            <ul class="wizard-step">
              <li :class="{active: step === 0, past: step > 0, full: step > 1}">
                {{ $t('createAccount.basicInformation') }}
                <IconArrowRight/>
              </li>
              <li :class="{active: step === 1, past: step > 1}">
                {{ $t('user.password') }}
                <IconArrowRight/>
              </li>
              <li :class="{active: step === 2, past: step > 2}">
                {{ $t('createAccount.finalize') }}
              </li>
            </ul>

            <vee-form v-slot="{ handleSubmit }" as="div">
              <form @submit.prevent.stop="handleSubmit(doSave)">
                <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
                  <span v-html="alert.message"></span>
                  <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
                </div>

                <div :key="0" v-if="step === 0">
                  <RegistrationUserStepOne v-if="email" :account="account" :email="email"/>
                </div>

                <div :key="1" v-if="step === 1">
                  <RegistrationUserStepTwo :account="account" :email="email"/>
                </div>

                <div :key="2" v-if="step === 2">
                  <RegistrationUserStepThree :account="account"/>
                </div>

                <div class="action-buttons">
                  <button class="btn btn-default" type="button" name="button" v-if="step === 2" data-test="btnChangeData" @click="step = 0">{{ $t('createAccount.changeData') }}</button>
                  <button class="btn btn-success" type="submit" name="button" v-if="step < 2" data-test="btnNext">{{ $t('pagination.next') }}</button>
                  <button class="btn btn-success" type="submit" name="button" v-if="step === 2" :disabled="loading" data-test="btnFinish">{{ $t('createAccount.finalize') }}</button>
                </div>
              </form>
            </vee-form>
          </div>
        </div>
      </div>

      <div class="powered-box">
        <p class="d-inline-block">POWERED BY</p>
        <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
          <img src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
        </a>
        <p class="d-inline-block">v {{ appVersion }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.registration-page {
  height:                         100vh;
}

.container {
  display:                        flex;
  justify-content:                space-between;
  align-items:                    center;
  flex-direction:                 column;
  height:                         100%;
  padding-top:                    2rem;
  padding-bottom:                 1rem;
  > img {
    display:                      block;
    margin-left:                  auto;
    margin-right:                 auto;
  }
}

.col-lg-6 {
  display:                        flex;
  justify-content:                flex-start;
  align-items:                    center;
  > div {
    width:                        70%;
  }
  h1 {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    38px;
    font-weight:                  300;
    line-height:                  normal;
  }
  &:first-child {
    justify-content:              flex-end;
  }
  &:last-child {
    gap:                          18px;
  }
}

.clinic-info {
  @include                        border_radius(30px);
  color:                          var(--Shades-80, #232323);
  font-size:                      var(--font-size-14);
  font-weight:                    500;
  line-height:                    normal;
  letter-spacing:                 0.7px;
  text-overflow:                  ellipsis;
  overflow:                       hidden;
  display:                        flex;
  flex-direction:                 column;
  width:                          100%;
  padding:                        10px 10px 10px 20px;
  border:                         1px solid var(--Shades-30, #D8D8D8);
  background:                     var(--Shades-10, #FAFAFA);

  span {
    color:                        var(--Shades-50, #8F8F8F);
    font-size:                    var(--font-size-10);
    letter-spacing:               1.5px;
    text-transform:               uppercase;
    padding-bottom:               5px;
  }
}

.powered-box {
  p {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    var(--font-size-8);
    font-weight:                  400;
    letter-spacing:               1.52px;
    margin:                       0;
  }
  .logo {
    color:                        #1B294A;
    text-decoration:              none;
    width:                        90px;
    margin:                       0 10px;
    img {
      width:                      100%;
    }
  }
}

.btn {
  width:                          100%;
}

@media (max-width: 1500px) {
  .wizard-step {
    margin-bottom:                0;
  }
}

@media (max-width: 991px) {
  .container {
    > img {
      width:                       150px;
    }
  }
  .registration-page {
    width:                         100%;
    overflow:                      hidden;

    .col-lg-6 {
      width:                       100vw;
      h1 {
        font-size:                 32px;
      }

      > div {
        width:                     100%;
      }

      .scrollbar {
        max-height:                calc(100dvh - 332px);
        overflow-y:                scroll;
      }
    }
  }
}
</style>

<script>
import RegistrationUserStepOne from './RegistrationUserStepOne.vue'
import RegistrationUserStepTwo from './RegistrationUserStepTwo.vue'
import RegistrationUserStepThree from './RegistrationUserStepThree.vue'
import utilService from '../../../../services/util-service.js';

export default {
  name: 'invitation-form',
  components: {
    RegistrationUserStepOne,
    RegistrationUserStepTwo,
    RegistrationUserStepThree,
  },
  data: () => ({
    alert: {
      message: null,
      class: null,
    },
    loading: false,
    step: 0,
    account: {
      firstName: "",
      lastName: "",
      document: "",
      npi: "", // physician professional ID number
      password: "",
      confirmPassword: "",
      pin: "",
      telephone: "",
      telephone2: "",
      mobile: ""
    },
    clinic: {},
    owner: {},
    fullName: null,
    code: null,
    email: null,
    invitation: {},
    existingUser: null,
    appVersion: process.env.VUE_APP_VERSION,
  }),
  mounted() {
    const data = this.$utils.parseDataQuery(this.$route.query._data)
    this.code = data.code
    this.email = data.email

    this.processInvitation()
  },
  methods: {
    async processInvitation() {
      this.loading = true
      NProgress.start()
      try {
        let result = await this.$registrationService.invitation({code: this.code})
        this.existingUser = result.existingUser

        this.invitation = result.invitation
        this.owner = this.invitation.owner
        this.clinic = this.invitation.clinic
        this.fullName = this.owner.firstName + ' ' + this.owner.lastName

        this.$nextTick(() => {
          this.processRegistration()
        })
      } catch (err) {
        this.$message.popup(this, 'createAccount.invalidLink', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'createAccount.invalidLink', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    processRegistration: async function() {
      if (this.existingUser) {
        this.loading = true
        NProgress.start()
        try {
          await this.$registrationService.registerExistingUser({code: this.code})

          let data = this.$utils.dataQuery({
            fullName: `${this.account.firstName} ${this.account.lastName}`,
            clinicName: this.clinic.name
          })

          this.$router.push({name: 'invitation-done', query: {data: data}})
        } catch(err) {
          this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
          // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
        }
        this.loading = false
        NProgress.done(true)
        return
      }

      // let data = this.$utils.dataQuery({
      //   code: this.code,
      //   email: this.email,
      //   fullName: this.fullName
      // })

      // this.$router.push({name: 'invitation-form', query: {data: data}})
    },
    doSave: async function() {
      let params = {
        code: this.code,
        account: this.account
      }

      try {
        if (this.step != 2) {
          this.step += 1;
          return
        }
        let responseValidate = await utilService.validatePassword(params.account)

        if (responseValidate.valid == false){
            this.$message.popup(this, 'zxcvbnMessages.error', 'operationFail', 'error')
            return
        }

        this.loading = true
        NProgress.start()
        await this.$registrationService.createCollaborator(params)

        let data = this.$utils.dataQuery({
          fullName: `${this.account.firstName} ${this.account.lastName}`,
          clinicName: this.clinic.name
        })

        this.$router.push({name: 'invitation-done', query: {data: data}})
      } catch (err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
  }
}
</script>
