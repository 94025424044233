<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path d="M14 4.35742C8.47301 4.35742 4 8.82992 4 14.3574C4 19.8843 8.4725 24.3574 14 24.3574C19.527 24.3574 24 19.8849 24 14.3574C24 8.83043 19.5275 4.35742 14 4.35742ZM14 22.9621C9.25539 22.9621 5.39535 19.1021 5.39535 14.3574C5.39535 9.61277 9.25539 5.75277 14 5.75277C18.7446 5.75277 22.6046 9.61277 22.6046 14.3574C22.6046 19.1021 18.7446 22.9621 14 22.9621Z" fill="#3CB391"/>
    <path d="M13.7033 17.0107C13.1504 17.0107 12.7029 17.4714 12.7029 18.0242C12.7029 18.5639 13.1373 19.0378 13.7033 19.0378C14.2693 19.0378 14.7168 18.5639 14.7168 18.0242C14.7168 17.4714 14.2561 17.0107 13.7033 17.0107ZM13.8744 9.33667C12.0973 9.33667 11.2812 10.3898 11.2812 11.1005C11.2812 11.6139 11.7156 11.8508 12.071 11.8508C12.7818 11.8508 12.4923 10.8373 13.8349 10.8373C14.493 10.8373 15.0196 11.1269 15.0196 11.7324C15.0196 12.4432 14.2824 12.8512 13.848 13.2198C13.4663 13.5488 12.9661 14.0885 12.9661 15.2205C12.9661 15.905 13.1504 16.1025 13.6901 16.1025C14.335 16.1025 14.4667 15.8129 14.4667 15.5627C14.4667 14.8783 14.4798 14.4834 15.2038 13.9174C15.5592 13.641 16.678 12.7458 16.678 11.5085C16.678 10.2712 15.5592 9.33667 13.8744 9.33667Z" fill="#1B294A"/>
  </svg>
</template>

<style lang="css" scoped>
</style>

<script>
export default {
  name: 'IconHelpMenu'
}
</script>
