<template>
  <div>
    <AppMenu @docollapse="doCollapse"></AppMenu>

    <div class="right_col content ease-all">

      <div>
        <div id="principal"></div>
        <!-- <p class="clinic-title">{{ session.currentClinic.name }}</p> -->
        <router-view class="panel-content" @collapse-menu="doCollapse"></router-view>
      </div>
    </div>

    <HomeFooter></HomeFooter>
  </div>
</template>

<style lang="scss" scoped>
.main {
  position:                             relative;
  width:                                100%;
  min-height:                           740px;
  &.full-height {
    height:                             100vh;
  }
  &.retract {
    .content,
    .right_col,
    .footer {
      width:                            calc(100% - 50px) !important;
    }
    .menu,
    .sidebar {
      width:                            50px !important;
    }
  }
}

.content {
  display:                              inline-block;
  min-height:                           calc(100vh - 45px);
  width:                                calc(100% - 342px);
  float:                                right;
  overflow-x:                           hidden;
  &.full-width {
    width:                              100%;
    float:                              unset;
  }
}

.content,
.panel-content {
  position:                             relative;
}

.panel-content {
  padding:                              15px 0;
  border:                               1px solid transparent;
  -webkit-box-shadow:                   0 1px 1px rgb(0 0 0 / 5%);
  box-shadow:                           0 1px 1px rgb(0 0 0 / 5%);
}

.control-bo {
  position:                             absolute;
  right:                                10px;
  top:                                  10px;
  z-index:                              999999;
}

.fa-wifi {
  color:                                green;
  &.red {
    color:                              red;
  }
}

.clinic-title {
  display:                              none;
}

@media (max-width: 767px) {
  .main {
    .content,
    .right_col {
      float:                            unset;
      position:                         relative;
      height:                           100%;
    }
    .content,
    &.retract .content,
    .right_col,
    &.retract .right_col {
      width:                            100% !important;
      margin-left:                      0 !important;
    }
  }
  .panel-content {
    padding:                            8px 0;
  }
  .clinic-title {
    font-size:                          0.9em;
    font-weight:                        500;
    text-align:                         center;
    letter-spacing:                     1px;
    display:                            block;
    margin:                             7px 0 0;
    opacity:                            0.7;
  }
}

@media (max-width: 991px) {
  .main {
    min-height:                         645px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1450px) {
  .content {
    width:                              calc(100% - 300px);
  }
}

// safari
@media not all and (min-resolution: .001dpcm) {
@supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
  @media (max-width: 767px) {
    .main.retract {
      .footer {
        width:                          100% !important;
      }
    }
  }
}}

@media not all and (min-resolution:.001dpcm) {
@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  @media (max-width: 767px) {
    .main.retract {
      .footer {
        width:                          100% !important;
      }
    }
  }
}}
</style>

<script>
import { useEyerwebStore } from '@/store/eyerweb'
import { setLocale } from '@vee-validate/i18n';

import HomeFooter from '../account/HomeFooter.vue'
import AppMenu from './eyerweb-menu/eyerweb-menu.vue'

const TAG = "[home]"
export default {
  name: 'home',
  components: {
    HomeFooter,
    AppMenu
  },
  data: () => ({
    loading: false,
    isCustomRoute: false,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    clinic: null,
    features: null,
    state: false,
    value: 0,
    attention: null,
    sessionExpired: null,
    isLogin: true,
  }),
  mounted() {
    const eyerwebStore = useEyerwebStore()
    this.session = eyerwebStore.sessionInfo

    if (this.session && this.session.currentClinic) {
      this.$i18n.locale = this.session.currentClinic.locale
      setLocale(this.$i18n.locale);
      console.log("[app] setting locale (from clinic):", this.session.currentClinic.locale)
    }
  },
  methods: {
    reload() {
      this.state = false;
      this.value++;
      this.$nextTick(() => this.state = true);
    },
    checkRoute() {
      return this.state ? this.$route.fullPath : [this.$route.fullPath, this.value.toString()]
    },
    doLogin() {
      // this.showModalWarning = false;
      window.open('/link', '_self');
    },
    defineLocale(value) {
      this.$i18n.locale = value;
      window.VeeValidate.localize(value);
    },
    doCollapse(value) {
      this.collapse = !this.collapse;

      if (this.collapse) {
        $('.main').addClass('retract')
      } else {
        $('.main').removeClass('retract')
      }
    },
  }
}
</script>
