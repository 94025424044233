<template>
  <!-- , 'has-mutirao': showMutirao -->
  <div class="main-img eyer2 imgTab" :class="{'dnh-ia-advanced': !hasIaAdvancedFunctions}" v-loader.opacity.tab="loading">
    <div class="">
      <div v-if="false" class="toolbar"> <!-- FENANDO: REMOVIDO TEMPORARIAMENTE -->
        <label for="">{{ $t('selectPicker.filter') }}</label>

        <input type="checkbox" class="btn-check" id="btnCheckAnterior" autocomplete="off" @change="doFilter('anterior')">
        <label class="btn btn-outline-secondary" for="btnCheckAnterior">{{ $t('imageType.anterior') }}</label>

        <input type="checkbox" class="btn-check" id="btnCheckColor" autocomplete="off" @change="doFilter('color')">
        <label class="btn btn-outline-secondary" for="btnCheckColor">{{ $t('imageType.color') }}</label>

        <input type="checkbox" class="btn-check" id="btnCheckRedfree" autocomplete="off" @change="doFilter('redfree')">
        <label class="btn btn-outline-secondary" for="btnCheckRedfree">{{ $t('imageType.redfree') }}</label>

        <input type="checkbox" class="btn-check" id="btnCheckHeatmap" autocomplete="off" @change="doFilter('heatmap')">
        <label class="btn btn-outline-secondary" for="btnCheckHeatmap">{{ $t('imageType.heatmap') }}</label>

        <input type="checkbox" class="btn-check" id="btnCheckCustom" autocomplete="off" @change="doFilter('custom')">
        <label class="btn btn-outline-secondary" for="btnCheckCustom">{{ $t('imageType.custom') }}</label>

        <input type="checkbox" class="btn-check" id="btnCheckTexture" autocomplete="off" @change="doFilter('texture')">
        <label class="btn btn-outline-secondary" for="btnCheckTexture">{{ $t('imageType.texture') }}</label>

        <input type="checkbox" class="btn-check" id="btnCheckPanoramic" autocomplete="off" @change="doFilter('panoramic')">
        <label class="btn btn-outline-secondary" for="btnCheckPanoramic">{{ $t('imageType.panoramic') }}</label>

        <input type="checkbox" class="btn-check" id="btnCheckVideo" autocomplete="off" @change="doFilter('video')">
        <label class="btn btn-outline-secondary" for="btnCheckVideo">{{ $t('imageType.video') }}</label>

        <input type="checkbox" class="btn-check" id="btnCheckStereo" autocomplete="off" @change="doFilter('stereo')">
        <label class="btn btn-outline-secondary" for="btnCheckStereo">{{ $t('imageType.stereo') }}</label>

        <input type="checkbox" class="btn-check" id="btnCheckStereoImage" autocomplete="off" @change="doFilter('stereo_image')">
        <label class="btn btn-outline-secondary" for="btnCheckStereoImage">{{ $t('imageType.stereo_image') }}</label>

        <input type="checkbox" class="btn-check" id="btnCheckStereoVideo" autocomplete="off" @change="doFilter('stereo_video')">
        <label class="btn btn-outline-secondary" for="btnCheckStereoVideo">{{ $t('imageType.stereo_video') }}</label>

        <input type="checkbox" class="btn-check" id="btnCheckProgressionImage" autocomplete="off" @change="doFilter('progression_image')">
        <label class="btn btn-outline-secondary" for="btnCheckProgressionImage">{{ $t('imageType.progression_image') }}</label>

        <input type="checkbox" class="btn-check" id="btnCheckProgressionVideo" autocomplete="off" @change="doFilter('progression_video')">
        <label class="btn btn-outline-secondary" for="btnCheckProgressionVideo">{{ $t('imageType.progression_video') }}</label>
      </div>

      <div v-if="alert.message" class="alert alert-dismissible fade show" :class="alert.class" role="alert">
        <span v-html="alert.message"></span>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="clearMessage"></button>
      </div>
<!-- :lgBeforeClose="addEventResize" -->
      <div class="scrollbar" v-if="examImages.length">
        <teleport to="#aiBox" v-if="showMutirao">
          <div class="flex-end" style="gap: 5px; display: flex; flex-direction: column;">
            <div v-for="result of mcRASResults" class="mcRAS-box">
              <div class="ai-box" :data-title="$t('exam.iaText')">
                <p>AI</p>
                <span :class="result.classOD"></span>
                <span :class="result.classOS"></span>
              </div>
              <p class="mb-0 ms-2">{{ $t(result.text) }}</p>
            </div>
          </div>
        </teleport>

        <div class="action-box">
          <div v-if="showMutirao">
            <ExamImagePreAnalysis v-if="exam" :patient="patient" :exam="exam" @alertmsg="setAlert"></ExamImagePreAnalysis>
          </div>
        </div>

        <lightgallery-vue
          class="image-info-box grid-wrap-between ease-all" ref="lGallery" id="lightgallery"
          :settings="lightGallerySettings" :onInit="onInit" :onBeforeSlide="removeDownloadButton" :onAfterOpen="extraCustomization">
          <template v-for="(item, index) in examImages" :key="index">

            <a v-if="item.type.endsWith('VIDEO')" onclick="return false" class="thumbnail-box loading-eye" :class="item.type" :key="`video_${index}`" data-lg-size="1280-720"
              :data-video="getVideoSource(item)" data-download-url="" data-sub-html="<h4></h4><p></p>" :data-test="`anchorVideo_${index}`">
              <img class="lg-selector ease-all" src="@/assets/images/default-video2.png" alt="" @load="removeLoading($event)"/>
              <div class="thumb-actions">
                <div class="btn-group btn-group-sm" role="group" aria-label="Thumb actions">
                  <button class="btn btn-outline-secondary group" type="button" name="button" :data-test="`btnOpenModalDeleteVideo_${index}`" @click.stop.prevent="openModalDelete(item.id)">
                    <i class="fa fa-trash-o"></i>
                  </button>
                  <button class="btn btn-outline-secondary group" type="button" name="button" :data-test="`btnDownloadVideo_${index}`" @click.stop.prevent="doDownload(item, item.type.endsWith('VIDEO'))">
                    <i class="fa fa-download"></i>
                  </button>
                </div>
              </div>
            </a>

            <a v-else class="thumbnail-box loading-eye" onclick="return false" :class="item.type" :href="dataPath +'/'+ item.name" :data-test="`anchorImage_${index}`">
              <img class="lg-selector ease-all" :src="thumbDataPath +'/'+ item.name" :id="item.name" alt="" @load="removeLoading($event)"/>
              <BaseFeature feature="iaAdvancedFunctions">
                <div class="checkbox" v-show="allowEdition(item.type)">
                  <BaseRoundedCheckBox :id="item.name" :item="item" @checked="doCheck"/>
                  <!-- <input class="form-check-input" type="checkbox" :id="'checkImg_'+item.name" :value="item.uuid" aria-label=""
                  @click.stop="doCheck(item, 'checkImg_'+item.name)"> -->
                </div>
              </BaseFeature>
              <div class="thumb-actions">
                <div class="btn-group btn-group-sm" role="group" aria-label="Thumb actions">
                  <button class="btn btn-outline-secondary group" type="button" name="button" :data-test="`btnOpenModalDeleteImage_${index}`" @click.stop.prevent="openModalDelete(item.id)">
                    <i class="fa fa-trash-o"></i>
                  </button>
                  <button class="btn btn-outline-secondary group" type="button" name="button" :data-test="`btnDownloadImage_${index}`" @click.stop.prevent="doDownload(item, item.type == 'STEREO')">
                    <i class="fa fa-download"></i>
                  </button>
                  <BaseFeature feature="imageEditor">
                    <button v-if="allowEdition(item.type)" class="btn btn-outline-secondary group" type="button" name="button" :data-test="`btnEditImage_${index}`" @click.stop.prevent="doEdit(item)">
                      <i class="fa fa-edit"></i>
                    </button>
                  </BaseFeature>
                </div>
              </div>
            </a>
          </template>
        </lightgallery-vue>
      </div>
    </div>

    <div class="action-bar">
      <BaseFeature feature="iaAdvancedFunctions">
        <label for="">{{ $t('aipo') }}</label>
        <button class="btn btn-success" :class="{'disabled': !isValidForHeatmap}" type="button" name="button" data-test="btnFunctionHeatmap" @click="openModalGeneric('heatmap')">{{ $t('imageType.heatmap') }}</button>
        <button class="btn btn-success" :class="{'disabled': selectedImages.length < 2}" type="button" name="button" data-test="btnFunctionPanoramic" @click="openModalGeneric('panoramic')">{{ $t('imageType.panoramic') }}</button>
        <button class="btn btn-success" :class="{'disabled': selectedImages.length < 2}" type="button" name="button" data-test="btnFunctionStereo" @click="openModalGeneric('stereo')">{{ $t('imageType.stereo') }}</button>
      </BaseFeature>
    </div>

    <teleport to="body">
      <BaseCropperModal ref="modalCropper"></BaseCropperModal>

      <BaseGenericModal :modal_id="'modalImageGeneric'" ref="modalGeneric"></BaseGenericModal>
      <BaseGenericOffcanvas :offcanvas_id="'offcanvasDeleteGeneric'" ref="offcanvasGeneric"/>

      <BasePatientAccessInfoModal :patient="patient" v-if="showAccessInfoModal" @close="showAccessInfoModal = false"></BasePatientAccessInfoModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.mcRAS-box {
  color: var(--Shades-90, #101010);
  font-weight: 500;
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  align-items: center
}

.ai-box {
  @include border_radius(99px);
  display: flex;
  justify-content: flex-start;
  padding: 5px 5px 5px 10px;
  align-items: center;
  max-width: 64px;
  border: 1px solid var(--Shades-30, #D8D8D8);
  background: var(--Shades-W, #FFF);

  p {
    color: var(--Shades-70, #404040);
    font-size: var(--font-size-10);
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.5px;
    margin: 0 10px 0 0;
    padding-top: 1px;
  }

  span {
    @include border_radius(50%);
    position: relative;
    width: 12px;
    height: 12px;
    background-color: white;
    border: 2px solid var(--Shades-30, #D8D8D8);
    z-index: 2;

    &:last-child {
      left: -2px;
      z-index: 1;
    }

    &.green {
      background-color: #3CB391;
      border: 1px solid white;
    }

    &.yellow {
      background-color: $yellow-red;
      border: 1px solid white;
    }

    &.red {
      background-color: #D7541C;
      border: 1px solid white;
    }

    &.no-info {
      border-color: #404040;
      border-radius: 99px;
      border-width: 1.5px;
      border-style: solid;
    }
  }
}

.btn-check:checked + .btn {
  color:                               var(--Brand-Secondary) !important;
  background:                          #CAF1E6 !important;
  border:                              1px solid var(--Brand-Secondary) !important;
}

.action-bar {
  display:                             flex;
  justify-content:                     flex-start;
  align-items:                         center;
  gap:                                 10px;
  height:                              64px;
  padding:                             10px 20px;
  border-top:                          1px solid var(--Shades-30);
}

.main-img {
  > div:first-child {
    padding:                           10px;
  }

  .scrollbar {
    height:                            100%;
    max-height:                        calc(100vh - 434px);
    padding-right:                     10px;
    overflow-y:                        auto;
  }

  &.has-mutirao {
    .scrollbar {
      max-height:                      calc(100vh - 522px) !important;
    }
  }

  .toolbar {
    display:                           flex;
    justify-content:                   flex-start;
    align-items:                       center;
    flex-wrap:                         wrap;
    gap:                               10px;
    margin-bottom:                     10px;

    label {
      color:                            var(--Shades-70);
      font-size:                        var(--font-size-12);
      font-weight:                      400;
      line-height:                      normal;
      letter-spacing:                   1.8px;
      text-transform:                   uppercase;
    }

    .btn {
      color:                           var(--Shades-70);
      font-size:                       var(--font-size-12);
      font-weight:                     400;
      line-height:                     normal;
      letter-spacing:                  1.8px;
      text-overflow:                   ellipsis;
      overflow:                        hidden;
      gap:                             5px;
      padding:                         10px 17px;
      background:                      var(--Shades-10);
      border:                          1px solid var(--Shades-30);
    }
  }
  .thumbnail-box {
    img {
      @include                        border_radius(11.898px);
      aspect-ratio:                   87 / 74;
    }
  }
}

.retract {
  .main-img {
    .scrollbar {
      max-height:                     calc(100vh - 344px);
    }

    &.dnh-ia-advanced {
      .action-bar {
        display:                      none;
      }

      .scrollbar {
        max-height:                   calc(100vh - 370px);
      }
    }
  }
}

.action-bar {
  label {
    color:                            var(--Shades-70);
    font-size:                        var(--font-size-12);
    font-weight:                      400;
    line-height:                      normal;
    letter-spacing:                   1.8px;
    text-transform:                   uppercase;
  }

  .btn-success {
    @include                           border_radius(99px);
    color:                             var(--Shades-W);
    font-size:                         var(--font-size-12);
    font-weight:                       700;
    letter-spacing:                    3.64px;
    text-transform:                    uppercase;
    height:                            44px;
    padding:                           15px 20px;
    background:                        var(--Brand-Secondary);
    border:                            1px solid var(--Brand-Secondary);
  }
}

.retract {
  .main-img {
    &.dnh-ia-advanced {
      .scrollbar {
        max-height:                    calc(100vh - 280px);
      }
    }

    &.has-mutirao {
      .scrollbar {
        max-height:                    calc(100vh - 522px) !important;
      }
    }
  }
}

@media (max-width: 480px) {
  .main-img {
    .text-end.mb-2, .toolbar {
      text-align:                      center !important;
    }
    .toolbar {
      .filters {
        max-width:                     100% !important;
      }
      &, .btn-group {
        display:                       block;
      }
    }
  }

  .retract .image-info-box.grid-wrap-between,
  .image-info-box.grid-wrap-between {
    grid-template-columns:             repeat(auto-fill, 100%) !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .main-img {
    .toolbar {
      .filters, .btn-group {
        display:                       inline-block;
      }
      .btn-group {
        text-align:                    right;
      }
    }
  }
}

@media (max-width: 767px) {
  .main-img {
    .toolbar {
      margin-bottom:                   10px;
      span {
        margin-right:                  10px;
      }
      .filters,
      .btn-group {
        margin-bottom:                 10px;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 991px) {
  .retract .image-info-box.grid-wrap-between,
  .image-info-box.grid-wrap-between {
    grid-template-columns:             repeat(auto-fill, 48%) !important;
  }
}

@media (max-width: 1199px) {
  .main-img {
    // min-height:                        100%;
    .action-box {
      > div {
        width:                         auto;
      }
    }
    .bootstrap-select.show-tick.bs3 {
      margin-bottom:                   0;
    }
  }
}

@media (max-width: 1500px) {
  .main-img {
    .scrollbar {
      max-height:                      calc(100vh - 424px) !important;
    }

    &.dnh-ia-advanced {
      .scrollbar {
        max-height:                    calc(100vh - 360px) !important;
      }
    }

    &.has-mutirao {
      .scrollbar {
        max-height:                    calc(100vh - 504px) !important;
      }
    }
  }

  .retract {
    .main-img {
      .scrollbar {
        max-height:                    calc(100vh - 338px) !important;
      }

      &.dnh-ia-advanced {
        .scrollbar {
          max-height:                  calc(100vh - 274px) !important;
        }
      }

      &.has-mutirao {
        .scrollbar {
          max-height:                  calc(100vh - 504px) !important;
        }
      }
    }

  }
}

@media (min-width: 1501px) {
  .main-img {
    min-height:                        100%;
  }
}

@media (max-width: 991px) {
  .main-img {
    > div:first-child {
      padding-right:                   0;
    }

    .toolbar {
      display:                         flex;
      flex-direction:                  row;
      flex-wrap:                       nowrap;
      justify-content:                 flex-start;
      align-items:                     center;
      gap:                             10px;
      overflow-x:                      scroll;
      margin:                          -10px 0 0 0;
      padding:                         0 10px 10px 10px;
      border-bottom:                   1px solid var(--Shades-20, #F0F0F0);

      &::-webkit-scrollbar {
        display:                       none;
      }

      .btn {
        overflow:                      unset;
        white-space:                   nowrap;
      }
    }

    // .scrollbar {
    //   max-height:                      calc(100dvh - 299px) !important;
    // }
    //
    // &.dnh-ia-advanced {
    //   .scrollbar {
    //     max-height:                    calc(100dvh - 235px) !important;
    //   }
    // }
  }

  .main-img,
  .retract {
    .main-img {
      .scrollbar {
        max-height:                      calc(100dvh - 299px) !important;
      }

      &.dnh-ia-advanced {
        .scrollbar {
          max-height:                    calc(100dvh - 200px) !important;
        }
      }

      &.has-mutirao {
        .scrollbar {
          max-height:                    calc(100dvh - 200px) !important;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1365px) {
  .main-img,
  .retract {
    .main-img {
      .scrollbar {
        max-height:                      calc(100vh - 323px) !important;
      }

      &.has-mutirao {
        .scrollbar {
          max-height:                    calc(100vh - 489px) !important;
        }
      }
    }
  }
}
</style>

<script src="./ExamImageTab.js"></script>
