<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" viewBox="0 0 4 16" fill="none">
    <circle cx="1.99997" cy="2.03854" r="1.71921" fill="black"/>
    <circle cx="1.99997" cy="7.99997" r="1.71921" fill="black"/>
    <circle cx="1.99997" cy="13.9614" r="1.71921" fill="black"/>
  </svg>
</template>

<style lang="css" scoped>
</style>

<script>
export default {
  name: 'IconDot'
}
</script>
