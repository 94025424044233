const storeResponse = {
    "clinic": {
        "createdAt": 1687351463479,
        "updatedAt": 1687351463653,
        "id": 1,
        "uuid": "8faae91e-b1ef-47e2-a4fb-439313fc50d4",
        "deleted": false,
        "name": "Phelcom BRASIL",
        "fantasyName": "Phelcom BRASIL",
        "document": "111.111.111-11",
        "active": true,
        "address": "Rua José Missali, 820",
        "addressNumber": "",
        "district": "",
        "postalCode": "",
        "city": "São Carlos",
        "state": "SP",
        "country": "BR",
        "locale": "pt-br",
        "telephone1": "1",
        "telephone2": "",
        "telephone3": "",
        "email": "admin@phelcom.com.br",
        "mandatoryDocument": "document1",
        "logo": "",
        "synchronizePatientData": true,
        "systemConfiguration": null,
        "deviceConfiguration": null,
        "code": "03e9",
        "lastPatientCode": "523.001",
        "parent": null,
        "owner": 1
    },
    "features": [
        "mutirao",
        "medicalRecord",
        "patientAccess",
        "imageEditor",
        "iaAdvancedFunctions",
        "generalReport",
        "backoffice",
        "eyerweb",
        "quickReport"
    ]
}
export default storeResponse;