<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" :id="offcanvas_id" data-bs-backdrop="static" :aria-labelledby="`${offcanvas_id}_label`">
    <div class="offcanvas-header">
      <button class="btn-back" type="button" name="button" id="closeBtn" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" :id="`${offcanvas_id}_label`">{{ title }}</h5>
    </div>
    <div class="offcanvas-body" v-if="title">
      <vee-form v-slot="{ handleSubmit }" as="div">
        <form @submit.prevent.stop="handleSubmit(doConfirm)" @keydown.enter="$event.preventDefault()">
          <p v-html="text"></p>

          <div class="form-floating">
            <vee-field :name="target" v-model="mfaValue" :rules="target == 'email' ? 'required|email' : 'required'" v-slot="{ field, errorMessage }">
              <input v-bind="field"  :type="target == 'email' ? 'email' : 'text'" class="form-control" :id="target" :placeholder="inputLabel" data-test="inputPatientFirstName">
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label :for="target">{{ inputLabel }}*</label>
          </div>

          <div class="offcanvas-footer">
            <button type="submit" class="btn" :class="okButtonClass" data-test="btnConfirm">{{ okButton }}</button>
            <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="offcanvas" v-show="cancelButton" data-test="btnCancel" @click="doCancel">{{ cancelButton }}</button>
          </div>
        </form>
      </vee-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {

    form {
      display:                          flex;
      flex-direction:                   column;
      gap:                              26px;
    }

    p {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin:                           0;
    }

    .vee-error {
      width:                            100%;
    }
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn {
      font-size:                        var(--font-size-14);
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   4.2px;
      text-align:                       center;
      text-transform:                   uppercase;
      // gap:                              10px;
      // flex:                             1 0 0;
      width:                            100%;
      padding:                          20px;
    }

    .btn-success {
      color:                            var(--Shades-W, #FFF);
      // margin-top:                       26px;
    }
  }
}
</style>

<script>
export default {
  name: 'UserMfaRegisterOffcanvas',
  props: ['offcanvas_id'],
  data: () => ({
    title: null,
    text: null,
    inputLabel: null,
    // inputValue: null,
    target: null,
    okButton: null,
    okButtonClass: null,
    cancelButton: null,
    cancelButtonClass: null,
    offcanvas_mfa: null,
    mfaValue: null,
  }),
  mounted() {
  },
  methods: {
    show(options = {}) {
      this.mfaValue = null

      this.title = options.title
      this.text = options.text
      this.inputLabel = options.inputLabel
      this.target = options.target
      this.okButton = options.okButton
      this.okButtonClass = options.okButtonClass

      this.mfaValue = options.target == 'mobile' ? options.inputValue : ""

      if (options.cancelButton) {
        this.cancelButton = options.cancelButton
        this.cancelButtonClass = options.cancelButtonClass
      }

      this.offcanvas_mfa = new bootstrap.Offcanvas(document.getElementById(this.offcanvas_id)).show();


      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.offcanvas_mfa = null;
      console.log('this.mfaValue', this.mfaValue);
      this.resolvePromise(this.mfaValue)
    },

    doCancel() {
      this.offcanvas_mfa = null;
      this.resolvePromise(false)
    },

    close() {
      document.getElementById('closeBtn').click()
      this.offcanvas_mfa = null;
    }
  }
}
</script>
