<template>
  <div class="external-page" v-loader.opacity="loading">
    <div class="container">
      <div class="row">
        <div class="col-lg-7" :style="backgroundStyle">
          <!-- <div class="">
            <h6>{{ $t('createAccount.welcome') }}</h6>
            <img src="@/assets/images/phelcom/eyercloud_logo_white.svg" alt="Eyercloud logo">
          </div> -->
        </div>

        <div class="col-lg-5 flex-between">
          <div class="">
            <img src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo">
          </div>

          <div class="external-content">
            <h3>{{ $t('createAccount.userRegister') }}</h3>

            <vee-form v-slot="{ handleSubmit }" as="div">
              <form @submit.prevent.stop="handleSubmit($event, doSave)">
                <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
                  <span v-html="alert.message"></span>
                  <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
                </div>

                <div class="form-floating mb-3">
                  <vee-field name="firstName" v-model="account.firstName" rules="required" v-slot="{ field, errorMessage }">
                    <input v-bind="field" type="text" class="form-control" ref="firstName" id="firstName" :placeholder="$t('createAccount.name')" data-test="inputAccountFirstName">
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                  <label for="firstName">{{ $t('createAccount.name') }}</label>
                </div>

                <div class="form-floating mb-3">
                  <vee-field name="lastName" v-model="account.lastName" rules="required" v-slot="{ field, errorMessage }">
                    <input v-bind="field" type="text" class="form-control" ref="lastName" id="lastName" :placeholder="$t('createAccount.surname')" data-test="inputAccountLastName">
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                  <label for="lastName">{{ $t('createAccount.surname') }}</label>
                </div>

                <div class="form-floating mb-3">
                  <vee-field name="email" v-model="account.email" rules="required|email" v-slot="{ field, errorMessage }">
                    <input v-bind="field" type="email" class="form-control" ref="email" id="email" :placeholder="$t('createAccount.email')" data-test="inputAccountEmail">
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                  <label for="email">{{ $t('createAccount.email') }}</label>
                </div>

                <p>{{ $t('createAccount.setPassword') }}</p>

                <div class="form-floating mb-3">
                  <vee-field name="password" v-model="password" rules="required|min:4" v-slot="{ field, errorMessage }">
                    <input v-bind="field" :type="showPassword ? 'text' : 'password'" data-test="inputAccountPassword" :placeholder="$t('createAccount.password')"
                           new-password class="form-control" ref="password" id="password" @keyup="checkPassword(password)">

                    <button class="btn-icon" type="button" name="button" data-test="btnShowPassword" @click="showPassword = !showPassword">
                      <IconShowPassword/>
                    </button>

                    <small class="strength-password" :class="strengthInfo" v-show="strengthInfo">{{ strengthInfo ? $t(`strengthPassword.${strengthInfo}`) : "" }}</small>

                    <span class="vee-error">{{ errorMessage ? errorMessage : $t(`${strengthPasswordMessage}`) }}</span>
                  </vee-field>
                  <label for="password">{{ $t('createAccount.password') }}</label>
                </div>

                <div class="form-floating mb-3">
                  <vee-field name="confirmPassword" v-model="account.confirmPassword" rules="required|confirmed:password" v-slot="{ field, errorMessage }">
                    <input v-bind="field" :type="showConfirmPassword ? 'text' : 'password'" data-test="inputAccountConfirmPassword"
                           class="form-control" ref="confirmPassword" id="confirmPassword" :placeholder="$t('createAccount.confirmPassword')">

                    <button class="btn-icon" type="button" name="button" data-test="btnShowPassword" @click="showConfirmPassword = !showConfirmPassword">
                      <IconShowPassword/>
                    </button>
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                  <label for="confirmPassword">{{ $t('createAccount.confirmPassword') }}</label>
                </div>

                <hr>

                <p>{{ $t('createAccount.setPin') }}</p>

                <div class="form-floating mb-3">
                  <vee-field name="pin" v-model="account.pin" rules="required|min:4" v-slot="{ field, errorMessage }">
                    <input v-bind="field" type="text" class="form-control" ref="pin" id="pin" v-only-numbers :placeholder="$t('user.devicePIN')" data-test="inputAccountPin">
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                  <label for="pin">{{ $t('user.devicePIN') }}</label>
                </div>

                <div class="action-buttons">
                  <div class="">
                    <vue-recaptcha
                      ref="recaptcha"
                      @verify="onSaveVerify"
                      @expired="onExpired"
                      :sitekey=sitekey
                      size="invisible"
                      :load-recaptcha-script="true">
                    </vue-recaptcha>
                    <button class="btn btn-success" type="submit" name="button" :disabled="loading" data-test="btnRegister">{{ $t('createAccount.register') }}</button>
                  </div>
                </div>
              </form>
            </vee-form>
          </div>

          <div class="powered-box">
            <p class="d-inline-block">POWERED BY</p>
            <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
              <img src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
            </a>
            <p class="d-inline-block">v {{ appVersion }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
.vee-error {
  width:                        100% !important;
}

.form-control {
  position:                     relative;
}


.btn-icon {
  right:                        12px;
}

.strength-password {
  position:                     absolute;
  color:                        white;
  font-size:                    0.7em;
  text-align:                   center;
  height:                       12px;
  width:                        100%;
  top:                          -3px;
  &.danger {
    background:                 $phelcom-red;
  }
  &.warning {
    background:                 $yellow-phelcom;
  }
  &.success {
    background:                 $phelcom-green;
  }
}

.col-lg-5 {
  > div > img {
    display:                    none;
    width:                      160px;
  }
}
.col-lg-7 {
    @include                               border_radius(10px);
    display:                               flex;
    flex-direction:                        column;
    justify-content:                       space-between;
    background:                            linear-gradient(252deg, #000 0%, #0D4636 100%);
    background:                            url('@/assets/images/phelcom/register_banner_en.png') no-repeat center bottom 100%, linear-gradient(252deg, #000 0%, #0D4636 100%);
    background-size:                       cover;
    // background:                            url('@/assets/images/phelcom/welcome_banner_en-US.png') no-repeat right center, linear-gradient(252deg, #000 0%, #0D4636 100%);
    padding:                               5rem 3rem 3rem;
}
@media (max-width: 991px) {
  .external-page {
    width:                      100%;
    overflow-y:                 hidden;

    .col-lg-7 {
      display:                  none;
    }
    .col-lg-5 {
      > div > img {
        display:                block;
      }

      .external-content {
        width:                  100%;
        padding:                1rem 0;
      }

      .powered-box {
        justify-content:        center;
      }
    }
  }
}
</style>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import { useUserStore } from '@/store/user'
import utilService from '../../../services/util-service.js';

export default {
  name: 'registration-account-create',
  components: {
    'vue-recaptcha': VueRecaptcha,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null,
    },
    account: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      pin: "",
    },
    accountType: "online",
    showPassword: false,
    showConfirmPassword: false,
    password: "",
    confirmPassword: "",
    strengthPasswordMessage: 'zxcvbnMessages.default',
    strengthInfo: null,
    sitekey: process.env.VUE_APP_SITE_KEY,
    appVersion: process.env.VUE_APP_VERSION,
    countryCode: 'en',
    backgroundStyle: null,
  }),
  mounted() {
    const userStore = useUserStore()
    userStore.reset() // avoid reusing other clinic info
    this.countryCode = utilService.getCurrentLanguage()      
    const imagePath = require(`@/assets/images/phelcom/register_banner_${this.countryCode}.png`);
    this.backgroundStyle = `background: url(${imagePath}) no-repeat center bottom 100%, linear-gradient(252deg, #000 0%, #0D4636 100%); background-size: cover;`

    this.$refs.firstName.focus()
    if (this.$route.params.accountType)
      this.accountType = this.$route.params.accountType

    if (this.$route.meta.accountType)
      this.accountType = this.$route.meta.accountType

    this.disableCopyPaste();
  },
  methods: {
    async doSave() {
      this.$refs.recaptcha.execute();
    },
    async checkPassword(password) {
      this.account.password = password
      let params = {
        account: this.account
      }

      let isValidPassword = await utilService.validatePassword(params.account)

      switch (isValidPassword.score) {
        case 0:
        case 1:
        case 2:
          this.strengthInfo = 'danger'
          break;
        case 3:
          this.strengthInfo = 'warning'
          break;
        case 4:
          this.strengthInfo = 'success'
          break;
      }

      if (isValidPassword.valid) {
        this.strengthPasswordMessage = 'zxcvbnMessages.default'
      } else {
        this.strengthPasswordMessage = isValidPassword.warning ? 'zxcvbnMessages.warnings.' + isValidPassword.warning :
                                                            'zxcvbnMessages.suggestions.' + isValidPassword.suggestion[0]
      }
    },
    onSaveVerify: async function (gtoken){
      this.$refs.recaptcha.reset();
      let params = {
        account: this.account,
        accountType: this.accountType,
        recaptcha: gtoken,
      }
      let responseValidate = await utilService.validatePassword(params.account)

      if (responseValidate.valid == false){
        this.$message.popup(this, 'zxcvbnMessages.error', 'operationFail', 'error')
        return
      }
      this.loading = true
      NProgress.start()
      try {
          let response = await this.$registrationService.createAccount(params)
          let data = this.$utils.dataQuery({user: response.user})
          this.$router.push({name: 'registration-account-created', query: {data: data}})
      } catch (err) {
        this.$message.popup(this, `createAccount.${err.response.data.errorMessage}`, 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },
    onExpired: function () {
      this.$refs.recaptcha.reset();
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShowConfirm() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    disableCopyPaste() {
      let inputArray = [this.$refs.password, this.$refs.confirmPassword, this.$refs.pin]
      inputArray.forEach((element) => {
        element.addEventListener('contextmenu', (e) => e.preventDefault()); // Disable right-click menu
        element.addEventListener('cut', (e) => e.preventDefault()); // Disable cut
        element.addEventListener('copy', (e) => e.preventDefault()); // Disable copy
        element.addEventListener('paste', (e) => e.preventDefault()); // Disable paste
      })
    },
  },

}
</script>
