<template>
  <div class="limbo-box">
    <i class="icon-exam"></i>
    <h1>{{ $t('limbo.incompleteRegistration') }}</h1>

    <div class="">
      <h3>{{ $t('limbo.userWithoutClinic') }}</h3>
      <h2>{{ $t('limbo.checkEmail') }}</h2>
      <br/>
      <p>{{ $t('limbo.problemPersists') }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.limbo-box {
  display:                     flex;
  align-items:                 center;
  justify-content:             center;
  flex-direction:              column;
  text-align:                  center;
  height:                      90vh;
  padding-left:                15px;
  padding-right:               15px;
  h1 {
    font-weight:               bold;
  }
  p {
    font-size:                 1.1em;
  }
  i {
    font-size:                 4em;
    margin-bottom:             30px;
    &:before {
      color:                   #73879C;
    }
  }
}

@media (max-width: 500px) {
  .limbo-box {
    h1 {
      font-size:               2.1em;
    }
    h3 {
      font-size:               1.6em;
    }
    h2 {
      font-size:               1em;
    }
    p {
      font-size:               0.9em;
    }
  }
}
</style>
