<template>
  <div class="row">
    <div class="col flex-center">
      <div class="">
        <h1>{{ $t('externalPatient.termsOfUse') }}</h1>

        <p>
          {{ $t('terms.modalTextLine1') }}
          {{ $t('terms.modalTextLine2') }}
          {{ $t('terms.modalTextLine3') }}
        </p>
        <a href="https://phelcom.com/lgpd" target="_blank">{{ $t('terms.knowOur') }}{{ $t('terms.privacyPolicy') }} {{ $t('terms.and') }} {{ $t('terms.termsOfUse') }}.</a>

        <!-- <div class="check-box" v-if="!accepted"> -->
        <div class="check-box">
          <label>
            <input type="checkbox" id="acceptedTerm" v-model="collaborator.acceptedTerm" data-test="checkboxAcceptedTerm">
            {{ $t('terms.readAndConfirm') }}<b>{{ $t('terms.privacyPolicy') }}</b> {{ $t('terms.and') }} <b>{{ $t('terms.termsOfUse') }}</b> {{ $t('terms.fromEyerCloud') }}
          </label>
        </div>

        <button class="btn btn-success" type="submit" name="button" data-test="btnAccept" @click="doAccept">{{ $t('termsPatient.accept') }}</button>
        <button class="btn btn-default" type="button" name="button" data-test="btnDontAccept" @click="close">{{ $t('termsPatient.doNotAccept') }}</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.col {
  flex-direction:                 column;
  > div:not(.flex-center) {
    display:                      flex;
    flex-direction:               column;
    align-items:                  flex-start;
    gap:                          26px;
    width:                        70%;
    max-width:                    340px;
    // padding:                      0;
    padding-bottom:               3rem;
    // padding-top:                  1rem;
  }
  h1 {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    38px;
    font-weight:                  300;
    line-height:                  normal;
  }
  p,
  a,
  label {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    var(--font-size-14);
    font-weight:                  300;
    line-height:                  140%; /* 19.6px */
  }
  a {
    text-align: left;
  }
}

.btn {
  font-size:                      var(--font-size-14);
  font-weight:                    700;
  line-height:                    normal;
  letter-spacing:                 4.2px;
  text-align:                     center;
  text-transform:                 uppercase;
  // gap:                            10px;
  // flex:                           1 0 0;
  width:                          100%;
  padding:                        20px;
}

@media (max-width: 991px) {
  .col {
    > div:not(.flex-center) {
      width:                      100%;
    }

    h1 {
      font-size:                  32px;
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'terms-of-use',
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    collaborator: {
      acceptedTerm: false,
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    accepted: false,
  }),
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.accepted = this.session.collaborator.acceptedTerm
  },
  methods: {
    async doAccept() {
      NProgress.start()
      try {
        let response = await this.$userService.acceptTerms(this.collaborator)
        const userStore = useUserStore()
        userStore.termAccepted(response)
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)

      // this.$nextTick(() => {
      //   $('#closeBtn').click();
      // });
    }
  }
}
</script>
