<template>
  <div >
  </div>
</template>

<style lang="scss">
 
</style>

<script>
   export default {
    name: 'external-logoff',
    components: {
    },
    mounted() {
        this.logout()
    },
    methods: {
        async logout() {
            await this.$externalService.accessLogout(this)
            window.open('/acesso', '_self');
            
        }
    }
    
  }
</script>
