import { useUserStore } from '@/store/user'
import NewNotificationModal from '@/components/NewNotificationModal.vue'

export default {
  name: 'ClinicConfigurationTab',
  props: ['clinic_id'],
  components: {
    NewNotificationModal,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null,
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    showRemoveNotification: false,
    showModalNotification: false,
    disabled: false,
    notifications: [],
    forwardedSelected: 'none',
    flowSelected: 'none',
    forwardedOptions: [],
    flowOptions: [],
    alertOptions: [],
    preAnalysisOptions: {
      // "threshold": 0.7, "nextScreen": "examList", "autoReferrerMode": "referrer"
    },
    clinic: {},

    triggerOptions: [],
    ruleOptions: [],
    mcRASOptions: [],
  }),
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.triggerOptions = [
      {value: null, label: this.$i18n.t('createClinic.select')},
      {value: 'threshold', label: this.$i18n.t('clinicConfig.threshold')},
      {value: 'mcRAS', label: this.$i18n.t('clinicConfig.mcRAS')},
    ]

    this.ruleOptions = [
      {value: null, label: this.$i18n.t('clinicConfig.select')},
      {value: 'geThan', label: this.$i18n.t('clinicConfig.greaterOrEqual')}
    ]

    this.mcRASOptions = [ // mcRAS classes
      {value: 'c1', label: this.$i18n.t('clinicConfig.mcRAS_c1')},
      {value: 'c2', label: this.$i18n.t('clinicConfig.mcRAS_c2')},
      {value: 'c3', label: this.$i18n.t('clinicConfig.mcRAS_c3')},
      {value: 'c4', label: this.$i18n.t('clinicConfig.mcRAS_c4')},
      {value: 'c5', label: this.$i18n.t('clinicConfig.mcRAS_c5')},
      {value: 'c6', label: this.$i18n.t('clinicConfig.mcRAS_c6')}
    ]


    this.flowOptions = [
      {value: 'none', label: this.$i18n.t('none')},
      {value: 'examList', label: this.$i18n.t('clinicConfig.examsList')},
      {value: 'quickReport', label: this.$i18n.t('clinicConfig.quickReport')},
    ]
    this.forwardedOptions = [
      {value: 'none', label: this.$i18n.t('none')},
      {value: 'auto', label: this.$i18n.t('clinicConfig.autoThreshold')},
    ]
    this.alertOptions = [
      {value: 'none', label: this.$i18n.t('none')},
      {value: 'closedExam', label: this.$i18n.t('clinicConfig.closedExam')},
      {value: 'thresholdClosedExam', label: this.$i18n.t('clinicConfig.thresholdClosedExam')},
    ]
    this.fetchData()
  },
  methods: {
    fetchData: async function() {
      let params = {
        id: this.clinic_id
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.getClinic(params);
        this.clinic = response.clinic
        this.$emit('clinic-configuration-changed', this.clinic.systemConfiguration)

        let systemConfiguration = this.$clinicService.getSystemConfiguration(this.clinic)
        this.preAnalysisOptions = systemConfiguration.preAnalysis ? systemConfiguration.preAnalysis : {}
        this.notifications = systemConfiguration.notifications ? systemConfiguration.notifications : []

        if (this.notifications) {
          this.notifications.forEach(o => {
            o.table = this.fillTargetTable(o.targets)
          })
        }
      } catch (err) {
        console.log(err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    doSave: async function() {
      this.alert.message = null

      let params = {
        configuration: {
          preAnalysis: this.preAnalysisOptions,
          notifications: this.notifications
        }
      }

      this.loading = true
      NProgress.start()
      try {
        await this.$clinicService.saveConfiguration(this.session, params);
        this.$message.popup(this, 'general.createdSuccess', 'operationSuccess', 'success')

        this.$nextTick(() => {
          this.fetchData()
        })
      } catch (err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    openModalNotification(notification) {
      this.selectedNotification = notification ? Object.assign({}, notification) : null
      this.showModalNotification = true;

      setTimeout(function() {
        // let modal_new_notification = document.getElementById("modal_new_notification");
        new bootstrap.Modal(modal_new_notification).show();
      }, 100);
    },
    closeModalNewNotification() {
      this.showModalNotification = false;
      this.fetchData()
    },
    doDeleteNotification(value) {
      console.log("doDeleteNotification()", value)
      // TODO EC-337 remove date from the ID (this is just to force a new removal when multiple clicks are made)
      // this.deleteNotificationID = `${value.uuid}_${moment().format()}`;
      console.log("this.notifications >>>> BEFORE", this.notifications)

      this.alert.message = null
      const targetUUID = value.uuid
      let filtered = this.notifications.filter(function(n) { return n.uuid != targetUUID; });
      this.notifications = filtered

      this.doSave()
      this.showRemoveNotification = false;
      console.log("this.notifications >>>> AFTER", this.notifications)
    },
    fillTargetTable(value) {
      // TODO tabela esta adicionando valores no momento em que é adicionado um novo destinatario dentro do modal de novas notificações
      console.log("fillTargetTable()", value)
      let table = {
        'items': value,
        'columns': [
          {name: this.$i18n.t('clinicConfig.userName'), value: 'name', width: '50%'},
          {name: this.$i18n.t('clinicConfig.destination'), value: 'destination', width: '50%'},
        ]
      };
      return table;
    },
    async confirmNotificationRemoval(item) {
      console.log("confirmNotificationRemoval() >>>", item)

      const ok = await this.$refs.modalGeneric.show({
        modalType: 'danger',
        title: this.$i18n.t('confirm'),
        text: this.$i18n.t('general.removeConfirm'),
        okButton: this.$i18n.t('remove'),
        okButtonClass: 'btn-danger',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.doDeleteNotification(item)
      }
    },
  }
}
