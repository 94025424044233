<template>
  <div class="user-main" v-loader.opacity="loading">
    <div class="sub-header-box">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>

      <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/exam/" data-test="btnRouter">
              {{ $t('home') }}
            </router-link>
          </li>
          <li class="breadcrumb-item" aria-current="page">
            <router-link to="/settings/" data-test="btnRouter">
              {{ $t('createClinic.configuration') }}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ $t('user.user') }}</li>
        </ol>
      </nav>
    </div>

    <div class="title-box">
      <div class="flex-start">
        <div class="">
          <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
            <IconArrowBack/>
          </button>

          <h2 class="title">{{ $t('user.userSettings') }}</h2>
        </div>
      </div>
    </div>
    <!-- <div class="title-box info clear">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
        <IconBack/>
      </button>

      <h2 class="title">
        {{ $t('user.user') }} |
        <span v-if="user">{{ user.collaborator.rawName }}</span>
      </h2>
    </div> -->

    <BaseTab class="tab-users" :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-data>
        {{ $t('user.data') }}
      </template>
      <template v-slot:data>
        <UserDataTab v-if="tabActive === 'data' && userId" :user_id="userId" :alertmsg="alert ? alert : undefined"></UserDataTab>
      </template>
    </BaseTab>

    <!-- <div class="action-bar">
      <div id="userActionBar"></div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.breadcrumb-item {
  text-transform:                    capitalize;
}

.sub-header-box {
  display:                           flex;
  flex-direction:                    row;
  justify-content:                   flex-start;
  align-items:                       center;
  gap:                               20px;
  width:                             100%;
  padding:                           15px 20px;

  ol {
    margin:                          0;
  }
}

.title-box {
  display:                            flex;
  flex-direction:                     row;
  align-items:                        center;
  justify-content:                    space-between;
  height:                             55px;
  margin:                             0;
  padding:                            10px 25px;
  // margin:                             2.3rem 0 1.7rem;

  > div {
    width:                            auto !important;
  }

  .btn-back {
    display:                          none;
  }
}

@media (max-width: 991px) {
  .sub-header-box,
  .title-box .btn-outline-secondary span {
    display:                          none;
  }

  .title-box {
    padding:                          10px;

    .title {
      font-size:                      18px !important;
    }

    .flex-start {
      > div {
        display:                      flex;
        align-items:                  center;
        gap:                          15px;
      }
    }

    .btn-back {
      display:                        flex;
    }

    .btn-outline-secondary {
      padding:                        11.5px 15px;
      svg {
        margin:                       0;
      }
    }

    .btn-back,
    .btn-outline-secondary {
      height:                         35px;
      margin:                         0 !important;
    }
  }
}
</style>

<script>
import UserDataTab from './UserDataTab.vue'

export default {
  name: 'user-show',
  // props: ['userid'],
  components: {
    UserDataTab,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tabActive: 'data',
    tabs: ['data'],
    photo: null,
    signature: null,
  }),
  computed: {
    userId() {
      return this.$route.params.userID
    },
    tab() {
      return this.$route.query.tab;
    },
  },
  mounted: function() {
    this.$activeLink.value = 'users'

    if (this.tab) {
      this.tabActive = this.tab;
    }
  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
    goBack() {
      this.$router.push({name: 'settings', query: {tab: 'users'}})
    },
  }
}
</script>
