import eyerCloud from '@/config/eyercloud'

class PasswordService {

    /**
     * Starts the user registration process after receiving an email with a link.
     *
     * @param {*} params
     * @returns
     */
    async zxcvbnValidation(params) {
      let response = await eyerCloud.post(`/api/v2/eyercloud/user/validate-password`, params)
      return response.data
    }

  }

  const passwordService = new PasswordService();
  export default passwordService;
