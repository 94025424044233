<template>
  <div class="main-box patient-list-box" v-loader-eyerweb.opacity="loading">
    <div class="title-box rounded clear">
      <h2 class="title">{{ $t('patient.patientList') }}</h2>

      <div class="input-group">
        <input type="text" class="form-control" v-model="search" :placeholder="$t('patient.searchMessage')"
          :aria-label="$t('patient.searchMessage')" aria-describedby="searchPatient" @keypress.enter="fetchData">
        <span class="input-group-text" id="searchPatient" @click="fetchData">{{ $t('patient.search') }}</span>
      </div>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseTable v-if="tablePatient" :tabledata="tablePatient" @item-opened="toDetails">
      <!-- <template v-slot:column_check="{ item }">
        <div class="position-relative">
          <BaseCheckBox :id="item.uuid" :value="item.uuid" :item="item" @checked="doCheck"></BaseCheckBox>
        </div>
      </template> -->
    </BaseTable>

    <div class="row align-items-center page-row" v-if="tablePatient">
      <div class="col">
        <p>{{ $t("pagination.totalEntries") }} {{ tablePatient.total }}</p>
      </div>
      <div class="col-auto">
        <BasePagination :total-pages="totalPages" :total="tablePatient.total" :per-page="perPage"
          :current-page="currentPage" @pagechanged="onPageChange" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input-group,
.title-box,
.title-box .btn {
  margin-bottom:                 0;
}

.page-row,
.page-row p,
.table {
  margin-bottom:                 0 !important;
}

.page-row {
  margin-top:                    20px;

  p,
  .page-row .pagination {
    padding-right:               10px;
    padding-left:                10px;
  }
}

.base-check-box {
  margin-top:                    0 !important;

  &.checked:after {
    top:                         3px !important;
  }
}

.title-box {
  .input-group {
    display:                     flex !important;
    width:                       50%;
    max-width:                   550px;

    input {
      @include                   border_radius(20px 0 0 20px);
    }

    span {
      @include                   border_radius(0 20px 20px 0);
      font-size:                 1.2em;
      color:                     inherit;
      padding:                   0 15px;
      cursor:                    pointer;
    }
  }
}

@media (max-width: 767px) {
  .patient-list-box .title-box .title {
    width:                       calc(100% - 90px);
  }
}

@media (max-width: 991px) {
  .input-group {
    margin-top:                  10px;
    width:                       100% !important;
    max-width:                   unset !important;
  }
}
</style>

<script>
import { useEyerwebStore } from '@/store/eyerweb'

export default {
  name: 'eyerweb-patient-list',
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    tablePatient: null,
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
    search: null,
    patientName: '',
    patientMrn: '',
    patientDocument0: '',
    selectedPatients: [],
  }),
  computed: {
    isSpecialist() {
      return this.session.permissions === 'specialist'
    }
  },
  created() {
    this.$activeLink.value = 'patient'
    this.patientName = this.$i18n.t('patient.name')
    this.patientMrn = this.$i18n.t('patient.mrn')
    this.patientDocument0 = this.$i18n.t('document0')
    const eyerwebStore = useEyerwebStore()
    this.session = eyerwebStore.sessionInfo
    this.currentPage = this.session.patientCurrentPage ? this.session.patientCurrentPage : 1;
    this.$eyerwebService.patientListSubject.subscribe(this.patientListCallback)
    this.fetchData()
  },
  unmounted(){
    this.$eyerwebService.patientListSubject.unsubscribe(this.patientListCallback)
  },
  methods: {
    patientListCallback(result) {
      this.loadTableData(result)
      this.loading = false
      NProgress.done(true)

    },
    onPageChange(page) {
      this.currentPage = page;
      this.session.patientCurrentPage = page;
      this.fetchData();
    },
    fetchData() {
      this.alert.message = undefined

      let params = {
        page: this.currentPage
      }
      if (this.search)
        params.query = this.search

      this.loading = true
      NProgress.start()
      try {
        this.$eyerwebService.listPatients(params)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
    },

    loadTableData(response) {

      this.tablePatient = {
        'items': response.result.patients,
        'total': response.result.totalCount,
        'columns': [
          { name: this.patientName, type: 'string', value: 'rawName', width: '60%' },
          { name: this.patientMrn, type: 'string', value: 'mrn', width: '15%' },
          { name: this.patientDocument0, type: 'string', value: 'document0', width: '25%' }
        ]
      }

      this.totalPages = Math.ceil(response.result.totalCount / this.perPage);

      if (response.result.patients.length === 0) {
        this.tablePatient = undefined;
        this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
      }
    },
    toDetails(item) {
      let data = this.$utils.dataQuery({
        patientName: item.rawName
      })

      this.$router.push({ name: 'eyerweb-patient-show', params: { patientID: item.id }, query: { data: data } })
    },
    doCheck: async function (value) {
      if (this.selectedPatients.includes(value.uuid)) {
        // document.getElementById(value.uuid).checked = false;
        this.selectedPatients.splice(this.selectedPatients.indexOf(value.uuid), 1);
      } else {
        this.selectedPatients.push(value.uuid);
      }
    },
  },
  destroyed() {
    this.session = null
  }
}
</script>
