<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="offCanvasNewExam" data-bs-backdrop="static" aria-labelledby="offCanvasNewExamLabel">
    <div class="offcanvas-header">
      <button class="btn-back" id="closeBtn" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" id="offCanvasNewExamLabel">{{ $t('exam.addExam') }}</h5>
    </div>
    <div class="offcanvas-body">
      <p>{{ $t('exam.addExamInfo') }}</p>

      <!-- <button class="btn btn-dashed" type="button" name="button">{{ $t('exam.addPatient') }}</button> -->

      <p class="patient-selected" v-if="patient_name">{{ patient_name }}</p>

      <div class="date-box">
        <div class="form-floating">
          <date-picker
            menuClassName="dp-date-time"
            v-model="exam.date"
            :placeholder="$t('exam.dateAndTime')"
            :locale="locale"
            value-type="date"
            :selectText="$t('createClinic.select')"
            :cancelText="$t('cancel')"
            minimumView="time"
            :format="$t('dateTimeFormatFns')"
            :inputFormat="$t('dateTimeFormatFns')"
            :min-date="new Date()"
            data-test="datePickerModalNewExam">
          </date-picker>
          <label for="examDate">{{ $t('exam.dateAndTime') }}</label>
        </div>
      </div>

      <!-- footer inside body to avoid empty space between body and footer -->
      <div class="offcanvas-footer">
        <button type="button" class="btn btn-success" data-test="btnSave" @click="doSave">{{ $t('exam.addExam') }}</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  // width:                                510px !important;
  // padding:                              40px;

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    p:not(.patient-selected) {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin-bottom:                    0;
    }

    .patient-selected {
      @include                          border_radius(99px);
      color:                            var(--Brand-Primary, #1B294A);
      text-align:                       center;
      font-size:                        18px;
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   0.9px;
      display:                          flex;
      justify-content:                  center;
      align-items:                      center;
      height:                           55px;
      margin-bottom:                    0;
      padding:                          20px;
      border:                           2px solid var(--Brand-Primary, #1B294A);
      background:                       var(--Shades-10, #FAFAFA);
    }
  }

  .btn {
    font-size:                          var(--font-size-14);
    font-weight:                        700;
    line-height:                        normal;
    letter-spacing:                     4.2px;
    text-align:                         center;
    text-transform:                     uppercase;
    width:                              100%;
    margin:                             0;
    padding:                            20px;
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn-success {
      color:                            var(--Shades-W, #FFF);
    }
  }
}

.date-box {
  max-width: unset;
  margin: 0;
}
</style>

<script>
import { useUserStore } from '@/store/user'
import { enUS, ptBR, es } from 'date-fns/locale'

export default {
  name: 'PatientExamNewOffcanvas',
  props: ['patient_id', 'patient_name'],
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    exam: {
      date: new Date(),
      mydriasis: false
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
  }),
  computed: {
    locale() {
      return this.$utils.datePickerLocale()
    }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
  },
  methods: {
    close() {
      this.$emit('close')
    },
    doSave: async function() {
      let params = {
        id: this.patient_id,
        exam: this.exam
      }

      NProgress.start()
      try {
        let response = await this.$patientService.saveExam(params)
        this.$message.popup(this, 'general.createdSuccess', 'operationSuccess', 'success')

        this.$nextTick(() => {
          $('#closeBtn').click();
        });
      } catch (err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
    },
  }
}
</script>
