<template>
  <div class="tab-exam-box" v-loader.opacity.tab="loading">
    <!-- <div class="title-box rounded">
      <h2 class="title">{{ $t('exam.examsList') }}</h2>

      <button class="btn btn-outline-success rounded" type="button" name="button" data-test="btnOpenModalNewExam" @click="openModalNewExam">
        <i class="fa fa-plus"></i>
        <span>{{ $t('exam.newExam') }}</span>
      </button>
    </div> -->

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseInfiniteTable class="no-border" v-if="tableExams" :tabledata="tableExams" @item-opened="examSelected">
      <template v-slot:column_captures="{item}">
        <div class="preview-image-box" v-if="item.examImages.length" :data-title="$t('exam.iaText')">
          <ul class="preview-image-list">
            <template v-for="(image, index) in item.examImages">
              <li v-if="allowedImages(image.type) && index < 3">
                <img :src="thumbDataPath +'/'+ image.name" alt="">
              </li>
            </template>
            <li class="counter" v-if="item.examImages.length > 2">+{{ item.examImages.length }}</li>
          </ul>
        </div>
        <p class="no-captures" v-else>{{ $t('exam.noCaptures') }}</p>
      </template>

      <template v-slot:column_content="{item}">
        <div class="d-inline-block">
          <span v-show="item.examType.key == 'patient_progression'" class="label label-blue-phelcom" :data-title="$t(`exam.examTypeName_${item.examType.key}`)">{{ $t(`exam.examType_${item.examType.key}`) }}</span>
          <i v-if="item.patient.referred > 0" class="fa" :class="item.patient.referred == 1 ? 'fa-tint' : 'fa-check-circle-o'" aria-hidden="true"></i>
          <button class="no-btn" type="button" name="button" data-title="Download" :data-test="`btnDownloadExam_${item.uuid}`" @click.stop.prevent="doDownload(item)">
            <IconDownload/>
          </button>
          <button :disabled="!item.hasImage" class="no-btn" type="button" name="button" :data-title="$t('tooltips.image')" :data-test="`btnHasImageExam_${item.uuid}`">
            <IconImage/>
          </button>
          <button :disabled="!item.hasReport" class="no-btn" type="button" name="button" :data-title="$t('tooltips.report')" :data-test="`btnReportExam_${item.uuid}`">
            <IconPdf/>
          </button>
        </div>
      </template>

      <template v-slot:column_ai="{item}">
        <div class="ai-box" v-show="item.scoreResult" :data-title="$t('exam.iaText')">
          <p>AI</p>
          <span :class="item.scoreResult ? item.scoreResult.odStatus : ''"></span>
          <span :class="item.scoreResult ? item.scoreResult.osStatus : ''"></span>
        </div>
      </template>
    </BaseInfiniteTable>

    <!-- <button class="btn-icon btn-top" type="button" name="button" v-if="currentPage > 1 && tableExams.items.length > 20" @click="goTop">
      <IconArrowUp/>
    </button> -->

    <div class="action-bar">
      <button class="btn btn-success" type="button" name="button" data-test="btnOpenOffCanvasNewExam" @click="openOffCanvasNewExam">
        <span>{{ $t('exam.newExam') }}</span>
      </button>
    </div>

    <teleport to="body">
      <PatientExamNewOffcanvas v-if="showOffcanvasNewExam" :patient_id="patient_id" :patient_name="patient_name" @close="showOffcanvasNewExam = false"/>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.ai-box {
  @include                          border_radius(99px);
  display:                          flex;
  justify-content:                  flex-start;
  padding:                          5px 5px 5px 10px;
  align-items:                      center;
  max-width:                        64px;
  border:                           1px solid var(--Shades-30, #D8D8D8);
  background:                       var(--Shades-W, #FFF);

  p {
    color:                          var(--Shades-70, #404040);
    font-size:                      var(--font-size-10);
    font-weight:                    700;
    line-height:                    normal;
    letter-spacing:                 1.5px;
    margin:                         0 10px 0 0;
    padding-top:                    1px;
  }

  span {
    @include                        border_radius(50%);
    position:                       relative;
    width:                          12px;
    height:                         12px;
    background-color:               white;
    border:                         2px solid var(--Shades-30, #D8D8D8);;
    z-index:                        2;

    &:last-child {
      left:                         -2px;
      z-index:                      1;
    }

    &.green {
      background-color:             #3CB391;
      border:                       1px solid white;
    }

    &.yellow {
      background-color:             $yellow-red;
      border:                       1px solid white;
    }

    &.red {
      background-color:             #D7541C;
      border:                       1px solid white;
    }
  }
}

.preview-image-box {
  ul {
    display:                        flex;
    align-items:                    center;
    flex-direction:                 row;
    list-style-type:                none;
    padding:                        0;
    margin:                         0;

    li {
      width:                        31px;
      height:                       26px;

      img {
        @include                    border_radius(99px);
        width:                      100%;
        height:                     100%;
        border:                     2px solid var(--Shades-W, #FFF);
      }

      &:not(:first-child) {
        margin-left:                -5px;
      }

      &.counter {
        @include                    border_radius(99px);
        color:                      var(--Shades-60, #727272);
        text-align:                 center;
        font-size:                  12px;
        font-weight:                700;
        line-height:                2;
        letter-spacing:             0.6px;
        border:                     1px solid var(--Shades-40, #B7B7B7);
        background:                 var(--Shades-W, #FFF);
      }
    }
  }
}

.tab-exam-box .title-box .btn {
  margin-bottom:                     0;
}

.no-btn {
  padding:                           0;
  margin:                            0 10px 0 0;
}

.action-bar {
  display:                           flex;
  justify-content:                   flex-start;
  align-items:                       center;
  gap:                               10px;
  height:                            64px;
  padding:                           10px 20px;
  border-top:                        1px solid var(--Shades-30);
}

.btn-success {
  @include                           border_radius(99px);
  color:                             var(--Shades-W);
  font-size:                         var(--font-size-12);
  font-weight:                       700;
  letter-spacing:                    3.64px;
  text-transform:                    uppercase;
  height:                            44px;
  padding:                           15px 20px;
  background:                        var(--Brand-Secondary);
  border:                            1px solid var(--Brand-Secondary);
}

@media (max-width: 400px) {
  .tab-exam-box .title-box {
    .title {
      text-align:                    center;
      width:                         100%;
      max-width:                     unset;
    }
    .btn {
      display:                       block;
      margin-right:                  auto !important;
      margin-left:                   auto !important;
    }
  }
}

@media screen and (min-width: 401px) and (max-width: 767px) {
  .tab-exam-box .title-box .title {
    text-align:                      left;
    width:                           calc(100% - 152px);
  }
}

@media (min-width: 767px) {
  .icon-phelcom-ai {
    vertical-align:                  top;
    margin-top:                      1px;
  }
}
</style>

<script>
import PatientExamNewOffcanvas from './PatientExamNewOffcanvas.vue'

const IS_PRODUCTION = process.env.NODE_ENV == 'production'

export default {
  name: 'PatientExamTab',
  props: ['patient_id', 'force_fetch', 'patient_name'],
  components: {
    PatientExamNewOffcanvas
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tableExams: null,
    thumbDataPath: null,
    dataPath: null,
    isPatientMounted: false,
    showOffcanvasNewExam: false
  }),
  mounted: async function() {
    if (this.$route.query.data) {
      let data = this.$utils.parseDataQuery(this.$route.query.data)
      if (data.patientName)
        this.$emit('patient_name', data.patientName)
    }

    this.fetchData();

    this.isPatientMounted = true
  },
  provide() {
    return {
      scrollToTop: (callback) => {
        this.scrollTopCallback = callback;
      }
    };
  },
  methods: {
    goTop() {
      if (this.scrollTopCallback) {
        this.scrollTopCallback();
      }

      this.$nextTick(() => {
        // this.currentPage = 1

        this.fetchData()
      })
    },
    fetchData: async function() {
      this.alert = {}

      this.loading = true
      NProgress.start()
      try {
        let params = {
          id: this.patient_id
          // page: this.currentPage,
        }

        // TODO YAMANAKA endpoint (recebendo patient id) pra trazer as imagens dos exames
        let response = await this.$patientService.getExamListWithImages(params);
        response.result.forEach(o => {
          try {
            // console.log('o', o);
            o.status = this.$i18n.t(o.status);
            o.scoreResult = this.$aiService.alterationScore(o.metadata);
          } catch(error) {
            o.scoreResult = 'error'
          }
        });

        this.thumbDataPath = `${response.thumbDataPath}`
        this.dataPath = `${response.dataPath}`

        // TODO move to service
        if (!IS_PRODUCTION) {
          this.thumbDataPath = `http://localhost:1337${response.thumbDataPath}`
          this.dataPath = `http://localhost:1337${response.dataPath}`
        }

        this.tableExams = {
          'items': response.result,
          'columns': [
            {name: this.$i18n.t('exam.date'), type: 'date', value: 'date', width: '25%'},
            {key: 'captures', name: this.$i18n.t('exam.captures'), type: 'custom', value: 'captures', width: '25%'},
            {key: 'content', name: this.$i18n.t('exam.content'), type: 'custom', value: 'content', width: '25%'},
            {key: 'ai', name: this.$i18n.t('exam.statusAI'), type: 'custom', value: 'ai', width: '25%'}
          ]
        }

        if (response.result.length === 0) {
          this.tableExams = null;
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    examSelected(item) {
      if (!item) {
        return
      }

      let data = this.$utils.dataQuery({
        previous: 'patient'
      })

      this.$router.push({name: 'exam-show', params: {examID: item.id}, query: {tab: 'images', data: data}})
    },
    doDownload(exam) {
      try {
        this.loading = true
        NProgress.start()
        this.$examService.download(exam.id)
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    activeAiClasses(exam) {
      console.log('this.$aiService.mcRASClasses(exam)', this.$aiService.mcRASClasses(exam));
      return this.$aiService.mcRASClasses(exam)
    },
    useHeader(exam) {
      return this.$aiService.mcRASHeader(exam) != ""
    },
    aiHeader(exam) {
      return this.$aiService.mcRASHeader(exam)
    },
    openOffCanvasNewExam() {
      this.showOffcanvasNewExam = true

      setTimeout(function() {
        new bootstrap.Offcanvas(offCanvasNewExam).show();
      }, 100);
    },
    allowedImages(image) {
      return image.endsWith('COLOR') ||
             image.endsWith('REDFREE') ||
             image.endsWith('redfree') ||
             image.endsWith('TEXTURE') ||
             image.endsWith('HEATMAP') ||
             image.endsWith('heatmap') ||
             image.endsWith('image') &&
             !image.endsWith('VIDEO')
    },
  }
}
</script>
