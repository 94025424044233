<template>
  <div class="main-box patient-list-box" v-loader.opacity="loading">
    <div class="">
      <div class="title-box clear">
        <h2 class="title">{{ $t('patient.patientList') }}</h2>

        <button class="btn btn-outline-secondary" type="button" name="button" data-test="btnNewPatient" v-if="!isSpecialist" @click="newPatient">
          <IconPlus/>
          <span>{{ $t('patient.create') }}</span>
        </button>
      </div>
    </div>


    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseInfiniteTable id="patientList" class="no-border" v-if="tablePatient" :tabledata="tablePatient" @item-opened="toDetails" @loadmore="fetchMore()"/>

    <button class="btn-icon btn-top" type="button" name="button" v-if="currentPage > 1 && patientList.items.length > 20" @click="goTop">
      <IconArrowUp/>
    </button>

    <BaseGenericOffcanvas :offcanvas_id="'offcanvasPatientGeneric'" ref="offcanvasGeneric"/>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-toggle {
  &.show {
    z-index:                        3 !important;
  }
  &:after {
    display:                        none;
  }
}

.dropdown-menu {
  @include                          border_radius(25px);
  @include                          box_shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.08));
  // display: inline-flex;
  padding:                          56px 15px 15px 15px;
  flex-direction:                   column;
  align-items:                      flex-start;
  gap:                              20px;
  border:                           1px solid var(--Shades-30, #D8D8D8);
  background:                       var(--Shades-W, #FFF);
  z-index:                          1;
  top:                              -50px !important;
  left:                             -10px !important;
}

.title-box {
  display:                          flex;
  justify-content:                  flex-start !important;
  align-items:                      center;
  gap:                              10px;
  width:                            auto;
  margin:                           0;
  padding:                          0;

  .btn-outline-secondary {
    font-weight:                    400;
    letter-spacing:                 3.12px;
    gap:                            10px;
    padding:                        10px 15px 10px 10px;
    margin:                         0;
  }
}

// .title-box {
//   justify-content:                  flex-start !important;
//   display:                          none !important;
// }

.title {
  color:                            var(--Shades-90, #101010);
  text-overflow:                    ellipsis;
  font-size:                        26px;
  font-weight:                      400;
  line-height:                      normal;
  margin:                           0;
}

.filter-box {
  gap:                              10px;
  // padding-bottom:                   1.5rem;

  > label,
  .btn-outline-secondary {
    color:                          var(--Shades-70, #404040);
  }

  > label {
    font-size:                      12px;
    font-weight:                    400;
    line-height:                    normal;
    letter-spacing:                 1.8px;
    text-transform:                 uppercase;
  }

  .btn-outline-secondary {
    font-size:                      12px;
    font-weight:                    400;
    line-height:                    normal;
    letter-spacing:                 1.8px;
    text-transform:                 uppercase;
    padding:                        10px 15px;
    border:                         1px solid var(--Shades-30, #D8D8D8);
    background:                     var(--Shades-W, #FFF);

    &:hover,
    &:focus,
    &:active {
      border:                       1px solid var(--Shades-30, #D8D8D8);
      background:                   var(--Shades-W, #FFF);
    }
  }
}

.page-row,
.page-row p,
.table {
  margin-bottom:                    0 !important;
}

.page-row {
  margin-top:                       20px;
  p,
  .page-row .pagination {
    padding-right:                  10px;
    padding-left:                   10px;
  }
}

.title-box {
  .search-group {
    margin-right:                   20px;
  }
}

.patient-list-box {
  > div {
    &:first-child {
      display:                      flex;
      align-items:                  center;
      padding:                      15px 20px;
    }
  }
}

@media (max-width: 991px) {
  .title-box {
    display:                        flex !important;
    justify-content:                space-between !important;
    gap:                            0;
    width:                          100%;
    margin-bottom:                  0 !important;

    .btn-outline-secondary {
      height:                       35px;
      padding:                      10px 12px;

      span {
        display:                    none;
      }
    }

    .title {
      font-size:                    18px !important;
      text-align:                   left;
      width:                        calc(100% - 44px) !important;
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'patient-list',
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    tablePatient: null,
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
    search: null,
    patientList: {
      items: [],
      total: 0,
      columns: []
    },
    items: {},
    mergedItems: {},
    isSpecialist: null
  }),
  computed: {
    isSpecialist() {
      return this.session.permissions === 'specialist'
    }
  },
  mounted() {
    this.$activeLink.value = 'patient'
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.isSpecialist = this.session.isSpecialist
    // this.currentPage = this.session.patientCurrentPage ? this.session.patientCurrentPage : 1;
    this.fetchData()
  },
  provide() {
    return {
      scrollToTop: (callback) => {
        this.scrollTopCallback = callback;
      }
    };
  },
  methods: {
    // onPageChange(page) {
    //   this.currentPage = page;
      // this.session.patientCurrentPage = page;
    //   this.fetchData();
    // },
    goTop() {
      if (this.scrollTopCallback) {
        this.scrollTopCallback();
      }

      this.$nextTick(() => {
        this.currentPage = 1

        this.fetchData()
      })
    },
    async fetchData() {
      this.alert = {}

      let params = {
        page: 1
      }
      if (this.search)
        params.q = this.search

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.getList(params)

        this.tablePatient = {
          'items': response.result,
          'total': response.totalCount,
          'columns': [
            {name: this.$i18n.t('patient.name'), type: 'string', value: 'rawName', width: '34%'},
            {name: this.$i18n.t('patient.mrn'), type: 'string', value: 'mrn', width: '33%'},
            {name: this.$i18n.t('document0'), type: 'string', value: 'document0', width: '33%'}
          ]
        }

        // this.totalPages = Math.ceil(response.totalCount / this.perPage);

        if (response.result.length === 0) {
          this.tablePatient = {};
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async fetchMore() {
      console.log('emitiu loadmore');
      this.currentPage++
      this.alert = {}

      let params = {
        page: this.currentPage
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.getList(params)

        if (this.tablePatient.items.length == response.totalCount) {
          this.$message.popup(this, 'request_end', 'warning')
          NProgress.done(true)
          this.loading = false
          return
        }

        response.result.forEach((item) => {
          this.tablePatient.items.push(item);
        })
      } catch (err) {
       this.$message.popup(this, 'request_error', 'operationFail', 'error')
       // this.alert = this.$message.alert(this, 'request_error', 'danger')
     }
     NProgress.done(true)
     this.loading = false
    },
    async newPatient() {
      let text = this.$i18n.t('termsPatient.infoPatient') + this.$i18n.t('termsPatient.authPatient')

      const ok = await this.$refs.offcanvasGeneric.show({
        title: this.$i18n.t('warning'),
        text: text,
        okButton: this.$i18n.t('termsPatient.accept'),
        okButtonClass: 'btn-success',
        // cancelButton: this.$i18n.t('termsPatient.doNotAccept'),
        // cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.$router.push({name: 'patient-create', params: {patientID: ''}})
      }
    },
    toDetails(item) {
      this.$router.push({name: 'patient-show', params: {patientID: item.id}, query: {tab: 'data'}})
    },
  },
  // unmounted() {
  //   this.session = null
  // }
}
</script>
