import eyercloud from '@/config/eyercloud'

class AuditService {

  async getList(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/audit/list`, params)
    return response.data
  }

}
const auditService = new AuditService();
export default auditService;
