import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useEyerwebStore = defineStore('eyerweb', {
    sessionInfo: useStorage('sessionInfo', {})
    /*  
    state: () => ({
    //    console.log('sessionInfo: ', sessionInfo),
    //    isAuthenticated: useStorage('isAuthenticated', false),
        sessionInfo: useStorage('sessionInfo', {}),
    }),

    actions: {
        loggedIn(_sessionInfo) {
            this.$patch({
                isAuthenticated: true,
                sessionInfo: _sessionInfo
            })
        },

        logout() {
            this.$patch({
                isAuthenticated: false,
                sessionInfo: {}
            })
        },

        termAccepted(_sessionInfo) {
            this.$patch({
                sessionInfo: _sessionInfo
            })
        }
    },
*/
})
