<template>
  <div class="" v-show="tabledata">
    <div class="vtable-grid inside-box" ref="gridContainer" @scroll="handleScroll()">
      <ul class="">
        <template v-for="(item, index) in tabledata.items">
          <li @click="selectRow(item)">
            <template :class="`${column.type}-line`" v-for="(column, indexColumn) in tabledata.columns" :key="indexColumn">
              <slot v-if="column.key == 'name'" :name="`column_${column.key}`" :item="item"/>
              <slot v-if="column.type == 'custom'" :name="`column_${column.key}`" :item="item"/>
              <span v-if="column.type == 'externalDate'" :class="column.type">{{ formatDate(item, column.value) }}</span>
              <span v-if="column.type == undefined || column.type == 'string' && column.key != 'name'" onclick="return false">{{ getValue(item, column.value) }}</span>
            </template>
          </li>
        </template>
        <li v-if="shouldShowLoadBox && isExamRoute">
          <button class="btn btn-outline-secondary btn-load" type="button" name="button" @click="$emit('load-more-months')">{{ $t('exam.loadMore') }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-load {
  display:                        block;
  margin:                         0 auto;
  padding:                        10px 20px;
  background:                     white;
}
</style>

<script>
export default {
  name: 'BaseTable',
  props: ['tabledata'],
  inject: ['scrollToTop'],
  data: () => ({
    dateFormat: "",
  }),
  computed: {
    shouldShowLoadBox() {
      return this.tabledata.items && Number(this.tabledata.items.length > 0) && (Number(this.tabledata.items.length) === Number(this.tabledata.total))
    },
    isExamRoute() {
      return this.$router.currentRoute._value.name == 'exam-list'
    }
  },
  mounted: function() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    this.dateFormat = this.$i18n.t('dateTimeFormat')

    this.scrollToTop(() => {
      const container = this.$refs.gridContainer;
      if (container) {
        container.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    });
  },
  methods: {
    getValue(data, key) {
      return _.get(data, key)
    },
    formatDate(data, key) {
      let value = _.get(data, key)
      let result = moment(value).format(this.dateFormat);
      return result
    },
    checkColumn(column){
      // if (column !== 'createdAt' && column !== 'updatedAt' && column !== 'id') return true;
    },
    selectRow(item) {
      this.$emit('item-opened', item);
    },
    // @deprecated
    checkStatus(status) {
      if (status === 'Closed' || status === 'Fechado' || status === 'closed' || status === 'fechado')
        return 'label-closed';

      return 'label-open';
    },
    handleScroll() {
      const gridContainer = this.$refs.gridContainer;
      const scrollTop = gridContainer.scrollTop;
      const scrollHeight = gridContainer.scrollHeight;
      const clientHeight = gridContainer.clientHeight;

      if (scrollHeight - scrollTop - clientHeight < clientHeight * 0.1) {
        // this.$emit('loadmore');
        this.debounce()
      }
    },
    debounce: _.debounce(function() {
      this.$emit('loadmore');
    }, 250, { 'maxWait': 100 }),

  },
  beforeDestroy() {
    this.$refs.scrollContainer.removeEventListener('scroll', this.onScroll);
  }
}
</script>
