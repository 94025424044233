import clinicService from './clinic-service.js'
class SecurityService {

  /**
   * Tells whether a clinic demands MFA to allow user access.
   * @param {*} sessionInfo 
   * @returns 
   */
  isMFAMandatory(sessionInfo) {
    const security = clinicService.getSecurityConfiguration(sessionInfo.currentClinic)
    return security.mfaMode == "mandatory"
  }  

  /**
   * Checks whether the clinic enforces MFA.
   * @param {*} sessionInfo 
   * @returns true if MFA is needed otherwise returns false
   */
  isBlockedByMFA(sessionInfo) {
    const isMFAMandatory = this.isMFAMandatory(sessionInfo)
    if (!isMFAMandatory)
      return false

    return !sessionInfo.isMFAEnabled // free pass when user has MFA configured
  }

}
const securityService = new SecurityService();
export default securityService;
