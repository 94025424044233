<template>
  <div class="" v-loader.opacity.tab="loading">
    <div class="alert" :class="alert.class" role="alert" v-if="alert.message || logomsg.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <vee-form v-slot="{ handleSubmit }" as="div">
      <form @submit.prevent.stop="handleSubmit(saveOrUpdate)">

        <ClinicFormData v-if="clinic.id"
          :clinic="clinic"
          :disabled="disabled"
          @option-selected="setConfiguration"
          @security-changed="securityChanged">
        </ClinicFormData>

        <div class="action-bar">
          <button class="btn btn-success" type="submit" name="button" data-test="btnUpdate" v-if="hasFormChanged && !isSpecialist && isCurrentClinic">
            <span>{{ $t('updateAction') }}</span>
          </button>
          <button class="btn btn-secondary" type="button" v-if="!isSpecialist  && isCurrentClinic" name="button" data-test="btnOpenModalLogo" @click="openModalChangelogo">
            <span>{{ $t('clinic.changeLogoSmall') }}</span>
          </button>
        </div>
      </form>
    </vee-form>

    <teleport to="body">
      <BaseCropperOffcanvas :offcanvas_id="'offcanvasCropper'" ref="offcanvasCropper"/>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.action-bar {
  display:                           flex;
  justify-content:                   flex-start;
  align-items:                       center;
  gap:                               10px;
  height:                            64px;
  padding:                           10px 20px;
  border-top:                        1px solid var(--Shades-30);
}

.btn {
  @include                         border_radius(99px);
  font-size:                       var(--font-size-12);
  font-weight:                     400;
  letter-spacing:                  3.64px;
  text-transform:                  uppercase;
}

.btn-outline-danger {
  @include                         border_radius(99px);
  color:                           var(--Complementary-Orange);
  margin:                          0 !important;
  background:                      #FFEBE8;
  border:                          1px solid var(--Complementary-Orange);
}

.btn-success {
  color:                           var(--Shades-W);
  background:                      var(--Brand-Secondary);
  border:                          1px solid var(--Brand-Secondary);
}

.btn-secondary {
  color:                           var(--Brand-Primary);
  background:                      var(--Shades-10);
  border:                          unset;
  // border:                          1px solid var(--Shades-10);
}

.btn-outline-secondary,
.btn-secondary,
.btn-success {
  font-weight:                     700;
  height:                          44px;
  padding:                         15px 20px;
}
</style>

<script>
import { useUserStore } from '@/store/user'
import ClinicFormData from '../ClinicFormData.vue'

export default {
  name: 'ClinicInfoTab',
  props: ['clinic_id', 'logomsg'],
  components: {
    ClinicFormData,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    isSpecialist: null,
    statusOptions: [],
    statusSelected: 'open',
    clinic: {
      name: '',
      fantasyName: '',
      document: '',
      email: '',
      telephone1: '',
      telephone2: '',
      address: '',
      addressNumber: '',
      district: '',
      city: '',
      locale: '',
      state: '',
    },
    clinicLogo: null,
    disabled: true,
    isCurrentClinic: false,
    isClinicMounted: false,
    systemConfiguration: {},
    hasFormChanged: false,
    changedFormItems: [],
    initialForm: {}
  }),
  watch: {
    logomsg: function(newVal) {
      if (newVal) {
        this.alert.message = newVal.message
        this.alert.class = newVal.class
        this.fetchData();
      } else {
        this.alert.message = null
      }
    },
    clinic_id: {
      immediate: true,
      handler: function (clinic_id) {
        if (clinic_id) {
          this.fetchData()

          this.$nextTick(() => {
            this.hasFormChanged = false
            this.changedFormItems = []
          })
        }
    }, deep: true },
    clinic: {
      immediate: true,
      handler: function(newValue) {
        this.checkFormChange(newValue, 'clinic')
    }, deep: true},
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.isCurrentClinic = this.clinic_id === this.session.currentClinic.id
    this.isSpecialist = this.session.isSpecialist

    this.statusOptions = [
      {value: 'open', name: this.$i18n.t('open')},
      {value: 'closed', name: this.$i18n.t('closed')}
    ];

    this.fetchData();

    this.isClinicMounted = true
  },
  methods: {
    checkFormChange(value, obj) {
      let hasObj = this.changedFormItems.includes(obj)
      if (JSON.stringify(value) === JSON.stringify(this.initialForm[obj])) {
        if (hasObj) {
          let index = this.changedFormItems.indexOf(obj)
          this.changedFormItems.splice(index, 1)
        }

        if (this.changedFormItems.length > 0)
          return

        this.hasFormChanged = false
      } else {
        if (!hasObj)
          this.changedFormItems.push(obj)

        this.hasFormChanged = true
      }
    },
    fetchData: async function() {
      this.initialForm = {}

      let params = {
        id: this.clinic_id
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.getClinic(params);
        this.clinic = response.clinic
        this.clinicLogo = response.clinicLogo

        const formWrap = {
          clinic: this.clinic,
        }

        this.initialForm = _.cloneDeep(formWrap)

        this.$nextTick(() => {
          this.hasFormChanged = false
          this.changedFormItems = []
        })

        this.systemConfiguration = this.$clinicService.getSystemConfiguration(this.clinic)
      } catch (err) {
        this.$message.popup(this, `Error: ${err.message}`, 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },
    saveOrUpdate: async function() {
      let params = {
        clinic: this.clinic
      }

      params.clinic.id = this.clinic.id

      if (this.clinic.parent)
        params.clinic.parent = this.clinic.parent.id

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.updateClinic(params)
        if (response.result.locale)
          this.$i18n.locale = response.result.locale

        await this.$authService.refreshSession(this)

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
        this.disabled = true
      } catch (e) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    setConfiguration(val) {
      this.clinic.country = val.country
      this.clinic.mandatoryDocument = val.mandatoryDocument
      this.clinic.locale = val.locale
    },
    securityChanged(value) {
      console.log("securityChanged()", value)

      // TODO move to clinic-service
      let systemConfiguration = this.clinic.systemConfiguration ? this.clinic.systemConfiguration : {}
      let security = systemConfiguration.security ? systemConfiguration.security : {}
      security.mfaMode = value.mfaMode
      this.clinic.systemConfiguration.security = security
    },
    async openModalChangelogo() {
      this.alert.message = null;

      this.clinicLogo ? this.clinicLogo : '@/assets/images/phelcom/profile_img.png'

      const ok = await this.$refs.offcanvasCropper.show({
        title: this.$i18n.t('clinic.changeLogo'),
        imgSrc: this.clinicLogo,
        okButton: this.$i18n.t('save'),
        okButtonClass: 'btn-primary',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        let canvas = ok.canvas
        canvas.toBlob(blob => {
          var formData = new FormData()
          formData.append('data', blob)
          this.doSaveLogo(formData)
        }, 'image/png')
      }
    },
    doSaveLogo: async function(value) {
      let params = {
        id: this.clinic_id,
        logo: value.get('data')
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.uploadLogo(params)
        this.clinicLogo = response.clinicLogo

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },
  }
}
</script>
