<template>
  <slot v-if="isFeatureEnabled==true"></slot>
</template>

<script>

export default {
  name: 'BaseFeature',
  props: ['feature'],
  data: () => ({
    isFeatureEnabled: false
  }),
  created() {
    this.isFeatureEnabled = this.$utils.isFeatureEnabled(this.feature)
  }
}
</script>