<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" :id="offcanvas_id" data-bs-backdrop="static" :aria-labelledby="`${offcanvas_id}_label`">
    <div class="offcanvas-header">
      <button class="btn-back" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" :id="`${offcanvas_id}_label`">{{ title }}</h5>
    </div>
    <div class="offcanvas-body">
      <div class="loading-eye" v-show="imgSrc.loading"></div>
      <cropper ref="imgCrop" id="imgCrop" class="cropper" :class="{'min-height': imgSrc.loading}" :src="imgSrc.src" background-class="cropper__background"
               :stencil-props="cropperOptions" :minWidth="minWidth" :minHeight="minHeight" @ready="readyImg" @change="refreshCropper"/>

      <!-- footer inside body to avoid empty space between body and footer -->
      <div class="offcanvas-footer">
        <!-- <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="modal" v-show="cancelButton" data-test="btnCancelCropper" @click="doCancel">{{ cancelButton }}</button> -->
        <div v-show="!modalType">
          <label for="file-upload" class="btn btn-success">
            <i class="glyphicon glyphicon-upload"></i>
            {{ $t('clinic.selectFile') }}
          </label>
          <input id="file-upload" type="file" @change="changeImg"/>
        </div>
        <button type="button" class="btn btn-danger" name="button" data-bs-dismiss="modal" @click="doRemove" v-show="removeButton && imgSrc.src" data-test="btnRemoveCropper">{{ $t('remove') }}</button>
        <button type="button" class="btn btn-primary" :class="okButtonClass" data-bs-dismiss="modal" data-test="btnConfirmCropper" @click="doConfirm">{{ okButton }}</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  // width:                                510px !important;
  // padding:                              40px;

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    p {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin-bottom:                    26px;
    }

    .loading-eyer {
      width:                            200px;
      height:                           200px;
      margin:                           0 auto;
      display:                          block;
    }

    .min-height {
      height:                           1px;
    }
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;
    padding-top:                        26px;

    .btn {
      font-size:                        var(--font-size-14);
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   4.2px;
      text-align:                       center;
      text-transform:                   uppercase;
      // gap:                              10px;
      // flex:                             1 0 0;
      width:                            100%;
      padding:                          20px;
    }

    .btn-success {
      color:                            var(--Shades-W, #FFF);
      // margin-top:                       26px;
    }

    input[type="file"] {
      display:                          none;
    }
    label {
      margin:                           0;
    }
  }
}
</style>

<script>
export default {
  name: 'BaseCropperOffcanvas',
  props: ['offcanvas_id'],
  data: () => ({
    modalType: null,
    title: null,
    imgSrc: {
      name: null,
      src: null,
      type: null,
      loading: false,
    },
    okButton: null,
    okButtonClass: null,
    removeButton: false,
    cancelButton: null,
    cancelButtonClass: null,
    cropperOptions: {
      class: 'cropper-background',
      aspectRatio: 2/1,
    },
    minWidth: 0,
    minHeight: 0,
    offcanvas_cropper: null,
  }),
  watch: {
    'imgSrc.src'(value) {
      console.log('watch', value);
      if (value) {
        this.imgSrc.loading = true;
        console.log('this.imgSrc.loading watch', this.imgSrc.loading);
        // let cropWidth = document.getElementById('imgCrop').innerWidth
        // console.log('cropWidth', cropWidth);
        // setTimeout(() => {
        //   this.imgSrc.loading = false
        // }, 1000)
      }
    }
  },
  mounted() {
  },
  methods: {
    show(options = {}) {
      this.modalType = options.modalType
      this.title = options.title
      this.imgSrc.src = options.imgSrc
      // this.loadImage(options.imgSrc)
      // this.imgSrc.src = URL.createObjectURL(options.imgSrc);
      this.okButton = options.okButton
      this.okButtonClass = options.okButtonClass
      this.removeButton = options.removeButton

      if (options.cancelButton) {
        this.cancelButton = options.cancelButton
        this.cancelButtonClass = options.cancelButtonClass
      }

      // if (options.aspectRatio != null)
      this.cropperOptions.aspectRatio = options.aspectRatio == null ? 2/1 : options.aspectRatio

      if (options.minHeight)
        this.minHeight = options.minHeight

      if (options.minWidth)
        this.minWidth = options.minWidth

      this.offcanvas_cropper = new bootstrap.Offcanvas(document.getElementById(this.offcanvas_id)).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.offcanvas_cropper = null;
      this.resolvePromise(this.saveImg())
    },

    doCancel() {
      this.offcanvas_cropper = null;
      this.resolvePromise(false)
    },

    doRemove() {
      this.offcanvas_cropper = null;
      this.removeSignature = true;
      this.resolvePromise(this.saveImg())
    },

    refreshCropper() {
      this.loading = false
      this.removeSignature = false
      if (this.loopCounter > 2) {
        return
      }
      this.loopCounter++;
      this.$refs.imgCrop.refresh();
    },

    changeImg(event) {
      const file = event.target.files[0]
      console.log(file, ' this file ', /^image\/\w+/.test(file.type));

      if (!file) {
        console.log('Please choose an image file.');
        return false;
      }

      if (/^image\/\w+/.test(file.type)) {
        this.imgSrc.name = file.name
        this.imgSrc.type = file.type
        this.imgSrc.src = URL.createObjectURL(file);
      }

      if (!this.showSaveButton)
        this.showSaveButton = !this.showSaveButton;
    },

    saveImg() {
      let vm = this;
      let dataFile = [];

      NProgress.start()
      try {
        const {canvas} = this.$refs.imgCrop.getResult();
        let params = {
          coordinates: this.$refs.imgCrop.getResult().coordinates,
          canvas: canvas,
          removeSignature: this.removeSignature ? 'yes' : null
        }

        return params
      } catch (e) {
        console.log(e);
      }
      NProgress.done(true)

      this.refreshCropper()
    },

    readyImg() {
      console.log('READYYYYYYYYYYYYYY Image is successfully loaded');
      this.imgSrc.loading = false;
      console.log('this.imgSrc.loading ready', this.imgSrc.loading);
    }
  },
  unmounted() {
    this.cropperOptions.aspectRatio = 2/1
    this.imgSrc.src = null
    this.loopCounter = 0
  }
}
</script>
