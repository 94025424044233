// reference https://stephanlangeveld.medium.com/simple-local-storage-implementation-using-vue-3-vueuse-and-pinia-with-zero-extra-lines-of-code-cb9ed2cce42a
import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    clinicData: useStorage('clinicData', {}) 
  }),


  getters: {
    getNotificationData: (state) => (clinicID) => {
      const key = `clinic_${clinicID}`
      let result = state.clinicData[key]

      if (result == null) {
        result = {
          count: 0,
          events: []
        }
      }
      return result
    },
          
  },

  actions: {

    updateNotificationData(notificationData, clinicID) {
      const key = `clinic_${clinicID}`
      this.clinicData[key] = notificationData
    }

  },

})