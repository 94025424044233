const examImagesResponse = [
    {
        name: "https://www.gta.ufrj.br/grad/10_1/retina/figuras/retina2.jpg",
        type: "IMAGE",
        blob: {},
        fileType: "jpg",
        fileName: "7c1d4a9d-8557-4dd6-8580-85ff643075e0"
    },
    {
        name: "https://clinicaikegami.com.br/wp-content/uploads/2021/07/Mapeamentoderetina-1024x687.jpg",
        type: "IMAGE",
        blob: {},
        fileType: "jpg",
        fileName: "3a9675b5-8f86-46a1-807e-4a55c5d4b402"
    },
    {
        name: "https://www.gta.ufrj.br/grad/10_1/retina/figuras/retina2.jpg",
        type: "IMAGE",
        blob: {},
        fileType: "jpg",
        fileName: "a9aa4912-712a-4a29-9e0c-31d31436e25f"
    },
    {
        name: "https://clinicaikegami.com.br/wp-content/uploads/2021/07/Mapeamentoderetina-1024x687.jpg",
        type: "IMAGE",
        blob: {},
        fileType: "jpg",
        fileName: "85aa9784-689d-4f3d-8826-3cf9d0381654"
    }
]

export default examImagesResponse;
