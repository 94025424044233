<template>
  <div :class="showMutirao ? 'main-home-itabuna' : 'main-home'">
    <AppMenu @docollapse="doCollapse"/>

    <div class="right_col content ease-all">
      <HomeHeader class="home-header-box" @docollapse="doCollapse"/>

      <HomeLimbo v-if="!session.currentClinic"></HomeLimbo>

      <div class="main-content" :class="{'has-alert': session.currentClinic.percentageValue > 60 && currentRoutePath != '/dashboard'}" v-else>
        <!-- <div id="principal"></div> -->

        <!-- <div class="alert" :class="session.currentClinic.percentageValue < 80 ? 'alert-warning' : 'alert-danger'"
             v-show="session.currentClinic.percentageValue > 60 && currentRoutePath != '/dashboard'">
          <img src="@/assets/images/erjart/ico_alert_warning.svg" alt="">
          <b>{{ $t('dashboard.usageFullMessage', {usagePercentage: percentageValue}) }}</b>
          <p>{{ session.currentClinic.percentageValue < 80 ? $t('dashboard.usageFullWarningMessage') : $t('dashboard.usageFullDangerMessage') }}</p>

          <a class="btn btn-danger" :class="session.currentClinic.percentageValue < 80 ? 'btn-warning' : 'btn-danger'"
             href="https://phelcom.com/pt-br/suporte/" target="_blank">{{ $t('dashboard.talkSupport') }}</a>
          <button class="btn btn-outline-warning" type="button" name="button" data-test="btnClosePercentageAlert"
                  aria-label="Close" v-show="session.currentClinic.percentageValue < 80" @click="session.currentClinic.percentageValue = 0">
                  depois</button>
        </div> -->

        <router-view class="panel-content" @collapse-menu="doCollapse" v-if="isDataReady"></router-view>
      </div>

      <teleport to="body">
        <AcceptanceTermsOffcanvas ref="acceptanceTermsOffcanvas"/>
        <AcceptanceWelcomeTipsOffcanvas ref="acceptanceWelcomeTipsOffcanvas"/>
        <BaseGenericOffcanvas :offcanvas_id="'offcanvasUserMfaGeneric'" ref="offcanvasGeneric"/>
      </teleport>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.main-home {
  height:                               100vh;
}

.main-home-itabuna{
  min-height:                               100vh;

}

.alert {
  @include                              border_radius(5px);
  margin-left:                          10px;
  padding:                              12px;
  display:                              flex;
  justify-content:                      space-between;
  gap:                                  10px;
  align-items:                          center;
  height:                               55px;

  b {
    font-size:                          var(--font-size-14);
    white-space:                        nowrap;
  }

  p {
    font-size:                          var(--font-size-12);
    font-weight:                        400;
    margin:                             0;
  }

  .btn {
    font-weight:                        500;
    text-transform:                     uppercase;
    white-space:                        nowrap;
    padding:                            8px 20px;
  }

  .btn-warning,
  .btn-danger {
    color:                              white;
    font-size:                          12px;
    letter-spacing:                     2.64px;
  }

  .btn-outline-warning {
    color:                              var(--Complementary-Orange, #D7541C);
    font-size:                          11px;
    letter-spacing:                     2.42px;
    background:                         transparent;
    border:                             1px solid #D4D3A1;
  }

  .btn-warning {
    background:                         var(--Complementary-Orange, #D7541C);
    border:                             1px solid var(--Complementary-Orange, #D7541C);
  }

  .btn-danger {
    background:                         var(--Support-Error, #EE4930);
    border:                             1px solid var(--Support-Error, #EE4930);
  }


  &.alert-warning {
    color:                              var(--Complementary-Orange, #D7541C);
    background:                         #EEE2D0;
    border:                             1px solid #E39853;
  }

  &.alert-danger {
    color:                              var(--Support-Error, #EE4930);
    background:                         #EED0D0;
    border:                             1px solid var(--Support-Error, #EE4930);
  }
}

.content {
  display:                              inline-block;
  // min-height:                           calc(100vh - 45px);
  height:                               100%;
  width:                                calc(100% - 230px);
  float:                                right;
  overflow:                             hidden;
  // margin-top:                           70px; // melhorar isso
  .main-content {
    height: 100%;
    // height:                             calc(100vh - 74px);
    // height:                             calc(100vh - 90px);
    // height:                             calc(100vh - 50px);
  }
  &.full-width {
    width:                              100%;
    float:                              unset;
  }
}

.main {
  position:                             relative;
  width:                                100%;
  min-height:                           740px;
  &.full-height {
    height:                             100vh;
  }
  &.retract {
    .content {
      margin-top: 0;
    }
    .content,
    .right_col,
    .footer {
      width:                            calc(100% - 55px) !important;
      // width:                            100%;
    }
    .menu,
    .sidebar {
      width:                            55px !important;
      // width:                            84px !important;
    }
    .title-box.eyer2 {
      width:                            calc(100% - 300px) !important;
    }
    .tab-box {
      .col-md-9 {
        max-height:                     calc(100vh - 217px) !important;
      }
    }
  }
}

.content,
.panel-content {
  position:                             relative;
}

.panel-content {
  // padding-left:                         10px;
  height:                               100%;
  overflow:                             hidden;
}

.control-bo {
  position:                             absolute;
  right:                                10px;
  top:                                  10px;
  z-index:                              999999;
}

.fa-wifi {
  color:                                green;
  &.red {
    color:                              red;
  }
}

.clinic-title {
  display:                              none;
}

.storage-info {
  @include                              border_radius(10px);
  position:                             relative;
  display:                              flex;
  justify-content:                      flex-start;
  align-items:                          center;
  padding:                              15px;
  margin:                               15px;
  border:                               1px solid $charcoal;
  img {
    margin-right:                       20px;
  }
  h6, p {
    color:                              $charcoal;
    font-weight:                        normal;
    line-height:                        1.2;
    margin:                             0;
  }
  .btn-close {
    position:                           absolute;
    right:                              5px;
    top:                                5px;
  }
}

@media (max-width: 991px) {
  .main-home {
    height:                             100dvh;
  }

  .main {
    min-height:                         645px;
    .content,
    .right_col {
      float:                            unset;
      position:                         relative;
      // height:                           100%;
    }
    .content,
    &.retract .content,
    .right_col,
    &.retract .right_col {
      width:                            100% !important;
      margin-left:                      0 !important;
      margin-top:                       -10px;
    }
  }
  .panel-content {
    padding:                            0px;
  }
  .storage-info {
    h6 {
      font-size:                        1em;
    }
    p {
      font-size:                        0.8em;
    }
  }
  .main-home {
    padding:                            0;
    overflow-y:                         hidden;
  }

  .content {
    width:                              100%;
    .main-content {
      // height:                           calc(100vh - 78px);
      // height:                           calc(100vh - 98px);
    }
  }

  .home-header-box {
    display:                            none;
  }
}

// @media (max-width: 991px) {
//   .main {
//   }
//
// }

// safari
@media not all and (min-resolution: .001dpcm) {
@supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
  @media (max-width: 767px) {
    .main.retract {
      .footer {
        width:                          100% !important;
      }
    }
  }
}}

@media not all and (min-resolution:.001dpcm) {
@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  @media (max-width: 767px) {
    .main.retract {
      .footer {
        width:                          100% !important;
      }
    }
  }
}}
</style>

<script>
import { useUserStore } from '@/store/user'
import { setLocale } from '@vee-validate/i18n';

import HomeHeader from './HomeHeader.vue'
import HomeFooter from './HomeFooter.vue'
import AppMenu from './menu/menu.vue'
import HomeLimbo from './HomeLimbo.vue'
import AcceptanceTermsOffcanvas from '@/components/AcceptanceTermsOffcanvas.vue'
import AcceptanceWelcomeTipsOffcanvas from './AcceptanceWelcomeTipsOffcanvas.vue'

const TAG = "[home]"
export default {
  name: 'home',
  components: {
    HomeHeader,
    HomeFooter,
    AppMenu,
    HomeLimbo,
    AcceptanceTermsOffcanvas,
    AcceptanceWelcomeTipsOffcanvas
  },
  data: () => ({
    loading: false,
    isCustomRoute: false,
    collapse: false,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    clinic: null,
    features: null,
    state: false,
    value: 0,
    attention: null,
    sessionExpired: null,
    isLogin: true,
    isWebSocketConnected: false,
    clinicUsage: {},
    percentageValue: null,
    currentRoutePath: null,
    viewportHeight: 0,
    isDataReady: false,
    showMutirao:false
  }),
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    userStore.incrementTipsCount(0)
    this.showMutirao = this.session.features.includes('mutirao')
    this.$watch(
      () => userStore.tipsCount,
      (newValue) => {
        if (newValue == 3)
          this.showWelcomeTipsOffcanvas()
      }
    );

    this.currentRoutePath = this.$route.path

    // console.log('<><><><><><><><><> SESSION <<><><><><><><><><>', this.session);

    if (this.session && this.session.currentClinic) {
      this.$i18n.locale = this.session.currentClinic.locale.toLowerCase();
      setLocale(this.$i18n.locale);
      console.log("[app] setting locale (from clinic):", this.session.currentClinic.locale)
    }

    const showTerm = !this.session.collaborator.acceptedTerm && this.session.currentClinic.country == "BR"

    if (showTerm)
      this.openTermsOffcanvas()

    this.webSocketInitialization()

    this.checkMFA(this.session)
    this.fetchDataUsage()

    this.doCollapse() // starting collapsed

    this.isDataReady = true
  },
  methods: {
    async checkMFA(session) {
      console.log("checkMFA >>>", session)
      const isBlockedByMFA = this.$security.isBlockedByMFA(session)
      if (!isBlockedByMFA)
        return

      const ok = await this.$refs.offcanvasGeneric.show({
        title: this.$i18n.t('mfa.securityAlertTitle'),
        text:  this.$i18n.t('mfa.securityAlertText'),
        okButton: this.$i18n.t('mfa.yes'),
        okButtonClass: 'btn-success',
        cancelButton: this.$i18n.t('mfa.no'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.$router.push(`/user/mfa/${this.session.user.id}`)
       //this.$router.push({name: 'user-show', params: {userID: this.session.user.id}})
      }

    },
    reload() {
      this.state = false;
      this.value++;
      this.$nextTick(() => this.state = true);
    },
    checkRoute() {
      return this.state ? this.$route.fullPath : [this.$route.fullPath, this.value.toString()]
    },
    webSocketInitialization() {
      console.log(`${TAG} webSocketInitialization()`)
      const io = this.$io
      this.isWebSocketConnected = io.socket.isConnected()

      // DON'T TOUCH, MAGIC -> avoid bogous io socket connection on initialization (notification/listen gets a 302 http status response)
      if (this.isWebSocketConnected) {
        io.socket.disconnect()
        io.socket.reconnect()
      }

      io.socket.on('connect', () => {
        this.isWebSocketConnected = true
        this.webSocketForNotifications()
      })

      io.socket.on('disconnect', () => {
        this.isWebSocketConnected = false
      })
    },
    webSocketForNotifications() {
      console.log(`${TAG} webSocketForNotifications() <<<<`)
      const io = this.$io

      for (let e of ['notification_listening', 'notification_event']) {
        console.log('[app] unlisten to ws event:', e)
        io.socket.off(e);
      }

      io.socket.get('/api/v2/eyercloud/clinic/notification/listen', function gotResponse(body, response) {
        console.log(`${TAG} notification/listen:`, body);
        // TODO REMOVE websocket connection magic... here we get a http status 302 on response... WHY?
        // can we just save when we get a 200 response and avoid calling socket.reconnect when hitting the navigator URL?
      })

      io.socket.on('notification_listening', function(data) {
        console.log(`${TAG} notification_listening`, data);
      })

      io.socket.on('notification_event', data => {
        this.$notificationService.createNewNotification(this, data)
      })
    },
    doLogin() {
      // this.showModalWarning = false;
      window.open('/', '_self');
    },
    doCollapse(value) {
      this.collapse = !this.collapse;

      if (this.collapse) {
        $('.main').addClass('retract')
      } else {
        $('.main').removeClass('retract')
      }
    },
    defineLocale(value) {
      this.$i18n.locale = value;
      window.VeeValidate.localize(value);
    },
    async openTermsOffcanvas() {
      const ok = await this.$refs.acceptanceTermsOffcanvas.show({
        okButton: this.$i18n.t('terms.accept'),
        okButtonClass: 'btn-success',
        cancelButton: this.$i18n.t('terms.close'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        console.log('ok terms offcanvas', ok);
        // this.doDelete()
      }
    },
    async fetchDataUsage() {
      let params = {
        id: this.session.currentClinic.id
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$dashboardService.getClinicUsage(params);
        this.clinicUsage = response.clinicUsage

        // this.clinicUsage.examTotal = 3540

        this.getPercentageValue()
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true);
      this.loading = false
    },
    getPercentageValue() {
      this.percentageValue = this.$dashboardService.getPercentageValue(this.clinicUsage.examTotal)
      this.session.currentClinic.percentageValue = this.session.currentClinic.percentageValue == 0 ? this.session.currentClinic.percentageValue : this.percentageValue
    },
    async showWelcomeTipsOffcanvas() {
      const ok = await this.$refs.acceptanceWelcomeTipsOffcanvas.show()
      if (ok) {
        console.log('deu ok', ok);
        await this.$authService.refreshSession(this)
      }
    }
  }
}
</script>
