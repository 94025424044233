<template>
  <div class="external-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-7" :style="backgroundStyle">
          <!-- <div class="">
            <h6>{{ $t('createAccount.welcome') }}</h6>
            <img src="@/assets/images/phelcom/eyercloud_logo_white.svg" alt="Eyercloud logo">
          </div> -->
        </div>
        <div class="col-lg-5 flex-center">
          <div class="external-content">
            <h3 class="mb-3">{{ $t('createAccount.success') }}</h3>

            <h3 class="mb-0">
              <span>{{ user.collaborator.firstName }}</span>,
              {{ $t('createAccount.emailSent') }}
              <span> {{ user.email }}</span>.
            </h3>

            <!-- <div class="flex-between">
              <input class="form-control" type="text" name="" value="">
              <input class="form-control" type="text" name="" value="">
              <input class="form-control" type="text" name="" value="">
              <input class="form-control" type="text" name="" value="">
              <input class="form-control" type="text" name="" value="">
              <input class="form-control" type="text" name="" value="">
            </div>

            <button class="btn btn-success mb-4" type="button" name="button">Ativar</button>

            <p>Não recebeu? <a>Reenvie o e-mail clicando aqui</a> </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.col-lg-5 {
  h3 {
    span {
      font-weight:              700;
    }
  }
  p {
    a {
      text-decoration-line:     underline;
    }
  }
  .flex-between {
    gap:                        20px;
    margin:                     45px 0;
  }
  .form-control {
    @include                    border_radius(5px);
    height:                     67px;
    border:                     1px solid #DCDCDC;
    background:                 #FFF;
  }
}
.col-lg-7 {
    @include                               border_radius(10px);
    display:                               flex;
    flex-direction:                        column;
    justify-content:                       space-between;
    background:                            linear-gradient(252deg, #000 0%, #0D4636 100%);
    background:                            url('@/assets/images/phelcom/register_banner_en.png') no-repeat center bottom 100%, linear-gradient(252deg, #000 0%, #0D4636 100%);
    background-size:                       cover;
    // background:                            url('@/assets/images/phelcom/welcome_banner_en-US.png') no-repeat right center, linear-gradient(252deg, #000 0%, #0D4636 100%);
    padding:                               5rem 3rem 3rem;
}

@media (max-width: 991px) {
  .external-page {
    width:                      100%;
    overflow-y:                 hidden;

    .col-lg-7 {
      order:                    2;
    }

    .col-lg-5 {
      .external-content {
        width:                  100%;
      }
    }
  }
}
</style>

<script>
  import AuthFooter from '../../auth/AuthFooter.vue'
  import AuthHeader from '../../auth/AuthHeader.vue'
  import utilService from '../../../services/util-service.js';

  export default {
    name: 'registration-account-created',
    components: {
      AuthFooter,
      AuthHeader,
    },
    data: () => ({
      user: {
        collaborator: {}
      },
      countryCode: 'en',
      backgroundStyle: null, 
    }),

    mounted() {
      this.countryCode = utilService.getCurrentLanguage()
      const imagePath = require(`@/assets/images/phelcom/register_banner_${this.countryCode}.png`);
      this.backgroundStyle = `background: url(${imagePath}) no-repeat center bottom 100%, linear-gradient(252deg, #000 0%, #0D4636 100%); background-size: cover;`
      
      try {
        let data = this.$utils.parseDataQuery(this.$route.query.data)
        this.user = data.user
      } catch(err) {
        this.$message.popup(this, err.message, 'operationFail', 'error')
        console.log("error:", err)
      }
    },
  }
</script>
