import { useUserStore } from '@/store/user'
import ExamImagePreAnalysis from './ExamImagePreAnalysis.vue'

import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';
import lgZoom from '@/assets/dependencies/lightGallery/plugins/zoom';
// import lgZoom from 'lightgallery/plugins/zoom';

const TAG = '[ExamImageTab]'
const IS_PRODUCTION = process.env.NODE_ENV == 'production'

export default {
  name: 'ExamImageTab',
  props: ['exam_id', 'altscore', 'alert_msg', 'patient'],
  components: {
    ExamImagePreAnalysis,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    exam: null,
    examImages: [],
    filterSelected: [],
    filterOptions: [],
    thumbDataPath: null,
    dataPath: null,
    modalType: null,
    session: null,
    selectedImages: [],
    imageToCrop: null,
    showAccessInfoModal: false,
    isSpecialist: false,
    lightGallerySettings: {
      showZoomInOutIcons: true,
      actualSize: false,
      autoplayVideoOnSlide: true,
      speed: 300,
      licenseKey: 'CE440F6C-34CE4C2A-86065648-48895C83',
      plugins: [],
    },
    isValidForHeatmap: false,
    lightGallery: null,
    hasIaAdvancedFunctions: false,
    showMutirao: false,
    mcRASResults: [],
  }),
  watch: {
    alert_msg: function (newVal) {
      if (newVal) {
        this.alert.message = newVal.message
        this.alert.class = newVal.class
        this.fetchData()
      } else {
        this.alert.message = null
      }
    },
  },
  computed: {
    alertClass() {
      return this.alert.class
    }
  },
  created() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    console.log('sessao image tab', this.session);
    this.hasIaAdvancedFunctions = this.session.features.includes('iaAdvancedFunctions')
    this.showMutirao = this.session.features.includes('mutirao')
    this.isSpecialist = this.session.isSpecialist
    // this.addEventResize()
    this.lightGallerySettings.plugins = [lgAutoplay, lgFullscreen, lgThumbnail, lgVideo, lgZoom]

    console.log('this.lightGallerySettings', this.lightGallerySettings);
  },
  async mounted() {
    // this.showMutirao = this.session.features.includes('mutirao') ? true : false
    // this.initSelectPicker()
    await this.fetchData()
    this.webSocketInitialization()
  },
  unmounted() {
    // this.removeEventResize()
  },
  methods: {
    // initSelectPicker() {
    //   $('.selectpicker').selectpicker({
    //     noneSelectedText: this.$i18n.t('selectPicker.noneSelectedText'),
    //     noneResultsText: this.$i18n.t('selectPicker.noneResultsText'),
    //     selectAllText: this.$i18n.t('selectPicker.selectAllText'),
    //     deselectAllText: this.$i18n.t('selectPicker.deselectAllText'),
    //   });
    //
    //   this.filterOptions = [
    //     { name: this.$i18n.t('imageType.anterior'), value: 'anterior' },
    //     { name: this.$i18n.t('imageType.color'), value: 'color' },
    //     { name: this.$i18n.t('imageType.redfree'), value: 'redfree' },
    //     { name: this.$i18n.t('imageType.heatmap'), value: 'heatmap' },
    //     { name: this.$i18n.t('imageType.custom'), value: 'custom' },
    //     { name: this.$i18n.t('imageType.texture'), value: 'texture' },
    //     { name: this.$i18n.t('imageType.panoramic'), value: 'pano' },
    //     { name: this.$i18n.t('imageType.video'), value: 'video' },
    //     { name: this.$i18n.t('imageType.stereo'), value: 'stereo' },
    //     { name: this.$i18n.t('imageType.stereo_image'), value: 'stereo_image' },
    //     { name: this.$i18n.t('imageType.stereo_video'), value: 'stereo_video' },
    //     { name: this.$i18n.t('imageType.progression_image'), value: 'progression_image' },
    //     { name: this.$i18n.t('imageType.progression_video'), value: 'progression_video' },
    //   ];
    //   this.$nextTick(() => {
    //     $('.selectpicker').selectpicker('refresh');
    //   });
    // },
    doFilter(imageType) {
      let images = document.querySelectorAll('.image-info-box > .thumbnail-box');
      let hasImg = _.find(this.filterSelected, function (o) { return o == imageType; });
      console.log('hasImg', hasImg);

      if (hasImg) {
        this.filterSelected = _.remove(this.filterSelected, function (n) {
          return n != imageType;
        });
      } else {
        this.filterSelected.push(imageType);
      }

      images.forEach(o => {
        this.filterSelected.length === 0 ? o.style.display = 'inline-block' : o.style.display = 'none';

        this.filterSelected.forEach(f => {
          if (o.classList.contains(f.toUpperCase()))
            o.style.display = 'list-item';
        });
      });
    },
    async fetchData() {
      this.examImages = []
      this.loading = true
      NProgress.start()
      try {
        let examResponse = await this.$examService.get({ id: this.exam_id });
        this.exam = examResponse.exam

        let response = await this.$examDataService.list({ id: this.exam.id });
        this.examImages = response.examDataList

        this.thumbDataPath = `${response.thumbDataPath}`
        this.dataPath = `${response.dataPath}`

        this.$nextTick(() => {
          if (this.showMutirao && this.$aiService.hasMCRAS(this.exam)) {
            this.processMCRAS()
            return
          }
          this.getScoreResult(this.exam)
        })

        // TODO move to service
        if (!IS_PRODUCTION) {
          this.thumbDataPath = `http://localhost:1337${response.thumbDataPath}`
          this.dataPath = `http://localhost:1337${response.dataPath}`
        }

        if (response.examDataList.length === 0) {
          this.examImages = []
          this.alert = this.$message.alert(this, 'request_empty', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        console.log("OPSSS...", err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    processMCRAS() {
      this.mcRASResults = []
      const version = this.$aiService.getModelVersion(this.exam)
      if (version === 'mcRASE2_1.0') {

        const mcRASOD = this.$aiService.getMCRAS(this.exam, 'OD')
        const mcRASOS = this.$aiService.getMCRAS(this.exam, 'OS')
        const mcRASQualityOD = this.$aiService.getMaxAlterationQualityClassMCRAS(this.exam, 'OD')
        const mcRASQualityOS = this.$aiService.getMaxAlterationQualityClassMCRAS(this.exam, 'OS')

        let hasAlterationOD = false
        let hasAlterationOS = false
        const length = mcRASOD?.length ?? mcRASOS?.length
        if (!length)
          return
        for (let i = 1; i < length; i++) {
          const scoreOD = mcRASOD ? mcRASOD[i] : 'LQ'
          const scoreOS = mcRASOS ? mcRASOS[i] : 'LQ'
          const qualityOD = mcRASQualityOD ? mcRASQualityOD[i] : 0
          const qualityOS = mcRASQualityOS ? mcRASQualityOS[i] : 0

          const minThreshold = i == 1 ? 0.004 : 0.2

          if (!hasAlterationOD)
            hasAlterationOD = scoreOD > minThreshold
          if (!hasAlterationOS)
            hasAlterationOS = scoreOS > minThreshold


          if (scoreOD > minThreshold || scoreOS > minThreshold)
            this.mcRASResults.push({
              classOD: this.$aiService.processMCRASScore(scoreOD, i, qualityOD),
              classOS: this.$aiService.processMCRASScore(scoreOS, i, qualityOS),
              text: this.$aiService.mcRASLabels[i],
              scoreOD,
              scoreOS
            })
        }

        if (!hasAlterationOD || !hasAlterationOS) {
          const scoreOD = mcRASOD && !hasAlterationOD ? mcRASOD[0] : 'LQ'
          const scoreOS = mcRASOS && !hasAlterationOS ? mcRASOS[0] : 'LQ'
          const qualityOD = mcRASQualityOD ? mcRASQualityOD[0] : 0
          const qualityOS = mcRASQualityOS ? mcRASQualityOD[0] : 0
          const classOD = this.$aiService.processMCRASScore(scoreOD, 0, qualityOD)
          const classOS = this.$aiService.processMCRASScore(scoreOS, 0, qualityOS)
          if (classOD === 'no-info' && classOS === 'no-info')
            return
          this.mcRASResults.push({
            classOD,
            classOS,
            text: this.$aiService.mcRASLabels[0],
            scoreOD,
            scoreOS
          })
        }
        return
      }
    },
    async getScoreResult(exam) {
      try {
        this.exam.scoreResult = await this.$aiService.alterationScore(exam.metadata);
        console.log('this.exam.scoreResult', this.exam.scoreResult);
      } catch (error) {
        console.log('error', error);
      }
    },
    async doDelete(deleteId) {
      this.clearMessage()

      let params = {
        id: deleteId
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$examDataService.remove(params);
        this.$message.popup(this, 'general.removedSuccess', 'operationSuccess', 'success')
        this.fetchData()
        this.selectedImages = new Array()
        // this.clearSelectedImages()
      } catch (err) {
        this.$message.popup(this, 'general.removedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.removedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async doDownload(item, isVideo) {
      console.log('item do download', item);
      this.loading = true
      NProgress.start()
      try {
        let params = {}
        if (item.id)
          params['id'] = item.id

        if (item.uuid)
          params['uuid'] = item.uuid

        let response = await this.$examDataService.download(params)

        fetch(response.downloadURL)
          .then(res => res.blob())
          .then(blob => {
            let objectURL = URL.createObjectURL(blob);
            let el = document.createElement('a');
            el.href = objectURL;
            el.download = isVideo ? `${item.uuid}.mp4` : `${item.uuid}.jpeg`;
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            URL.revokeObjectURL(objectURL);
          });
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    doEdit(item) {
      this.$router.push({ name: 'exam-image-edit', params: { examDataID: item.id } })
    },
    extraCustomization() {
      let self = this

      $(".lg-download").on("click", function (event) {
        console.log('x vezes downloaderrrrr');
        event.preventDefault();
        let examDataUUID = this.href.split('/').pop()
        let item = { uuid: examDataUUID }
        self.doDownload(item)
      })

      // this.removeEventResize()

      $('.lg-fullscreen.lg-icon').click();
    },
    getAndSetWidth() {
      let element = document.querySelectorAll('.image-info-box > .thumbnail-box');
      if (element.length) {
        let cw = element[0].offsetWidth;
        console.log('width element >>>>>>>> ', cw);
        element.forEach(o => {
          o.style.height = cw + 'px';
        });
      }
    },
    // addEventResize() {
    //   window.addEventListener("resize", this.getAndSetWidth);
    // },
    // removeEventResize() {
    //   window.removeEventListener("resize", this.getAndSetWidth);
    // },
    removeLoading(event) {
      let parentNode = event.target.parentElement.classList;
      let currentlyNode = event.target.classList;

      this.$nextTick(() => {
        currentlyNode.add('loaded')
        parentNode.remove('loading-eye')
        // this.getAndSetWidth();
      });
    },
    setAlert(val) {
      this.alert.message = val.message
      this.alert.class = val.class
    },
    clearMessage() {
      this.alert.message = null
    },
    async openModalDelete(deleteId) {
      const ok = await this.$refs.offcanvasGeneric.show({
        //const ok = await this.$refs.modalGeneric.show({
        modalType: 'danger',
        title: this.$i18n.t('confirm'),
        text: this.$i18n.t('exam.confirmImageRemoval'),
        okButton: this.$i18n.t('remove'),
        okButtonClass: 'btn-danger',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.doDelete(deleteId)
      }
    },
    doCheck: async function (value) {
      let hasImg = _.find(this.selectedImages, function (o) { return o.uuid == value.uuid; });

      if (hasImg) {
        this.selectedImages = _.remove(this.selectedImages, function (n) {
          document.getElementById(value.uuid).classList.remove('checked')
          return n.uuid != value.uuid;
        });
      } else {
        document.getElementById(value.uuid).classList.add('checked')
        this.selectedImages.push(value);
      }

      // console.log('this.selectedImages', this.selectedImages);

      let hasColor = _.find(this.selectedImages, function (o) { return o.type == 'COLOR' });
      let hasOther = _.find(this.selectedImages, function (o) { return o.type != 'COLOR' });

      this.isValidForHeatmap = !hasOther && hasColor ? true : false

      // console.log('isValidForHeatmap? >>> ', this.isValidForHeatmap);

      if (!this.selectedImages.length)
        this.isValidForHeatmap = false
    },
    // clearSelectedImages() {
    //   this.selectedImages.forEach(o => {
    //     document.getElementById('checkImg_' + o.name).checked = false
    //   })
    //   this.selectedImages = new Array()
    // },

    allowEdition(image) {
      return image.endsWith('COLOR') || image.endsWith('REDFREE') || image.endsWith('TEXTURE') || image.endsWith('image')
    },

    isImagesValid(examDataList) {
      if (examDataList.length < 2) {
        this.$message.popup(this, 'examImages.invalidImages', 'operationWarning', 'warn')
        return false
      }

      let laterality = examDataList[0].imageLaterality;
      for (let examData of examDataList) {
        if (examData.imageLaterality != laterality) {
          this.$message.popup(this, 'examImages.notSameLaterality', 'operationWarning', 'warn')
          return false
        }
      }
      return true
    },

    isValidImagesForHeatmap(examDataList) {
      if (examDataList.length == 0) {
        this.$message.popup(this, 'examImages.noEnoughImages', 'operationWarning', 'warn')
        return false
      }
      // Max of 3 images for heatmap - Docker memory limitation.
      if (examDataList.length > 3) {
        this.$message.popup(this, 'examImages.invalidImagesHeatmap', 'operationWarning', 'warn')
        return false
      }

      return true
    },

    async createPanoramic() {
      const processID = this.$utils.uuidv4() // TODO use it on the general notification system
      const params = {
        processID: processID,
        images: this.selectedImages.map(i => i.uuid),
        examData: this.selectedImages[0].img
      }
      // images: this.selectedImages.map(i.uuid),

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$examDataService.createPanoramic(params)
        this.$message.popup(this, 'examImages.requestProcessed', 'operationWarning', 'warn')
      } catch (err) {
        this.$message.popup(this, 'examImages.lambdaError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'examImages.lambdaError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },

    async createStereo() {
      // images: this.selectedImages.map(i.uuid),
      const params = {
        images: this.selectedImages.map(i => i.uuid),
        examData: this.selectedImages[0].img
      }

      this.loading = true
      NProgress.start()
      try {
        this.$message.popup(this, 'examImages.generating_preview', 'operationWarning', 'warn')
        let response = await this.$examDataService.createPreview(params)
        this.openModalCropper(response.data)
      } catch (err) {
        //let res_error = err.response.data.error
        // TODO: BRUNO The same in here, in case of "instant" error on lambda, I've added this timeout
        // because the "in progress" alert is still being presented then this error alert is bypassed
        setTimeout(() => {
          this.$message.popup(this, 'examImages.createdError', 'operationFail', 'error')
        }, 2000)
        // this.alert = this.$message.alert(this, `examImages.createdError`, 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },

    async createHeatmap(locale) {
      const processID = this.$utils.uuidv4() // TODO use it on the general notification system
      const params = {
        processID: processID,
        images: this.selectedImages.map(function (item) {
          return [item.uuid, item.imageLaterality];
        }),
        examData: this.selectedImages,
        locale: locale
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$examDataService.createHeatmap(params)
        this.$message.popup(this, 'examImages.requestProcessed', 'operationWarning', 'warn')
      } catch (err) {
        this.$message.popup(this, 'examImages.lambdaError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'examImages.lambdaError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },

    async openModalCropper(image) {
      this.alert.message = null;

      const ok = await this.$refs.modalCropper.show({
        modalType: 'aipo',
        title: this.$i18n.t('aipo'),
        imgSrc: this.dataPath + '/' + image,
        aspectRatio: 0,
        minWidth: 100,
        minHeight: 100,
        okButton: this.$i18n.t('save'),
        okButtonClass: 'btn-primary',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        console.log(ok.coordinates);
        this.doSaveCroppedImage(ok.coordinates)
      }
    },
    doSaveCroppedImage: async function (value) {
      const processID = this.$utils.uuidv4() // TODO use it on the general notification system
      // images: this.selectedImages.map(e => e.uuid),
      let params = {
        processID: processID,
        id: this.exam.id,
        images: this.selectedImages.map(e => e.uuid),
        examData: this.selectedImages[0].img,
        coordinates: value,
      }

      this.loading = true
      NProgress.start()
      try {
        await this.$examDataService.createStereo(params)

        // this.fetchData()
        // this.clearSelectedImages()
        this.$message.popup(this, 'examImages.requestProcessed', 'operationWarning', 'warn')
      } catch (err) {
        this.$message.popup(this, 'examImages.lambdaError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'examImages.lambdaError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    webSocketInitialization() {
      console.log(`${TAG} webSocketInitialization()`)
      const io = this.$io
      this.isWebSocketConnected = io.socket.isConnected()
      if (this.isWebSocketConnected)
        this.webSocketForGallery()

      io.socket.on('connect', () => {
        this.isWebSocketConnected = true
        this.webSocketForGallery()
      })

      io.socket.on('disconnect', () => {
        this.isWebSocketConnected = false
      })
    },
    webSocketForGallery() {
      console.log(`${TAG} webSocketForGallery()`)
      const io = this.$io

      for (let e of ['gallery_listening', 'gallery_event']) {
        console.log('[app] unlisten to ws event:', e)
        io.socket.off(e);
      }

      const params = {
        id: this.exam.id
      }

      // TODO one listen function for clinic?
      io.socket.post('/api/v2/eyercloud/ws/listen-gallery', params, function gotResponse(body, response) {
        console.log(`${TAG} ws/listen-gallery:`, body);
      })

      io.socket.on('gallery_listening', function (data) {
        console.log(`${TAG} gallery_listening`, data);
      })

      io.socket.on('gallery_event', data => {
        console.log("gallery_event >>> >> >", data)
        // this.$notificationService.createNewNotification(this, data)
        NProgress.done(true)
        this.loading = false

        if (data.successfull) {
          this.$message.popup(this, `examImages.createdSuccess`, 'operationSuccess', 'success')
          this.fetchData()
          this.selectedImages = new Array()
          // this.clearSelectedImages()
        } else {
          // TODO: BRUNO In case of "instant" error on lambda, I've added this timeout
          // because the "in progress" alert is still being presented then this error alert is bypassed
          setTimeout(() => {
            this.$message.popup(this, `examImages.createdError`, `examImages.operationError`, 'error')
          }, 2000)
          // this.alert = this.$message.alert(this, `examImages.createdError`, 'danger')
        }
      })
    },
    async openModalGeneric(value) {

      if (value == 'heatmap') {
        if (!this.isValidImagesForHeatmap(this.selectedImages))
          return
      } else {
        if (!this.isImagesValid(this.selectedImages))
          return
      }

      let text = this.$i18n.t('examImages.confirmTotalImages', { image_length: this.selectedImages.length })
      const ok = await this.$refs.modalGeneric.show({
        modalType: 'warning',
        title: this.$i18n.t(`examImages.title_${value}`),
        text: text,
        okButton: 'OK',
        okButtonClass: 'btn-primary',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        if (value == 'panoramic')
          this.createPanoramic()
        else if (value == 'stereo')
          this.createStereo()
        else
          this.createHeatmap(this.locale())
      }
    },
    getVideoSource(item) {
      let result = {
        "source": [
          {
            "src": `${this.dataPath}/${item.name}?autoplay=1`,
            "type": "video/mp4"
          }
        ],
        "attributes": {
          "preload": false,
          "playsinline": false,
          "controls": true,
          "loop": true
        }
      }

      return JSON.stringify(result)
    },
    locale() {
      return this.$utils.datePickerLocale()
    },
    shareInformation() {
      this.showAccessInfoModal = true

      setTimeout(function () {
        let access_info_modal = document.getElementById('access_info_modal');
        new bootstrap.Modal(access_info_modal).show();
      }, 100);
    },
    onInit(detail) {
      this.lightGallery = detail.instance;
    },
    removeDownloadButton(value) {
      let item = this.examImages[value.index]
      if (item.type.endsWith('VIDEO'))
        document.querySelector('.lg-download').style.display = 'none'
      else
        document.querySelector('.lg-download').style.display = ''
    }
  }
}
