<template>
  <div class="main">
    <Notifications position="bottom center"/>
    <router-view/>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { useUserStore } from '@/store/user'
const TAG = "[app]"
export default {
  name: 'layout',
  setup() {
  },
  data: () => ({
    collapse: false,
  }),
  mounted() {
    const userStore = useUserStore()
    const sessionInfo = userStore.sessionInfo

    if (sessionInfo && sessionInfo.currentClinic) {
      this.$i18n.locale = sessionInfo.currentClinic.locale.toLowerCase();
      console.log("[app] setting locale (from clinic):", sessionInfo.currentClinic.locale)
    }

    this.$notificationService.enable() // BEWARE, do not move this from HERE! Magic, don't touch!

    const thePath = window.location.pathname
    const noRouteWasSpecified = thePath.length <= 1

    if (noRouteWasSpecified) {
     this.$authService.goToFirstPage(this)
    }
  },
  methods: {

  },
}

</script>
