<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" :id="offcanvas_id" data-bs-backdrop="static" :aria-labelledby="`${offcanvas_id}_label`">
    <div class="offcanvas-header">
      <button class="btn-back" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" :id="`${offcanvas_id}_label`">{{ title }}</h5>
    </div>
    <div class="offcanvas-body">
      <vee-form v-slot="{ handleSubmit }" as="div">
        <form @submit.prevent.stop="handleSubmit(doConfirm)">
            <!-- <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
              <span v-html="alert.message"></span>
              <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
            </div> -->

            <div class="form-floating">
              <vee-field name="loginPassword" v-model="loginPassword" rules="required" v-slot="{ field, errorMessage }">
                <input v-bind="field" type="password" class="form-control" ref="loginPassword" :placeholder="$t('user.loginPassword')" id="loginPassword" data-test="inputPassword">
                <span class="vee-error">{{ errorMessage }}</span>
              </vee-field>
              <label for="loginPassword">{{ $t('user.loginPassword') }}</label>
            </div>
            <div class="form-floating">
              <vee-field name="newPin" v-model="newPin" rules="required|min:4" v-slot="{ field, errorMessage }">
                <input v-bind="field" type="text" class="form-control" ref="newPin" id="newPin" :placeholder="$t('user.newPIN')" v-only-numbers data-test="inputNewPin">
                <span class="vee-error">{{ errorMessage }}</span>
              </vee-field>
              <label for="newPin">{{ $t('user.newPIN') }}</label>
            </div>

          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" data-test="btnCancel" @click="close()">{{ $t('user.cancel') }}</button>
            <button type="submit" class="btn btn-primary" data-test="btnSave">{{ $t('user.modify') }}</button>
          </div> -->
          <!-- footer inside body to avoid empty space between body and footer -->
          <div class="offcanvas-footer">
            <button type="submit" class="btn" :class="okButtonClass" data-test="btnConfirm" @click="doConfirm">{{ okButton }}</button>
            <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="offcanvas" v-show="cancelButton" data-test="btnCancel" @click="doCancel">{{ cancelButton }}</button>
          </div>
        </form>
      </vee-form>

      <!-- footer inside body to avoid empty space between body and footer -->
      <!-- <div class="offcanvas-footer">
        <button type="button" class="btn" :class="okButtonClass" data-bs-dismiss="offcanvas" data-test="btnConfirm" @click="doConfirm">{{ okButton }}</button>
        <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="offcanvas" v-show="cancelButton" data-test="btnCancel" @click="doCancel">{{ cancelButton }}</button>
      </div> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  // width:                                510px !important;
  // padding:                              40px;

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    form {
      display:                          flex;
      flex-direction:                   column;
      gap:                              26px;
    }

    .vee-error {
      width:                            100% !important;
    }
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn {
      font-size:                        var(--font-size-14);
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   4.2px;
      text-align:                       center;
      text-transform:                   uppercase;
      // gap:                              10px;
      // flex:                             1 0 0;
      width:                            100%;
      padding:                          20px;
    }

    .btn-success {
      color:                            var(--Shades-W, #FFF);
      // margin-top:                       26px;
    }
  }
}
</style>

<script>
import utilService from '../../../../services/util-service.js';
import { useUserStore } from '@/store/user'

export default {
  name: 'UserChangePinOffcanvas',
  props: ['offcanvas_id'],
  data: () => ({
    title: null,
    text: null,
    okButton: null,
    okButtonClass: null,
    cancelButton: null,
    cancelButtonClass: null,
    offcanvas_pin: null,
    loginPassword: "",
    newPin: "",
  }),
  mounted() {
    this.$nextTick(() => [
      loginPassword.focus()
    ])
  },
  methods: {
    show(options = {}) {
      if (options.title)
        this.title = options.title

      if (options.text)
        this.text = options.text

      if (options.okButton) {
        this.okButton = options.okButton
        this.okButtonClass = options.okButtonClass
      }

      if (options.cancelButton) {
        this.cancelButton = options.cancelButton
        this.cancelButtonClass = options.cancelButtonClass
      }

      this.offcanvas_pin = new bootstrap.Offcanvas(document.getElementById(this.offcanvas_id)).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.changePin()
      // this.offcanvas_pin = null;
      // this.resolvePromise(true)
    },

    doCancel() {
      this.offcanvas_pin = null;
      this.resolvePromise(false)
    },

    async changePin() {
      NProgress.start()
      try {
        await this.$userService.changePin(this.loginPassword, this.newPin)

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')

        this.$nextTick(() => {
          this.offcanvas_pin = null;
          this.resolvePromise(true)
        });
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    }
  }
}
</script>
