<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="47" height="46" viewBox="0 0 47 46" fill="none">
    <path d="M6.43848 21.6106L18.7393 33.9115L40.5624 12.0885" stroke="black" stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconCheckBig'
}
</script>
