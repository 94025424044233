import { notify } from "@kyvg/vue3-notification";

class MessageService {
  popup(self, message, title, type) {
    notify({
      title: self.$i18n.t(title),
      text: self.$i18n.t(message),
      type: type,
      duration: 3000
    });
  }

  alert(self, alertMessage, alertClass) {
    let alert = {
      message: self.$i18n.t(alertMessage),
      class: `alert-${alertClass}`
    }
    return alert
  }
}

const messageService = new MessageService()
export default messageService
