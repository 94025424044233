<template>
  <div class="" v-loader.opacity="loading">
    <div class="sub-header-box">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="$router.back()">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>

      <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/exam/" data-test="btnRouter">
              {{ $t('home') }}
            </router-link>
          </li>
          <li class="breadcrumb-item" aria-current="page">
            <router-link to="/settings/" data-test="btnRouter">
              {{ $t('createClinic.configuration') }}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ $t('clinic.clinic') }}</li>
        </ol>
      </nav>
    </div>

    <div class="title-box">
      <div class="flex-start">
        <h2 class="title">{{ $t('createClinic.newClinic') }}</h2>
      </div>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" @tab-activated="switchTab">
      <template v-slot:tab_head_data>
        {{ $t('createClinic.data') }}
      </template>
      <template v-slot:data>
        <ClinicCreateTab v-if="tabActive == 'data'"></ClinicCreateTab>
      </template>
    </BaseTab>

    <!-- <div class="action-bar">
      <div id="clinicCreateActionBar"></div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.sub-header-box {
  display:                           flex;
  flex-direction:                    row;
  justify-content:                   flex-start;
  align-items:                       center;
  gap:                               20px;
  width:                             100%;
  padding:                           15px 20px;

  ol {
    margin:                          0;
  }
}

.title-box {
  display:                            flex;
  flex-direction:                     row;
  align-items:                        center;
  justify-content:                    space-between;
  height:                             55px;
  padding:                            10px 25px;
  // margin:                             2.3rem 0 1.7rem;

  > div {
    width:                            auto !important;
  }
}
</style>

<script>
import ClinicCreateTab from './ClinicCreateTab.vue'

export default {
  name: 'clinic-create',
  components: {
    ClinicCreateTab,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null,
    },
    parentClinicOptions: [
      {value: null, label: 'None'}
    ],
    clinicTemplate: null,
    parentClinicSelected: null,
    tabActive: 'data',
    tabs: ['data'],
  }),
  mounted: function() {
    // this.initialize()
  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
    // initialize: async function() {
    //   this.loading = true
    //   NProgress.start()
    //   try {
    //     let response = await this.$clinicService.create();
    //     this.clinicTemplate = response.clinicTemplate
    //
    //     response.parentClinics.forEach(o => {
    //       o.value = o.id;
    //       o.label = o.name;
    //       this.parentClinicOptions.push(o)
    //     })
    //   } catch (err) {
    //     this.$message.popup(this, 'request_error', 'operationFail', 'error')
    //     // this.alert = this.$message.alert(this, 'request_error', 'danger')
    //   }
    //   this.loading = false
    //   NProgress.done(true)
    // },
  }
}
</script>
