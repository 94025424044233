<template>
  <div class="registration-page">
    <div class="container">
      <div class="row">
        <div class="col flex-between">
          <img src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo">

          <div class="flex-center">
            <h1>{{ $t('createAccount.collaboratorNewSuccess') }}</h1>

            <p class="mb-0" v-if="fullName && clinicName">
              {{ $t('createAccount.collaboratorNewSuccessInfo1') }}
              <span>{{ fullName }}</span>
              {{ $t('createAccount.collaboratorNewSuccessInfo2') }}
              <span>{{ clinicName }}</span>.
              {{ $t('createAccount.collaboratorNewSuccessInfo3') }}
            </p>

            <a href="/" class="btn btn-success">{{ $t('createAccount.clinicAccess') }}</a>
          </div>

          <div class="powered-box flex-center">
            <p class="d-inline-block">POWERED BY</p>
            <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
              <img src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
            </a>
            <p class="d-inline-block">v {{ appVersion }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.flex-between,
.flex-center {
  flex-direction:                 column;
}
.flex-center {
  width:                          40%;
  min-width:                      350px;
  max-width:                      400px;
  gap:                            28px;
  text-align:                     center;

  h1 {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    38px;
    font-weight:                  300;
    line-height:                  normal;
  }

  > p {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    var(--font-size-14);
    font-weight:                  300;
    line-height:                  140%;
    span {
      font-weight:                700;
    }
  }
}
.registration-page {
  height:                         100vh;

  .container,
  .row {
    height:                       100%;
  }

  .container {
    padding-top:                  2rem;
    padding-bottom:               1rem;
  }
}

.powered-box {
  flex-direction:                 row;
  gap:                            10px;
  p {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    var(--font-size-8);
    font-weight:                  400;
    letter-spacing:               1.52px;
    margin:                       0;
  }
  .logo {
    color:                        #1B294A;
    text-decoration:              none;
    width:                        90px;
    // margin:                       0 10px;
    img {
      width:                      100%;
    }
  }
}

@media (max-width: 991px) {
  .flex-between > img {
    width:                        160px;
  }

  .flex-center {
    min-width:                    300px;

    h1 {
      font-size:                  32px;
    }
  }
}
</style>

<script>
  export default {
    name: 'invitation-done',
    data: () => ({
      fullName: null,
      clinicName: null,
      appVersion: process.env.VUE_APP_VERSION,
    }),
    mounted() {
      const data = this.$utils.parseDataQuery(this.$route.query.data)
      console.log('const data', data);
      this.fullName = data.fullName
      this.clinicName = data.clinicName
    }
  }
</script>
