import en from './en.json'
import es from './es.json'
import ptBR from './pt-br.json'

const SUPPORTED_LANGUAGES = ['en', 'pt-br', 'es']

function getCurrentLanguage() {
  let language = window.navigator.userLanguage || window.navigator.language
  if (language == 'pt' || language.startsWith('pt-')) {
    return 'pt-br'
  } else if (language.startsWith('en-')) {
    return 'en'
  } else if (language.startsWith('es-')) {
    return 'es'
  }

  return language
}

let language = getCurrentLanguage()
if (!SUPPORTED_LANGUAGES.includes(language))
  language = 'en'

export const defaultLocale = language.toLowerCase();
console.log(language, 'browser lang');

export const languages = {
  'en': en,
  'es': es,
  'pt-br': ptBR,
}
