import eyerCloud from '@/config/eyercloud'

class MedicalRecordService {

  async get(medicalRecordID) {
    let params = {id: medicalRecordID}
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/medicalRecord/get`, params)
    return response.data
  }

  async list(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/medicalRecord/list`, params)
    return response.data
  }

  async save(params, files) {
    let formData = new FormData()
    formData.append('patient', JSON.stringify(params.patient))
    formData.append('content', JSON.stringify(params.content))

    let keys = Object.keys(files)
    let fileEntries = {} // TODO refactor filter info only
    for (let k of keys) {
      fileEntries[k] = files[k].info
    }
    formData.append('fileEntries', JSON.stringify(fileEntries))

    for (let k of keys) {
      formData.append('files', files[k].blob) // binary data ALWAYS at the end
    }

    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/medicalRecord/save`, formData)
    return response.data
  }

  async update(params, files) {
    let formData = new FormData()
    formData.append('id', params.id)
    formData.append('patient', JSON.stringify(params.patient))
    formData.append('content', JSON.stringify(params.content))

    let keys = Object.keys(files)
    let fileEntries = {} // TODO refactor filter info only
    for (let k of keys) {
      fileEntries[k] = files[k].info
    }
    formData.append('fileEntries', JSON.stringify(fileEntries))

    for (let k of keys) {
      formData.append('files', files[k].blob) // binary data ALWAYS at the end
    }

    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/medicalRecord/update`, formData)
    return response.data
  }

  async remove(id) {
    let params = {id: id}
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/medicalRecord/remove`, params)
    return response.data
  }

  async createGeneralReport(params) {
    // TODO REFACTOR rename endpoint to new-general-report
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/medicalRecord/create`, params)
    return response.data
  }

  async getMedicalDataURL(id) {
    let params = {id: id}
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/medicalRecord/file-download-link`, params)
    return response.data
  }

  async getPatientAssets(patientID) {
    let params = {id: patientID}
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/medicalRecord/patient-assets`, params)
    return response.data
  }

}
const medicalRecordService = new MedicalRecordService();
export default medicalRecordService;
