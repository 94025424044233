export default {
  updated: function (el, binding) {
    $(el).keypress(isNumber);
  }
}


var isNumber = function(evt) {
  evt = (evt) ? evt : window.event;
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    console.log('if');
    evt.preventDefault();;
  } else {
    console.log('else');
    return true;
  }
}
