<template>
  <div class="clinic-list eyer2" v-loader.opacity="loading">
    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseInfiniteTable class="no-border" v-if="tableClinic && session" :tabledata="tableClinic" @item-opened="toDetails">
      <template v-slot:column_parent="{item}">
        <span v-if="item.parent">{{ item.parent.name }}</span>
        <span v-else>{{ $t('clinic.noParentClinic') }}</span>
      </template>

      <!-- <template v-slot:column_permission="{item}">
        <span class="label label-warning">{{ $t(`${item.userPermission}`) }}</span>
      </template> -->
      <template v-slot:column_permission="{item}">
        <span class="label" :class="getPermissionClass(item)">
          {{ $t(item.userPermission) }}
        </span>
      </template>

      <!-- <template v-slot:column_actions="{item}">
        <div class="table-actions">
          <button class="btn btn-success" type="button" name="button" :data-title="$t('tooltips.selectClinic')"
                  v-show="session.currentClinic && session.currentClinic.id !== item.id" @click.stop="useClinic(item)">
            <i class="fa fa-home"></i>
            {{ $t('createClinic.select') }}
          </button>
        </div>
      </template> -->
    </BaseInfiniteTable>

    <button class="btn-icon btn-top" type="button" name="button" v-if="currentPage > 1 && tableClinic.items.length > 20" @click="goTop">
      <IconArrowUp/>
    </button>

    <teleport to="#settingsActionBar" v-if="isSettingsMenuMounted">
      <router-link class="btn btn-success" to="/clinic/create" data-test="btnRouter">
        {{ $t('clinicIndex.create') }}
      </router-link>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.eyer2 {
  padding:                           10px;
}

.action-bar {
  .btn {
    @include                         border_radius(99px);
    font-size:                       var(--font-size-12);
    font-weight:                     400;
    letter-spacing:                  3.64px;
    text-transform:                  uppercase;
  }

  .btn-success {
    color:                           var(--Shades-W);
    font-weight:                     700;
    height:                          44px;
    padding:                         15px 20px;
    background:                      var(--Brand-Secondary);
    border:                          1px solid var(--Brand-Secondary);
  }
}

.title-box,
.title-box .btn {
  margin-bottom:                     0;
}

.vtable > tbody > tr > td {
  button {
    &:first-child {
      margin-right:                  5px;
    }
  }
}

.vtable-mob > ul > li p span.glyphicon {
  display:                           none;
}

@media (max-width: 400px) {
  .vtable-mob > ul > li {
    p {
      > span:first-child {
        // display:                     none;
      }
      span:last-child {
        float:                       unset !important;
      }
    }
  }
}

@media (max-width: 991px) {
  .eyer2 {
    padding:                          0;
  }
   .inside-box-mob-item p {
    display:                          flex;
    &:last-child {
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'clinic-list',
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tableClinic: null,
    perPage: 20, // TODO take this from profile-service (we may allow customization later)
    currentPage: 1,
    totalPages: 0,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    isSettingsMenuMounted: false,
  }),
  created() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
  },
  mounted: async function() {
    this.$activeLink.value = 'clinic'
    this.fetchData();

    this.$nextTick(() => {
      this.isSettingsMenuMounted = true
    })
  },
  provide() {
    return {
      scrollToTop: (callback) => {
        this.scrollTopCallback = callback;
      }
    };
  },
  methods: {
    goTop() {
      if (this.scrollTopCallback) {
        this.scrollTopCallback();
      }

      this.$nextTick(() => {
        this.currentPage = 1

        this.fetchData()
      })
    },
    onPageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    fetchData: async function() {
      this.tableClinic = null

      this.loading = true
      NProgress.start()
      try {
        let params = {
          page: this.currentPage,
        }

        let response = await this.$clinicService.getList(params);
        this.loadTableData(response)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true);
      this.loading = false
    },
    useClinic: async function(item) {
      NProgress.start()
      try {
        let params = {
          id: item.id,
        }

        const sessionInfo = await this.$clinicService.changeClinic(params);
        this.session = sessionInfo;
        this.session.examCurrentPage = 1
        this.$authService.sessionChanged(sessionInfo)

        let loader = this.$loading.show({
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            onCancel: this.onCancel,
        });

        setTimeout(async _ => {
          await this.$router.push({name: 'app'})
          loader.hide()
        }, 300)

      } catch (err) {
        this.$message.popup(this, 'clinic.errorChangeClinic', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'clinic.errorChangeClinic', 'danger')
      }
      NProgress.done(true);
    },
    loadTableData(response) {
      this.alert = {}

      this.tableClinic = {
        'items': response.result,
        // 'total': response.totalCount,
        'columns': [
          {name: this.$i18n.t('createClinic.name'), type: 'string', value: 'name', width: '25%'},
          {name: this.$i18n.t('clinic.totalUsers'), type: 'string', value: '', width: '25%'},
          {key: 'parent', name: this.$i18n.t('createClinic.parentClinic'), type: 'custom', value: 'parent.name', width: '25%'},
          {key: 'permission', name: this.$i18n.t('createClinic.permission'), type: 'custom', value: 'userPermission', width: '25%'},
          // {key: "actions", name: '<span class="glyphicon glyphicon-cog"></span>', type: 'custom', value: 'actions', width: '20%', align: 'text-center'},
        ]
      };

      // this.tableClinic.items.forEach(o => {
      //   o.userPermissionLabeled = `<span class="label label-warning">${this.$i18n.t(o.userPermission)}</span>`;
      // })

      this.$nextTick(() => {
        let element = $(`.tr_${this.session.currentClinic.id} td:first-child`)
        if (element.find('span.label-info').length === 0) {
          element.append(`<span class="label label-success ms-2">${this.$i18n.t('createClinic.currentClinic')}</span> `)
        }
      })

      if (response.result.length === 0) {
        this.tableClinic = {};
        this.alert = this.$message.alert(this, 'request_notfound', 'warning')
        // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
      }
    },
    toDetails(item) {
      this.$router.push({name: 'clinic-show', params: {clinicID: item.id}, query: {tab: 'data'}})
    },
    getPermissionClass(item) {
      if (item.userPermission == 'admin') {
        return 'label-blue'
      } else if (item.userPermission == 'physician') {
        return 'label-green'
      } else if (item.userPermission == 'technician') {
        return 'label-purple'
      } else if (item.userPermission == 'specialist') {
        return 'label-warning'
      } else {
        return 'label-pink'
      }
    }
  }
}
</script>
