import { createApp, ref } from 'vue'
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import Datepicker from '@vuepic/vue-datepicker'
import { QuillEditor } from '@vueup/vue-quill'
import { Cropper } from 'vue-advanced-cropper'
import { Form, Field } from 'vee-validate'
import _ from 'lodash'
import lightGallery from 'lightgallery.js'
import LightGalleryVue from 'lightgallery/vue'
import selectpicker from '@/assets/dependencies/bootstrap-select/bootstrap-select.js'
import {vMaska} from 'maska'
import mitt from 'mitt';
import grapesjs from 'grapesjs';
// import VuePdf from 'vue3-pdfjs'
import pdfVuer from 'pdfvuer'
import VueSweetalert2 from 'vue-sweetalert2';
import Notifications from '@kyvg/vue3-notification'
import Print from 'vue3-print-nb'
import VueLoading from 'vue-loading-overlay';
import { createPinia } from 'pinia'
import vSelect from 'vue-select'
// import ElTableInfiniteScroll from 'el-table-infinite-scroll'
import ElementPlus from 'element-plus'
import VueVirtualScroller from 'vue-virtual-scroller'
// import { VirtualScroller } from 'vue-virtual-scroller'
import { RecycleScroller } from 'vue-virtual-scroller'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'element-plus/dist/index.css'

import * as socketIoClient from 'socket.io-client'
import * as sailsIo from 'sails.io.js'
import 'bootstrap';

import App from './layout/layout.vue'

import router from './router'

import Hotjar from '@hotjar/browser'


// DIRTY STUFF GOES HERE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
window.bootstrap = require('bootstrap')
window.NProgress = require('nprogress');
window.$ = window.jQuery = require('jquery');
window.moment = require('moment')
import("select2/dist/js/select2.full").then((S) => {
  $.fn.select2.defaults.set("dir", "rtl");
});
window.$titleAlert = require('jquery-titlealert');
// DIRTY STUFF GOES HERE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

// load global styles
import './styles/base.scss'

// load global components
import BaseAccordion from './components/base/BaseAccordion.vue'
import BaseCheckBox from './components/base/BaseCheckBox.vue'
import BaseRoundedCheckBox from './components/base/BaseRoundedCheckBox.vue'
import BaseSquareCheckBox from './components/base/BaseSquareCheckBox.vue'
import BaseCropperOffcanvas from './components/base/BaseCropperOffcanvas.vue'
import BaseDateFilter from './components/base/BaseDateFilter.vue'
import BaseDateFilterNew from './components/base/BaseDateFilterNew.vue'
import BaseGenericOffcanvas from './components/base/BaseGenericOffcanvas.vue'
import BaseInfiniteGridTable from './components/base/BaseInfiniteGridTable.vue'
import BaseInfiniteTable from './components/base/BaseInfiniteTable.vue'
import BasePagination from './components/base/BasePagination.vue'
import BasePatientAccessInfoOffcanvas from './components/base/BasePatientAccessInfoOffcanvas.vue'
import BasePatientFilter from './components/base/BasePatientFilter.vue'
import BaseQLEditor from './components/base/BaseQLEditor.vue'
import BaseSelect from './components/base/BaseSelect.vue'
import BaseTab from './components/base/BaseTab.vue'
import BaseTable from './components/base/BaseTable.vue'
import BaseFeature from './components/base/BaseFeature.vue'

// load global component icons
import IconAlert from './components/icons/IconAlert.vue'
import IconArrowBack from './components/icons/IconArrowBack.vue'
import IconArrowRight from './components/icons/IconArrowRight.vue'
import IconArrowUp from './components/icons/IconArrowUp.vue'
import IconAudit from './components/icons/IconAudit.vue'
import IconBack from './components/icons/IconBack.vue'
import IconCalendar from './components/icons/IconCalendar.vue'
import IconChange from './components/icons/IconChange.vue'
import IconCheck from './components/icons/IconCheck.vue'
import IconCheckBig from './components/icons/IconCheckBig.vue'
import IconClinics from './components/icons/IconClinics.vue'
import IconClose from './components/icons/IconClose.vue'
import IconCloseHamburger from './components/icons/IconCloseHamburger.vue'
import IconCross from './components/icons/IconCross.vue'
import IconDashboard from './components/icons/IconDashboard.vue'
import IconDevices from './components/icons/IconDevices.vue'
import IconDot from './components/icons/IconDot.vue'
import IconDownload from './components/icons/IconDownload.vue'
import IconDownloadPatient from './components/icons/IconDownloadPatient.vue'
import IconExams from './components/icons/IconExams.vue'
import IconExamsSimple from './components/icons/IconExamsSimple.vue'
import IconExpiredSession from './components/icons/IconExpiredSession.vue'
import IconEye from './components/icons/IconEye.vue'
import IconEyerCloudLogo from './components/icons/IconEyerCloudLogo.vue'
import IconFavorite from './components/icons/IconFavorite.vue'
import IconFilter from './components/icons/IconFilter.vue'
import IconGrid from './components/icons/IconGrid.vue'
import IconHamburger from './components/icons/IconHamburger.vue'
import IconHelp from './components/icons/IconHelp.vue'
import IconHelpMenu from './components/icons/IconHelpMenu.vue'
import IconImage from './components/icons/IconImage.vue'
import IconLetter from './components/icons/IconLetter.vue'
import IconList from './components/icons/IconList.vue'
import IconLogout from './components/icons/IconLogout.vue'
import IconPatients from './components/icons/IconPatients.vue'
import IconPatientsSimple from './components/icons/IconPatientsSimple.vue'
import IconPdf from './components/icons/IconPdf.vue'
import IconPhelcomEye from './components/icons/IconPhelcomEye.vue'
import IconPlus from './components/icons/IconPlus.vue'
import IconReports from './components/icons/IconReports.vue'
import IconSave from './components/icons/IconSave.vue'
import IconSearch from './components/icons/IconSearch.vue'
import IconSearchFilter from './components/icons/IconSearchFilter.vue'
import IconSettings from './components/icons/IconSettings.vue'
import IconShare from './components/icons/IconShare.vue'
import IconShowPassword from './components/icons/IconShowPassword.vue'
import IconTrash from './components/icons/IconTrash.vue'
import IconUsers from './components/icons/IconUsers.vue'
import IconWarning from './components/icons/IconWarning.vue'

// languages i18n
import { languages } from './config/locales/index.js'
import { defaultLocale } from './config/locales/index.js'
const messages = Object.assign(languages)

// services
import aiService from './services/ai-service.js';
import auditService from './services/audit-service.js';
import authService from './services/auth-service.js';
import clinicService from './services/clinic-service.js';
import dashboardService from './services/dashboard-service.js'
import deviceService from './services/device-service.js';
import examService from './services/exam-service.js';
import examDataService from './services/examdata-service.js';
import externalService from './services/external-service.js';
import eyerwebService from './services/eyerweb-service.js';
import messageService from './services/message-service.js';
import mutiraoService from './services/mutirao-service.js';
import notificationService from './services/notification-service.js';
import patientService from './services/patient-service.js';
import medicalRecordService from './services/medical-record-service.js';

import registrationService from './services/registration-service.js';
import reportService from './services/report-service.js';
import userService from './services/user-service.js';
import utilService from './services/util-service.js';
import securityService from './services/security-service.js';

const app = createApp(App).use(i18n);

// fallbackLocale: 'pt-br',
const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  formatFallbackMessages: true,
  fallbackWarn: false,
  fallbackRoot: false,
  missingWarn: false,
  messages
})

// directives
import Loader from './directives/loader.js'
import LoaderEyerweb from './directives/loader-eyerweb.js'
import OnlyNumbers from './directives/only-numbers.js'

// filters
import {date, dateTime, time} from './filters/date.js'
import rules from './filters/rules.js';

const emitter = mitt();

app.use(VueLoading)
app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(_)
app.use(selectpicker)
app.use(lightGallery)
app.use(Print)
app.use(grapesjs)
app.use(ElementPlus)
app.use(Notifications)
app.use(VueSweetalert2)
app.use(VueVirtualScroller)
// app.use(Maska)
// app.use(ElTableInfiniteScroll)
// app.use(VuePdf)

app.component('cropper', Cropper)
app.component('date-picker', Datepicker)
app.component('quill-editor', QuillEditor)
app.component('lightgallery-vue', LightGalleryVue)
app.component('v-select', vSelect)
app.component('vee-form', Form)
app.component('vee-field', Field)
app.component('pdf-vuer', pdfVuer)
// app.component('VirtualScroller', VirtualScroller)
app.component('RecycleScroller', RecycleScroller)
// global components
app.component('BaseAccordion', BaseAccordion)
app.component('BaseCheckBox', BaseCheckBox)
app.component('BaseRoundedCheckBox', BaseRoundedCheckBox)
app.component('BaseSquareCheckBox', BaseSquareCheckBox)
app.component('BaseCropperOffcanvas', BaseCropperOffcanvas)
app.component('BaseDateFilter', BaseDateFilter)
app.component('BaseDateFilterNew', BaseDateFilterNew)
app.component('BaseGenericOffcanvas', BaseGenericOffcanvas)
app.component('BaseInfiniteGridTable', BaseInfiniteGridTable)
app.component('BaseInfiniteTable', BaseInfiniteTable)
app.component('BasePagination', BasePagination)
app.component('BasePatientAccessInfoOffcanvas', BasePatientAccessInfoOffcanvas)
app.component('BasePatientFilter', BasePatientFilter)
app.component('BaseQLEditor', BaseQLEditor)
app.component('BaseSelect', BaseSelect)
app.component('BaseTab', BaseTab)
app.component('BaseTable', BaseTable)
app.component('BaseFeature', BaseFeature)
// global component icons
app.component('IconAlert', IconAlert);
app.component('IconArrowBack', IconArrowBack);
app.component('IconArrowRight', IconArrowRight);
app.component('IconArrowUp', IconArrowUp);
app.component('IconAudit', IconAudit);
app.component('IconBack', IconBack);
app.component('IconCalendar', IconCalendar);
app.component('IconChange', IconChange);
app.component('IconCheck', IconCheck);
app.component('IconCheckBig', IconCheckBig);
app.component('IconClinics', IconClinics);
app.component('IconClose', IconClose);
app.component('IconCloseHamburger', IconCloseHamburger);
app.component('IconCross', IconCross);
app.component('IconDashboard', IconDashboard)
app.component('IconDevices', IconDevices);
app.component('IconDot', IconDot);
app.component('IconDownload', IconDownload);
app.component('IconDownloadPatient', IconDownloadPatient);
app.component('IconExams', IconExams)
app.component('IconExamsSimple', IconExamsSimple);
app.component('IconExpiredSession', IconExpiredSession);
app.component('IconEye', IconEye);
app.component('IconEyerCloudLogo', IconEyerCloudLogo);
app.component('IconFavorite', IconFavorite);
app.component('IconFilter', IconFilter);
app.component('IconGrid', IconGrid);
app.component('IconHamburger', IconHamburger);
app.component('IconHelp', IconHelp);
app.component('IconHelpMenu', IconHelpMenu);
app.component('IconImage', IconImage);
app.component('IconLetter', IconLetter);
app.component('IconList', IconList);
app.component('IconLogout', IconLogout);
app.component('IconPatients', IconPatients);
app.component('IconPatientsSimple', IconPatientsSimple);
app.component('IconPdf', IconPdf);
app.component('IconPhelcomEye', IconPhelcomEye);
app.component('IconPlus', IconPlus);
app.component('IconReports', IconReports);
app.component('IconSave', IconSave);
app.component('IconSearch', IconSearch);
app.component('IconSearchFilter', IconSearchFilter);
app.component('IconSettings', IconSettings);
app.component('IconShare', IconShare);
app.component('IconShowPassword', IconShowPassword);
app.component('IconTrash', IconTrash);
app.component('IconUsers', IconUsers);
app.component('IconWarning', IconWarning);

app.directive('loader', Loader)
app.directive('loader-eyerweb', LoaderEyerweb)
app.directive('only-numbers', OnlyNumbers)
app.directive('maska', vMaska)

app.config.globalProperties.$filters = {
  'date': date,
  'dateTime': dateTime,
  'time': time,
}

const axiosExternalInstance = axios.create({
  baseURL: window.location.origin,
  timeout: 16000,
  headers: {
    "Content-type": "application/json"
  }
});

const IS_PRODUCTION = process.env.NODE_ENV == 'production'
let siteId = 5196717
const hotjarVersion = 6

if (!IS_PRODUCTION) {
   siteId = 5076341    
}

Hotjar.init(siteId, hotjarVersion);

// websocket configuration
// const IS_PRODUCTION = process.env.NODE_ENV == 'production'
let io = sailsIo(socketIoClient)
// io.sails.autoConnect = false // BEWARE... daemons around...
let url = process.env.VUE_APP_API_URL
io.sails.url = url
console.log(`[main] WebSocket LISTENING URL:`, url)
io.sails.environment = process.env.NODE_ENV || 'development'
io.sails.useCORSRouteToGetCookie = false
io.sails.reconnection = true

app.config.globalProperties.$io = io
app.config.globalProperties.$axios = { ...axiosExternalInstance }
app.config.globalProperties.$activeLink = ref('exam')

app.config.globalProperties.$aiService = aiService;
app.config.globalProperties.$auditService = auditService;
app.config.globalProperties.$authService = authService;
app.config.globalProperties.$clinicService = clinicService;
app.config.globalProperties.$dashboardService = dashboardService;
app.config.globalProperties.$deviceService = deviceService;
app.config.globalProperties.$examService = examService;
app.config.globalProperties.$examDataService = examDataService;
app.config.globalProperties.$externalService = externalService;
app.config.globalProperties.$eyerwebService = eyerwebService;
app.config.globalProperties.$message = messageService;
app.config.globalProperties.$mutiraoService = mutiraoService;
app.config.globalProperties.$notificationService = notificationService;
app.config.globalProperties.$patientService = patientService;
app.config.globalProperties.$medicalRecordService = medicalRecordService;
app.config.globalProperties.$registrationService = registrationService;
app.config.globalProperties.$reportService = reportService;
app.config.globalProperties.$userService = userService;
app.config.globalProperties.$utils = utilService;
app.config.globalProperties.$security = securityService;

app.config.globalProperties.emitter = emitter;

app.mount('#app')

export {app};
