<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="termsOffcanvas" data-bs-backdrop="static" aria-labelledby="termsOffcanvasLabel">
    <div class="offcanvas-header">
      <button class="btn-back" :class="{'d-none' : !accepted}" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" id="termsOffcanvasLabel">
        {{ `${$t('terms.privacyPolicy')} ${$t('terms.and')} ${$t('terms.termsOfUse')}` }}
      </h5>
    </div>
    <div class="offcanvas-body">
      <p>
        {{ $t('terms.modalTextLine1') }}
        {{ $t('terms.modalTextLine2') }}
        {{ $t('terms.modalTextLine3') }}
      </p>
      <a href="https://phelcom.com/lgpd" target="_blank">{{ `${$t('terms.knowOur')} ${$t('terms.privacyPolicy')} ${$t('terms.and')} ${$t('terms.termsOfUse')}` }}.</a>

      <div class="check-box" v-if="!accepted">
        <label>
          <input type="checkbox" id="acceptedTerm" v-model="collaborator.acceptedTerm" data-test="checkboxAcceptedTerm">
          {{ $t('terms.readAndConfirm') }} <strong>{{ $t('terms.privacyPolicy') }}</strong> {{ $t('terms.and') }} <strong>{{ $t('terms.termsOfUse') }}</strong> {{ $t('terms.fromEyerCloud') }}
        </label>
      </div>
    </div>

    <div class="offcanvas-footer">
      <button type="button" class="btn" :class="okButtonClass" data-bs-dismiss="offcanvas" data-test="btnConfirm" @click="doAccept" :disabled="!collaborator.acceptedTerm" v-if="!accepted">{{ okButton }}</button>
      <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="offcanvas" v-show="cancelButton" data-test="btnCancel" @click="doCancel">{{ cancelButton }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    display:                            flex;
    flex-direction:                     column;
    justify-content:                    flex-start;
    gap:                                26px;

    p,
    a,
    label {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin:                           0;
    }

    .check-box {
      display:                          flex;
      align-items:                      center;

      input {
        margin-right:                   10px;
        vertical-align:                 middle;
      }
    }
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn {
      font-size:                        var(--font-size-14);
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   4.2px;
      text-align:                       center;
      text-transform:                   uppercase;
      // gap:                              10px;
      // flex:                             1 0 0;
      width:                            100%;
      padding:                          20px;
    }

    .btn-success {
      color:                            var(--Shades-W, #FFF);
      // margin-top:                       26px;
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'AcceptanceTermsOffcanvas',
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    collaborator: {
      acceptedTerm: false,
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    accepted: false,
    okButton: null,
    okButtonClass: null,
    cancelButton: null,
    cancelButtonClass: null,
    offcanvas_terms: null,
  }),
  mounted() {
  },
  methods: {
    show(options = {}) {
      if (options.okButton) {
        this.okButton = options.okButton
        this.okButtonClass = options.okButtonClass
      }

      if (options.cancelButton) {
        this.cancelButton = options.cancelButton
        this.cancelButtonClass = options.cancelButtonClass
      }

      this.offcanvas_terms = new bootstrap.Offcanvas(document.getElementById('termsOffcanvas')).show();

      const userStore = useUserStore()
      this.session = userStore.sessionInfo
      this.accepted = this.session.collaborator.acceptedTerm

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.offcanvas_terms = null;
      this.resolvePromise(true)
    },

    doCancel() {
      this.offcanvas_terms = null;
      this.resolvePromise(false)
    },

    async doAccept() {
      NProgress.start()
      try {
        let response = await this.$userService.acceptTerms(this.collaborator)
        const userStore = useUserStore()
        userStore.termAccepted(response)

        this.$nextTick(() => {
          this.doConfirm()
        });
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    }
  }
}
</script>
