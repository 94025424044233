<template>
  <div class="" :key="examId" v-loader.opacity="loading">
    <div class="sub-header-box">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
        <IconArrowBack />
        {{ $t('goBack') }}
      </button>

      <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/exam/" data-test="btnRouter">
              {{ $t('home') }}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page" v-if="exam" @click="toPatient">
            <span>{{ patientName }}</span>
            <!-- {{ patient.fullName }} -->
          </li>
        </ol>
      </nav>
    </div>

    <div class="title-box">
      <div class="flex-start">
        <div class="">
          <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
            <IconArrowBack />
          </button>

          <div class="" v-if="exam">
            <p class="date">{{ `${$t('user.exam')} ${formatDate(exam.createdAt)}` }}</p>
            <h2>{{ patientName }}</h2>
          </div>
        </div>

        <div class="">
          <button class="btn btn-outline-secondary" v-if="!isSpecialist && !isJokerPatient" type="button" name="button"
            data-test="btnPatientShare" @click="shareInformation">
            <IconShare />
            <span>{{ $t('externalPatient.shareSimple') }}</span>
          </button>

          <button class="btn btn-outline-secondary" v-if="!isSpecialist && !isJokerPatient" type="button" name="button"
            :data-title="$t('tooltips.download')" data-test="`btnExamHasImage`" @click="doDownload">
            <IconDownload />
            <span>{{ $t('tooltips.download') }}</span>
          </button>

          <button class="btn btn-outline-secondary" v-if="!isSpecialist" type="button" name="button"
            data-test="btnPatientRemove" @click="confirmRemoval">
            <IconTrash />
          </button>
        </div>
      </div>

      <div id="aiBox"></div>
      <!-- <div>
        <button class="btn btn-outline-danger" v-if="!isSpecialist" type="button" name="button" data-test="btnRemove" @click="confirmRemoval">
          <IconTrash/>
          <span>{{ $t("remove") }}</span>
        </button>
      </div> -->
    </div>
    <BaseTab :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-images>
        {{ $t("exam.images") }}
      </template>
      <template v-slot:images>
        <ExamImageTab v-if="tabActive === 'images' && exam" :exam_id="exam.id" :patient="patient"
          :alert_msg="alert ? alert : null"></ExamImageTab>
      </template>

      <template v-slot:tab-head-data>
        {{ $t("exam.information") }}
      </template>
      <template v-slot:data>
        <ExamInfoTab v-if="tabActive === 'data' && exam" :exam="exam" :patient="patient"
          :alert_msg="alert ? alert : null"></ExamInfoTab>
      </template>

      <template v-slot:tab-head-report>
        {{ $t("exam.report") }}
      </template>
      <template v-slot:report>
        <ExamReportTab v-if="tabActive === 'report' && exam" :patient="patient" :exam_id="exam.id"></ExamReportTab>
      </template>
    </BaseTab>

    <!-- <div class="action-bar">
      <div id="examActionBar"></div>
    </div> -->

    <teleport to="body">
      <BaseGenericOffcanvas :offcanvas_id="'offcanvasExamDeleteGeneric'" ref="offcanvasGeneric" />
      <BasePatientAccessInfoOffcanvas ref="offcanvasPatientAccessInfo" />
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.breadcrumb-item {
  text-transform: capitalize;
}

.sub-header-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 15px 20px;

  ol {
    margin: 0;
  }
}

.title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding: 10px 25px;
  // margin:                          2.3rem 0 1.7rem;

  >div {
    width: auto !important;
  }

  .flex-start {
    gap: 20px;

    >div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }
  }

  .btn-back,
  .btn {
    margin: 0;
  }

  h2,
  .date {
    color:                         var(--Brand-Primary);
    font-weight:                   400;
    line-height:                   normal;
    text-overflow:                 ellipsis;
    overflow:                      hidden;
    margin:                        0;
  }

  .date {
    font-size:                     var(--font-size-12);
    padding-bottom:                5px;
  }

  h2 {
    font-size:                     var(--font-size-22);
  }

  .btn-outline-secondary {
    font-size: var(--font-size-12);
    height: 40px;
    margin: 0;
    padding: 11.5px 15px 11.5px 10px;

    svg {
      margin-right: 10px;
    }
  }

  .flex-start>div:last-child .btn-outline-secondary:last-child {
    padding: 11.5px 15px;

    svg {
      margin: 0;

      * {
        stroke: var(--Brand-Primary);
      }
    }
  }

  .btn-back {
    display: none;
  }
}

.btn-outline-danger {
  @include border_radius(99px);
  color: var(--Complementary-Orange, #D7541C);
  font-size: var(--font-size-14);
  font-weight: 400;
  letter-spacing: 3.64px;
  text-transform: uppercase;
  padding: 10px 15px;
  margin: 0 !important;
  border: 1px solid var(--Complementary-Orange, #D7541C);
  background: #FFEBE8;

  svg {
    margin-right: 10px;
  }
}

@media (max-width: 991px) {

  .sub-header-box,
  .title-box .btn-outline-secondary span {
    display: none;
  }

  .title-box {
    padding: 10px;

    h2 {
      font-size:                   var(--font-size-18);
      white-space:                 nowrap;
    }

    .flex-start {
      >div {
        gap: 15px;
      }
    }

    // .flex-start > div:last-child .btn-outline-secondary:last-child {
    .btn-back {
      display: flex;
    }

    .btn-outline-secondary {
      padding: 11.5px 15px;

      svg {
        margin: 0;
      }
    }

    .btn-back,
    .btn-outline-secondary {
      height: 35px;
      margin: 0 !important;
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import ExamImageTab from './ExamImageTab.vue'
import ExamInfoTab from './ExamInfoTab.vue'
// import Report from '../../report/report.vue'
import ExamReportTab from './ExamReportTab.vue'

export default {
  name: 'exam-show',
  components: {
    ExamImageTab,
    ExamInfoTab,
    ExamReportTab
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    loading: null,
    msgError: null,
    user: null,
    tabActive: 'images',
    tabs: ['images', 'data', 'report'],
    deleteID: null,
    exam: null,
    patient: null,
    previous: null,
    isSpecialist: null,
    patientName: null,
    isJokerPatient: false,
  }),
  computed: {
    examId() {
      return this.$route.params.examID;
    },
    tab() {
      return this.$route.query.tab;
    },
  },
  created() {
    // watch param exam id coming from notifications to force reload data
    this.$watch( // TODO REFACTOR KISS
      () => this.$route.params.examID,
      (toParams, previousParams) => {
        if (toParams != previousParams && this.$route.name == 'exam-show')
          this.fetchData()
      }
    )
  },
  mounted: function () {
    this.$activeLink.value = 'exam'

    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.isSpecialist = this.session.isSpecialist

    this.fetchData()
    // this.fetchReportData()

    if (this.tab) {
      this.switchTab(this.tab)
    }

    window.addEventListener('popstate', function(event) {
        document.querySelector('.offcanvas-backdrop').remove()
    })

  },
  methods: {
    goBack() {
      if (this.$route.query.data) {
        let data = this.$utils.parseDataQuery(this.$route.query.data)
        this.previous = data.previous
      }

      if (this.previous == 'patient') {
        let data = this.$utils.dataQuery({
          patientName: this.patient.rawName
        })

        this.$router.push({ name: 'patient-show', params: { patientID: this.patient.id }, query: { tab: 'exams', data: data } })
      } else {
        let data = this.$route.query.data
        this.$router.push({ name: 'exam-list', query: { data: data } })
      }
    },
    switchTab(tab) {
      this.tabActive = tab;
      // this.$router.push({name: 'exam-show', params: {examID: this.examId, tab: tab}})
    },
    fetchData: async function () {
      this.loading = true
      NProgress.start()
      try {
        let params = {
          id: this.examId,
        }

        let response = await this.$examService.get(params);
        console.log('response fetch', response);
        this.exam = response.exam

        this.patient = response.patient

        this.isJokerPatient = this.patient.uuid == this.session.currentClinic.jokerPatientUuid
        this.patientName = this.isJokerPatient ? this.$i18n.t('noPatientSelected') : this.patient.fullName
        this.tabs = this.isJokerPatient ? ['images'] : this.tabs
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    toPatient() {
      let data = this.$utils.dataQuery({
        patientName: this.patient.rawName
      })

      if (!this.isJokerPatient) {
        this.$router.push({ name: 'patient-show', params: { patientID: this.patient.id }, query: { tab: 'exams', data: data } })
      }
    },
    activeAiClasses(exam) {
      console.log('this.$aiService.mcRASClasses(exam)', this.$aiService.mcRASClasses(exam));
      return this.$aiService.mcRASClasses(exam)
    },
    formatDate(date) {
      return moment(date).format(this.$i18n.t('dateFormat'))
    },
    async shareInformation() {
      const ok = await this.$refs.offcanvasPatientAccessInfo.show({
        patient: this.patient
      })
      if (ok) {
        NProgress.done(true)
      }
    },
    async confirmRemoval() {
      const ok = await this.$refs.offcanvasGeneric.show({
        title: this.$i18n.t('confirm'),
        text: this.$i18n.t('exam.confirmExamRemoval'),
        okButton: this.$i18n.t('remove'),
        okButtonClass: 'btn-danger',
        // cancelButton: this.$i18n.t('cancel'),
        // cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.doDelete()
      }
    },
    doDownload(){
      try {
          NProgress.start()
          this.$examService.download(this.exam.id)
      } catch (err) {
          this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
      NProgress.done(true)
    },
    doDelete: async function () {
      this.alert.message = null;

      let params = {
        id: this.exam.id,
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$examService.remove(params)
        this.$message.popup(this, 'general.removedSuccess', 'operationSuccess', 'success')

        // TODO ENH change behavior:
        // when comming from patient > exams, go back to patient/exams/list
        // when comming from home > exams, go back to exams
        // this.$router.push({name: 'exam-list'})
        this.goBack()
      } catch (err) {
        this.$message.popup(this, 'general.removedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.removedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
  },
  unmounted() {
    this.tabActive = 'images'
  },
}
</script>
