import eyercloud from '@/config/eyercloud'
import { useEyerwebStore } from '../store/eyerweb.js'
import { Manager } from 'socket.io-client'
import router from '../router/index.js'

// TODO: LOCAL TEST PURPOSE, TO BE REMOVED
import examDataResponse from '../assets/eyerweb-data/exam-data-response.js'
import patientDataResponse from '../assets/eyerweb-data/patient-data-response.js'
import EyerwebConstants from '@/assets/eyerweb-data/constants.js';
import isTestEnv from '../assets/eyerweb-data/env.js';
import { Subject } from './subject.js';
import utilService from './util-service.js';


const moment = require('moment-timezone');

const worker = new Worker('/worker.js')
let TAG = ''


class EyerwebService {

  constructor() {
    TAG = `[${this.constructor.name}]`
  }
  currentPeer = null
  socket = null
  userToSignal = ''
  // examList = undefined
  examListFiltered = undefined
  patientList = undefined
  examListSubject = new Subject()
  examSubject = new Subject()
  patientListSubject = new Subject()
  patientSubject = new Subject()
  connectionSubject = new Subject()
  downloadSubject = new Subject()
  imageSubject = new Subject()
  DATE_FORMAT = "DD/MM/YYYY" // why this [this.$i18n.t('dateFormat')] is not working ?
  destroyPeer = false
  sessionUuid = utilService.uuidv4()


  workerCallback = (event) => {
    if (event.data.type === EyerwebConstants.TYPE_DOWNLOAD)
      this.downloadFile(event.data.blob, event.data.fileName)
    else if (event.data.type === EyerwebConstants.TYPE_IMAGE) {
      this.imageSubject.next(event.data)
    }
    else if (event.data.type === EyerwebConstants.TYPE_JSON) {

      let receivedData = {}
      if (isTestEnv)
        receivedData = examDataResponse
      else {
        receivedData = event.data.json
      }
      switch (receivedData.action) {
        case EyerwebConstants.ACTION_EXAM_LIST:
          this.examListSubject.next({ result: receivedData.exams, totalLenght: receivedData.exams.length })
          break
        case EyerwebConstants.ACTION_EXAM_LIST_FILTERED:
          this.examListSubject.next({ result: receivedData.exams, totalLenght: receivedData.exams.length })
          break
        case EyerwebConstants.ACTION_PATIENT_LIST:
          this.patientListSubject.next({ result: receivedData.patients, totalLenght: receivedData.patients.length })
          break
        case EyerwebConstants.ACTION_PATIENT_EXAM_LIST:
          this.examSubject.next({ result: receivedData.exams, totalLenght: receivedData.exams.length })
          break
        case EyerwebConstants.ACTION_CONNECTED:
          if (!this.destroyPeer) {
            this.connectionEstablished = true
            const eyerwebStore = useEyerwebStore()
            eyerwebStore.sessionInfo.eyerwebStatus.connected = true
            
            this.connectionSubject.next({ event: EyerwebConstants.ACTION_CONNECTED })
          } else {
            this.disconnect()
          }
          break
        case EyerwebConstants.ACTION_PATIENT_DETAIL:
          this.patientSubject.next({ patient: receivedData.patient })
          break
        default:
          this.disconnect()
          break
      }
    }
  }

  async webSocketInitialization(qrCode) {
    worker.addEventListener('message', this.workerCallback)
    let wssUri = "wss://" + qrCode.host + ":" + EyerwebConstants.EYERWEB_SOCKET_PORT

    //const manager = new Manager({ host: qrCode.host, room: qrCode.uuid, port: EyerwebConstants.EYERWEB_SOCKET_PORT , transports:['websocket'] })
    const manager = new Manager(wssUri, { room: qrCode.uuid, transports: ['websocket'] })
    this.socket = manager.socket('/').connect()

    this.socket.on(EyerwebConstants.EVENT_SOCKET_CONNECTED, () => {
      this.connectionSubject.next({ event: EyerwebConstants.EVENT_SOCKET_CONNECTED })
    })

    this.socket.on(EyerwebConstants.EVENT_ERROR_CONNECT, err => this.handleErrors(err))
    this.socket.on(EyerwebConstants.EVENT_ERROR_FAILED, err => this.handleErrors(err))

    this.socket.on(EyerwebConstants.EVENT_QRCODE, async clinicInfo => this.handleQrCodeRead(clinicInfo))

    this.socket.on(EyerwebConstants.EVENT_ALL_USERS, (users) => {
      console.log(`${TAG} ${EyerwebConstants.EVENT_ALL_USERS}: ${JSON.stringify(users)}`)
      this.currentPeer = this.createPeer(users[0], this.socket.id)
    })

    this.socket.on(EyerwebConstants.EVENT_USER_JOINED, payload => {
      console.log(`${TAG} ${EyerwebConstants.EVENT_USER_JOINED}: ${JSON.stringify(payload)}`)
      this.userToSignal = payload.callerID
      this.currentPeer = this.addPeer(payload.signal, payload.callerID)
    })

    this.socket.on(EyerwebConstants.EVENT_RECEIVING_RETURN_SIGNAL, payload => {
      console.log(`${TAG} ${EyerwebConstants.EVENT_RECEIVING_RETURN_SIGNAL}: ${JSON.stringify(payload)}`)
      this.currentPeer.signal(payload.signal)
    })

    this.socket.emit(EyerwebConstants.EVENT_JOIN_ROOM, qrCode.uuid);
  }

  sendAction(action, args = undefined) {
    console.log(`${TAG} sendAction: ${action}`)
    this.currentPeer.send(JSON.stringify({ action: action, params: args }))
  }

  isConnected() {
    return this.connectionEstablished
  }

  getExam(examUuid) {
    try {
      if (examUuid)
        this.sendAction(EyerwebConstants.GET_EXAMDATA, { examUuid: examUuid })
    } catch (err) {
      console.log(`${TAG} Error: getExam: ${err}`)
      this.disconnect()
    }
  }

  /**
   * Once socket is connnected, it creates a SimplePeer connection.
   *
   * @param {*} userToSignal
   * @param {*} callerID
   * @returns peer instance
   */
  createPeer(userToSignal, callerID) {
    const peer = new window.SimplePeer({
      initiator: true,
      trickle: true, // minimize connection establishement in prod
      config: {
        iceServers: EyerwebConstants.EYERWEB_ICE_SERVERS
      }
    })
    this.userToSignal = userToSignal
    peer.on(EyerwebConstants.PEER_SIGNAL, signal => {
      this.socket.emit(EyerwebConstants.PEER_SENDING_SIGNAL, { userToSignal, callerID, signal });
    });

    peer.on(EyerwebConstants.PEER_DATA, this.handleData);

    peer.on(EyerwebConstants.PEER_ERROR, (err) => {
      console.log(`${TAG} Error: ${err}`)
      this.disconnect()
    })

    peer.on(EyerwebConstants.PEER_CLOSE, () => {
      //console.timeEnd('Peer disconnected')
      console.log("Peer closed")
    })
    return peer
  }

  /**
   * Once socket is connnected, it adds a SimplePeer connection to random room.
   *
   * @param {*} incomingSignal
   * @param {*} callerID
   * @returns peer instance
   */
  addPeer(incomingSignal, callerID) {
    const peer = new window.SimplePeer({
      initiator: false,
      trickle: true, // minimize connection establishement in prod
      config: {
        iceServers: EyerwebConstants.EYERWEB_ICE_SERVERS
      }
    });

    peer.on(EyerwebConstants.PEER_SIGNAL, signal => {
      this.socket.emit(EyerwebConstants.EVENT_RETURN_SIGNAL, { signal, callerID });
    });
    peer.on(EyerwebConstants.PEER_DATA, this.handleData);

    peer.on(EyerwebConstants.PEER_ERROR, (err) => {
      console.log(`${TAG} Error: ${err}`)
      this.disconnect()
    })

    peer.on(EyerwebConstants.PEER_CLOSE, () => {
      console.log(`${TAG} Peer closed`)
    })

    peer.signal(incomingSignal);
    return peer;
  }

  /**
   * Using worker to manage incomming data from RTP connection.
   *
   * @param {*} data
   * @returns
   */
  handleData(data) {
    if (data.toString().includes('finish')) {
      console.log(data.toString())
      worker.postMessage(data.toString())
      return
    }
    worker.postMessage(data);
  }

  disconnect() {
    this.unsubscribeAllSubjects()

    if (this.currentPeer && this.currentPeer.connected) {
      this.currentPeer.destroy()
    }

    if (this.socket) {
      this.socket.emit(EyerwebConstants.EVENT_DISCONNECT)
    }
    this.connectionSubject.next({ event: EyerwebConstants.EVENT_ERROR_CONNECT })
    router.push({ name: 'eyerweb' })

  }
  handleErrors(error) {
    this.connectionSubject.next({ event: EyerwebConstants.EVENT_SOCKET_ERROR })
  }

  unsubscribeAllSubjects() {
    worker.removeEventListener('message', this.workerCallback)
    this.connectionSubject.unsubscribe() //??
    this.downloadSubject.unsubscribe()
    this.examSubject.unsubscribe()
    this.patientListSubject.unsubscribe()
    this.patientSubject.unsubscribe()
    this.imageSubject.unsubscribe()
  }

  async getQrCode() {
    return {
      host: EyerwebConstants.EYERWEB_SOCKET_SERVER,
      uuid: this.sessionUuid
    }
  }

  /**
   *  Eyer endpoints implementations
   */
  downloadFile(blob, fileName) {
    try {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove()
      this.downloadSubject.next({ result: "completed" })
    } catch (err) {
      console.log(`${TAG} Error: downloadFile: ${err}`)
      this.downloadSubject.next({ result: "error" })
      this.disconnect()
    }
  }

  async listExams(params) {
    console.log(`Request: ${EyerwebConstants.GET_EXAM_LIST}`)
    if (isTestEnv == true)
      this.examListSubject.next({ result: examDataResponse, totalLenght: examDataResponse.length })
    else {
      try {
        this.sendAction(EyerwebConstants.GET_EXAM_LIST, params)
      } catch (err) {
        console.log(`${TAG} Error: listExams: ${err}`)
        this.disconnect()
      }
    }
  }

  async filteredListExam(params) {
    console.log(`Request: ${EyerwebConstants.GET_EXAM_LIST_FILTERED}`)

    if (params.startDate) {
      params.startDate = moment(params.startDate, this.DATE_FORMAT).utc(false).startOf('day').unix() * 1000 // TODO BEWARE, confirm
    }
    if (params.endDate) {
      params.endDate = moment(params.endDate, this.DATE_FORMAT).utc(false).endOf('day').unix() * 1000
    }

    if (isTestEnv == true)
      setTimeout(() => {
        this.examListSubject.next({ result: examDataResponse, totalLenght: examDataResponse.length })
      }, 2000)
    else {
      try {
        this.sendAction(EyerwebConstants.GET_EXAM_LIST_FILTERED, params)
      } catch (err) {
        console.log(`${TAG} Error: filteredListExam: ${err}`)
        this.disconnect()
      }
    }

  }

  listPatients(params) {
    console.log(`Request: ${EyerwebConstants.GET_PATIENT_LIST}`)

    if (isTestEnv == true) {
      setTimeout(() => {
        this.patientListSubject.next({ result: patientDataResponse, totalLenght: patientDataResponse.length })
      }, 2000)
    } else {
      try {
        this.sendAction(EyerwebConstants.GET_PATIENT_LIST, params)
      } catch (err) {
        console.log(`${TAG} Error: listPatients: ${err}`)
        this.disconnect()
      }
    }
  }

  listPatientsExams(params) {
    console.log(`Request: ${EyerwebConstants.GET_PATIENT_EXAM_LIST}`)

    if (isTestEnv == true) {
      setTimeout(() => {
        this.examSubject.next({ result: [examDataResponse[0], examDataResponse[1]], totalLenght: 2 })
      }, 2000)
    } else {
      try {
        this.sendAction(EyerwebConstants.GET_PATIENT_EXAM_LIST, params)
      } catch (err) {
        console.log('Error: listPatientsExams: ', err)
        this.disconnect()
      }
    }
  }

  getPatient(params) {
    console.log(`Request: ${EyerwebConstants.GET_PATIENT}`)

    if (isTestEnv == true) {
      console.log(`isTestEnv`)
      setTimeout(() => {
        this.patientSubject.next({ patient: patientDataResponse.patients[0] })
      }, 500)
    } else {
      try {
        this.sendAction(EyerwebConstants.GET_PATIENT, params)
      } catch (err) {
        console.log(`${TAG} Error: getPatients: ${err}`)
        this.disconnect()
      }
    }
  }

  downloadExamsFiles(params) {
    console.log(`Request: ${EyerwebConstants.DOWNLOAD_EXAM_DATA}`)

    if (isTestEnv)
      this.patientList = patientDataResponse
    else {
      try {
        this.sendAction(EyerwebConstants.DOWNLOAD_EXAM_DATA, params)
      } catch (err) {
        console.log(`${TAG} Error: downloadExamsFiles: ${err}`)
        this.disconnect()
      }
    }
  }

  fullBackup() {
    console.log('Requesting full backup')
    this.sendAction(EyerwebConstants.DOWNLOAD_EXAM_DATA)
  }

  async handleQrCodeRead(clinicInfo) {
    let params = {
      uuid: clinicInfo.uuid
    }

    try {
      let response = await eyercloud.post(`/api/v2/eyercloud/auth/login-sync`, params)

      if (response.data) {
        const eyerwebStore = useEyerwebStore()
        let session = {
          currentClinic: response.data.clinic,
          features: response.data.features,
          eyerwebStatus: {
            connected: false,
            sessionUuid: this.sessionUuid
          }
        }
        if (clinicInfo.examUuid)
          eyerwebStore.sessionInfo = session
      
        this.connectionSubject.next({ event: EyerwebConstants.EVENT_QRCODE, examUuid: clinicInfo.examUuid})
      }
    } catch (err) {
      this.destroyPeer = true
      this.disconnect()
    }
  }

}
const eyerwebService = new EyerwebService();
export default eyerwebService;
