import eyerCloud from '@/config/eyercloud'

class MutiraoService {

  async keepAlive(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/mutirao/keep-alive`)
    return response
  }

  async resetMutirao(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/mutirao/reset`, params)
    return response
  }

  async importMutirao(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/mutirao/import`, params)
    return response
  }

  async get(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/mutirao/get`, params)
    return response.data
  }

  async updateMutirao(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/mutirao/update`, params)
    return response
  }

  async savePreAnalysis(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/exam/pre-analysis/save`, params)
    return response
  }

  // async listen(params) {
  //   let response = await axios.post(`/api/v2/eyercloud/clinic/mutirao/listen`)
  //   return response
  // }

}
const mutiraoService = new MutiraoService();
export default mutiraoService;
