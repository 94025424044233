<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="accessInfoOffcanvas" data-bs-backdrop="static" aria-labelledby="accessInfoOffcanvasLabel">
    <div class="offcanvas-header">
      <button class="btn-back" id="btnClose" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close" @click="doCancel">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>

      <h5 class="offcanvas-title d-block" id="accessInfoOffcanvasLabel">{{ $t('externalPatient.shareInformation') }}</h5>
    </div>
    <div class="offcanvas-body">
      <p v-if="!isSent">
        {{ patient.email || patient.telephone2 ? $t('externalPatient.shareInformationSelect') : $t('externalPatient.shareInformationMessage') }}
      </p>

      <vee-form v-slot="{ handleSubmit }" as="div" v-if="!isSent">
        <form @submit.prevent.stop="handleSubmit(doUpdate)" @keydown.enter="$event.preventDefault()">
          <div class="form-floating" v-if="patient.email">
            <div class="form-check form-switch">
              <label class="form-check-label" for="emailCheck">
                {{ $t('createClinic.email') }}: {{ patient.email }}
              </label>
              <input class="form-check-input" type="checkbox" role="switch" id="emailCheck" v-model="isEmailChecked" data-test="checkboxEmail">
            </div>
          </div>

          <div class="form-floating" v-else>
            <vee-field name="email" v-model="newEmail" rules="email" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" ref="email" id="email" :placeholder="$t('createClinic.email')" data-test="inputEmail">
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="email">{{ $t('createClinic.email') }}</label>
          </div>

          <div class="form-floating" v-if="patient.telephone2">
            <div class="form-check form-switch">
              <label class="form-check-label" for="whatsappCheck">
                {{ $t('patient.telephone2') }}: {{ patient.telephone2 }}
              </label>
              <input class="form-check-input" type="checkbox" role="switch" id="whatsappCheck" v-model="isWhatsappChecked" data-test="checkboxWhatsapp">
            </div>
          </div>

          <div class="form-floating" v-else>
            <input type="text" class="form-control" ref="telephone2" id="telephone2" :placeholder="$t('patient.telephone2')" v-model="newTelephone2" data-test="inputPhone">
            <label for="telephone2">{{ $t('patient.telephone2') }}</label>
          </div>

          <button class="btn btn-outline-secondary"
                  type="submit"
                  name="button"
                  data-test="btnSave"
                  v-if="!patient.email || !patient.telephone2">
            {{ $t('save') }}
          </button>
        </form>
      </vee-form>

      <button class="btn btn-success"
              :class="isEmailChecked || isWhatsappChecked ? '' : 'disabled'"
              type="button"
              name="button"
               v-if="!isSent"
              @click="doShare">
        {{ $t('externalPatient.shareSimple') }}
      </button>

     <p v-if="!isSent">
       {{ $t('externalPatient.patientData') }}
     </p>

     <div class="sent-box" v-else>
       <IconLetter/>
       <h1>{{ $t('externalPatient.successSent') }}</h1>
       <p class="text-center">{{ $t('externalPatient.successSentMessage') }}</p>
       <p>{{ $t('externalPatient.successSentInfo') }}</p>
     </div>

      <div class="clinic-box" id="contentPrint">
        <div class="clinic-header" v-if="clinic">
          <img :src="clinicLogo" alt="" width="180">
          <div class="clinic-header-content">
            <p>
              <strong>{{ clinic.name }}</strong> -
              <span>{{ clinic.address }}</span>
              <span v-show="clinic.addressNumber">, {{ clinic.addressNumber }}</span>
            </p>
            <p>
              <span vif="clinic.district">{{ clinic.district }} - </span>{{ `${clinic.city}/${clinic.state}` }} - {{ clinic.telephone1 ? clinic.telephone1 : clinic.telephone2 }}
            </p>
          </div>
        </div>

        <div class="access-info-box">
          <p>
            <strong>{{ $t('patient.patient') }}:</strong>
            {{patient.fullName}}
          </p>
          <p>
            <strong>{{ $t('externalPatient.accessCode') }}:</strong>
            {{user.username}}
          </p>
          <p v-if="user.accessCode">
            <strong>{{ $t('externalPatient.accessPassword') }}:</strong>
            {{user.accessCode}}
          </p>
          <p>
            <strong>{{ $t('login.access') }}:</strong>
            {{accessURL}}
          </p>
        </div>
      </div>

      <button type="button" class="btn btn-outline-secondary" v-print="printData" data-test="btnPrint">{{ $t('externalPatient.print') }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  // width:                                510px !important;
  // padding:                              40px;

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    display: flex;
    flex-direction: column;
    gap: 26px;
    p {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      // margin-bottom:                    26px;
      margin:                           0;
      strong {
        // font-family:                    'Courier new';
      }
    }

    .btn {
      font-size:                        var(--font-size-14);
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   4.2px;
      text-align:                       center;
      text-transform:                   uppercase;
      width:                            100%;
      margin: 0;
      padding: 15px 20px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .btn {
        padding: 10px 20px;
      }
    }

    .btn-outline-secondary {
      background: unset;
      border: 2px solid var(--Brand-Primary, #1B294A);
    }
  }
}

.sent-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  gap: 19px;
  padding-top: 2rem;

  h1 {
    color: var(--Brand-Primary, #1B294A);
    font-size: 26px;
    font-weight: 300;
    line-height: normal;
  }
}

.clinic-box {
  @include border_radius(10px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding:                          20px;
  background: var(--Shades-20, #F0F0F0);
}

.clinic-header {
  display: flex;
  flex-direction: row;
  justify-self: flex-start;
  align-items: center;
  gap: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--Shades-30, #D8D8D8);
  img {
    width:                          100px;
    display:                        inline-block;
  }
  .clinic-header-content {
    width:                          calc(100% - 185px);
    display:                        inline-block;
    // padding-left:                   20px;
    vertical-align:                 top;
    width: 100%;

    p {
      color: var(--Brand-Primary, #1B294A);
      font-size: var(--font-size-14);
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      margin:                       0;
    }
  }

  .access-info-box {
    p {
      color: var(--Brand-Primary, #1B294A);
      font-size: var(--font-size-14);
      font-weight: 300;
      line-height: 192%;
    }
  }
}

@media not print {
  @media (max-width: 650px) {
    .clinic-header {
      img,
      .clinic-header-content {
        display:                    block;
      }
      img {
        margin:                     0 auto;
      }
      .clinic-header-content {
        text-align:                 center;
        width:                      100%;
        padding:                    0;
        margin-top:                 10px;
        .content-data {
          float:                    unset;
          p {
            margin:                 0 10px 0 0;
          }
        }
      }
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'PatientAccessInfoOffcanvas',
  // props: ['patient'],
  data: () => ({
    printData: {
      id: 'contentPrint',
      popTitle: '',
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    patient: {},
    clinic: null,
    clinicLogo: null,
    date: null,
    time: null,
    user: {},
    accessURL: "",
    alert: {},
    whatsAppText: null,
    countryDdi: null,
    showFields: false,
    newEmail: null,
    newTelephone2: null,
    isEmailChecked: false,
    isWhatsappChecked: false,
    isSent: false,
    offcanvas_access_patient: null,
  }),
  async mounted() {
  },
  methods: {
    show(options = {}) {
      if (options.patient)
        this.patient = options.patient

      this.offcanvas_access_patient = new bootstrap.Offcanvas(document.getElementById('accessInfoOffcanvas')).show();

      const userStore = useUserStore()
      this.session = userStore.sessionInfo

      const now = moment(new Date())
      this.date = now.format(this.$i18n.t('dateFormat'))
      this.time = now.format(this.$i18n.t('timeFormat'))

      this.fetchData()

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.offcanvas_access_patient = null;
      this.resolvePromise(true)
    },

    doCancel() {
      this.isSent = false
      this.offcanvas_access_patient = null;
      this.resolvePromise(false)
    },

    async fetchData() {
      NProgress.start()
      try {
        let response = await this.$patientService.getAccessInfo(this.patient.id)
        this.clinic = response.clinic
        this.clinicLogo = response.clinicLogo

        const accessData = response.accessData ? response.accessData : {}
        this.user = accessData.user
        this.accessURL = `${window.location.host}/acesso`

        this.checkShareEnabled()

        this.countryDdi = this.$utils.getCountryDdi(this.clinic.country).substring(1)
        let messageText = `${this.$i18n.t('externalPatient.messageSubject')}\r\n\r\n${this.$i18n.t('user.clinic')}: ${this.clinic.name}\r\n${this.$i18n.t('patient.patient')}: ${this.patient.fullName}\r\n${this.$i18n.t('externalPatient.accessCode')}: ${this.user.username}\r\n${this.$i18n.t('externalPatient.accessPassword')}: ${this.user.accessCode}\r\n${this.$i18n.t('login.access')}: ${this.accessURL}?_data=${this.user.username}`.toString()
        this.whatsAppText = encodeURIComponent(messageText)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
    },
    doShare() {
      if (this.isEmailChecked)
        this.sendEmail()

      if (this.isWhatsappChecked)
        this.sendWhatsapp()
    },
    checkShareEnabled(){
      if (this.patient.email){
          this.isEmailChecked = true
      }
      if (!this.patient.email && this.patient.telephone2){
          this.isWhatsappChecked = true
      }
    },
    sendWhatsapp() {
      const anchor = document.createElement('a');
      anchor.href = `https://api.whatsapp.com/send?phone=${this.countryDdi}${this.patient.telephone2}&text=${this.whatsAppText}`;
      anchor.target = '_blank';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      this.isSent = true
    },
    async sendEmail() {
      NProgress.start()
      try {
        await this.$externalService.sendEmailPatientAccessData(this.patient.id)
        //this.$message.popup(this, 'operationSuccess', 'operationSuccess', 'success')

        this.isSent = true
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
      NProgress.done(true)
    },
    async doUpdate() {
      let patient = this.patient

      if (this.newEmail)
        patient.email = this.newEmail
      if (this.newTelephone2)
        patient.telephone2 = this.newTelephone2

      let params = {
        patient: patient
      }

      NProgress.start()
      try {
        let response = await this.$patientService.updatePatient(params)
        this.$message.popup(this, 'operationSuccess', 'operationSuccess', 'success')
        this.showFields = false
        this.checkShareEnabled()
      } catch (err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
      }
      NProgress.done(true)
    },
    hasFormChanged() {
      return (this.patient.email != this.newEmail) || (this.patient.telephone2 != this.newTelephone2)
    }
  }
}
</script>
