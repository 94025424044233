import ReportForm from './ReportForm.vue'
import ReportQuick from './ReportQuick.vue'

import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from '@/assets/dependencies/lightGallery/plugins/zoom';

const BASE_URL = process.env.VUE_APP_API_URL
const IS_PRODUCTION = process.env.NODE_ENV == 'production'

export default {
  name: 'report',
  props: ['report_id', 'exam_id'],
  components: {
    ReportForm,
    ReportQuick,
  },
  data: () => ({
    loading: false,
    alert: {},
    alertImages: {},
    templateOptions: [],
    columnOptions: [],

    selectedTemplate: null,

    examImages: [],
    patient: {},
    selectedImages: [],
    selectedImagesLoaded: [],
    dataPath: null,
    thumbDataPath: null,
    exam: {},

    quickReportEntries: [],
    loadedDiagnosisEntries: {
      right: [],
      left: []
    },
    diagnosisOptions: [],

    customTemplate: null,
    currentReport: {
      isQuickReport: true,
      fullWidthPanoramic: false,
      observation: ''
    },
    lGalleryDiagnosisSettings: {
      showZoomInOutIcons: true,
      actualSize: false,
      speed: 300,
      licenseKey: 'CE440F6C-34CE4C2A-86065648-48895C83',
      plugins: [lgAutoplay, lgFullscreen, lgThumbnail, lgZoom],
    },
    lightGallery: null,
    isLoaded: false,
    showSelectedDiagnosisBox: false,
    tabActive: 'quickReport',
    quickReportObservation: '',
    completeReportObservation: ''
  }),
  watch: {
    examImages(newVal, oldVal) {
      this.$nextTick(() => {
        // window.addEventListener("resize", this.getAndSetWidth);
        this.lightGallery.refresh();
      });
    },
  },
  async created() {
    this.templateOptions = [
      {id: null, name: this.$i18n.t('exam.selectTemplate'), content: ""},
    ]
    this.columnOptions = [
      {value: 1, label: this.$i18n.t('exam.oneColumn')},
      {value: 2, label: this.$i18n.t('exam.twoColumn')},
      {value: 3, label: this.$i18n.t('exam.threeColumn')},
      {value: 4, label: this.$i18n.t('exam.fourColumn')}
    ]
    // window.addEventListener("resize", this.getAndSetWidth);
  },
  async mounted() {

    if (this.report_id)
      await this.loadReport(this.report_id)

    await this.fetchData();
    this.initializeView();
    this.isLoaded = true

    $('#selectedDiagnosis').innerHTML = '' // avoid duplicated CID's

    this.$nextTick(() => {
      if (this.selectedImagesLoaded.length > 0)
        this.loadImages()
    })
  },
  methods: {
    onInit(detail) {
      this.lightGallery = detail.instance;
    },
    loadImages() {
      this.selectedImagesLoaded.forEach(o => {
        document.getElementById(o).click();
      })
    },
    async loadReport(reportID) {
      this.selectedImagesLoaded = []
      this.loading = true
      NProgress.start()
      try {
        let params = {id: reportID}
        let response = await this.$reportService.getReport(params)
        this.currentReport = response.report
        this.patient = response.patient
        this.fullWidthPanoramic = this.currentReport.fullWidthPanoramic

        if (this.currentReport.images.length > 0)
          this.selectedImagesLoaded = this.currentReport.images.split(',')

        this.setCustomTemplate(this.currentReport.customTemplate);

        this.$nextTick(() => {
          if (this.currentReport.isQuickReport) {
            this.tabActive = 'quickReport'
            // this.quickReportObservation = this.currentReport.observation
            this.currentReport.patientDiagnosis.forEach((o) => {
              this.loadedDiagnosisEntries[o.laterality].push(o.key)
            });
          } else {
            this.tabActive = 'completeReport'
            // this.completeReportObservation = this.currentReport.observation
            this.currentReport.diagnosisReferences.forEach(o => {
              this.addDiagnosisEntry(o.type.uuid, `${o.type.key} - ${o.type.description}`)
            });
          }
        })
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger');
      }
      NProgress.done(true)
      this.loading = false
    },
    getSelectedDiagnosis() {
      let result = [];
      $("#selectedDiagnosis span.tag2").each(function() {
        result.push($(this).data('value'));
      });

      return result
    },
    addDiagnosisEntry(id, text) {
      this.showSelectedDiagnosisBox = true

      let span = `<span id="tag_${id}" data-value="${id}" class="tag2 alert" role="tag">
                    <span>${text}</span>
                    <a href="#" data-target="#tag_${id}" data-bs-dismiss="alert">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <circle cx="10.5" cy="10" r="9.66667" fill="#265D6E" stroke="#265D6E" stroke-width="0.666667"/>
                        <path d="M14.4994 14.3334L6.5 6.33398" stroke="#A5EDD5" stroke-width="1.33333"/>
                        <path d="M14.4994 6.33356L6.5 14.333" stroke="#A5EDD5" stroke-width="1.33333"/>
                      </svg>
                    </a>
                  </span>`;
      this.$nextTick(_ => {
        $('#selectedDiagnosis').append(span);
        $('#diagnosis_select').val('').trigger("change");
      })
    },
    async fetchData() {
      let params = {examID: this.exam_id}

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$reportService.reportViewData(params)

        // this.examImages = response.images
        this.examImages = response.images.filter(o => !o.type.endsWith('VIDEO'))

        this.thumbDataPath = `${response.thumbDataPath}`
        this.dataPath = `${response.dataPath}`

        if (!IS_PRODUCTION) {
          this.thumbDataPath = `http://localhost:1337${response.thumbDataPath}`
          this.dataPath = `http://localhost:1337${response.dataPath}`
        }

        response.templates.forEach(o => {
          this.templateOptions.push(o)
        })

        if (response.images.length == 0) {
          this.$message.popup(this, 'request_empty', 'operationWarning', 'warn')
        }
      } catch(err) {
        console.log('error: ', err)
        //this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger');
      }
      NProgress.done(true)
      this.loading = false
    },
    async doCreate() {
      let newReport = Object.assign({}, this.currentReport)
      newReport['exam'] = this.exam_id
      newReport['selectedImages'] = this.selectedImages.filter(item => item !== null)
      newReport['customTemplate'] = this.customTemplate

      // if (this.currentReport.isQuickReport) {
      if (this.tabActive == 'quickReport') {
        newReport.isQuickReport = true
        // this.currentReport.observation = this.quickReportObservation
        newReport.quickReportEntries = this.quickReportEntries;
      } else {
        newReport.isQuickReport = false
        // this.currentReport.observation = this.completeReportObservation
        newReport.selectedDiagnosis = this.getSelectedDiagnosis()
      }

      // edition
      if (this.currentReport.id) {
        newReport.id = this.currentReport.id;
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$reportService.saveReport(newReport)
        console.log('response', response)
        if (this.currentReport.id) {
          this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
        } else {
          this.$message.popup(this, 'general.createdSuccess', 'operationSuccess', 'success')

          this.loadReport(response.result.id)

          this.$nextTick(() => {
            this.fetchData()
          })
        }
      } catch (err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger');
      }
      NProgress.done(true)
      this.loading = false
    },
    goBack() {
      let data
      if (this.$route.query.data) {
        data = this.$route.query.data
      }

      this.$router.push({name: 'exam-show', params: {examID: this.exam_id}, query: {tab: 'report', data: data}})
    },
    doCancel() {
      this.$emit('cancel')
    },
    setQuickReportData(data) {
      this.quickReportEntries = data.selectedEntries;
      // this.currentReport.observation = data.observation
      // this.currentReport.observation = data.observation;
    },
    switchTemplate() {
      if (this.selectedTemplate == null) {
        this.customTemplate = ""
        this.$refs.reportForm.reloadEditorContent(this.customTemplate)
        return
      }

      for (let t of this.templateOptions) {
        if (t.id == this.selectedTemplate) {
          this.customTemplate = t.content
          break
        }
      }
      this.$refs.reportForm.reloadEditorContent(this.customTemplate)
    },
    setCustomTemplate(value) {
      this.customTemplate = value;
    },
    // doCollapse() {
    //   if (window.screen.width > 767)
    //     $('#btnCollapseMenu').click()
    //     // this.$root.$emit('collapsemenu')
    // },
    checkAll(type) {
      let checks = document.querySelectorAll('.thumbnail-box .checkbox .base-rounded-checkbox');

      // if (type === 'check') {
        for (let check of checks) {
          if (!check.classList.contains('checked')) {
            check.click();
            this.selectedImages.push(check.getAttribute('value'));
          }
        }
      // } else {
      //   for (let check of checks) {
      //     check.click();
      //   }
      //   this.selectedImages = new Array();
      // }
		},
    uncheckAll() {
      let checks = document.querySelectorAll('.thumbnail-box .checkbox .base-rounded-checkbox.checked')

      for (let check of checks) {
        check.click();
      }
      this.selectedImages = new Array();
    },
    doCheck: async function(value) {
      let hasImg = _.find(this.selectedImages, function(o) { return o == value.uuid; });

      if (hasImg) {
        this.selectedImages = _.remove(this.selectedImages, function(n) {
          // document.getElementById(value.uuid).classList.remove('checked')
          return n != value.uuid;
        });
      } else {
        // document.getElementById(value.uuid).classList.add('checked')
        this.selectedImages.push(value.uuid);
      }
    },
    extraCustomization() {
      let self = this

      $(".lg-download").on("click", function (event) {
        event.preventDefault();
        let examDataUUID = this.href.split('/').pop()
        let item = { uuid: examDataUUID }
        self.doDownload(item)
      })

      $('.lg-fullscreen.lg-icon').click();
    },
    async doDownload(item, isVideo) {
      this.loading = true
      NProgress.start()
      try {
        let params = {}
        if (item.id)
          params['id'] = item.id

        if (item.uuid)
          params['uuid'] = item.uuid

        let response = await this.$examDataService.download(params)

        fetch(response.downloadURL)
          .then(res => res.blob())
          .then(blob => {
            let objectURL = URL.createObjectURL(blob);
            let el = document.createElement('a');
            el.href = objectURL;
            el.download = isVideo ? `${item.uuid}.mp4` : `${item.uuid}.jpeg`;
            el.click();
            URL.revokeObjectURL(objectURL);
        });
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },
    doDownloadPdf() {
      let params = {
        id: this.currentReport.id
      }

      // try {
        this.$reportService.generateReport(params);
      // } catch (err) {
      //   this.$message.popup(this, 'request_error', 'operationFail', 'error')
      // }
    },
    getAndSetWidth() {
      let element = document.querySelectorAll('.diagnosis-gallery .thumbnail-box');
      if (element.length) {
        let cw = element[0].offsetWidth;
        element.forEach(o => {
          o.style.height = cw + 'px';
        });
      }
    },
    removeLoading(event) {
      let parentNode = event.target.parentElement.classList;
      let currentlyNode = event.target.classList;

      this.$nextTick(() => {
        currentlyNode.add('loaded')
        parentNode.remove('loading-eye')
        // this.getAndSetWidth();
      });
    },
    initializeView() {
      let vm = this;
      $('#diagnosis_select').select2({
        "language": {
          errorLoading: () => {
            return this.$i18n.t('request_error');
          },
          inputTooShort: () => {
            return this.$i18n.t('cid.inputTooShort');
          },
          noResults: () => {
            return this.$i18n.t('request_empty');
          },
          searching: () => {
            return this.$i18n.t('cid.searching');
          },
        },
        minimumInputLength: 1,
        ajax: {
          url: BASE_URL + '/api/v2/eyercloud/report/cid10-search',
          xhrFields: {
            withCredentials: true
          },
          data: function (params) {
            if (!params.term) {
              return;
            }

            let query = {
              search: params.term
            }
            return query;
          },
        }
      });

      $('#diagnosis_select').on('select2:select', function (e) {
          let data = e.params.data;
          let selectedValues = vm.getSelectedDiagnosis();

          if (selectedValues.indexOf(data.id) > -1) {
            $('#diagnosis_select').val('').trigger("change");
            return;
          }

          vm.addDiagnosisEntry(data.id, data.text);
      });
    },
    formatDate(date) {
      return moment(date).format(this.$i18n.t('dateFormat'))
    },
  },
  beforeUnmount() {
    // window.removeEventListener("resize", this.getAndSetWidth);
    // this.doCollapse();
  }
}
