import moment from 'moment'
import { useUserStore } from '@/store/user'
import CountryCodeList from '@/config/country-codes.json'
import passwordService from './password-service.js';

class UtilService {
  /**
  *
  * @param {*} metadata
  * @param laterality must be 'od' or 'os' (lowercase)
  * @returns
  */
  alterationScoreByEye(metadata, laterality) {
    if (!metadata || !laterality)
    return;

    let fullScore = alterationScore(metadata)

    return {
      value: fullScore[laterality],
      text: fullScore[`${laterality}Text`]
    }
  }

  b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob( str )));
  }

  utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  dataQuery(json) {
    return this.utf8_to_b64(JSON.stringify(json))
  }

  parseDataQuery(queryData) {
    try {
      return JSON.parse(this.b64_to_utf8(queryData))
    } catch(err) {
      console.error("Error parsing URL query...")
      return {}
    }
  }

  formatDate(self, date) {
    if (date)
      return moment(date).format(self.$i18n.t('dateFormat'))

    return;
  }

  goToCustomRoute(target) {
    window.location =`/ec2/?_target-route=${target}`
  }

  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  raw(stuff) {
    return JSON.parse(JSON.stringify(stuff))
  }

  getCurrentLanguage() {
    let language = window.navigator.userLanguage || window.navigator.language
    if (language == 'pt' || language.startsWith('pt-'))
      return 'pt-br'

    if (language.startsWith('en-'))
      return 'en'

    if (language.startsWith('es-'))
      return 'es'

    return language
  }

  countryCodeList(locale) {
    let _locale = locale
    if (_locale.includes('-'))
      _locale = _locale.split('-')[0]

    switch(_locale) {
      case "pt":
        return ["AF","ZA","AL","DE","AD","AO","AI","AQ","AG","SA","DZ","AR","AM","AW","AU","AT","AZ","BS","BH","BD","BB","BE","BZ","BJ","BM","BY","BO","BQ","BA","BW","BR","BN","BG","BF","BI","BT","CV","CM","KH","CA","KZ","TD","CL","CN","CY","CO","KM","KR","KP","CI","CR","HR","CU","CW","DK","DJ","DM","EG","SV","AE","EC","ER","SK","SI","ES","US","EE","ET","FJ","PH","FI","FR","GA","GM","GH","GE","GI","GR","GD","GL","GP","GU","GT","GG","GF","GY","GQ","GW","GN","HT","HN","HK","HU","YE","BV","CX","IM","HM","NF","AX","KY","CC","CK","FO","GS","MH","UM","SB","VI","VG","IN","ID","IR","IQ","IE","IS","IL","IT","JM","JP","JE","JO","KI","KW","LA","LS","LV","LB","LR","LY","LI","LT","LU","MO","MG","MY","MW","MV","ML","MT","FK","MP","MA","MQ","MU","MR","YT","MX","FM","MZ","MD","MC","MN","ME","MS","MM","NA","NR","NP","NI","NE","NG","NU","NO","NC","NZ","OM","NL","PW","PS","PA","PG","PK","PY","PE","PN","PF","PL","PR","PT","QA","KE","KG","GB","CF","CZ","MK","CD","CG","DO","RE","RO","RW","RU","EH","PM","WS","AS","SM","SH","LC","BL","KN","MF","SX","ST","VC","SN","SL","RS","SC","SG","SY","SO","LK","SZ","SS","SD","SE","CH","SR","SJ","TH","TW","TJ","TZ","TF","IO","TL","TG","TO","TK","TT","TN","TC","TM","TR","TV","UA","UG","UY","UZ","VU","VA","VE","VN","WF","ZM","ZW"]
      case "es":
        return ["AF","AL","DE","AD","AO","AI","AQ","AG","SA","DZ","AR","AM","AW","AU","AT","AZ","BS","BD","BB","BH","BE","BZ","BJ","BM","BY","BO","BQ","BA","BW","BR","BN","BG","BF","BI","BT","CV","KH","CM","CA","QA","TD","CL","CN","CY","CO","KM","CG","KR","KP","CR","CI","HR","CU","CW","DK","DM","EC","EG","SV","AE","ER","SK","SI","ES","US","EE","ET","PH","FI","FJ","FR","GA","GM","GS","GE","GH","GI","GD","GR","GL","GP","GU","GT","GF","GG","GQ","GW","GN","GY","HT","HN","HK","HU","IN","ID","IQ","IR","IE","BV","IM","CX","HM","NF","IS","AX","KY","CC","CK","UM","FO","FK","MP","MH","SB","TC","VG","VI","IL","IT","JM","JP","JE","JO","KZ","KE","KG","KI","KW","LA","LS","LV","LB","LR","LY","LI","LT","LU","MO","MK","MG","MY","MW","MV","ML","MT","MA","MQ","MU","MR","YT","MX","FM","MD","MC","MN","ME","MS","MZ","MM","NA","NR","NP","NI","NE","NG","NU","NO","NC","NZ","OM","NL","PK","PW","PS","PA","PG","PY","PE","PN","PF","PL","PT","PR","GB","CF","CZ","CD","DO","RE","RW","RO","RU","EH","AS","WS","BL","KN","SM","MF","PM","VC","SH","LC","VA","ST","SN","RS","SC","SL","SG","SX","SY","SO","LK","SZ","ZA","SD","SS","SE","CH","SR","SJ","TH","TW","TZ","TJ","IO","TF","TL","TG","TK","TO","TT","TN","TM","TR","TV","UA","UG","UY","UZ","VU","VE","VN","WF","YE","DJ","ZM","ZW"]
      default: // # en
        return ["AF","AX","AL","DZ","AS","AD","AO","AI","AQ","AG","AR","AM","AW","AU","AT","AZ","BS","BH","BD","BB","BY","BE","BZ","BJ","BM","BT","BO","BQ","BA","BW","BV","BR","IO","BN","BG","BF","BI","CV","KH","CM","CA","KY","CF","TD","CL","CN","CX","CC","CO","KM","CG","CK","CR","CI","HR","CU","CW","CY","CZ","CD","DK","DJ","DM","DO","EC","EG","SV","GQ","ER","EE","ET","FK","FO","FJ","FI","FR","GF","PF","TF","GA","GM","GE","DE","GH","GI","GR","GL","GD","GP","GU","GT","GG","GW","GN","GY","HT","HM","VA","HN","HK","HU","IS","IN","ID","IR","IQ","IE","IM","IL","IT","JM","JP","JE","JO","KZ","KE","KI","KP","KR","KW","KG","LA","LV","LB","LS","LR","LY","LI","LT","LU","MO","MK","MG","MW","MY","MV","ML","MT","MH","MQ","MR","MU","YT","MX","FM","MD","MC","MN","ME","MS","MA","MZ","MM","NA","NR","NP","NL","NC","NZ","NI","NE","NG","NU","NF","MP","NO","OM","PK","PW","PS","PA","PG","PY","PE","PH","PN","PL","PT","PR","QA","RE","RO","RU","RW","BL","SH","KN","LC","MF","PM","VC","WS","SM","ST","SA","SN","RS","SC","SL","SG","SX","SK","SI","SB","SO","ZA","GS","SS","ES","LK","SD","SR","SJ","SZ","SE","CH","SY","TW","TJ","TZ","TH","TL","TG","TK","TO","TT","TN","TR","TM","TC","TV","UG","UA","AE","GB","UM","US","UY","UZ","VU","VE","VN","VG","VI","WF","EH","YE","ZM","ZW"]
    }
  }

  isFeatureEnabled(feature) {
    const userStore = useUserStore()
    const session = userStore.sessionInfo
    const features = session.features
    return features.includes(feature)
  }

  datePickerLocale() {
    const userStore = useUserStore()
    const session = userStore.sessionInfo

    const locale = session.currentClinic ? session.currentClinic.locale : this.getCurrentLanguage()

    if (locale == 'pt-br') {
      return 'pt-BR'
    } else if (locale == 'es') {
      return 'es'
    } else {
      return 'en-US'
    }
  }

  getCountryDdi(locale) {
    let result

    CountryCodeList.forEach(o => {
      if (o.code == locale)
        result = o.dial_code
    })

    return result
  }

  async validatePassword(account){
    try{
      let response =  await passwordService.zxcvbnValidation({account: account})

      if (response) {
        console.log('zxcvbn Score: ', response.data.score)
        return response.data
      }
    } catch (err) {
      console.log('error: ', err)
    }
    return false
  }

  getCurrentLanguage() {
    let language = window.navigator.userLanguage || window.navigator.language
    if (language == 'pt' || language.startsWith('pt-')) {
      return 'pt-br'
    } else if (language.startsWith('en-')) {
      return 'en'
    } else if (language.startsWith('es-')) {
      return 'es'
    }

    console.log('language>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', language);
    return language
  }
  
  getIsPaidService(){
    let hasPaidService = false
    try{
        hasPaidService = this.isFeatureEnabled('paidService')
    } catch (error){
        console.log('error = ', error)  
    }
    return hasPaidService
  }

}

const utilService = new UtilService()
export default utilService
