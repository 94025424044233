<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" :id="offcanvas_id" data-bs-backdrop="static" :aria-labelledby="`${offcanvas_id}_label`">
    <div class="offcanvas-header">
      <button class="btn-back" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close" @click="doCancel">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" :id="`${offcanvas_id}_label`">{{ title }}</h5>
    </div>
    <div class="offcanvas-body">
      <div class="download-dates" v-show="!loadingDownload && !downloadReadyUrl">
        <div class="form-floating">
          <date-picker
            v-model="examDate"
            :locale="locale"
            value-type="date"
            :selectText="$t('exam.select')"
            :cancelText="$t('cancel')"
            :format="$t('dateFormatFns')"
            :inputFormat="$t('dateFormatFns')"
            :enableTimePicker="false"
            :disabled-date="disabledDate"
            data-test="datePickerModalDownload">
          </date-picker>
          <label for="">{{ $t('exam.selectDay') }}</label>
        </div>
        <div class="form-floating">
          <date-picker
            menuClassName="dp-time"
            v-model="startTime"
            :minute-step="30"
            timePicker
            :selectText="$t('exam.select')"
            :cancelText="$t('cancel')"
            placeholder="HH:mm"
            data-test="datePickerStartHourModalDownload">
          </date-picker>
          <label for="">{{ $t('exam.selectStartTime') }}</label>
        </div>
        <div class="form-floating">
          <date-picker
            menuClassName="dp-time"
            v-model="endTime"
            :minute-step="30"
            timePicker
            :selectText="$t('exam.select')"
            :cancelText="$t('cancel')"
            placeholder="HH:mm"
            data-test="datePickerEndHourModalDownload">
          </date-picker>
          <label for="">{{ $t('exam.selectEndTime') }}</label>
        </div>
      </div>

      <div class="text-center" v-show="loadingDownload && !downloadReadyUrl">
        <h3>{{ $t('exam.preparingFileForDownload') }}</h3>
        <img class="mb-3" src="@/assets/images/loading_points.gif" alt="">
        <div v-if="downloadInfo.examsCount">
          <p><strong>{{ $t('exam.examsCount') }}</strong> {{ downloadInfo.examsCount }}</p>
          <p><strong>{{ $t('exam.imagesCount') }}</strong> {{ downloadInfo.imageCount }}</p>
          <p><strong>{{ $t('exam.estimatedSize') }}</strong> {{ downloadInfo.payloadStimatedSize }} Mb</p>
        </div>
      </div>

      <div class="text-center" v-show="!loadingDownload && downloadReadyUrl">
        <h3>{{ $t('exam.fileReadyToDownload') }}</h3>
        <a class="btn btn-outline-secondary" :href="downloadReadyUrl" target="_blank" data-test="anchorDownloadExam">{{ $t('exam.clickHereToDownload') }}</a>
      </div>
    </div>
    <div class="offcanvas-footer" v-if="!loadingDownload && !downloadReadyUrl">
      <button type="button" class="btn" :class="okButtonClass" data-test="btnConfirm" @click="doConfirm">{{ okButton }}</button>
      <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="offcanvas" v-show="cancelButton" data-test="btnCancel" @click="doCancel">{{ cancelButton }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  // width:                                510px !important;
  // padding:                              40px;

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title,
  h3 {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  h3 {
    font-size:                          var(--font-size-18);
  }

  .offcanvas-body {
    p,
    li {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin-bottom:                    26px;
    }

    .download-dates {
      display:                          flex;
      flex-direction:                   column;
      gap:                              19px;
    }
  }

  .btn {
    font-size:                          var(--font-size-14);
    font-weight:                        700;
    line-height:                        normal;
    letter-spacing:                     4.2px;
    text-align:                         center;
    text-transform:                     uppercase;
    width:                              100%;
    padding:                            20px;

    &.btn-outline-secondary {
      padding:                          20px 18px;
    }
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn-success {
      color:                            var(--Shades-W, #FFF);
      // margin-top:                       26px;
    }
  }
}
</style>

<script>
import { ref } from 'vue'
import { useUserStore } from '@/store/user'
import { enUS, ptBR, es } from 'date-fns/locale'

export default {
  name: 'ExamDownloadOffcanvas',
  props: ['offcanvas_id'],
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    title: null,
    text: null,
    okButton: null,
    okButtonClass: null,
    cancelButton: null,
    cancelButtonClass: null,
    offcanvas_download: null,
    loadingDownload: false,
    downloadReadyUrl: null,
    downloadInfo: {
      examsCount: null,
      imageCount: null,
      payloadStimatedSize: null
    },
    examDate: new Date(),
    session: {
      collaborator: {},
      currentClinic: {}
    },
  }),
  setup() {
    let initialDate = new Date().setHours(8,0,0)
    let finishDate = new Date().setHours(20,0,0)
    const startTime = ref({
      hours: new Date(initialDate).getHours(),
      minutes: new Date(initialDate).getMinutes()
    });

    const endTime = ref({
      hours: new Date(finishDate).getHours(),
      minutes: new Date(finishDate).getMinutes()
    });

    return {
      startTime, endTime
    }
  },
  computed: {
    locale() {
      return this.$utils.datePickerLocale()
    }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
  },
  methods: {
    show(options = {}) {
      if (options.title)
        this.title = options.title

      if (options.text)
        this.text = options.text

      if (options.okButton) {
        this.okButton = options.okButton
        this.okButtonClass = options.okButtonClass
      }
      if (options.cancelButton) {
        this.cancelButton = options.cancelButton
        this.cancelButtonClass = options.cancelButtonClass
      }

      this.offcanvas_download = new bootstrap.Offcanvas(document.getElementById(this.offcanvas_id)).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      // this.offcanvas_download = null;
      this.doDownload()
      this.resolvePromise(true)
    },

    doCancel() {
      this.loadingDownload = false
      this.downloadReadyUrl = null
      this.offcanvas_download = null;
      this.resolvePromise(false)
    },

    disabledDate(date) {
      const today = moment()
      return date > today
    },
    formattDate(date, time) {
      let dateTimeFormat = this.$i18n.t('dateTimeFormat')
      let dateFormat = this.$i18n.t('dateFormat')
      if (date && time) {
        let dateAndTime = moment(date).format(dateFormat) + ' ' + moment(time).format('LT')
        return moment(dateAndTime, dateTimeFormat).format(dateTimeFormat);
      }
    },
    doDownload: async function() {
      this.alert.message = null;

      let params = {
        'startTime': this.formattDate(this.examDate, this.startTime),
        'endTime': this.formattDate(this.examDate, this.endTime)
      };

      this.loadingDownload = true;
      NProgress.start()
      try {
        // alert('TODO não permitir outro clique enquanto a requisição estiver sendo processada...')
        let response = await this.$examService.downloadMultiple(params);
        console.log("DOWNLOAD MULTIPLE >>>", response);
        if (response.noExamsFound) {
          this.loadingDownload = false;
          this.$message.popup(this, 'exam.noExamsFoundForTheSelectedDay', 'operationFail', 'error')
          // this.alert = this.$message.alert(this, 'exam.noExamsFoundForTheSelectedDay', 'danger')
          NProgress.done(true)
          return
        }

        this.processDownload(response)
      } catch(err) {
        this.loadingDownload = false;
        console.log("OUCHHH", err)
        // TODO unexpected error in here?
        this.$message.popup(this, err.message, 'operationFail', 'error')
        // this.alert = this.$message.alert(this, err.message, 'danger')
      }
      NProgress.done(true)
    },
    processDownload: function(response) {
      console.log(">>>>>>>>>>>> DOWNLOAD ID", response.downloadID)
      const io = this.$io
      let payloadInfo = JSON.stringify(response.payloadInfo)

      // unlisten first
      io.socket.off('preparingDownloadExams')
      io.socket.off('downloadFinished')

      io.socket.get('/online/listenToDownloadExams?id=' + response.downloadID, function gotResponse(body, response) {
        console.log('device registration response > ', response);
        console.log('device registration body > ', body);
      });

      io.socket.on('preparingDownloadExams', data => {
        console.log("Preparing DOWNLOAD >> " + JSON.stringify(data));
      });

      io.socket.on('downloadFinished', data => {
        this.loadingDownload = false
        if (data.error) {
          console.log("ERROR", data.error)
          return
        }

        console.log("DOWNLOAD FINISHED", data)
        this.downloadReadyUrl = data.downloadLink
      });

      this.downloadInfo = {
        examsCount: response.payloadInfo.examsCount,
        imageCount: response.payloadInfo.imageCount,
        payloadStimatedSize: (response.payloadInfo.payloadStimatedSize / 1048576).toFixed(2)
      };
    }
  }
}
</script>
