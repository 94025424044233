<template>
    <div></div>
</template>
<script>
    module.exports = {
        name: 'proxy-router',
        mounted: function() {
            let target = this.$route.params.target;
            let params = this.$route.params.data

            try {
                if (params)
                    params = JSON.parse(params)
            } catch(err) {
                console.warn("An error occurred while processing the proxy-router params...")
            }

            console.log(`fire in the hole! target:${target}, params: ${Object.keys(params)}`)

            this.$router.push({name: target, params: params})
        }
    }
</script>