const patientDataResponse = {
        patients: [
            {
                "id": 644,
                "uuid": "34024ab9-bf7e-620f-972d-cb815cae9687",
                "firstName": "000003teste",
                "lastName": "Teste",
                "rawName": "000003teste teste",
                "birthday": "2020-03-10T00:00:00-03:00",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383587743,
                "localUpdatedAt": 1685383587743,
                "createdAt": 1583850752187,
                "updatedAt": 1583850756422,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 3966,
                "uuid": "ae831907-aad9-4371-2a1d-c76fbbf4f727",
                "firstName": "0013",
                "lastName": "Teste",
                "rawName": "0013 teste",
                "birthday": "",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"left\":{},\"right\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "(19)999877253",
                "email": "dimas.crocco@gmail.com",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383850669,
                "localUpdatedAt": 1685383850669,
                "createdAt": 1599820221954,
                "updatedAt": 1685136560710,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 567,
                "uuid": "4d17f099-a324-0de6-b225-d6ecdbf95674",
                "firstName": "017",
                "lastName": "Euler",
                "rawName": "017 euler",
                "birthday": "",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383579832,
                "localUpdatedAt": 1685383579832,
                "createdAt": 1580241467983,
                "updatedAt": 1580241476174,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 226,
                "uuid": "1dc0430b-0a35-1ce5-be2e-d56df98e4a43",
                "firstName": "019",
                "lastName": "Teste",
                "rawName": "019 teste",
                "birthday": "2019-09-19T00:00:00-03:00",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "11111111111",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383531383,
                "localUpdatedAt": 1685383531383,
                "createdAt": 1568900200194,
                "updatedAt": 1569449481055,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 1992,
                "uuid": "c1d32957-aabe-1e45-5dc1-1cd1a75d0014",
                "firstName": "019 teste",
                "lastName": "Teste",
                "rawName": "019 teste teste",
                "birthday": "2020-10-28T00:00:00+00:00",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"left\":{\"spherical\":\"\",\"cylindrical\":\"\",\"axis\":\"\"},\"right\":{\"spherical\":\"\",\"cylindrical\":\"\",\"axis\":\"\"}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{\"diabetes\":true,\"cholesterol\":true,\"glaucoma\":true}",
                "gender": "M",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383733294,
                "localUpdatedAt": 1685383733294,
                "createdAt": 1603903140132,
                "updatedAt": 1634755112753,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 368,
                "uuid": "918e23d5-c3a7-5420-395a-ce6274b360b3",
                "firstName": "049",
                "lastName": "Teste",
                "rawName": "049 teste",
                "birthday": "2019-11-19T00:00:00-02:00",
                "mrn": "",
                "document0": "",
                "document1": "123",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383548083,
                "localUpdatedAt": 1685383548083,
                "createdAt": 1574166007117,
                "updatedAt": 1574182050910,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 2093,
                "uuid": "fd69a230-7e19-2397-bf91-3ecc5c65b36b",
                "firstName": "066",
                "lastName": "Retorno ",
                "rawName": "066 retorno ",
                "birthday": "",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383739019,
                "localUpdatedAt": 1685383739019,
                "createdAt": 1638206803627,
                "updatedAt": 1638206808148,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 1571,
                "uuid": "830d9af5-a897-e6d3-5542-f3975cb30cd5",
                "firstName": "066",
                "lastName": "Teste",
                "rawName": "066 teste",
                "birthday": "2021-06-07T00:00:00-03:00",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383706964,
                "localUpdatedAt": 1685383706964,
                "createdAt": 1623098771948,
                "updatedAt": 1623179764209,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 1926,
                "uuid": "7e0b952c-6d89-bb33-c0c7-195db2674b8c",
                "firstName": "087",
                "lastName": "Assistência",
                "rawName": "087 assistencia",
                "birthday": "",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383729523,
                "localUpdatedAt": 1685383729523,
                "createdAt": 1632424274392,
                "updatedAt": 1632484728266,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 2232,
                "uuid": "b6ace363-db00-b13e-e9be-c6e5f600c978",
                "firstName": "087",
                "lastName": "Retorno",
                "rawName": "087 retorno",
                "birthday": "",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383747294,
                "localUpdatedAt": 1685383747294,
                "createdAt": 1642109307585,
                "updatedAt": 1642109310867,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 1292,
                "uuid": "978ce24d-296f-3c7a-0b78-9c0433721129",
                "firstName": "087Ass",
                "lastName": "Tencia",
                "rawName": "087ass tencia",
                "birthday": "2021-02-11T00:00:00-03:00",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383666655,
                "localUpdatedAt": 1685383666655,
                "createdAt": 1613064785460,
                "updatedAt": 1613744119759,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 2711,
                "uuid": "52e7e014-4999-d6a3-cf9f-5fb606195bf7",
                "firstName": "1",
                "lastName": "1",
                "rawName": "1 1",
                "birthday": "",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"left\":{\"spherical\":\"\",\"cylindrical\":\"\",\"axis\":\"\"},\"right\":{\"spherical\":\"\",\"cylindrical\":\"\",\"axis\":\"\"}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "M",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383774929,
                "localUpdatedAt": 1685383774929,
                "createdAt": 1607109388094,
                "updatedAt": 1655293914714,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 269,
                "uuid": "d189cfda-083f-a352-5f5d-21abf1daa85a",
                "firstName": "100",
                "lastName": "Teste",
                "rawName": "100 teste",
                "birthday": "1984-05-20T00:00:00+00:00",
                "mrn": "",
                "document0": "",
                "document1": "teste",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"left\":{\"spherical\":\"\",\"cylindrical\":\"\",\"axis\":\"\"},\"right\":{\"spherical\":\"\",\"cylindrical\":\"\",\"axis\":\"\"}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "M",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383534386,
                "localUpdatedAt": 1685383534386,
                "createdAt": 1566393340930,
                "updatedAt": 1571864685249,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 1623,
                "uuid": "6ded554f-d58c-64f5-3c8a-cf77d562f0a7",
                "firstName": "1000",
                "lastName": "Teste",
                "rawName": "1000 teste",
                "birthday": "2021-06-18T00:00:00-03:00",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383710712,
                "localUpdatedAt": 1685383710712,
                "createdAt": 1624013921801,
                "updatedAt": 1624477071738,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 1624,
                "uuid": "bf26d442-1456-ecc6-e3be-438345882d05",
                "firstName": "1001",
                "lastName": "Teste",
                "rawName": "1001 teste",
                "birthday": "2021-06-18T00:00:00-03:00",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383710797,
                "localUpdatedAt": 1685383710797,
                "createdAt": 1624013939667,
                "updatedAt": 1624478239656,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 1625,
                "uuid": "8e0a38e1-3b34-8c24-743e-9264fac58635",
                "firstName": "1002",
                "lastName": "Teste",
                "rawName": "1002 teste",
                "birthday": "2021-06-18T00:00:00-03:00",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383710873,
                "localUpdatedAt": 1685383710873,
                "createdAt": 1624013960360,
                "updatedAt": 1624479580431,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 1634,
                "uuid": "1ba2c520-455a-f7c3-2b5c-43b8076ccd94",
                "firstName": "1003",
                "lastName": "Teste",
                "rawName": "1003 teste",
                "birthday": "2021-06-18T00:00:00-03:00",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383711532,
                "localUpdatedAt": 1685383711532,
                "createdAt": 1624013981277,
                "updatedAt": 1624538782405,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 1626,
                "uuid": "88b2dbab-ffb2-8bf3-0dd2-1d3efa5c9ed8",
                "firstName": "1004",
                "lastName": "Teste",
                "rawName": "1004 teste",
                "birthday": "2021-06-18T00:00:00-03:00",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383710949,
                "localUpdatedAt": 1685383710949,
                "createdAt": 1624013995199,
                "updatedAt": 1624482011088,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 1627,
                "uuid": "08a4404a-2a7c-804e-e063-892430666598",
                "firstName": "1005",
                "lastName": "Teste",
                "rawName": "1005 teste",
                "birthday": "2021-06-18T00:00:00-03:00",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383711008,
                "localUpdatedAt": 1685383711008,
                "createdAt": 1624014009504,
                "updatedAt": 1624483934475,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            },
            {
                "id": 1628,
                "uuid": "da5fba00-9704-812d-48cd-75621890723b",
                "firstName": "1006",
                "lastName": "Teste",
                "rawName": "1006 teste",
                "birthday": "2021-06-18T00:00:00-03:00",
                "mrn": "",
                "document0": "",
                "document1": "",
                "document2": "",
                "document3": "",
                "document4": "",
                "document5": "",
                "mothersName": "",
                "observation": "",
                "diopter": "{\"right\":{},\"left\":{}}",
                "height": 0,
                "weight": 0,
                "anamnesis": "{}",
                "gender": "",
                "telephone1": "",
                "telephone2": "",
                "email": "",
                "address": "",
                "addressNumber": "",
                "district": "",
                "postalCode": "",
                "city": "",
                "state": "",
                "country": "",
                "localCreatedAt": 1685383711073,
                "localUpdatedAt": 1685383711073,
                "createdAt": 1624023130029,
                "updatedAt": 1624484637278,
                "sync": true,
                "syncTarget": "EYE001",
                "metadata": "",
                "toRemove": false
            }
        ],
        currentPage: 1,
        totalCount: 3992,
        totalPages: 200
}


export default patientDataResponse;