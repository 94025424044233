<template>
  <!-- <div class="external-home"> -->
  <div class="registration-page">
    <div class="container">
      <div class="clinic-box">
        <h1 v-if="session.clinic">
          {{ session.clinic.name }}
        </h1>

        <div class="">
          <p>
            powered by
          </p>
          <img class="logo" src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo white" width="52">
        </div>
      </div>
      <!-- <img src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo"> -->

      <div class="content ease-all">
        <router-view class="panel-content"></router-view>
      </div>

      <div class="powered-box">
        <p class="d-inline-block">POWERED BY</p>
        <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
          <img src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
        </a>
        <p class="d-inline-block">v {{ appVersion }}</p>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<style lang="scss" scoped>
.registration-page {
  height:                         100vh;
}

.clinic-box {
  display:                        flex;
  flex-wrap:                      wrap;
  flex-direction:                 column;
  justify-content:                flex-start;
  align-items:                    flex-start;
  width:                          auto;

  > div {
    display:                      flex;
  }

  h1 {
    font-size:                    28px;
    font-weight:                  500;
    line-height:                  normal;
    letter-spacing:               1.1px;
    white-space:                  nowrap;
    text-overflow:                ellipsis;
    overflow:                     hidden;
    background:                   linear-gradient(90deg, #1B294A 0.02%, #3CB391 99.98%);
    background-clip:              text;
    -webkit-background-clip:      text;
    -webkit-text-fill-color:      transparent;
    width:                        100%;
    max-width:                    300px;
    margin-bottom:                5px;
  }

  p {
    color:                        var(--Shades-40);
    font-size:                    var(--font-size-8);
    font-weight:                  500;
    line-height:                  normal;
    letter-spacing:               1.12px;
    text-transform:               uppercase;
    text-overflow:                ellipsis;
    overflow:                     hidden;
    margin:                       0 6px 0 0;
  }
}

.container {
  display:                        flex;
  flex-direction:                 column;
  align-items:                    center;
  justify-content:                space-between;
  height:                         100%;
  padding-top:                    2rem;
  padding-bottom:                 1rem;

  > img {
    display:                      block;
    margin-left:                  auto;
    margin-right:                 auto;
  }
}

.powered-box {
  p {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    var(--font-size-8);
    font-weight:                  400;
    letter-spacing:               1.52px;
    margin:                       0;
  }
  .logo {
    color:                        #1B294A;
    text-decoration:              none;
    width:                        90px;
    margin:                       0 10px;
    img {
      width:                      100%;
    }
  }
}

@media (max-width: 991px) {
  .container {
    > img {
      width:                      160px;
    }
  }
}

@media (min-width: 1366px) {
  .container {
    max-width:                    calc(100vw - 200px);

    .content {
      width:                      100%;
    }
  }
}
</style>

<script>
import { useExternalStore } from '@/store/external'

export default {
  name: 'external-home',
  data: () => ({
    isRegistered: false,
    active: 'exams',
    session: {
      collaborator: {},
      currentClinic: {},
      patient: {}
    },
    appVersion: process.env.VUE_APP_VERSION,
  }),
  mounted() {
    const externalStore = useExternalStore()
    this.session = externalStore.sessionInfo
    console.log('this.session', this.session);

    // if (this.session && this.session.patient) {
    //   this.$i18n.locale = this.session.patient.locale
    //   console.log("[app] setting locale (from patient):", this.session.patient.locale)
    // }

    //if (this.session.user.email.endsWith("@null.com") || this.session.patient.telephone2 == '') {
    //  this.dataConfirmMenu()
    //} else {
    //this.examsMenu()
    //}
    //YAMA: MY FIX
    this.externalTerms()


  },
  methods: {
    examsMenu: async function() {
      this.goToLink(this, 'exams', 'external-exam-list')
    },
    dataConfirmMenu: async function() {
      this.goToLink(this, 'data', 'external-data-confirm')
    },
    externalTerms: async function() {
      this.goToLink(this, 'data', 'external-terms')
    },
    goToLink(self, active, link) {
      self.active = active
      self.$router.push({name: link, query: { ...self.$route.query, t: Date.now() }})
    },
  }
}
</script>
