<template>
  <div class="dashboard-box" :class="toggleTheme ? 'dash-dark': ''" id="dashboard-box">
    <div class="logo">
      <img src="@/assets/images/erjart/logo_eyer-1.png" alt="">
    </div>

    <div class="container body">
      <div class="main_container">
        <div id="circle-status" :class="isConnected ? 'green' : 'red'" @click="toggleTheme = !toggleTheme"></div>
        <div class="header-box">
          <h1>{{ mutiraoName ? mutiraoName : 'Mutirao phelcom' }}</h1>
          <div class="row">
            <div class="col-md-6">
              <p>
                <small>{{ $t('dateAndTime') }}</small>
                <strong>{{ dateHour }}</strong>
              </p>
            </div>
            <div class="col-md-6">
              <p>
                <small>{{ $t('attendance.lastUpdate') }}</small>
                <strong>{{ lastUpdateMutirao ? lastUpdateMutirao : '&nbsp;' }}</strong>
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <h2 class="subtitle">{{ $t('clinicConfig.referred') }}</h2>
            <div class="donut-box">
              <div class="">
                <apexchart
                  ref="donut"
                  width="350"
                  type="donut"
                  :options="chartDonutOptions"
                  :series="donutSeries"
                ></apexchart>
                <!-- <Doughnut class="doughnut-chart" ref="doughnutChart" :chart-data="chartData" :chart-options="chartOptions" :width="400"/> -->
              </div>
              <div class="title-info">
                <h6>
                  <i class="fa fa-square blue"></i>
                  {{ $t('attendance.referred') }}
                  <span class="float-end">{{ referredCanvas }}</span>
                </h6>
                <h6>
                  <i class="fa fa-square green"></i>
                  {{ $t('attendance.notReferred') }}
                  <span class="float-end">{{ unreferredCanvas }}</span>
                </h6>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <h2 class="subtitle">{{ $t('attendance.performedExams') }}</h2>
            <div class="gauge-box">
              <div class="sidebar-widget">
                <apexchart width="450" type="radialBar" :options="chartGaugeOptions" :series="gaugeSeries"></apexchart>
                <div class="goal-wrapper">
                  <span class="gauge-value pull-left">{{ examinedCountText }}</span>
                  <span class="goal-value pull-right">{{ totalExamCountText }}</span>
                </div>
                <!-- <canvas ref="chartPatient" id="chartPatient" class=""></canvas> -->
              </div>

              <h2 class="patient-info">{{ $t('attendance.patientNumber') }} <strong>{{ totalPatientText }}</strong></h2>
              <h2 class="patient-info">{{ $t('attendance.examNumber') }} <strong>{{ totalExamCountText }}</strong></h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./dashboard-mutirao.scss" lang="scss" scoped></style>
<script src="./dashboard-mutirao.js"></script>
