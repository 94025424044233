const examDataResponse =  [
            {
                "id": 1,
                "uuid": "ba3da028-9647-0ee7-fdbe-e76ebc645224",
                "date": 1575626087000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "",
                "syncTarget": "",
                "localCreatedAt": 1685383559796,
                "localUpdatedAt": 1685383571443,
                "createdAt": "1575629706296",
                "updatedAt": "1575629869566",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 420,
                    "uuid": "6c5c5c46-d2ef-c517-2bfb-d1a30297bf4c",
                    "firstName": "Teste",
                    "lastName": "Letícia ",
                    "rawName": "teste leticia ",
                    "birthday": "",
                    "mrn": "231",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383556966,
                    "localUpdatedAt": 1685383556966,
                    "createdAt": 1575629699473,
                    "updatedAt": 1575629706299,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": null
            },
            {
                "id": 2,
                "uuid": "8a7c0aec-4587-bdf3-1ca6-658659d5f379",
                "date": 1685391305000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01000718.7069043492915249152\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "",
                "localCreatedAt": 1685458274795,
                "localUpdatedAt": 1685461800207,
                "createdAt": "1685391303650",
                "updatedAt": "1685391610879",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3983,
                    "uuid": "2793e9f4-2078-c090-cb03-c1e3a2d6d6fe",
                    "firstName": "718",
                    "lastName": "A.T.",
                    "rawName": "718 a.t.",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383851685,
                    "localUpdatedAt": 1685455629975,
                    "createdAt": 1685377461023,
                    "updatedAt": 1685391303655,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            },
            {
                "id": 3,
                "uuid": "c09d8d19-16ff-c10f-84f9-7bb8387a2ad6",
                "date": 1685377471000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01000718.7068985468271984640\\\"}\",\"maxAlterationScoreOD\":\"LQ\",\"mcRAS\":[0.9577788,0,0,0,0,0,0]}",
                "syncTarget": "",
                "localCreatedAt": 1685458274879,
                "localUpdatedAt": 1685458274879,
                "createdAt": "1685377468496",
                "updatedAt": "1685391298573",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3983,
                    "uuid": "2793e9f4-2078-c090-cb03-c1e3a2d6d6fe",
                    "firstName": "718",
                    "lastName": "A.T.",
                    "rawName": "718 a.t.",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383851685,
                    "localUpdatedAt": 1685455629975,
                    "createdAt": 1685377461023,
                    "updatedAt": 1685391303655,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "os": null,
                    "od": null,
                    "osText": null,
                    "odText": null,
                    "status": "green"
                }
            },
            {
                "id": 4,
                "uuid": "3783fbfc-01db-ef67-c4ae-31d4bf97f125",
                "date": 1684939541000,
                "duration": 0,
                "status": "open",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01002504.7067148660139622400\\\"}\"}",
                "syncTarget": "",
                "localCreatedAt": 1685558187760,
                "localUpdatedAt": 1685558200358,
                "createdAt": "1684939542707",
                "updatedAt": "1684939584063",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3942,
                    "uuid": "75861dd2-846e-2c38-326c-bb20020e00bb",
                    "firstName": "felipe",
                    "lastName": "scopy",
                    "rawName": "felipe scopy",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383849112,
                    "localUpdatedAt": 1685383849112,
                    "createdAt": 1684935374910,
                    "updatedAt": 1684939542711,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "osText": "-",
                    "odText": "-",
                    "status": null
                }
            },
            {
                "id": 5,
                "uuid": "7dfd6c34-443d-b964-af66-ca6d1d6d5282",
                "date": 1684935377000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": true,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01002504.7067131193392627712\\\"}\",\"mcRAS\":[0.9998907,0,0,0,0,0,0]}",
                "syncTarget": "",
                "localCreatedAt": 1685558187827,
                "localUpdatedAt": 1685558187827,
                "createdAt": "1684935377972",
                "updatedAt": "1684935566024",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3942,
                    "uuid": "75861dd2-846e-2c38-326c-bb20020e00bb",
                    "firstName": "felipe",
                    "lastName": "scopy",
                    "rawName": "felipe scopy",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383849112,
                    "localUpdatedAt": 1685383849112,
                    "createdAt": 1684935374910,
                    "updatedAt": 1684939542711,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "os": null,
                    "od": null,
                    "osText": null,
                    "odText": null,
                    "status": "green"
                }
            },
            {
                "id": 6,
                "uuid": "c568c5fd-b6e3-bdfe-70d2-09bcc0d1c708",
                "date": 1685032983000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01002321.7067540582033260544\\\"}\"}",
                "syncTarget": "",
                "localCreatedAt": 1685638989445,
                "localUpdatedAt": 1685639093609,
                "createdAt": "1685032985310",
                "updatedAt": "1685047297179",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3955,
                    "uuid": "e3fde354-2060-d3c7-d4ce-9246babfe99a",
                    "firstName": "wiliam",
                    "lastName": "nakasone",
                    "rawName": "wiliam nakasone",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383850075,
                    "localUpdatedAt": 1685383850075,
                    "createdAt": 1685032974506,
                    "updatedAt": 1685032985315,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "osText": "-",
                    "odText": "-",
                    "status": null
                }
            },
            {
                "id": 7,
                "uuid": "3c053e0f-f074-9dca-d43e-92a7b775e746",
                "date": 1685629429000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01000344.7070042257243504640\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "EYE001",
                "localCreatedAt": 1685639047934,
                "localUpdatedAt": 1685639047934,
                "createdAt": "1685629432126",
                "updatedAt": "1685639063753",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3992,
                    "uuid": "8af24cd7-b4b9-b943-5a98-d691fd366350",
                    "firstName": "Teste A.T.",
                    "lastName": "344",
                    "rawName": "teste a.t. 344",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685558187772,
                    "localUpdatedAt": 1685638978666,
                    "createdAt": 1685540683920,
                    "updatedAt": 1685629432131,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            },
            {
                "id": 8,
                "uuid": "3f37b98d-bbf2-7866-5d4a-cbfa591a8651",
                "date": 1685540685000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01000344.7069670040232001536\\\"}\",\"maxAlterationScoreOD\":\"LQ\",\"mcRAS\":[0.9995916375,0,0,0,0,0,0]}",
                "syncTarget": "",
                "localCreatedAt": 1685639047984,
                "localUpdatedAt": 1685639072106,
                "createdAt": "1685540687254",
                "updatedAt": "1685629428356",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3992,
                    "uuid": "8af24cd7-b4b9-b943-5a98-d691fd366350",
                    "firstName": "Teste A.T.",
                    "lastName": "344",
                    "rawName": "teste a.t. 344",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685558187772,
                    "localUpdatedAt": 1685638978666,
                    "createdAt": 1685540683920,
                    "updatedAt": 1685629432131,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "os": null,
                    "od": null,
                    "osText": null,
                    "odText": null,
                    "status": "green"
                }
            },
            {
                "id": 9,
                "uuid": "de6aaeb2-438b-fb7d-8249-feb7b3d9d77d",
                "date": 1685474882000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01000580.7069394043377025024\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "",
                "localCreatedAt": 1685639076732,
                "localUpdatedAt": 1685639084494,
                "createdAt": "1685474885128",
                "updatedAt": "1685554539745",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3991,
                    "uuid": "4f05edb3-0900-0734-8040-b6e610119985",
                    "firstName": "580 A.T.",
                    "lastName": "Teste",
                    "rawName": "580 a.t. teste",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685477325899,
                    "localUpdatedAt": 1685477325899,
                    "createdAt": 1685474867777,
                    "updatedAt": 1685474885132,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            },
            {
                "id": 10,
                "uuid": "dc11845d-b369-628e-4714-90347d65101a",
                "date": 1684872408000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01002504.7066867081035120640\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "",
                "localCreatedAt": 1685639099473,
                "localUpdatedAt": 1685639105717,
                "createdAt": "1684872410180",
                "updatedAt": "1684872710166",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3931,
                    "uuid": "d17d9841-d303-dca8-8b79-e31e867e27b9",
                    "firstName": "josimar",
                    "lastName": "sartori",
                    "rawName": "josimar sartori",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383848503,
                    "localUpdatedAt": 1685383848503,
                    "createdAt": 1684871735145,
                    "updatedAt": 1684872410185,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            },
            {
                "id": 11,
                "uuid": "f51eb833-2980-c7ff-4d48-c018af88b34d",
                "date": 1684871737000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": true,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01002504.7066864269060472832\\\"}\",\"mcRAS\":[0.8897311699999999,0,0,0,0,0,0]}",
                "syncTarget": "",
                "localCreatedAt": 1685639099521,
                "localUpdatedAt": 1685639116220,
                "createdAt": "1684871738938",
                "updatedAt": "1684872087895",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3931,
                    "uuid": "d17d9841-d303-dca8-8b79-e31e867e27b9",
                    "firstName": "josimar",
                    "lastName": "sartori",
                    "rawName": "josimar sartori",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383848503,
                    "localUpdatedAt": 1685383848503,
                    "createdAt": 1684871735145,
                    "updatedAt": 1684872410185,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "os": null,
                    "od": null,
                    "osText": null,
                    "odText": null,
                    "status": "green"
                }
            },
            {
                "id": 12,
                "uuid": "4fdf0d06-fa7a-1bba-b7df-1679e8150445",
                "date": 1683202346000,
                "duration": 0,
                "status": "open",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01002658.7059862334264573952\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "",
                "localCreatedAt": 1685649010858,
                "localUpdatedAt": 1685649017525,
                "createdAt": "1683202350255",
                "updatedAt": "1683203123539",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3847,
                    "uuid": "9c54410b-e609-b045-6e55-64d28b79c710",
                    "firstName": "2658",
                    "lastName": "Teste",
                    "rawName": "2658 teste",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383843589,
                    "localUpdatedAt": 1685383843589,
                    "createdAt": 1683037457589,
                    "updatedAt": 1683202350258,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            },
            {
                "id": 13,
                "uuid": "2f50e455-4965-0f5a-4139-823df34e0659",
                "date": 1683116954000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01002658.7059504173913473024\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "",
                "localCreatedAt": 1685649010941,
                "localUpdatedAt": 1685649027439,
                "createdAt": "1683116956208",
                "updatedAt": "1683117480620",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3847,
                    "uuid": "9c54410b-e609-b045-6e55-64d28b79c710",
                    "firstName": "2658",
                    "lastName": "Teste",
                    "rawName": "2658 teste",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383843589,
                    "localUpdatedAt": 1685383843589,
                    "createdAt": 1683037457589,
                    "updatedAt": 1683202350258,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            },
            {
                "id": 14,
                "uuid": "651e632f-7ba3-5126-2498-3d2e8e632f1d",
                "date": 1683046944000,
                "duration": 0,
                "status": "open",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01002645.7059210529646051328\\\"}\",\"maxAlterationScoreOD\":\"LQ\",\"mcRAS\":[0,0,0,0,0,0,0.9995939]}",
                "syncTarget": "",
                "localCreatedAt": 1685649024066,
                "localUpdatedAt": 1685649038155,
                "createdAt": "1683046945265",
                "updatedAt": "1683133869110",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3820,
                    "uuid": "1593951a-0097-81a2-eba0-ea9ec3dcf68b",
                    "firstName": "2645",
                    "lastName": "Teste",
                    "rawName": "2645 teste",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383842032,
                    "localUpdatedAt": 1685383842032,
                    "createdAt": 1683024795513,
                    "updatedAt": 1683046945270,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "os": null,
                    "od": null,
                    "osText": null,
                    "odText": null,
                    "status": "red"
                }
            },
            {
                "id": 15,
                "uuid": "34cb6507-5bb6-6d27-8f3f-bee1fb79af0f",
                "date": 1681418185000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01002599.7052379019773214720\\\"}\",\"mcRAS\":[0.9994137750000001,0,0,0,0,0,0]}",
                "syncTarget": "",
                "localCreatedAt": 1685649038711,
                "localUpdatedAt": 1685649048411,
                "createdAt": "1681418186903",
                "updatedAt": "1681418559907",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3739,
                    "uuid": "9d50d285-f4ab-38ff-449b-0dec357128d9",
                    "firstName": "Euzane",
                    "lastName": "anodont",
                    "rawName": "euzane anodont",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383837001,
                    "localUpdatedAt": 1685383837001,
                    "createdAt": 1681418182746,
                    "updatedAt": 1681418186907,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "os": null,
                    "od": null,
                    "osText": null,
                    "odText": null,
                    "status": "green"
                }
            },
            {
                "id": 16,
                "uuid": "a7b42bd3-42e7-7bcd-2df3-1585de51b6f0",
                "date": 1681759742000,
                "duration": 0,
                "status": "open",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01002611.7053811614058807296\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "",
                "localCreatedAt": 1685649048852,
                "localUpdatedAt": 1685649055564,
                "createdAt": "1681759744943",
                "updatedAt": "1681814250743",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3767,
                    "uuid": "cc8075c2-25c0-86fd-17b0-f4745ed4676b",
                    "firstName": "2611",
                    "lastName": "Teste",
                    "rawName": "2611 teste",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383838680,
                    "localUpdatedAt": 1685383838680,
                    "createdAt": 1681384405009,
                    "updatedAt": 1681759744946,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            },
            {
                "id": 17,
                "uuid": "2943cfcb-5a9a-fca0-83a0-c93b0b7cf119",
                "date": 1681496759000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01002611.7052708585091891200\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "",
                "localCreatedAt": 1685649048919,
                "localUpdatedAt": 1685649064700,
                "createdAt": "1681496761733",
                "updatedAt": "1681759738937",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3767,
                    "uuid": "cc8075c2-25c0-86fd-17b0-f4745ed4676b",
                    "firstName": "2611",
                    "lastName": "Teste",
                    "rawName": "2611 teste",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383838680,
                    "localUpdatedAt": 1685383838680,
                    "createdAt": 1681384405009,
                    "updatedAt": 1681759744946,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            },
            {
                "id": 18,
                "uuid": "384f0244-e9aa-d841-4c6d-ba7459b6c16b",
                "date": 1681210214000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01001157.7051506727685980160\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "",
                "localCreatedAt": 1685649072750,
                "localUpdatedAt": 1685649079889,
                "createdAt": "1681210216190",
                "updatedAt": "1681210373068",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3733,
                    "uuid": "fe7fc48a-f005-072f-2ef0-86d759b6be45",
                    "firstName": "Teste A.T.",
                    "lastName": "1157",
                    "rawName": "teste a.t. 1157",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383836626,
                    "localUpdatedAt": 1685383836626,
                    "createdAt": 1679516184777,
                    "updatedAt": 1681210216194,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            },
            {
                "id": 19,
                "uuid": "d657a0a5-ccbf-cbad-0007-28072c144552",
                "date": 1681139258000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01001157.7051209117813178368\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "",
                "localCreatedAt": 1685649072909,
                "localUpdatedAt": 1685649072909,
                "createdAt": "1681139347641",
                "updatedAt": "1681210212099",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3733,
                    "uuid": "fe7fc48a-f005-072f-2ef0-86d759b6be45",
                    "firstName": "Teste A.T.",
                    "lastName": "1157",
                    "rawName": "teste a.t. 1157",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383836626,
                    "localUpdatedAt": 1685383836626,
                    "createdAt": 1679516184777,
                    "updatedAt": 1681210216194,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            },
            {
                "id": 20,
                "uuid": "df90cb4d-eee5-f836-2f60-9cedd1c398a1",
                "date": 1681138255000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01001157.7051204909814775808\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "",
                "localCreatedAt": 1685649073025,
                "localUpdatedAt": 1685649083199,
                "createdAt": "1681138393011",
                "updatedAt": "1681145001923",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3733,
                    "uuid": "fe7fc48a-f005-072f-2ef0-86d759b6be45",
                    "firstName": "Teste A.T.",
                    "lastName": "1157",
                    "rawName": "teste a.t. 1157",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383836626,
                    "localUpdatedAt": 1685383836626,
                    "createdAt": 1679516184777,
                    "updatedAt": 1681210216194,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            },
            {
                "id": 21,
                "uuid": "6a0a6046-45cc-afd2-dd66-008cf8795b5a",
                "date": 1681137971000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01001157.7051203719941062656\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "",
                "localCreatedAt": 1685649073103,
                "localUpdatedAt": 1685649073104,
                "createdAt": "1681137996806",
                "updatedAt": "1681138558916",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3733,
                    "uuid": "fe7fc48a-f005-072f-2ef0-86d759b6be45",
                    "firstName": "Teste A.T.",
                    "lastName": "1157",
                    "rawName": "teste a.t. 1157",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383836626,
                    "localUpdatedAt": 1685383836626,
                    "createdAt": 1679516184777,
                    "updatedAt": 1681210216194,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            },
            {
                "id": 22,
                "uuid": "e0bb94f9-7ed5-4f5c-9352-82a3cc148559",
                "date": 1681126508000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01001157.7051155637866070016\\\"}\",\"maxAlterationScoreOD\":\"LQ\",\"mcRAS\":[0.9991890099999999,0,0,0,0,0,0]}",
                "syncTarget": "",
                "localCreatedAt": 1685649073168,
                "localUpdatedAt": 1685649073168,
                "createdAt": "1681126509371",
                "updatedAt": "1681138260744",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3733,
                    "uuid": "fe7fc48a-f005-072f-2ef0-86d759b6be45",
                    "firstName": "Teste A.T.",
                    "lastName": "1157",
                    "rawName": "teste a.t. 1157",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383836626,
                    "localUpdatedAt": 1685383836626,
                    "createdAt": 1679516184777,
                    "updatedAt": 1681210216194,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "os": null,
                    "od": null,
                    "osText": null,
                    "odText": null,
                    "status": "green"
                }
            },
            {
                "id": 23,
                "uuid": "4b850c99-fe0b-6649-15a0-97c5f7b68aea",
                "date": 1680630617000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01001157.7049075722232528896\\\"}\",\"maxAlterationScoreOD\":\"LQ\",\"mcRAS\":[0.999269695,0,0,0,0,0,0]}",
                "syncTarget": "",
                "localCreatedAt": 1685649073234,
                "localUpdatedAt": 1685649093589,
                "createdAt": "1680630619246",
                "updatedAt": "1681126506342",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3733,
                    "uuid": "fe7fc48a-f005-072f-2ef0-86d759b6be45",
                    "firstName": "Teste A.T.",
                    "lastName": "1157",
                    "rawName": "teste a.t. 1157",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383836626,
                    "localUpdatedAt": 1685383836626,
                    "createdAt": 1679516184777,
                    "updatedAt": 1681210216194,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "os": null,
                    "od": null,
                    "osText": null,
                    "odText": null,
                    "status": "green"
                }
            },
            {
                "id": 24,
                "uuid": "214b161f-6b69-d216-ec5f-f4a2cada6f9a",
                "date": 1679516185000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01000889.7044401456689971200\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "",
                "localCreatedAt": 1685649073320,
                "localUpdatedAt": 1685649073320,
                "createdAt": "1679516187611",
                "updatedAt": "1680630616098",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 3733,
                    "uuid": "fe7fc48a-f005-072f-2ef0-86d759b6be45",
                    "firstName": "Teste A.T.",
                    "lastName": "1157",
                    "rawName": "teste a.t. 1157",
                    "birthday": "",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "totalCount": 24,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383836626,
                    "localUpdatedAt": 1685383836626,
                    "createdAt": 1679516184777,
                    "updatedAt": 1681210216194,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            },
            {
                "id": 25,
                "uuid": "214b161f-6b69-d216-ec5f-f4a2cada6f9a",
                "date": 1679516185000,
                "duration": 0,
                "status": "closed",
                "observation": "",
                "mydriasis": false,
                "examType": "retinography",
                "pristine": true,
                "toRemove": false,
                "hasReport": false,
                "hasImage": true,
                "hasVideo": false,
                "metadata": "{\"dicomParams\":\"{\\\"StudyInstanceUID\\\":\\\"1.2.826.0.1.3680043.10.218.1.1.01000889.7044401456689971200\\\"}\",\"maxAlterationScoreOD\":\"LQ\"}",
                "syncTarget": "",
                "localCreatedAt": 1685649073320,
                "localUpdatedAt": 1685649073320,
                "createdAt": "1679516187611",
                "updatedAt": "1680630616098",
                "sync": true,
                "accessionNumber": "",
                "hasExamData": false,
                "patient": {
                    "id": 644,
                    "uuid": "34024ab9-bf7e-620f-972d-cb815cae9687",
                    "firstName": "000003teste",
                    "lastName": "Teste",
                    "rawName": "000003teste teste",
                    "birthday": "2020-03-10T00:00:00-03:00",
                    "mrn": "",
                    "document0": "",
                    "document1": "",
                    "document2": "",
                    "document3": "",
                    "document4": "",
                    "document5": "",
                    "mothersName": "",
                    "observation": "",
                    "diopter": "{\"right\":{},\"left\":{}}",
                    "height": 0,
                    "weight": 0,
                    "anamnesis": "{}",
                    "gender": "",
                    "telephone1": "",
                    "telephone2": "",
                    "email": "",
                    "address": "",
                    "addressNumber": "",
                    "district": "",
                    "postalCode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "localCreatedAt": 1685383587743,
                    "localUpdatedAt": 1685383587743,
                    "createdAt": 1583850752187,
                    "updatedAt": 1583850756422,
                    "sync": true,
                    "syncTarget": "EYE001",
                    "metadata": "",
                    "toRemove": false
                },
                "scoreResult": {
                    "od": "LQ",
                    "osText": "-",
                    "odText": "LQ",
                    "status": null
                }
            }
        ]
export default examDataResponse;
