<template>
  <div class="filter-box">
    <BaseDateFilter class="d-inline-block" @change-date="setDates"></BaseDateFilter>

    <div class="form-floating v-select-floating">
      <v-select
        label="label"
        class="v-select form"
        id="type"
        :placeholder="$t('exam.type')"
        v-model="filterSelected"
        :reduce="x => x.value"
        :options="filterOptions"
        data-test="vSelectFilter">
      </v-select>
      <label for="type">{{ $t('exam.type') }}</label>
    </div>

    <button class="btn btn-success" type="button" name="button" data-test="btnFilter" @click="doFilter(true)">{{ $t('filter') }}</button>
  </div>
</template>

<style lang="scss" scoped>
  .filter-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .v-select.form {
      min-width: 250px;
    }

    .btn {
      @include                         border_radius(99px);
      font-size:                       var(--font-size-12);
      letter-spacing:                  3.64px;
      text-transform:                  uppercase;
    }

    .btn-success {
      color:                           var(--Shades-W);
      font-weight:                     700;
      height:                          44px;
      padding:                         15px 20px;
      background:                      var(--Brand-Secondary);
      border:                          1px solid var(--Brand-Secondary);
    }
    // button {
    //   margin-left:                  5px;
    //   margin-bottom:                0;
    // }
    // .filter {
    //   width:                        170px !important;
    // }
    // .filter,
    // .d-inline-block {
    //   vertical-align:               bottom;
    // }
  }
</style>

<script>
export default {
  name: 'audit-filter',
  data: () => ({
    filterOptions: [],
    filterSelected: 'ALL',
    filterDates: undefined,
  }),
  mounted: async function() {
    this.filterOptions = [
      {value: 'ALL', label: this.$i18n.t('audit.all')},
      {value: 'LOGIN', label: this.$i18n.t('audit.login')},
      {value: 'LOGOUT', label: this.$i18n.t('audit.logout')},
      {value: 'PATIENT_REMOVED', label: this.$i18n.t('audit.patientRemoved')},
      {value: 'EXAM_REMOVED', label: this.$i18n.t('audit.examRemoved')},
      {value: 'EXAMDATA_REMOVED', label: this.$i18n.t('audit.examDataRemoved')}
    ];
  },
  methods: {
    setDates(val) {
      this.filterDates = val;
    },
    doFilter(newRequest) {
      let filter = {
        startDate: this.filterDates.startDate,
        endDate: this.filterDates.endDate,
        filterSelected: this.filterSelected,
        newRequest: newRequest ? newRequest : false
      };
      this.$emit('do-filter', filter);
    },
  },
  unmounted() {
    this.filterSelected = 'ALL'
    // this.filterDates = undefined
  },
}
</script>
