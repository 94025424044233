import { defineStore } from 'pinia';

export const useExamFilterStore = defineStore('examFilter', {
  state: () => ({
    filter: null, 
  }),
  actions: {
    refreshFilter(newFilterValue) {
      this.filter = newFilterValue;
    },
  },
});
