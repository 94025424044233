<template>
  <div>
    <h1>DEV AREA</h1>
    <hr/>
    <div class="">
      <label for="">teste</label>
      <input class="form-control" type="text" name="" value="" v-maska :data-maska="'##.##'">
    </div>
    <!-- <template v-for="record of records">
      <template v-for="(sessionValue, sessionKey) in record">
        <h2>{{sessionKey}}</h2>
        <template v-for="(entryValue, entryKey) in sessionValue">
          => [{{mainKey(entryKey)}}] {{entryKey}} -> {{entryValue}}
          <br/>
        </template>
        <br/>
      </template>
    </template> -->
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>

export default {
  name: 'dev',
  components: {
  },
  data: () => ({
    records: null,
    titleEntries: []
  }),
  mounted() {
    this.dev()

    this.records = [
      {
        "s1": {
          "e1": "muitas queixas",
          "e2": "pouca historia",
          "e3": "vai falar ou não?",
          "e4_s1": true,
          "e4_s2": true,
          "e4_s3": true,
          "e4_s4": "outros o q?",
          "e4_s5": "muitas alergias...."
        },
        "s2": {
          "e1": "inspecionado...",
          "e2": "go go go"
          },
        "s3": {
        }
      }
    ]
  },
  methods: {
    dev() {
      console.log(">>> dev() >>>")
      console.log(">>> CONTENT >>>", this.records)
    },
    mainKey(value) {
      console.error(">>> mainKey", value)
      const parts = value.split('_')
      return parts.length > 0 ? parts[0] : parts
    }
  }
}
</script>
