<template>
  <div class="clinic-header">
    <img :src="clinic.clinicLogo" alt="" width="180">
    <div class="clinic-header-content">
      <div class="content-data">
        <p>{{ clinic.date }}</p>
        <p>{{ clinic.time }}</p>
      </div>
      <p>{{ clinic.name }}</p>
      <p>
        {{ clinic.address }}
        <span v-if="clinic.addressNumber">, {{ clinic.addressNumber }}</span>
      </p>
      <p>{{ clinic.district }}</p>
      <p>{{ clinic.city }}/{{ clinic.state }}</p>
      <p>{{ clinic.telephone1 ? clinic.telephone1 : clinic.telephone2 }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'ReportClinicHeader',
  props: ['clinic']
}
</script>
