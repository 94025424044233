<template>
  <div class="" v-loader.opacity.tab="loading">
    <div class="title-box">
      <h2 class="title">{{ $t('clinic.editTemplate') }}</h2>
    </div>

    <vee-form v-slot="{ handleSubmit }" as="div">
      <form @submit.prevent.stop="handleSubmit(doSave)" @keydown.enter="$event.preventDefault()">
        <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
          <span v-html="alert.message"></span>
          <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
        </div>

        <div class="clinic-template-box scrollbar">
          <div class="row">
            <div class="col-lg-5">
              <div class="form-floating mb-3">
                <vee-field name="name" v-model="template.name" rules="required" v-slot="{ field, errorMessage }">
                  <input v-bind="field" type="text" class="form-control" ref="name" id="name" :placeholder="$t('name')" data-test="inputTemplateName" autofocus>
                  <span class="vee-error">{{ errorMessage }}</span>
                </vee-field>
                <label for="name">{{ $t('name') }}*</label>
                <!-- <input class="form-control" type="text" name="" ref="name" v-model="name" autofocus :disabled="disabled"> -->
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-floating v-select-floating mb-3">
                <v-select
                class="v-select form"
                id="columnSelected"
                :placeholder="$t('columns')"
                v-model="columnSelected"
                data-test="vSelectTemplateColumn"
                :reduce="x => x.value"
                :options="columnOptions">
              </v-select>
              <label for="columnSelected"># {{ $t('columns') }}*</label>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-floating mb-3">
              <vee-field name="description" v-model="template.description" rules="required" v-slot="{ field, errorMessage }">
                <input v-bind="field" type="text" class="form-control" ref="description" id="description" :placeholder="$t('description')" data-test="inputTemplateDescription" autofocus>
                <span class="vee-error">{{ errorMessage }}</span>
              </vee-field>
              <label for="description">{{ $t('description') }}</label>
              <!-- <input class="form-control" type="text" name="description" v-model="description" :disabled="disabled"> -->
            </div>
          </div>
        </div>

        <div class="">
          <quill-editor
            ref="quillEditor"
            class="editor clinic"
            v-model:content="content"
            data-test="quillEditorTemplate"
            :options="editorOption"
            content-type="html"
            @blue="checkRequired"
            />
            <span class="vee-error" v-show="required">{{ $t('validate.required') }}</span>

            <!-- <div class="content ql-editor" v-html="content"></div> -->
          </div>
        </div>

        <div class="action-bar">
          <button class="btn btn-success" v-if="hasFormChanged" type="submit" name="button" data-test="btnSave">
            <span>{{ $t("save") }}</span>
          </button>
          <button class="btn btn-secondary" type="button" name="button" data-test="btnCancel" @click="$emit('cancel-edit')">
            <span>{{ $t('cancel') }}</span>
          </button>
        </div>
      </form>
    </vee-form>
  </div>
</template>

<style lang="scss" scoped>
.clinic-template-box {
  padding:                      10px;
}

.action-bar {
  height:                       64px;
  padding:                      10px 20px;
  border-top:                   1px solid var(--Shades-30);
  display:                      flex;
  justify-content:              flex-start;
  align-items:                  center;
  gap:                          10px;
}

.btn {
  @include                      border_radius(99px);
  font-size:                    var(--font-size-12);
  font-weight:                  700;
  letter-spacing:               3.64px;
  text-transform:               uppercase;
  height:                       44px;
  padding:                      15px 20px;
}

.btn-success {
  color:                        var(--Shades-W);
  background:                   var(--Brand-Secondary);
  border:                       1px solid var(--Brand-Secondary);
}

.btn-secondary {
  color:                        var(--Brand-Primary);
  background:                   var(--Shades-10);
  border:                       unset;
  // border:                       1px solid var(--Shades-10);
}

.vee-error {
  width:                        100% !important;
}

@media (min-width: 320px) {
  .panel-content .form-group input[type="text"],
  .panel-content .form-group label,
  .panel-content .form-group .form {
    display:                    block !important;
    width:                      100% !important;
  }
}

@media (max-width: 991px) {
  .clinic-template-box {
    height:                     calc(100dvh - 309px);
    overflow-x:                 hidden;
    overflow-y:                 scroll;
  }
}

@media screen and (min-width: 992px) and (max-width: 1365px) {
  .clinic-template-box {
    height:                     calc(100dvh - 337px);
    overflow-x:                 hidden;
    overflow-y:                 scroll;
  }
}
</style>

<script>
const toolbarOptions = [
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }]
];

export default {
  props: ['template_id'],
  name: 'ClinicTemplateEdit',
  components: {
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    disabled: false,
    columnOptions: [],
    columnSelected: '1',
    editorOption: {
      modules: {
         toolbar: toolbarOptions
      },
      theme: 'snow',
    },
    template: {
      id: null,
      name: null,
      description: null,
      columnsDefault: null,
      content: null,
    },
    content: null,
    description: null,
    name: null,
    required: false,
    hasFormChanged: false,
    changedFormItems: [],
    initialForm: {}
  }),
  watch: {
    template_id: {
      immediate: true,
      handler: function (template_id) {
        console.log('template_id', template_id);
        if (template_id) {
          this.fetchData()
        }

        this.$nextTick(() => {
          this.hasFormChanged = false
          this.changedFormItems = []
        })
    }, deep: true },
    template: {
      immediate: true,
      handler: function(newValue) {
        this.checkFormChange(newValue, 'template')
    }, deep: true},
    columnSelected: {
      immediate: true,
      handler: function(newValue) {
        this.checkFormChange(newValue, 'columnsDefault')
    }, deep: true},
    content: {
      immediate: true,
      handler: function(newValue) {
        this.checkFormChange(newValue, 'content')
    }, deep: true},
  },
  computed: {
    editor() {
      return this.$refs.quillEditor.quill
    }
  },
  mounted: function() {
    this.columnOptions = [
      {value: 1, label: this.$i18n.t('exam.oneColumn')},
      {value: 2, label: this.$i18n.t('exam.twoColumn')},
      {value: 3, label: this.$i18n.t('exam.threeColumn')},
      {value: 4, label: this.$i18n.t('exam.fourColumn')}
    ];

    if (this.template_id) {
      this.template.id = this.template_id
      this.disabled = true
      this.fetchData()
    }
  },
  methods: {
    editFields() {
      this.disabled = !this.disabled;
      this.$nextTick(() => {
        this.$refs.name.focus()
      })
    },
    checkFormChange(value, obj) {
      let hasObj = this.changedFormItems.includes(obj)
      if (JSON.stringify(value) === JSON.stringify(this.initialForm[obj])) {
        if (hasObj) {
          let index = this.changedFormItems.indexOf(obj)
          this.changedFormItems.splice(index, 1)
        }

        if (this.changedFormItems.length > 0)
          return

        this.hasFormChanged = false
      } else {
        if (!hasObj)
          this.changedFormItems.push(obj)

        this.hasFormChanged = true
      }
    },
    fetchData: async function() {
      this.alert.message = undefined;

      this.loading = true
      NProgress.start()
      try {
        let params = {
          id: this.template_id,
        }

        let response = await this.$clinicService.getTemplate(params);

        this.setTemplate(response.result)

      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    doSave: async function() {
      console.log('oi');
      this.alert.message = undefined;

      let params = {
        template: {
          name: this.template.name,
          description: this.template.description,
          columnsDefault: this.columnSelected,
          content: this.content,
        }
      }

      if (this.template.id) {
        params.template.id = this.template.id
      }

      if (!this.content) {
        this.checkRequired()
        return
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.saveTemplate(params);

        this.$message.popup(this, 'general.createdSuccess', 'operationSuccess', 'success')
        this.setTemplate(response.result)

        this.disabled = !this.disabled;

        this.$nextTick(() => {
          this.$router.push({name: 'clinic-show', params: {clinicID: response.result.clinic}, query: {tab: 'templates'}})
        })
      } catch(err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
      this.loading = false

    },
    setTemplate(template) {
      this.template = template
      // this.name = this.template.name
      // this.description = this.template.description
      this.columnSelected = this.template.columnsDefault
      this.content = this.template.content
      this.$refs.quillEditor.setHTML(this.content)

      const formWrap = {
        template: template,
        columnsDefault: this.columnSelected,
        content: this.content,
      }

      this.initialForm = _.cloneDeep(formWrap)
    },
    checkRequired() {
      if (!this.content)
        this.required = !this.required
    }
  },
}

</script>
