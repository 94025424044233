export default {
  created: function(el) {
    let overlay = $("<div class='loading-overlay'><div class='spinner'></div></div>");
    $(el).append(overlay).data('overlay-div', overlay);
  },

  updated: function(el, binding) {
    if (binding.value === binding.oldValue) return;

    let $el = $(el);
    let overlay = $el.data('overlay-div');

    if (binding.value)
      overlay.show();

    $el.css('position', 'relative');
    if(binding.modifiers.opacity)
      overlay.addClass('opacity-loading');
    else
      overlay.removeClass('opacity-loading');

    if(binding.modifiers.small)
      overlay.addClass('small-loading');
    else
      overlay.removeClass('small-loading');

    if(binding.modifiers.tab)
      overlay.addClass('tab-loading');
    else
      overlay.removeClass('tab-loading');

    if (!binding.value)
      overlay.hide();
  }
}
