class EyerwebConstants {
    static EVENT_DISCONNECT = 'disconnect'
    static EVENT_JOIN_ROOM = 'join_room'
    static EVENT_SEND_SIGNAL = 'sending_signal'
    static EVENT_RETURN_SIGNAL = 'returning_signal'
    static EVENT_ALL_USERS = 'all_users'
    static EVENT_USER_JOINED = 'user_joined'
    static EVENT_RECEIVING_RETURN_SIGNAL = 'receiving_returned_signal'
    static EVENT_ROOM_FULL = 'room_full'
    static EVENT_QRCODE = 'event_qrcode'
    static EVENT_ERROR_CONNECT = 'connect_error'
    static EVENT_ERROR_FAILED = 'connect_failed'
    static EVENT_SOCKET_ERROR = 'socket_connection_error'
    static EVENT_SOCKET_CONNECTED = 'socket_connected'

    static ACTION_PATIENT_LIST = 'patientList'
    static ACTION_PATIENT_DETAIL = 'patientDetail'
    static ACTION_EXAM_LIST = 'examList'
    static ACTION_EXAM_LIST_FILTERED = 'examListFiltered'
    static ACTION_PATIENT_EXAM_LIST = 'patientExamList'
    static ACTION_CONNECTED = 'connected'

    static GET_PATIENT_LIST = 'getPatientList'
    static GET_PATIENT = 'getPatient'
    static GET_PATIENT_EXAM_LIST = 'getPatientExams'
    static GET_EXAM_LIST = 'getExamList'
    static GET_EXAM_LIST_FILTERED = 'getExamListFiltered'
    static GET_EXAMDATA = 'getExamData'
    static DOWNLOAD_EXAM_DATA = 'downloadExamData'

    static PEER_SIGNAL = 'signal'
    static PEER_DATA = 'data'
    static PEER_SENDING_SIGNAL = 'sending signal'
    static PEER_ERROR = 'error'
    static PEER_CLOSE = 'close'


    static TYPE_JSON = 'json'
    static TYPE_IMAGE = 'image'
    static TYPE_DOWNLOAD = 'download'
    static EYERWEB_SOCKET_SERVER = 'eyerweb.eyercloud.com'
    static EYERWEB_SOCKET_PORT = 443
    static EYERWEB_ICE_SERVERS = [
        /*
        {
            urls: ["stun:stun.l.google.com:19302"],
        },
        */
        
        //{
        //  urls: "stun:coturn.eyercloud.com:80",
        //},
        //{
        //  urls: "stun:coturn.eyercloud.com:80?transport=tcp",
        //},
        //{
        //    urls: "turn:coturn.eyercloud.com:443",
        //    username: "phelcom",
        //    credential: "phelcom123"
        //},
        //{
        //  urls: "turn:coturn.eyercloud.com:443?transport=tcp",
        //  username: "phelcom",
        //  credential: "phelcom123"
        //}
        
          {
            urls: "stun:stun.relay.metered.ca:80",
          },
          {
            urls: "turn:a.relay.metered.ca:80",
            username: "6657291a914f20892b035aca",
            credential: "aSuMgocy1VDzYliq",
          },
          {
            urls: "turn:a.relay.metered.ca:80?transport=tcp",
            username: "6657291a914f20892b035aca",
            credential: "aSuMgocy1VDzYliq",
          },
          {
            urls: "turn:a.relay.metered.ca:443",
            username: "6657291a914f20892b035aca",
            credential: "aSuMgocy1VDzYliq",
          },
          {
            urls: "turn:a.relay.metered.ca:443?transport=tcp",
            username: "6657291a914f20892b035aca",
            credential: "aSuMgocy1VDzYliq",
          }
          
    
    ]
}

export default EyerwebConstants;