import eyerCloud from '@/config/eyercloud'

class ReportService {

  async getList(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/report/list`, params)
    return response.data
  }

  // params: exam id
  async reportViewData(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/report/create`, params)
    return response.data
  }

  // params: report id
  async getReport(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/report/get`, params)
    return response.data
  }

  // params: report object
  async saveReport(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/report/save`, {report: params})
    return response.data
  }

  async getCid(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/report/cid10-search`, params)
    return response.data
  }

  // params: report id
  async deleteReport(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/report/remove`, params)
    return response.data
  }

  // params: report id && images
  async updateReportImages(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/report/update-images`, params)
    return response.data
  }

  async generateReport(params) {
    const BASE_URL = process.env.VUE_APP_API_URL

    let downloadURL = `${BASE_URL}/api/v2/eyercloud/report/generate-report?id=${params.id}`
    window.open(downloadURL)
  }

  async generateUrlPDF(params) {
    const BASE_URL = process.env.VUE_APP_API_URL

    let downloadURL = `${BASE_URL}/api/v2/eyercloud/report/generate-report?id=${params.id}`
    return downloadURL
  }

}
const reportService = new ReportService();
export default reportService;
