export default class User {

    constructor(user = {}) {
        this.mfa = Object.assign({
            email: {},
            mobile: {}
        }, user.mfa)
    }

}
